import { Icon, Skeleton, Stack, Typography } from '@mui/material';
import { useGetAssessmentsGradesForSchoolQuery } from '@schooly/api';
import { DoneIcon, SettingsIcon, SimpleButton, TypographyWithOverflowHint } from '@schooly/style';
import { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';

type AssessmentGradeSelectProps = PropsWithChildren<{
  schoolId: string;
  selectedGradeId?: string;
  isAdmin?: boolean;
  onSelectGradeId: (v: string) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const AssessmentGradeSelect: FC<AssessmentGradeSelectProps> = ({
  schoolId,
  selectedGradeId,
  onSelectGradeId,
  isAdmin,
  placeholder,
  ...dropdownProps
}) => {
  const dropdownRef = useRef<DropdownSelect | null>(null);
  const { $t } = useIntl();
  const { data } = useGetAssessmentsGradesForSchoolQuery(schoolId, {
    refetchOnMount: true,
  });
  const selectedGrade = data?.find((g) => g.id === selectedGradeId);

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const grades = data.filter((g) => !g.archived);

    if (!grades.length)
      return (
        <Stack p={1} alignItems="start" gap={1}>
          <Typography>
            {$t({
              id: isAdmin
                ? 'peopleDetail-EmploymentCaseModal-NoActivePropertiesForSchoolAdmin-grades'
                : 'peopleDetail-EmploymentCaseModal-NoActiveProperties-grades',
            })}
          </Typography>
          {isAdmin && (
            <SimpleButton
              startIcon={<SettingsIcon />}
              sx={{ mb: 1 }}
              onClick={() => window.open('/settings/assessments', '_blank')}
            >
              {$t({
                id: 'peopleDetail-EmploymentCaseModal-SchoolSettings',
              })}
            </SimpleButton>
          )}
        </Stack>
      );

    return (
      <>
        {grades.map((grade) => {
          const isSelected = selectedGradeId === grade.id;

          return (
            <Stack
              key={grade.id}
              onClick={() => {
                onSelectGradeId(grade.id);
                dropdownRef.current?.close();
              }}
              flexDirection="row"
              alignItems="center"
              px={1}
              gap={2}
              py={0.5}
              sx={(theme) => ({
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                },
              })}
            >
              <TypographyWithOverflowHint
                flex={1}
                color={!isSelected ? 'common.grey2' : undefined}
                variant="h3"
                noWrap
              >
                {grade.name}
              </TypographyWithOverflowHint>

              <Icon
                sx={{
                  visibility: isSelected ? 'visible' : 'hidden',
                }}
              >
                <DoneIcon />
              </Icon>
            </Stack>
          );
        })}
      </>
    );
  }, [$t, data, isAdmin, onSelectGradeId, selectedGradeId]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdownRef}
      placeholder={placeholder}
      hasValues={!!selectedGradeId}
      renderContent={renderContent}
    >
      {(opened) => (
        <>
          {selectedGradeId && (
            <Typography variant="h3">
              {selectedGrade?.name || <Skeleton>Value</Skeleton>}
            </Typography>
          )}
        </>
      )}
    </DropdownSelect>
  );
};
