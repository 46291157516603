import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import {
  ApiError,
  CustomField,
  CustomFieldValue,
  getCustomFieldsValuesFoRelation,
  setCustomFieldValueForRelation,
} from '@schooly/api';
import { DateSelect } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form-lts';

import { useProfile } from '../../../../context/profile/useProfile';
import IntlError from '../../../../utils/intlError';
import { PropertyModal2 } from '../PropertyModal2';

export interface CustomFieldDateModalProps {
  onClose: () => void;
  isOpen: boolean;
  field: CustomField;
  value?: CustomFieldValue;
}

interface CustomFieldFormStringData {
  value?: string;
}

export const CustomFieldDateModal: FC<CustomFieldDateModalProps> = ({
  isOpen,
  onClose,
  field,
  value,
}) => {
  const { showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);
  const { required, label } = field;
  const { user, schoolMembership, actions } = useProfile();

  const stringValue = `${value?.value ?? ''}`;

  const form = useForm<CustomFieldFormStringData>({
    mode: 'onChange',
    defaultValues: {
      value: stringValue,
    },
  });

  const {
    reset,
    formState: { isValid },
  } = form;

  const handleSubmit: SubmitHandler<CustomFieldFormStringData> = useCallback(
    async ({ value: newValue }) => {
      if (!schoolMembership) {
        return;
      }

      setIsUpdating(true);

      try {
        const res = await setCustomFieldValueForRelation(
          schoolMembership.relation_id,
          field,
          // empty value is passed as `true` by any reason, looks like react-hook-forms bug
          !newValue || (newValue as unknown as boolean) === true ? null : newValue,
        );

        if (!res) return;

        const newCustomFieldValues = await getCustomFieldsValuesFoRelation(
          schoolMembership.relation_id,
        );
        actions.setCustomFieldValues(newCustomFieldValues);
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, field, onClose, schoolMembership, showError],
  );

  const handleClose = useCallback(() => {
    reset();
    onClose();
  }, [onClose, reset]);

  useEffect(() => {
    reset({ value: stringValue });
  }, [reset, stringValue]);

  return (
    <PropertyModal2
      isOpen={isOpen}
      user={user}
      submitDisabled={!isValid}
      isUpdating={isUpdating}
      onClose={handleClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <Controller
        control={form.control}
        name="value"
        rules={{ required }}
        render={({ field, fieldState }) => {
          return (
            <DateSelect
              ref={field.ref}
              onSetDate={(date) => {
                field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
              }}
              date={field.value}
              placeholder={label}
              requiredLabel={required ? 'required' : 'optional'}
              error={fieldState.error}
              onClear={() => field.onChange('')}
              popperZIndex={(theme) => theme.zIndex.tooltip}
              hideTodayButton
            />
          );
        }}
      />
    </PropertyModal2>
  );
};
