import { Box, Checkbox, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import {
  CustomField,
  CustomFieldDataType,
  CustomFieldPlaceOfUse,
  CustomFieldSelectOption,
  CustomFieldUpdate,
  SelectOption,
} from '@schooly/api';
import { ControlSwitch } from '@schooly/components/form-switch';
import {
  CrossIcon,
  ModalContent,
  ModalHeader,
  ModalMain,
  ModalSmall,
  RadioOnIcon,
  RadioOnIconNegative,
  RadioOnIconPositive,
} from '@schooly/style';
import { FC, useCallback, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import FormSelect2 from '../../../components/ui/Input/FormSelect2';
import { FormCheckboxStyled } from '../../../components/uikit-components/FormCheckbox/FormCheckbox.styled';
import { FormRadioGroup } from '../../../components/uikit-components/FormCheckbox/FormRadioGroup';
import { ControlModalHeaderInput } from '../../../components/uikit-components/Modal/ControlModalHeaderInput';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import {
  APPLICABLE_TO_OPTIONS,
  APPLICATION_PROFILE_OPTIONS,
  REFERS_TO_APPLICATION_OPTIONS,
  REFERS_TO_PROFILE_OPTIONS,
  TYPE_OPTIONS,
} from './constants';
import { SchoolCustomFieldsSelect } from './SchoolCustomFieldsSelect';

type SchoolCustomFieldsModalContentProps = {
  customField?: CustomFieldUpdate;
  isSaving?: boolean;
  isNewItem: boolean;
  isArchiving?: boolean;
  customFields: CustomField[];
  onClose: () => void;
  onSubmit: (v: CustomFieldUpdate) => void;
  onArchiveClick?: () => void;
};

export interface SchoolCustomFieldForm
  extends Omit<CustomField, 'id' | 'select_type_options' | 'place_of_use'> {
  id?: CustomField['id'];
  select_type_options?: (CustomFieldSelectOption & {
    id: string;
  })[];
  place_of_use: string;
}

export const SchoolCustomFieldsModalContent: FC<SchoolCustomFieldsModalContentProps> = ({
  customField: initialCustomField,
  customFields,
  onClose,
  onArchiveClick,
  onSubmit,
  isNewItem,
  isSaving,
  isArchiving,
}) => {
  const { $t, formatMessage } = useIntl();

  const form = useForm<SchoolCustomFieldForm>({
    defaultValues: {
      ...(initialCustomField as SchoolCustomFieldForm),
      place_of_use: initialCustomField?.place_of_use[0] || CustomFieldPlaceOfUse.Application,
      select_type_options: initialCustomField?.select_type_options?.map((option) => ({
        ...option,
        id: uuidv4(),
      })),
      required: initialCustomField?.required ?? false,
    },
    mode: 'onChange',
  });

  const fieldType = form.watch('data_type');
  const placeOfUse = form.watch('place_of_use');
  const referTo = form.watch('applicable_to');
  const required = form.watch('required');

  const currentOptions = useMemo(() => {
    return placeOfUse === CustomFieldPlaceOfUse.Application
      ? REFERS_TO_APPLICATION_OPTIONS
      : REFERS_TO_PROFILE_OPTIONS;
  }, [placeOfUse]);

  const disabled = useMemo(() => {
    if (
      referTo &&
      (referTo.includes(APPLICABLE_TO_OPTIONS[0].value) ||
        referTo.includes(APPLICABLE_TO_OPTIONS[1].value))
    ) {
      return true;
    }

    return false;
  }, [referTo]);

  const handleUsedByClick = useCallback(
    (onChange: (value: CustomFieldPlaceOfUse) => void, optionValue: CustomFieldPlaceOfUse) => () => {
      onChange(optionValue);
    },
    [],
  );

  const handleSelectFieldTypeClose = useCallback(
    (v?: SelectOption<CustomFieldDataType>) => {
      if (v?.labelTextId === TYPE_OPTIONS[4].labelTextId) {
        form.setValue('select_type_options', [
          {
            id: uuidv4(),
            label: '',
            order: 0,
          },
        ]);
      } else {
        form.resetField('select_type_options');
      }
    },
    [form],
  );

  return (
    <ModalSmall open onClose={onClose}>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader
            active
            title={
              <ControlModalHeaderInput
                control={form.control}
                name="label"
                rules={{
                  required: true,
                  validate: (value) => {
                    const isUsed = customFields.filter(
                      (customField) =>
                        customField.id !== initialCustomField?.id &&
                        customField.label.toLowerCase() === value?.toString().toLowerCase(),
                    );

                    if (isUsed.length) {
                      return formatMessage({
                        id: 'school-sections-CustomFields-Message-NameExists',
                      });
                    }

                    return true;
                  },
                }}
                placeholder={$t({ id: 'school-sections-CustomFields-CustomFieldName' })}
                autoFocus
                sx={(theme) => ({
                  '.MuiInputBase-input': {
                    color: form.formState.errors.label
                      ? `${theme.palette.error.main} !important`
                      : theme.palette.primary.main,
                    '&::placeholder': {
                      color: theme.palette.primary.main,
                    },
                  },
                })}
              />
            }
          >
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>

          <ModalMain>
            <ModalContent active>
              <Stack gap={2}>
                <Box>
                  <Typography variant="h2" whiteSpace="nowrap">
                    <FormattedMessage id="school-sections-CustomFields-Create-UsedBy" />
                  </Typography>
                </Box>

                <FormRadioGroup
                  options={APPLICATION_PROFILE_OPTIONS}
                  name="place_of_use"
                  rules={{
                    validate: (value) => (value == null ? $t({ id: 'input-ErrorRequired' }) : true),
                  }}
                  renderOption={(option, { error, value }, field) => {
                    const CheckedIcon = option.checkColor
                      ? option.checkColor === 'POSITIVE'
                        ? RadioOnIconPositive
                        : RadioOnIconNegative
                      : RadioOnIcon;

                    const applicationTooltip =
                      option.labelTextId === APPLICATION_PROFILE_OPTIONS[0].labelTextId && !disabled
                        ? formatMessage({
                            id: 'school-sections-CustomFields-Create-PlaceOfUseApplicationTooltip',
                          })
                        : '';

                    const applicationDisabledTooltip =
                      value === APPLICATION_PROFILE_OPTIONS[1].value &&
                      value !== option.value &&
                      disabled
                        ? formatMessage({
                            id: 'school-sections-CustomFields-Create-PlaceOfUseApplicationDisabledTooltip',
                          })
                        : '';

                    const profileDisabledTooltip =
                      value === APPLICATION_PROFILE_OPTIONS[0].value &&
                      value !== option.value &&
                      disabled
                        ? formatMessage({
                            id: 'school-sections-CustomFields-Create-PlaceOfUseProfileDisabledTooltip',
                          })
                        : '';

                    return (
                      <Tooltip
                        title={
                          applicationTooltip || applicationDisabledTooltip || profileDisabledTooltip
                        }
                      >
                        <FormCheckboxStyled
                          key={`${option.value}`}
                          disabled={disabled}
                          control={
                            <Checkbox
                              name={field.name}
                              checked={option.value === value}
                              onChange={handleUsedByClick(field.onChange, option.value)}
                              checkedIcon={<CheckedIcon className="reset-svg-currentColor" />}
                              data-cy={`checkbox-${field.name}-${option.label}`}
                            />
                          }
                          label={
                            option.labelTextId ? (
                              <FormattedMessage id={option.labelTextId} />
                            ) : (
                              option.label
                            )
                          }
                          error={error}
                          withBorder
                        />
                      </Tooltip>
                    );
                  }}
                />

                <Box>
                  <Typography variant="h2" whiteSpace="nowrap">
                    <FormattedMessage id="school-sections-CustomFields-Create-ReferTo" />
                  </Typography>
                </Box>

                <FormSelect2
                  id="school-sections-CustomFields-Create-ApplicableToPlaceholder"
                  name="applicable_to"
                  labelTextId="school-sections-CustomFields-Create-ApplicableToPlaceholder"
                  options={currentOptions}
                  rules={{ required: true }}
                  multiple
                  required
                  renderOptionsAsTags
                  css={{ '& .form-group__error': { marginTop: '28px' } }}
                />

                <Stack direction="row" alignItems="center" justifyContent="space-between">
                  <Box>
                    <Typography variant="h2" whiteSpace="nowrap">
                      <FormattedMessage id="school-sections-CustomFields-Create-IsRequired" />
                    </Typography>
                  </Box>

                  <Stack direction="row" alignItems="center" justifyContent="center" gap={1}>
                    <Typography
                      variant="h3"
                      color={required ? 'text.secondary' : 'primary.main'}
                      pr={1}
                    >
                      <FormattedMessage id="input-Optional" />
                    </Typography>

                    <ControlSwitch
                      control={form.control}
                      label={
                        <Typography
                          variant="h3"
                          color={required ? 'primary.main' : 'text.secondary'}
                        >
                          {$t({ id: 'input-Required' })}
                        </Typography>
                      }
                      name="required"
                      sx={{
                        '&&& .MuiSwitch-thumb': {
                          backgroundColor: 'primary.main',
                        },
                      }}
                    />
                  </Stack>
                </Stack>

                <Box>
                  <Typography variant="h2" whiteSpace="nowrap">
                    <FormattedMessage id="school-sections-CustomFields-Create-Configure" />
                  </Typography>
                </Box>

                <FormSelect2
                  id="school-sections-CustomFields-AddCustomField-type"
                  name="data_type"
                  labelTextId="school-sections-CustomFields-CustomFieldType"
                  options={TYPE_OPTIONS}
                  rules={{ required: true }}
                  onSelectOption={handleSelectFieldTypeClose}
                />

                {fieldType === CustomFieldDataType.SELECT && <SchoolCustomFieldsSelect />}
              </Stack>
            </ModalContent>
          </ModalMain>

          <ModalFooterWithActions
            isNewItem={isNewItem}
            saving={isSaving}
            archiving={isArchiving}
            onArchiveClick={onArchiveClick}
            showArchiveButton={!isNewItem && !!onArchiveClick}
            active
          />
        </form>
      </FormProvider>
    </ModalSmall>
  );
};
