import { alpha, Box, Skeleton, Stack, Typography, useTheme } from '@mui/material';
import { AttendanceEntriesCounts } from '@schooly/api';
import { HashIcon, PercentIcon, PresentAbsentIcon, randomInt, StatIcon } from '@schooly/style';
import _ from 'lodash';
import { chunk } from 'lodash';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Data } from 'react-minimal-pie-chart/types/commonTypes';

import { useAttendanceCodes } from '../../../hooks/useAttendanceCodes';
import { AttendanceStatsLayout, PercentButton, PercentIconButton } from './AttendanceStats.styled';

interface AttendanceStatsProps {
  statistics: AttendanceEntriesCounts[];
  renderChart: (data: Data, total: string | number) => React.ReactNode;
  totalCount?: number;
  rowsPerColumnCount?: number;
  loading?: boolean;
  precisionForPercent?: number;
  showPercents?: boolean;
  showByPresentAbsent?: boolean;
}

export const AttendanceStats: FC<AttendanceStatsProps> = ({
  statistics,
  totalCount,
  renderChart,
  rowsPerColumnCount,
  loading,
  precisionForPercent = 0,
  showPercents,
  showByPresentAbsent,
}) => {
  const theme = useTheme();

  const [draftShowPercents, setDraftShowPercents] = useState(showPercents ?? false);
  const [draftShowByPresentAbsent, setDraftShowByPresentAbsent] = useState(
    showByPresentAbsent ?? false,
  );

  useEffect(() => {
    setDraftShowByPresentAbsent(showByPresentAbsent ?? false);
  }, [showByPresentAbsent]);

  useEffect(() => {
    setDraftShowPercents(showPercents ?? false);
  }, [showPercents]);

  const { $t } = useIntl();

  const { attendanceCodes: codes, isLoading: codesFetching } = useAttendanceCodes();

  const withoutStatusCount = useMemo(
    () => statistics.filter((s) => !s.attendance_code_id).reduce((acc, s) => acc + s.count, 0),
    [statistics],
  );

  const preparedStatistics = useMemo(() => {
    const withStatus =
      codes
        ?.map((code) => {
          const stats = statistics.filter((s) => s.attendance_code_id === code.id);

          return {
            title: code.name,
            count: stats.reduce((acc, s) => acc + s.count, 0),
          };
        })
        .filter((s) => !!s.count) ?? [];

    return !!withoutStatusCount
      ? [
          ...withStatus,
          {
            title: $t({ id: 'attendance-NoStatus' }),
            count: withoutStatusCount,
          },
        ]
      : withStatus;
  }, [$t, codes, statistics, withoutStatusCount]);

  const presentAbsentStatistics = useMemo(() => {
    const inSchoolCodes = codes?.filter((code) => code.in_school || code.is_present);
    const outOfSchoolCodes = codes?.filter((code) => !code.in_school && !code.is_present);

    const inSchoolCount = statistics
      .filter((s) => inSchoolCodes?.find((code) => code.id === s.attendance_code_id))
      .reduce((acc, s) => acc + s.count, 0);

    const outOfSchoolCount = statistics
      .filter((s) => outOfSchoolCodes?.find((code) => code.id === s.attendance_code_id))
      .reduce((acc, s) => acc + s.count, 0);

    return [
      {
        title: $t({ id: 'attendance-Present' }),
        count: inSchoolCount,
      },
      {
        title: $t({ id: 'attendance-Absent' }),
        count: outOfSchoolCount,
      },
      {
        title: $t({ id: 'attendance-NoStatus' }),
        count: withoutStatusCount,
      },
    ];
  }, [$t, codes, statistics, withoutStatusCount]);

  const countsTotal = useMemo(
    () => preparedStatistics?.reduce((acc, stat) => acc + stat.count ?? 0, 0),
    [preparedStatistics],
  );

  const prepareChartData = useCallback(
    (statistics: { title: string; count: number }[]) => {
      return statistics.map((stat, i) => {
        const value = draftShowPercents
          ? _.round((stat.count / countsTotal) * 100, precisionForPercent)
          : stat.count;
        const color = alpha(
          theme.palette.primary.main,
          (statistics.length - i) / statistics.length,
        );

        return {
          title: stat.title,
          value,
          color,
        };
      });
    },
    [countsTotal, precisionForPercent, draftShowPercents, theme.palette.primary.main],
  );

  const data = useMemo(
    () => prepareChartData(preparedStatistics),
    [prepareChartData, preparedStatistics],
  );

  const presentAbsentData = useMemo(
    () => prepareChartData(presentAbsentStatistics),
    [prepareChartData, presentAbsentStatistics],
  );

  const total = useMemo(() => {
    if (totalCount) return totalCount;
    if (draftShowPercents) return '100%';
    return countsTotal;
  }, [totalCount, draftShowPercents, countsTotal]);

  const columns = chunk(data, rowsPerColumnCount ?? _.round(data.length / 2));
  const shouldRenderSkeleton = codesFetching || loading;

  const renderStatItem = useCallback(
    ({ title, value, color }: { title?: string; value: number; color: string }) =>
      shouldRenderSkeleton ? (
        <Typography variant="h3" color="text.primary">
          <Skeleton variant="text" width={randomInt(100, 150)} />
        </Typography>
      ) : (
        <Stack direction="row" alignItems="center" gap={1.5}>
          <Box
            sx={{
              width: 10,
              height: 10,
              borderRadius: '50%',
              backgroundColor: color,
            }}
          />
          <Typography variant="h3" color="text.primary">
            {title}
          </Typography>
          <Typography variant="h3">{draftShowPercents ? `${value}%` : value}</Typography>
        </Stack>
      ),
    [shouldRenderSkeleton, draftShowPercents],
  );

  if (!statistics.length) return null;

  return (
    <AttendanceStatsLayout>
      <Stack
        direction="row"
        sx={{
          py: (theme) => theme.spacing(2.5),
          pl: (theme) => theme.spacing(2.5),
          gap: (theme) => theme.spacing(5.5),
          alignItems: 'center',
        }}
      >
        {renderChart?.(!draftShowByPresentAbsent ? data : presentAbsentData, total)}

        <Stack direction="row" alignItems="flex-start" justifyContent="space-between" flexGrow={1}>
          {!draftShowByPresentAbsent ? (
            columns.map((colItems, i) => {
              return (
                <Stack whiteSpace="nowrap" sx={{ pr: i !== columns.length - 1 ? 6 : 0, gap: 2.5 }}>
                  {colItems.map(renderStatItem)}
                </Stack>
              );
            })
          ) : (
            <Stack whiteSpace="nowrap" sx={{ gap: 2.5 }}>
              {presentAbsentData.map(renderStatItem)}
            </Stack>
          )}
        </Stack>
      </Stack>

      {(showPercents === undefined || showByPresentAbsent === undefined) && (
        <Stack alignSelf="baseline" p={2.5} direction={'column'} gap={1}>
          {showPercents === undefined && (
            <PercentButton variant="outlined" onClick={() => setDraftShowPercents((val) => !val)}>
              <PercentIconButton>
                {draftShowPercents ? <HashIcon /> : <PercentIcon />}
              </PercentIconButton>
            </PercentButton>
          )}

          {showByPresentAbsent === undefined && (
            <PercentButton
              variant="outlined"
              onClick={() => setDraftShowByPresentAbsent((val) => !val)}
            >
              <PercentIconButton>
                {!draftShowByPresentAbsent ? <PresentAbsentIcon /> : <StatIcon />}
              </PercentIconButton>
            </PercentButton>
          )}
        </Stack>
      )}
    </AttendanceStatsLayout>
  );
};
