import { AssessmentStatuses } from '@schooly/constants';
import { useInfiniteQuery, useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useState } from 'react';

import {
  Assessment,
  AssessmentAction,
  AssessmentEntriesForGroup,
  AssessmentEntry,
  AssessmentForGroup,
  AssessmentForRelation,
  AssessmentsGrade,
  IUpsertDeleteAssessmentEntry,
  ListAssessmentsForGroup,
  ListAssessmentsForRelation,
  ListAssessmentsForSchool,
} from './apiTypes/assessments';
import {
  AssessmentCreateRequest,
  AssessmentCreateResponse,
  AssessmentDeleteRequest,
  AssessmentDeleteResponse,
  AssessmentForGroupExportRequest,
  AssessmentForRelationExportRequest,
  AssessmentSingleExportRequest,
  AssessmentUpdateRequest,
  AssessmentUpdateResponse,
  CreateAssessmentGradeRequest,
  GetAssessmentGroupsRequest,
  GetAssessmentRequest,
  PerformAssessmentActionRequest,
  PerformAssessmentActionResponse,
  UpdateAssessmentGradeRequest,
} from './apiTypes/endpoints/assessments';
import { Group, GroupType } from './apiTypes/groups';
import { ApiError, PagedResponse, SORT_DIRECTION } from './apiTypes/misc';
import {
  RQUseInfiniteQueryOptions,
  RQUseMutationOptions,
  RQUseMutationResult,
  RQUseQueryOptions,
} from './apiTypes/query';
import { RepetitionType } from './apiTypes/recurring';
import { FilterKeys } from './apiTypes/users';
import * as api from './requests';
import { getFormattedParams } from './utils/getFormattedParams';
import { getSortParams } from './utils/getSortParam';
import { removeObjectEmptyArrayValues } from './utils/removeObjectEmptyArrayValues';
import { removeObjectUndefinedNullValues } from './utils/removeObjectUndefinedNullValues';

const ASSESSMENTS_URL = '/assessment/';
const EXPORT_ASSESSMENTS_URL = '/export/assessment';
const LISTS_URL = '/select_list/';
const DEFAULT_PAGE_SIZE = 50;

export function getAssessments({
  filters,
  page_number = 1,
  page_size = DEFAULT_PAGE_SIZE,
  school_id,
  sort = [{ columnTextId: 'name', direction: SORT_DIRECTION.ASC }],
  query,
}: ListAssessmentsForSchool): Promise<PagedResponse<Assessment>> {
  const dateFrom = filters?.date?.[0];
  const dateTo = filters?.date?.[1] || filters?.date?.[0];

  const params = removeObjectUndefinedNullValues({
    page_number,
    page_size,
    assessment_date_from: dateFrom,
    assessment_date_to: dateTo,
    search_query: query,
    sort_by: getSortParams(sort),
    group_ids: filters?.group?.join(','),
    subject_ids: filters?.subject?.join(','),
    staff: filters?.staff?.join(','),
    recurrence_id: filters?.recurrence_id?.[0],
  });

  const formattedParams = getFormattedParams(params, filters, [
    FilterKeys.Date,
    FilterKeys.Group,
    FilterKeys.Subject,
  ]);

  return api.get(`${ASSESSMENTS_URL}for-school/${school_id}`, {
    params: formattedParams,
  });
}

export const GET_ASSESSMENTS_QUERY = `${ASSESSMENTS_URL}GET_ASSESSMENTS_QUERY`;

export const ASSESSMENTS_FILTER_KEYS = [
  FilterKeys.Date,
  FilterKeys.Group,
  FilterKeys.Report,
  FilterKeys.AssessmentStatus,
  FilterKeys.Subject,
  FilterKeys.Staff,
  FilterKeys.RecurrenceId,
  FilterKeys.RepetitionType,
] as const;

export type GetAssessmentsQueryFilters = {
  [FilterKeys.AssessmentStatus]?: AssessmentStatuses[];
  [FilterKeys.Date]?: string[];
  [FilterKeys.Report]?: string[];
  [FilterKeys.Staff]?: string[];
  [FilterKeys.Group]?: string[];
  [FilterKeys.Subject]?: string[];
  [FilterKeys.RecurrenceId]?: string[];
  [FilterKeys.RepetitionType]?: RepetitionType[];
};

export type GetAssessmentsQuerySort = {
  columnTextId: 'name' | 'assessment_date' | 'assessment_status';
  direction: SORT_DIRECTION;
};

export const useGetAssessmentsQuery = (
  initialParams: Omit<ListAssessmentsForSchool, 'filters' | 'sort'> & {
    filters: GetAssessmentsQueryFilters;
    sort?: GetAssessmentsQuerySort;
  },
  options?: RQUseInfiniteQueryOptions<PagedResponse<Assessment>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<Assessment>, ApiError>(
    [GET_ASSESSMENTS_QUERY, { ...params, filters: removeObjectEmptyArrayValues(params.filters) }],
    ({ pageParam }) =>
      getAssessments({
        page_number: pageParam,
        ...params,
        filters: removeObjectEmptyArrayValues(params.filters),
        sort: params.sort ? [params.sort] : undefined,
      }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const CREATE_ASSESSMENT_MUTATION = `${ASSESSMENTS_URL}CREATE_ASSESSMENT_MUTATION`;

export function createAssessment({
  schoolId,
  assessment,
}: AssessmentCreateRequest): Promise<AssessmentCreateResponse> {
  return api.post(`${ASSESSMENTS_URL}for-school/${schoolId}`, assessment);
}

export const useCreateAssessmentMutation = (
  options?: RQUseMutationOptions<AssessmentCreateResponse, AssessmentCreateRequest>,
): RQUseMutationResult<AssessmentCreateResponse, AssessmentCreateRequest> => {
  return useMutation(
    [CREATE_ASSESSMENT_MUTATION],
    (params: AssessmentCreateRequest) => createAssessment(params),
    {
      ...options,
    },
  );
};

export const UPDATE_ASSESSMENT_MUTATION = `${ASSESSMENTS_URL}UPDATE_ASSESSMENT_MUTATION`;

export function updateAssessment({
  assessmentId,
  assessment,
  confirmed,
  withFollowing,
}: AssessmentUpdateRequest): Promise<AssessmentUpdateResponse> {
  const withFollowingParam = withFollowing !== undefined ? `&with_following=${withFollowing}` : '';
  return api.patch(
    `${ASSESSMENTS_URL}${assessmentId}?confirmed=${confirmed}${withFollowingParam}`,
    assessment,
  );
}

export const useUpdateAssessmentMutation = (
  options?: RQUseMutationOptions<AssessmentUpdateResponse, AssessmentUpdateRequest>,
): RQUseMutationResult<AssessmentUpdateResponse, AssessmentUpdateRequest> => {
  return useMutation(
    [UPDATE_ASSESSMENT_MUTATION],
    (params: AssessmentUpdateRequest) => updateAssessment(params),
    options,
  );
};

export function getAssessment({ id, modifyInfo }: GetAssessmentRequest): Promise<Assessment> {
  return api.get(`${ASSESSMENTS_URL}${id}`, {
    params: {
      get_modify_info: modifyInfo || undefined,
    },
  });
}

export const GET_ASSESSMENT_QUERY = `${ASSESSMENTS_URL}/GET_ASSESSMENT_QUERY`;

export const useGetAssessmentQuery = (
  params: GetAssessmentRequest,
  options?: RQUseQueryOptions<Assessment>,
) => {
  return useQuery<Assessment, ApiError>(
    [GET_ASSESSMENT_QUERY, params],
    () => getAssessment(params),
    {
      ...options,
    },
  );
};

export function getAssessmentList(select_list_id: string): Promise<AssessmentsGrade | undefined> {
  return api.get(`${LISTS_URL}${select_list_id}`, {
    params: {
      select_list: '',
    },
  });
}

export const GET_ASSESSMENT_LIST_QUERY = `${ASSESSMENTS_URL}/GET_ASSESSMENT_LIST_QUERY`;

export const useGetAssessmentListQuery = (
  select_list_id: string,
  options?: RQUseQueryOptions<AssessmentsGrade | undefined>,
) => {
  return useQuery<AssessmentsGrade | undefined, ApiError>(
    [GET_ASSESSMENT_LIST_QUERY, select_list_id],
    () => getAssessmentList(select_list_id),
    {
      ...options,
    },
  );
};

export type AssessmentGroupsQueryFilters = {
  [FilterKeys.AgeGroup]?: string[];
  [FilterKeys.StaffHouse]?: string[];
  [FilterKeys.StudentHouse]?: string[];
  [FilterKeys.StaffStatus]?: string[];
  [FilterKeys.StudentStatus]?: string[];
  [FilterKeys.OnlyTutorGroups]?: string[];
  [FilterKeys.Subject]?: string[];
};

export function getAssessmentGroups({
  assessment_id,
  filters: { only_tutor_groups, ...filters },
  pageNumber = 1,
  pageSize = DEFAULT_PAGE_SIZE,
  query,
}: GetAssessmentGroupsRequest): Promise<PagedResponse<Group>> {
  const onlyTutorGroups = only_tutor_groups?.join('') === '1';

  const params = removeObjectUndefinedNullValues({
    page_number: pageNumber,
    page_size: pageSize,
    age_group: filters?.age_group?.join(','),
    subject: onlyTutorGroups ? undefined : filters?.subject?.join(','),
    group_type: onlyTutorGroups ? GroupType.TutorGroup : undefined,
    student_status: filters.student_status?.join(','),
    student_house: filters.student_house?.join(','),
    staff_status: filters.staff_status?.join(','),
    staff_house: filters.staff_house?.join(','),
    query,
  });

  return api.get(`${ASSESSMENTS_URL}${assessment_id}/groups`, {
    params,
  });
}

export const GET_ASSESSMENT_GROUPS_QUERY = `${ASSESSMENTS_URL}/GET_ASSESSMENT_GROUPS_QUERY`;

export const useGetAssessmentGroupsQuery = (
  initialParams: GetAssessmentGroupsRequest,
  options?: RQUseInfiniteQueryOptions<PagedResponse<Group>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<Group>, ApiError>(
    [GET_ASSESSMENT_GROUPS_QUERY, params, JSON.stringify(params.filters)],
    ({ pageParam }) => getAssessmentGroups({ pageNumber: pageParam, ...params }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export function getAssessmentsForGroup({
  groupId,
  dateFrom,
  dateTo,
  query,
  sort = [{ columnTextId: 'name', direction: SORT_DIRECTION.ASC }],
  token,
}: ListAssessmentsForGroup): Promise<AssessmentForGroup[]> {
  return api.get(`${ASSESSMENTS_URL}for-group/${groupId}`, {
    params: {
      assessment_date_from: dateFrom,
      assessment_date_to: dateTo,
      sort_by: getSortParams(sort),
      search_query: query || undefined,
    },
    cancelToken: token,
  });
}

export const GET_ASSESSMENT_FOR_GROUP_QUERY = `${ASSESSMENTS_URL}/GET_ASSESSMENT_FOR_GROUP_QUERY`;

export const useGetAssessmentsForGroupQuery = (
  params: ListAssessmentsForGroup,
  options?: RQUseQueryOptions<AssessmentForGroup[]>,
) => {
  return useQuery<AssessmentForGroup[], ApiError>(
    [GET_ASSESSMENT_FOR_GROUP_QUERY, params],
    () => getAssessmentsForGroup(params),
    {
      ...options,
    },
  );
};

type GetAssessmentsForGroupEntriesParams = {
  group_id: string;
  assessment_ids: string[];
};

export function getAssessmentsForGroupEntries({
  group_id,
  assessment_ids,
}: GetAssessmentsForGroupEntriesParams): Promise<AssessmentEntriesForGroup | undefined> {
  return api.get(`${ASSESSMENTS_URL}for-group/${group_id}/entries`, {
    params: {
      assessment_ids: assessment_ids.join(','),
    },
  });
}

export const GET_ASSESSMENT_FOR_GROUP_ENTRIES_QUERY = `${ASSESSMENTS_URL}/GET_ASSESSMENT_FOR_GROUP_ENTRIES_QUERY`;

export const useGetAssessmentsForGroupEntriesQuery = (
  params: GetAssessmentsForGroupEntriesParams,
  options?: RQUseQueryOptions<AssessmentEntriesForGroup | undefined>,
) => {
  return useQuery<AssessmentEntriesForGroup | undefined, ApiError>(
    [GET_ASSESSMENT_FOR_GROUP_QUERY, params],
    () => getAssessmentsForGroupEntries(params),
    {
      ...options,
    },
  );
};

export function getAssessmentsForRelation({
  subjectIds,
  relationId,
  reportId,
  dateFrom,
  dateTo,
  query,
  sort = [{ columnTextId: 'name', direction: SORT_DIRECTION.ASC }],
  token,
  pageSize = DEFAULT_PAGE_SIZE,
  pageNumber,
}: ListAssessmentsForRelation): Promise<PagedResponse<AssessmentForRelation>> {
  return api.get(`${ASSESSMENTS_URL}for-relation/${relationId}/entries`, {
    params: {
      subject_ids: subjectIds?.length ? subjectIds.join(',') : undefined,
      assessment_date_from: dateFrom,
      assessment_date_to: dateTo,
      sort_by: getSortParams(sort),
      search_query: query || undefined,
      page_size: pageSize,
      page_number: pageNumber,
      report_id: reportId,
    },
    cancelToken: token,
  });
}

export const GET_ASSESSMENTS_FOR_RELATION_QUERY = `${ASSESSMENTS_URL}GET_ASSESSMENTS_FOR_RELATION_QUERY`;

export const useGetAssessmentsForRelationQuery = (
  initialParams: ListAssessmentsForRelation,
  options?: RQUseInfiniteQueryOptions<PagedResponse<AssessmentForRelation>>,
) => {
  const [params, setParams] = useState(initialParams);

  const query = useInfiniteQuery<PagedResponse<AssessmentForRelation>, ApiError>(
    [GET_ASSESSMENTS_FOR_RELATION_QUERY, params],
    ({ pageParam }) => getAssessmentsForRelation({ pageNumber: pageParam, ...params }),
    {
      getNextPageParam: (lastPage) => {
        return !lastPage.total_pages || lastPage.current_page === lastPage.total_pages
          ? undefined
          : lastPage.next_page;
      },
      getPreviousPageParam: (firstPage) => {
        return firstPage.current_page ? firstPage.previous_page : undefined;
      },
      ...options,
    },
  );

  return { ...query, setParams, params };
};

export const OnAssessmentActionEventType = 'OnAssessmentActionEvent';

export class OnAssessmentActionEvent extends Event {
  id: Assessment['id'];
  action: AssessmentAction;

  constructor(id: Assessment['id'], action: AssessmentAction, eventInitDict?: EventInit) {
    super(OnAssessmentActionEventType, eventInitDict);

    this.id = id;
    this.action = action;
  }
}

export const PERFORM_ASSESSMENT_ACTION_MUTATION = `${ASSESSMENTS_URL}PERFORM_ASSESSMENT_ACTION_MUTATION`;

export function performAssessmentAction({
  id,
  action,
}: PerformAssessmentActionRequest): Promise<PerformAssessmentActionResponse> {
  return api.post(`${ASSESSMENTS_URL}${id}/action`, { action });
}

export const usePerformAssessmentActionMutation = (
  options?: RQUseMutationOptions<PerformAssessmentActionResponse, PerformAssessmentActionRequest>,
): RQUseMutationResult<PerformAssessmentActionResponse, PerformAssessmentActionRequest> => {
  return useMutation([PERFORM_ASSESSMENT_ACTION_MUTATION], performAssessmentAction, {
    ...options,
  });
};

export function getAssessmentsGradesForSchool(
  schoolId?: string,
  listIds?: string[],
  pageSize?: number,
  pageNumber?: number,
): Promise<AssessmentsGrade[]> {
  return api.get(`${LISTS_URL}for-school/${schoolId}`, {
    params: {
      page_size: pageSize,
      page_number: pageNumber,
      select_lists: listIds,
    },
  });
}

export const GET_ASSESSMENT_GRADES_FOR_SCHOOL_QUERY = `${ASSESSMENTS_URL}GET_ASSESSMENT_GRADES_FOR_SCHOOL_QUERY`;

export const useGetAssessmentsGradesForSchoolQuery = (
  schoolId: string,
  options?: RQUseQueryOptions<AssessmentsGrade[]>,
) => {
  const queryClient = useQueryClient();

  return useQuery<AssessmentsGrade[], ApiError>(
    [GET_ASSESSMENT_GRADES_FOR_SCHOOL_QUERY, schoolId],
    () => getAssessmentsGradesForSchool(schoolId),
    {
      ...options,
      onSuccess: (data) => {
        for (const grade of data) {
          queryClient.setQueryData([GET_ASSESSMENT_GRADE_QUERY, grade.id], grade);
        }
      },
    },
  );
};

export function getAssessmentsGrade(listId: string): Promise<AssessmentsGrade> {
  return api.get(`${LISTS_URL}${listId}`);
}

export const GET_ASSESSMENT_GRADE_QUERY = `${ASSESSMENTS_URL}GET_ASSESSMENT_GRADE_QUERY`;

export const useGetAssessmentGradeQuery = (
  id: string,
  options?: RQUseQueryOptions<AssessmentsGrade>,
) => {
  return useQuery<AssessmentsGrade, ApiError>(
    [GET_ASSESSMENT_GRADE_QUERY, id],
    () => getAssessmentsGrade(id),
    {
      ...options,
    },
  );
};

export function createAssessmentsGrade({
  schoolId,
  grade,
}: CreateAssessmentGradeRequest): Promise<AssessmentsGrade> {
  const data = {
    ...grade,
    options: grade.options.map((o, i) => ({
      ...o,
      order: i,
    })),
  };

  return api.post(`${LISTS_URL}for-school/${schoolId}`, data);
}

export const useCreateAssessmentsGradeMutation = (
  options?: RQUseMutationOptions<AssessmentsGrade, CreateAssessmentGradeRequest>,
): RQUseMutationResult<AssessmentsGrade, CreateAssessmentGradeRequest> => {
  return useMutation((params: CreateAssessmentGradeRequest) => createAssessmentsGrade(params), {
    ...options,
  });
};

export function updateAssessmentsGrade({
  listId,
  grade,
  gradeOptionOrderStartsFrom = 0,
}: UpdateAssessmentGradeRequest): Promise<AssessmentsGrade> {
  const data = {
    ...grade,
    options: grade.options?.map((o, i) => ({
      ...o,
      order: gradeOptionOrderStartsFrom + i,
    })),
  };

  return api.patch(`${LISTS_URL}${listId}`, data);
}

export const useUpdateAssessmentsGradeMutation = (
  options?: RQUseMutationOptions<AssessmentsGrade, UpdateAssessmentGradeRequest>,
): RQUseMutationResult<AssessmentsGrade, UpdateAssessmentGradeRequest> => {
  return useMutation((params: UpdateAssessmentGradeRequest) => updateAssessmentsGrade(params), {
    ...options,
  });
};

export function archiveAssessmentsGrade(listId: string): Promise<any> {
  return api.remove(`${LISTS_URL}${listId}`);
}

export const useArchiveAssessmentsGradeMutation = (
  options?: RQUseMutationOptions<AssessmentsGrade, string>,
): RQUseMutationResult<AssessmentsGrade, string> => {
  return useMutation((listId: string) => archiveAssessmentsGrade(listId), {
    ...options,
  });
};

interface UpsertDeleteAssessmentEntry {
  success: string;
  assessment_entry?: AssessmentEntry;
}

export function upsertDeleteAssessmentEntry(
  method_id: string,
  recipient_relation_id: string,
  data: IUpsertDeleteAssessmentEntry,
): Promise<UpsertDeleteAssessmentEntry> {
  return api.post(
    `${ASSESSMENTS_URL}entries/for-method/${method_id}/for-relation/${recipient_relation_id}`,
    data,
  );
}

export const DELETE_ASSESSMENT_MUTATION = `${ASSESSMENTS_URL}DELETE_ASSESSMENT_MUTATION`;

export function deleteAssessment({
  assessmentId,
  confirmed,
  withFollowing,
}: AssessmentDeleteRequest): Promise<AssessmentDeleteResponse> {
  const withFollowingParam = withFollowing !== undefined ? `&with_following=${withFollowing}` : '';
  return api.remove(
    `${ASSESSMENTS_URL}${assessmentId}?confirmed=${confirmed}${withFollowingParam}`,
  );
}

export const useDeleteAssessmentMutation = (
  options?: RQUseMutationOptions<AssessmentDeleteResponse, AssessmentDeleteRequest>,
): RQUseMutationResult<AssessmentDeleteResponse, AssessmentDeleteRequest> => {
  return useMutation(
    [DELETE_ASSESSMENT_MUTATION],
    (params: AssessmentDeleteRequest) => deleteAssessment(params),
    {
      ...options,
    },
  );
};

export const exportAssessmentForGroup = ({
  groupId,
  date,
  assessmentIds,
}: AssessmentForGroupExportRequest) => {
  const params = removeObjectUndefinedNullValues({
    date_from: date[0],
    date_to: date[1],
    assessment_ids: assessmentIds?.length ? assessmentIds.join(',') : undefined,
  });

  return api.get(`${EXPORT_ASSESSMENTS_URL}/for-group/${groupId}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/csv',
    },
    params,
  });
};

export const useExportAssessmentForGroupMutation = (
  options?: RQUseMutationOptions<ArrayBuffer, AssessmentForGroupExportRequest>,
): RQUseMutationResult<ArrayBuffer, AssessmentForGroupExportRequest> => {
  return useMutation(
    (params: AssessmentForGroupExportRequest) => exportAssessmentForGroup(params),
    options,
  );
};

export const exportAssessmentForRelation = ({
  relationId,
  schoolYearIds,
}: AssessmentForRelationExportRequest) => {
  return api.get(`${EXPORT_ASSESSMENTS_URL}/for-relation/${relationId}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/csv',
    },
    params: {
      school_year_ids: schoolYearIds.join(','),
    },
  });
};

export const useExportAssessmentForRelationMutation = (
  options?: RQUseMutationOptions<ArrayBuffer, AssessmentForRelationExportRequest>,
): RQUseMutationResult<ArrayBuffer, AssessmentForRelationExportRequest> => {
  return useMutation(
    (params: AssessmentForRelationExportRequest) => exportAssessmentForRelation(params),
    options,
  );
};

export const exportAssessmentSingle = ({
  assessmentId,
  groupIds,
}: AssessmentSingleExportRequest) => {
  return api.get(`${EXPORT_ASSESSMENTS_URL}/single/${assessmentId}`, {
    responseType: 'arraybuffer',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/csv',
    },
    params: {
      group_ids: groupIds.join(','),
    },
  });
};

export const useExportAssessmentSingleMutation = (
  options?: RQUseMutationOptions<ArrayBuffer, AssessmentSingleExportRequest>,
): RQUseMutationResult<ArrayBuffer, AssessmentSingleExportRequest> => {
  return useMutation(
    (params: AssessmentSingleExportRequest) => exportAssessmentSingle(params),
    options,
  );
};
