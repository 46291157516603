import { getUserRolesForRelation, UserRole, UserRoleList } from '@schooly/api';
import { FC, PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import useRequestWithProgress from '../../hooks/useRequestWithProgress';
import { UserRoleForRelationContext } from './UserRoleForRelationContext';

export const WithUserRoleForRelation: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams<'id'>();
  const [relationRoles, setRelationRoles] = useState<UserRoleList>();
  const [allRelationRoles, setAllRelationRoles] = useState<UserRole[]>([]);
  const [init, setInit] = useState(false);

  const loadRoles = useCallback(async () => {
    if (!id) {
      return;
    }
    setInit(true);
    const relationRoles = await getUserRolesForRelation(id);
    setRelationRoles(relationRoles);
    setAllRelationRoles([
      ...relationRoles.available_to_assign,
      ...relationRoles.not_allowed_to_assign,
    ]);
  }, [id]);

  const [loadRelationRoles, isRolesLoading] = useRequestWithProgress(loadRoles);

  useEffect(() => {
    loadRelationRoles();
  }, [loadRelationRoles]);

  const value = {
    relationRoles,
    isRolesLoading: isRolesLoading || !init,
    relationId: id,
    allRelationRoles,
  };

  return (
    <UserRoleForRelationContext.Provider value={value}>
      {children}
    </UserRoleForRelationContext.Provider>
  );
};
