import React, { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

import useAppLocation from '../../../hooks/useAppLocation';
import buildClassName from '../../../utils/buildClassName';

import './index.scss';

interface IProps {
  className?: string;
  icon?: React.ReactNode;
  titleTextId?: string;
  isActive?: boolean;
  link?: string;
  hasIndicator?: boolean;
  onClick?: VoidFunction;
  testId?: string;
}

const NavItem: React.FC<IProps> = ({
  className,
  icon,
  titleTextId,
  isActive,
  link,
  hasIndicator,
  onClick,
  testId,
}) => {
  const { pathname } = useAppLocation();
  const fullClassName = buildClassName('NavItem', className);

  const getActiveClassName = useCallback(
    ({ isActive: isLinkActive }: { isActive?: boolean }) =>
      `btn NavItem__button${isLinkActive ? ' NavItem__button--active' : ''}`,
    [],
  );

  const content = (
    <>
      {icon && <div className="NavItem__icon-wrapper">{icon}</div>}
      {titleTextId && <FormattedMessage id={titleTextId} />}
      {hasIndicator && <div className="NavItem__indicator" />}
    </>
  );

  if (link) {
    return (
      <li data-cy={testId} className={fullClassName}>
        <NavLink
          end
          to={{ pathname: link }}
          state={{ prevPathname: pathname }}
          className={getActiveClassName}
        >
          {content}
        </NavLink>
      </li>
    );
  }

  const buttonClassName = buildClassName(
    'btn NavItem__button',
    isActive && 'NavItem__button--active',
  );

  return (
    <li className={fullClassName}>
      <button type="button" className={buttonClassName} onClick={onClick}>
        {content}
      </button>
    </li>
  );
};

export default NavItem;
