import { Stack, StackProps, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { LoadingIcon } from './LoadingIcon';

export interface LoadingProps extends StackProps {
  messageTextId?: string;
  withText?: boolean;
}

export const Loading: React.FC<LoadingProps> = ({
  messageTextId = 'pleaseWait',
  withText,
  ...props
}) => (
  <Stack gap={3} alignItems="center" justifyContent="center" width="100%" height="100%" {...props}>
    <LoadingIcon />

    {withText && (
      <Typography>
        <FormattedMessage id={messageTextId} />
      </Typography>
    )}
  </Stack>
);
