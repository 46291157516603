import React, { FC, useMemo } from 'react';

import { CloseAccountModal } from '../../components/common/CloseAccountModal/CloseAccountModal';
import DeleteUserModal from '../../components/common/DeleteUserModal';
import PasswordChangeModal from '../../components/common/PasswordChangeModal';
import { UserContexts } from '../../constants/userContexts';
import { useProfile } from '../../context/profile/useProfile';
import { ProfileModalIndividualContext } from './contexts/ProfileModalIndividualContext/ProfileModalIndividualContext';
import { ProfileModalSchoolContext } from './contexts/ProfileModalSchoolContext/ProfileModalSchoolContext';
import { ProfileModalProps } from './ProfileModalProps';

export const ProfileModal: FC<ProfileModalProps> = (props) => {
  const {
    user,
    userType,
    userContext,
    schoolMembership,
    isContextMenuAvailable,
    isPasswordChangeModalOpen,
    isCloseAccountModalOpen,
    isDeleteModalOpen,
    actions,
  } = useProfile();

  const contextMenuModal = useMemo(() => {
    if (!user || !userType || !isContextMenuAvailable) {
      return null;
    }
    return userType === 'profile' ? (
      <>
        <PasswordChangeModal
          isOpen={isPasswordChangeModalOpen}
          onClose={actions.hidePasswordChangeModal}
        />
        <CloseAccountModal
          isOpen={isCloseAccountModalOpen}
          onClose={actions.hideCloseAccountModal}
        />
      </>
    ) : (
      <DeleteUserModal
        isOpen={isDeleteModalOpen}
        onClose={actions.hideDeleteModal}
        user={user}
        schoolMembership={schoolMembership!}
        onUserDelete={actions.handleUserDelete}
        userType={userType!}
        setUserType={actions.setUserType}
        closeProfileModal={actions.handleClose}
      />
    );
  }, [
    actions.handleClose,
    actions.handleUserDelete,
    actions.hideCloseAccountModal,
    actions.hideDeleteModal,
    actions.hidePasswordChangeModal,
    actions.setUserType,
    isCloseAccountModalOpen,
    isContextMenuAvailable,
    isDeleteModalOpen,
    isPasswordChangeModalOpen,
    schoolMembership,
    user,
    userType,
  ]);

  const renderContext = () => {
    switch (userContext) {
      case UserContexts.School:
        return (
          <>
            <ProfileModalSchoolContext {...props} />
            {contextMenuModal}
          </>
        );
      case UserContexts.Individual:
      default:
        return (
          <>
            <ProfileModalIndividualContext {...props}>
              {/* TR-3350 FE Password input does not work in close account modal */}
              {contextMenuModal}
            </ProfileModalIndividualContext>
          </>
        );
    }
  };

  return renderContext();
};
