import { Box, Icon, Stack } from '@mui/material';
import {
  ApplicationCustomField,
  ApplicationDefaultCustomFields,
  CustomFieldDataType,
  DEFAULT_FORMATTED_DATE_FORMAT_FNS,
} from '@schooly/api';
import { GridRowName, TypographyWithOverflowHint } from '@schooly/style';
import { format, parseISO } from 'date-fns';
import { FC } from 'react';
import { useIntl } from 'react-intl';

import { RowsContainer } from '../../RowsContainer';
import { getCustomFieldIcon } from './ApplicationChildrenPreview';
import { ApplicationPreviewInfoRow } from './ApplicationPreviewInfoRow';

interface ApplicationAdditionalInformationPreviewProps {
  customFields?: ApplicationCustomField[];
  editable?: boolean;
  updating?: Record<string, boolean>;
  updatingIcon?: React.ReactNode;
  readonlyIcon?: React.ReactNode;
  withoutActionBackground?: boolean;
  withTitle?: boolean;
  maxWidth?: number;
  onEdit?: () => void;
}

export const ApplicationAdditionalInformationPreview: FC<ApplicationAdditionalInformationPreviewProps> =
  ({
    customFields,
    editable,
    updating,
    updatingIcon,
    readonlyIcon,
    withoutActionBackground,
    withTitle = true,
    maxWidth = 400,
    onEdit,
  }) => {
    const { $t } = useIntl();

    return (
      <RowsContainer containerTitle={withTitle ? 'applications-AdditionalInformation' : undefined}>
        {customFields?.map((c) => (
          <ApplicationPreviewInfoRow
            key={c.custom_field_id}
            onEdit={onEdit}
            editable={editable}
            editIconName="customFields"
            updating={updating?.['customFields']}
            updatingIcon={updatingIcon}
            readonlyIcon={readonlyIcon}
            withoutActionBackground={withoutActionBackground}
          >
            <Stack direction="row" alignItems="center" gap={1} maxWidth={maxWidth} width="100%">
              {[
                ApplicationDefaultCustomFields.BehaviourConcerns,
                ApplicationDefaultCustomFields.LearningDifficulties,
                ApplicationDefaultCustomFields.MedicalNeeds,
                ApplicationDefaultCustomFields.SpecialNeeds,
              ].includes(c.label as ApplicationDefaultCustomFields) ? (
                <Icon>{getCustomFieldIcon(c.label)}</Icon>
              ) : (
                <Box minWidth="50%">
                  <TypographyWithOverflowHint
                    variant="h3"
                    sx={(theme) => ({
                      '&&&.MuiTypography-root': {
                        color: `${theme.palette.text.secondary} !important`,
                      },
                    })}
                  >
                    {c.label}
                  </TypographyWithOverflowHint>
                </Box>
              )}

              <GridRowName minWidth={0}>
                {!!c.value ? (
                  <TypographyWithOverflowHint
                    variant="h3"
                    sx={(theme) => ({
                      '&&.MuiTypography-root': {
                        color:
                          c.data_type === CustomFieldDataType.MEDICAL && c.is_severe
                            ? `${theme.palette.error.main} !important`
                            : undefined,
                      },
                    })}
                  >
                    {(() => {
                      switch (c.data_type) {
                        case CustomFieldDataType.DATE:
                          return format(parseISO(c.value), DEFAULT_FORMATTED_DATE_FORMAT_FNS);
                        case CustomFieldDataType.BOOLEAN:
                          return $t({ id: c.value ? 'yes' : 'no' });
                        default:
                          return c.value;
                      }
                    })()}
                  </TypographyWithOverflowHint>
                ) : (
                  $t({ id: 'no' })
                )}
              </GridRowName>
            </Stack>
          </ApplicationPreviewInfoRow>
        ))}
      </RowsContainer>
    );
  };
