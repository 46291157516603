import {
  ApiError,
  CustomField,
  CustomFieldSelectOption,
  CustomFieldValue,
  getCustomFieldsValuesFoRelation,
  SelectOption,
  setCustomFieldValueForRelation,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form-lts';

import { useProfile } from '../../../../context/profile/useProfile';
import IntlError from '../../../../utils/intlError';
import { PropertyModal2 } from '../PropertyModal2';

export interface CustomFieldSelectModalProps {
  onClose: () => void;
  isOpen: boolean;
  field: CustomField;
  value?: CustomFieldValue;
  options?: CustomFieldSelectOption[];
}

interface CustomFieldFormArrStringData {
  value?: string;
}

export const CustomFieldSelectModal: FC<CustomFieldSelectModalProps> = ({
  isOpen,
  onClose,
  field,
  value,
  options,
}) => {
  const { showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);

  const { user, schoolMembership, actions } = useProfile();

  const selectOptions = useMemo(() => {
    return (
      options?.map<SelectOption>((v) => ({
        value: v.label,
        label: v.label,
      })) || []
    );
  }, [options]);

  const form = useForm<CustomFieldFormArrStringData>({
    mode: 'onChange',
    defaultValues: {
      value: value?.value as string,
    },
  });

  const {
    reset,
    formState: { isValid },
  } = form;

  const handleSubmit: SubmitHandler<CustomFieldFormArrStringData> = useCallback(
    async ({ value: newValue }) => {
      if (!schoolMembership) {
        return;
      }

      setIsUpdating(true);

      try {
        await setCustomFieldValueForRelation(
          schoolMembership.relation_id,
          field,
          // empty value is passed as `true` by any reason, looks like react-hook-forms bug
          !newValue || (newValue as unknown as boolean) === true ? null : newValue,
        );

        const newCustomFieldValues = await getCustomFieldsValuesFoRelation(
          schoolMembership.relation_id,
        );

        actions.setCustomFieldValues(newCustomFieldValues);
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, field, onClose, schoolMembership, showError],
  );

  useEffect(() => {
    const selectedOption = (value?.value as string[])?.[0];
    reset({ value: selectedOption });
  }, [reset, value]);

  return (
    <PropertyModal2
      isOpen={isOpen}
      user={user}
      submitDisabled={!isValid}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <FormSelect2
        name={'value'}
        options={selectOptions}
        labelText={field.label}
        required={field.required}
      />
    </PropertyModal2>
  );
};
