import { Icon, Stack, Typography } from '@mui/material';
import { TaxType } from '@schooly/api';
import { DropdownSelect, DropdownSelectProps } from '@schooly/components/filters';
import { DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import { FC, PropsWithChildren, useCallback, useRef } from 'react';
import { useIntl } from 'react-intl';

type TaxTypeSelectProps = PropsWithChildren<{
  value?: TaxType;
  onSelect: (v: TaxType) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const TaxTypeSelect: FC<TaxTypeSelectProps> = ({
  value,
  onSelect,
  placeholder,
  ...dropdownProps
}) => {
  const dropdownRef = useRef<DropdownSelect | null>(null);
  const { $t } = useIntl();
  const renderContent = useCallback(() => {
    return (
      <>
        {[TaxType.Inclusive, TaxType.Exclusive, TaxType.NoTax].map((v) => {
          const isSelected = value === v;

          return (
            <Stack
              key={String(v)}
              onClick={() => {
                onSelect(v);
                dropdownRef.current?.close();
              }}
              flexDirection="row"
              alignItems="center"
              px={1}
              gap={2}
              py={0.5}
              sx={(theme) => ({
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                },
              })}
            >
              <TypographyWithOverflowHint
                flex={1}
                color={!isSelected ? 'common.grey2' : undefined}
                variant="h3"
                noWrap
              >
                {$t({ id: `products-Tax-${v}` })}
              </TypographyWithOverflowHint>

              <Icon
                sx={{
                  visibility: isSelected ? 'visible' : 'hidden',
                }}
              >
                <DoneIcon />
              </Icon>
            </Stack>
          );
        })}
      </>
    );
  }, [$t, onSelect, value]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdownRef}
      placeholder={placeholder}
      hasValues={value !== undefined}
      renderContent={renderContent}
    >
      {() => (
        <>
          {value !== undefined && (
            <Typography variant="h3">{$t({ id: `products-Tax-${value}` })}</Typography>
          )}
        </>
      )}
    </DropdownSelect>
  );
};
