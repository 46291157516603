import { Button, ButtonProps, IconButton } from '@mui/material';
import { CrossCircleIcon } from '@schooly/style';
import { FC } from 'react';

type FiltersDropdownButtonProps = ButtonProps & { onClear?: () => void };

export const FiltersDropdownButton: FC<FiltersDropdownButtonProps> = ({
  onClear,
  ...buttonProps
}) => {
  return (
    <Button
      size="small"
      sx={(theme) => ({
        '&.MuiButton-containedSizeSmall': {
          ...theme.typography.h3,
          color: 'white',
          padding: theme.spacing(0.25, 0.75),
        },
      })}
      endIcon={
        onClear ? (
          <IconButton
            data-cy={'filter-clear'}
            sx={{
              color: 'white',
              '&:hover': {
                opacity: 0.75,
              },
            }}
            onClick={(e) => {
              e.stopPropagation();
              onClear();
            }}
          >
            <CrossCircleIcon />
          </IconButton>
        ) : undefined
      }
      {...buttonProps}
    />
  );
};
