import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

import {
  ArchiveCustomFieldRequest,
  CreateCustomFieldForSchoolProps,
  CreateCustomFieldForSchoolRequest,
  CreateCustomFieldForSchoolResponse,
  CustomField,
  CustomFieldDataType,
  CustomFieldValue,
  DeleteCustomFieldForSchoolProps,
  DeleteCustomFieldForSchoolResponse,
  GetCustomFieldsForSchoolProps,
  UpdateCustomFieldForSchoolProps,
  UpdateCustomFieldForSchoolResponse,
} from './apiTypes/endpoints/customFields';
import { ApiError, SORT_DIRECTION } from './apiTypes/misc';
import { RQUseMutationOptions, RQUseMutationResult, RQUseQueryOptions } from './apiTypes/query';
import * as api from './requests';
import { getSortParams } from './utils/getSortParam';

const CUSTOM_FIELDS_URL = '/custom_field/';

export const getCustomFieldsForSchool = ({
  schoolId,
  filter,
  sort = [{ columnTextId: 'label', direction: SORT_DIRECTION.ASC }],
}: GetCustomFieldsForSchoolProps): Promise<CustomField[]> => {
  return api.get(`${CUSTOM_FIELDS_URL}for-school/${schoolId}`, {
    params: {
      label: filter?.label ? filter.label.join(', ') : undefined,

      applicable_to: filter?.applicable_to ? filter.applicable_to.join(', ') : undefined,

      place_of_use: filter?.place_of_use ? filter.place_of_use.join(',') : undefined,

      required: filter?.required
        ? filter.required.map((val) => (val ? 1 : 0)).join(', ')
        : undefined,

      searchable: filter?.searchable
        ? filter.searchable.map((val) => (val ? 1 : 0)).join(', ')
        : undefined,

      custom_fields_ids: filter?.custom_fields_ids
        ? filter.custom_fields_ids.join(', ')
        : undefined,

      // TODO: field types are temporary limited while not designed/implemented
      data_type: [
        CustomFieldDataType.NUMBER,
        CustomFieldDataType.STRING,
        CustomFieldDataType.BOOLEAN,
        CustomFieldDataType.DATE,
        CustomFieldDataType.MEDICAL,
        CustomFieldDataType.SELECT,
      ]
        .filter((type) => !filter?.data_type || filter.data_type.includes(type))
        .join(','),

      sort_by: sort ? getSortParams(sort) : undefined,
    },
  });
};

export const GET_CUSTOM_FIELDS_FOR_SCHOOL_QUERY = `${CUSTOM_FIELDS_URL}GET_CUSTOM_FIELDS_FOR_SCHOOL_QUERY`;

export const useGetCustomFieldsForSchoolQuery = (
  params: GetCustomFieldsForSchoolProps,
  options?: RQUseQueryOptions<CustomField[]>,
) => {
  const queryClient = useQueryClient();

  return useQuery<CustomField[], ApiError>(
    [GET_CUSTOM_FIELDS_FOR_SCHOOL_QUERY, params],
    () => getCustomFieldsForSchool(params),
    {
      ...options,
      onSuccess: (data) => {
        for (const customField of data) {
          queryClient.setQueryData(
            [GET_CUSTOM_FIELDS_FOR_SCHOOL_QUERY, customField.id],
            customField,
          );
        }
      },
    },
  );
};

export const getCustomFieldForSchool = (id: string) => {
  return api.get(`${CUSTOM_FIELDS_URL}${id}`);
};

export const GET_CUSTOM_FIELD_FOR_SCHOOL_QUERY = `${CUSTOM_FIELDS_URL}GET_CUSTOM_FIELD_FOR_SCHOOL_QUERY`;

export const useGetCustomFieldForSchoolQuery = (
  id: string,
  options?: RQUseQueryOptions<CustomField>,
) => {
  return useQuery<CustomField, ApiError>(
    [GET_CUSTOM_FIELD_FOR_SCHOOL_QUERY, id],
    () => getCustomFieldForSchool(id),
    options,
  );
};

export const GET_CUSTOM_FIELDS_FOR_PARENT_APPLICATIONS_QUERY = `${CUSTOM_FIELDS_URL}GET_CUSTOM_FIELDS_FOR_PARENT_APPLICATIONS_QUERY`;

export const useGetCustomFieldsForParentApplicationsQuery = (
  params: GetCustomFieldsForSchoolProps,
  options?: RQUseQueryOptions<CustomField[]>,
) => {
  const queryClient = useQueryClient();

  return useQuery<CustomField[], ApiError>(
    [GET_CUSTOM_FIELDS_FOR_PARENT_APPLICATIONS_QUERY, params],
    () => getCustomFieldsForParentApplications(params),
    {
      ...options,
      onSuccess: (data) => {
        for (const customField of data) {
          queryClient.setQueryData(
            [GET_CUSTOM_FIELDS_FOR_PARENT_APPLICATIONS_QUERY, customField.id],
            customField,
          );
        }
      },
    },
  );
};

export const createCustomFieldForSchool = ({
  schoolId,
  data,
}: CreateCustomFieldForSchoolProps): Promise<CreateCustomFieldForSchoolResponse> => {
  const payload: CreateCustomFieldForSchoolRequest = {
    data_type: data.data_type,
    label: data.label,
    place_of_use: data.place_of_use,
    required: data.required,
    is_severe: data.is_severe,
    select_type_options: data.select_type_options,
    searchable: data.searchable ?? false,
    applicable_to: data.applicable_to.join(','),
  };

  return api.post(`${CUSTOM_FIELDS_URL}for-school/${schoolId}`, payload);
};

export const CREATE_CUSTOM_FIELD_FOR_SCHOOL_MUTATION = `${CUSTOM_FIELDS_URL}CREATE_CUSTOM_FIELD_FOR_SCHOOL_MUTATION`;

export const useCreateCustomFieldForSchoolMutation = (
  options?: RQUseMutationOptions<
    CreateCustomFieldForSchoolResponse,
    CreateCustomFieldForSchoolProps
  >,
) => {
  return useMutation(
    [CREATE_CUSTOM_FIELD_FOR_SCHOOL_MUTATION],
    (params: CreateCustomFieldForSchoolProps) => createCustomFieldForSchool(params),
    options,
  );
};

export const updateCustomFieldForSchool = ({
  id,
  data,
}: UpdateCustomFieldForSchoolProps): Promise<UpdateCustomFieldForSchoolResponse> => {
  const payload: CreateCustomFieldForSchoolRequest = {
    data_type: data.data_type,
    label: data.label,
    place_of_use: data.place_of_use,
    required: data.required,
    is_severe: data.is_severe,
    select_type_options: data.select_type_options,
    searchable: data.searchable ?? false,
    applicable_to: data.applicable_to.join(','),
  };

  return api.patch(`${CUSTOM_FIELDS_URL}${id}`, payload);
};

export const UPDATE_CUSTOM_FIELD_FOR_SCHOOL_MUTATION = `${CUSTOM_FIELDS_URL}UPDATE_CUSTOM_FIELD_FOR_SCHOOL_MUTATION`;

export const useUpdateCustomFieldForSchoolMutation = (
  options?: RQUseMutationOptions<
    UpdateCustomFieldForSchoolResponse,
    UpdateCustomFieldForSchoolProps
  >,
): RQUseMutationResult<UpdateCustomFieldForSchoolResponse, UpdateCustomFieldForSchoolProps> => {
  return useMutation(
    [UPDATE_CUSTOM_FIELD_FOR_SCHOOL_MUTATION],
    updateCustomFieldForSchool,
    options,
  );
};

export const deleteCustomField = ({
  id,
  confirmed,
}: DeleteCustomFieldForSchoolProps): Promise<DeleteCustomFieldForSchoolResponse> => {
  return api.remove(`${CUSTOM_FIELDS_URL}${id}${confirmed ? '?confirmed=true' : ''}`);
};

export const DELETE_CUSTOM_FIELD_FOR_SCHOOL_MUTATION = `${CUSTOM_FIELDS_URL}DELETE_CUSTOM_FIELD_FOR_SCHOOL_MUTATION`;

export const useDeleteCustomFieldMutation = (
  options?: RQUseMutationOptions<
    DeleteCustomFieldForSchoolResponse,
    DeleteCustomFieldForSchoolProps
  >,
): RQUseMutationResult<DeleteCustomFieldForSchoolResponse, DeleteCustomFieldForSchoolProps> => {
  return useMutation(
    [DELETE_CUSTOM_FIELD_FOR_SCHOOL_MUTATION],
    (params: DeleteCustomFieldForSchoolProps) => deleteCustomField(params),
    options,
  );
};

export function archiveCustomField({ id, archived }: ArchiveCustomFieldRequest): Promise<any> {
  return api.patch(`${CUSTOM_FIELDS_URL}${id}`, {
    archived,
  });
}

export const useArchiveCustomFieldMutation = (
  options?: RQUseMutationOptions<CustomField, ArchiveCustomFieldRequest>,
): RQUseMutationResult<CustomField, ArchiveCustomFieldRequest> => {
  return useMutation(archiveCustomField, options);
};

export const getCustomFieldsForParentApplications = async ({
  schoolId,
}: GetCustomFieldsForSchoolProps): Promise<CustomField[]> => {
  return api.get(`${CUSTOM_FIELDS_URL}for-school/${schoolId}/public`);
};

export const getCustomFieldsValuesFoRelation = (
  relationId: string,
): Promise<CustomFieldValue[]> => {
  return api.get(`${CUSTOM_FIELDS_URL}value/for-relation/${relationId}`);
};

export const setCustomFieldValueForRelation = (
  relationId: string,
  field: CustomField,
  value?: CustomFieldValue['value'] | null,
) => {
  return api.post(`${CUSTOM_FIELDS_URL}value/for-relation/${relationId}`, [
    field.data_type === CustomFieldDataType.SELECT
      ? {
          custom_field_id: field.id,
          custom_field_value: value ? [value] : [''],
        }
      : {
          custom_field_id: field.id,
          custom_field_value: (Array.isArray(value) ? value.join(',') : value) ?? null,
        },
  ]);
};
