import { IconButton, Stack, Typography } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS } from '@schooly/api';
import { ButtonDateSelect } from '@schooly/components/filters';
import { EditIcon, ExpandIcon, Loading, ModalSearch } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import AccessDenied from '../../../../components/common/AccessDenied';
import { NoSearchResultsFound } from '../../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import {
  ModalContentStickyTitle,
  ModalContentStickyWrapper,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { useAttendanceForGroup } from '../../../../context/attendance/useAttendanceForGroup';
import { GroupPreviewAttendanceEmptyStubNoButton } from './GroupPreviewAttendanceEmptyStub/GroupPreviewAttendanceEmptyStubNoButton';
import { GroupPreviewAttendanceEmptyStubWithButton } from './GroupPreviewAttendanceEmptyStub/GroupPreviewAttendanceEmptyStubWithButton';
import { GroupPreviewModalAttendanceEntries } from './GroupPreviewModalAttendanceEntries/GroupPreviewModalAttendanceEntries';

export const GroupPreviewModalAttendance: FC = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const {
    students,
    query,
    date,
    entries,
    canTakeAttendance,
    fetching,
    canEditAttendance,
    entriesMap,
    setQuery,
    setDate,
    isAttendanceViewer,
  } = useAttendanceForGroup({ refetchOnMount: 'always' });
  const today = format(new Date(), DEFAULT_DATE_FORMAT_FNS);

  const dateObj = useMemo(
    () => newDateTimezoneOffset(date === today ? undefined : date),
    [date, today],
  );

  const handleEditClick = useCallback(() => {
    navigate('attendance');
  }, [navigate]);

  const handleDatePickerChange = useCallback(
    (value: Date | null) => {
      const newDate = value
        ? format(value, DEFAULT_DATE_FORMAT_FNS)
        : format(new Date(), DEFAULT_DATE_FORMAT_FNS);

      if (newDate !== date) {
        setDate(newDate);
      }
    },
    [date, setDate],
  );

  const renderContent = useCallback(() => {
    if (fetching) {
      return (
        <Stack justifyContent="center" sx={{ flex: '1 1 100%' }}>
          <Loading />
        </Stack>
      );
    }

    if (canTakeAttendance && !entries?.relations.length) {
      return (
        <Stack justifyContent="center" sx={{ flex: '1 1 100%' }}>
          {query ? (
            <NoSearchResultsFound type="small" />
          ) : (
            <GroupPreviewAttendanceEmptyStubWithButton />
          )}
        </Stack>
      );
    } else if (entries?.register_id) {
      return <GroupPreviewModalAttendanceEntries students={students} entries={entriesMap ?? {}} />;
    } else {
      return (
        <Stack justifyContent="center" mt={5}>
          <GroupPreviewAttendanceEmptyStubNoButton />
        </Stack>
      );
    }
  }, [
    students,
    canTakeAttendance,
    entries?.register_id,
    entries?.relations.length,
    entriesMap,
    fetching,
    query,
  ]);

  if (!isAttendanceViewer) {
    return <AccessDenied />;
  }

  return (
    <ModalContentStickyWrapper>
      <ModalContentStickyTitle
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            '@media (orientation: portrait)': {
              paddingTop: 3,
            },
          },
        })}
      >
        <Stack direction="row" mb={2.5} gap={1} justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" gap={1}>
            <Typography variant="h2">
              <FormattedMessage id="section-Attendance" />
            </Typography>

            <Link
              to={{
                pathname: 'attendance/statistics',
                search: query ? `?query=${query}` : undefined,
              }}
            >
              <IconButton inverse>
                <ExpandIcon />
              </IconButton>
            </Link>
          </Stack>

          <Stack direction="row" justifyContent="flex-end" alignItems="center" gap={2.5}>
            <ModalSearch
              value={query}
              onChange={setQuery}
              placeholder={formatMessage({ id: 'people-Search' })}
            />

            <ButtonDateSelect
              onSetDate={handleDatePickerChange}
              date={format(dateObj, DEFAULT_DATE_FORMAT_FNS)}
              {...(date !== today && {
                canClear: true,
                onClear: () => handleDatePickerChange(new Date()),
              })}
            />

            {canEditAttendance && (
              <SimpleButton
                sx={{ flex: '0 0 auto' }}
                startIcon={<EditIcon />}
                onClick={handleEditClick}
              >
                <FormattedMessage id="attendance-EditAttendance" />
              </SimpleButton>
            )}
          </Stack>
        </Stack>
      </ModalContentStickyTitle>

      {renderContent()}
    </ModalContentStickyWrapper>
  );
};
