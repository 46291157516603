import { Stack } from '@mui/material';
import { useFlag } from '@schooly/hooks/use-flag';
import { convertCustomFieldsToApplicationCustomFields } from '@schooly/utils/application-helpers';
import isEqual from 'lodash.isequal';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { CreateCustomField } from '../../CreateCustomField.type';
import { CustomFieldsFormContainer } from '../../CustomFieldsFormContainer';
import { PreviewFormType } from '../ApplicationEditablePreviewContent';
import { ApplicationAdditionalInformationPreview } from '../ApplicationPreviewCommon/ApplicationAdditionalInformationPreview';
import { EditDialog } from './EditDialog';

export interface ApplicationEditablePreviewGeneralCustomFieldsProps {
  generalCustomFields: CreateCustomField[];
  onSubmit: (v: CreateCustomField[], type: string) => void;
  canEdit: boolean;
  withNotes?: boolean;
  updating?: Record<string, boolean>;
  updatingIcon?: React.ReactNode;
}

interface CreateGeneralCustomFieldsForm {
  custom_fields: CreateCustomField[];
}

export const ApplicationEditablePreviewGeneralCustomFields: FC<ApplicationEditablePreviewGeneralCustomFieldsProps> =
  ({ generalCustomFields, onSubmit, canEdit, withNotes, updating, updatingIcon }) => {
    const { formatMessage } = useIntl();
    const [previewType, setPreviewType] = useState<PreviewFormType | null>(null);

    const [isFormOpen, showFormModal, hideFormModal] = useFlag();

    useEffect(() => {
      if (previewType && !isFormOpen) {
        setPreviewType(null);
      }
    }, [previewType, isFormOpen]);

    const form = useForm<CreateGeneralCustomFieldsForm>({
      mode: 'onChange',
      values: {
        custom_fields: generalCustomFields,
      },
    });

    const formContent = useMemo(() => {
      switch (previewType) {
        case 'customFields': {
          return <CustomFieldsFormContainer />;
        }

        default:
          return null;
      }
    }, [previewType]);

    useEffect(() => {
      if (!form.formState.isValid) {
        form.trigger();
      }
    }, [form, generalCustomFields]);

    const onEditClick = (editType: PreviewFormType) => {
      setPreviewType(editType);
      showFormModal();
    };

    const normalizedGeneralCustomFields =
      convertCustomFieldsToApplicationCustomFields(generalCustomFields);

    return (
      <FormProvider {...form}>
        <form>
          <Stack flex="1 1 50%" spacing={3}>
            <ApplicationAdditionalInformationPreview
              customFields={normalizedGeneralCustomFields}
              editable={canEdit}
              updating={updating}
              updatingIcon={updatingIcon}
              withTitle={false}
              maxWidth={800}
              onEdit={() => onEditClick('customFields')}
            />
          </Stack>

          <EditDialog
            name={formatMessage({ id: 'userType-general-information' })}
            isOpen={isFormOpen}
            key={`edit-general-info`}
            onClose={() => {
              form.reset({ custom_fields: generalCustomFields });
              hideFormModal();
            }}
            onConfirm={async () => {
              const isValid = await form.trigger();

              if (!isValid) {
                return;
              }

              const updatedCustomFields = form.getValues();

              if (!isEqual(updatedCustomFields, generalCustomFields)) {
                onSubmit(updatedCustomFields.custom_fields || [], previewType ?? '');
              }

              hideFormModal();
            }}
          >
            {formContent}
          </EditDialog>
        </form>
      </FormProvider>
    );
  };
