import { IconButton } from '@mui/material';
import { CrossIcon, Loading, ModalLarge } from '@schooly/style';
import React, { FC } from 'react';

import { CollapsiblePanel } from '../../../../components/common/CollapsiblePanel';
import {
  ModalContent,
  ModalMain,
} from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalAccessDenied } from '../../../../components/uikit-components/Modal/ModalAccessDenied';
import {
  ModalHeaderActions,
  ModalHeaderStyled,
  ModalHeaderTitle,
} from '../../../../components/uikit-components/Modal/ModalHeader.styled';
import { ModalHeaderGoBack } from '../../../../components/uikit-components/Modal/ModalHeaderGoBack';
import { WithAttendanceForRelation } from '../../../../context/attendance/WithAttendanceForRelation';
import { WithConductForRelation } from '../../../../context/conduct/WithConductForRelation';
import { ProfileModalMode } from '../../../../context/profile/helpers';
import { useProfile } from '../../../../context/profile/useProfile';
import { useRouter } from '../../../../context/router/useRouter';
import { WithUserRoleForRelation } from '../../../../context/userRoles/WithUserRoleForRelation';
import { ProfileModalHeader } from '../../ProfileModalHeader/ProfileModalHeader';
import { ProfileModalLeftSidebar } from '../../ProfileModalLeftSidebar';
import { ProfileModalProps } from '../../ProfileModalProps';
import { ProfileModalAbout } from '../../tabs/ProfileModalAbout';
import { ProfileModalAssessments } from '../../tabs/ProfileModalAssessments';
import { ProfileModalAttendance } from '../../tabs/ProfileModalAttendance';
import { ProfileModalChildren } from '../../tabs/ProfileModalChildren';
import { ProfileModalConduct } from '../../tabs/ProfileModalCondcut';
import { ProfileModalConsentForms } from '../../tabs/ProfileModalConsentForms/ProfileModalConsentForms';
import { ProfileModalDependants } from '../../tabs/ProfileModalDependants';
import { ProfileModalEmploymentCases } from '../../tabs/ProfileModalEmploymentCases/ProfileModalEmploymentCases';
import { ProfileModalFamily } from '../../tabs/ProfileModalFamily';
import { ProfileModalGroups } from '../../tabs/ProfileModalGroups';
import { ProfileModalMessages } from '../../tabs/ProfileModalMessages';
import {
  ProfilePayableFeesParent,
  ProfilePayableFeesStudent,
} from '../../tabs/ProfileModalPayableFees';
import { ProfileModalPayers } from '../../tabs/ProfileModalPayers/ProfileModalPayers';
import { ProfileModalRegistrations } from '../../tabs/ProfileModalRegistrations/ProfileModalRegistrations';
import { ProfileModalReports } from '../../tabs/ProfileModalReports';
import { ProfileModalUserRoles } from '../../tabs/ProfileModalUserRoles';

export const ProfileModalSchoolContext: FC<ProfileModalProps> = (props) => {
  const { stack } = useRouter();
  const { user, userType, mode, showLoader, hasAccess, actions } = useProfile();

  const withGoBack = stack.length > 1;

  const renderContent = () => {
    if (!hasAccess) {
      return (
        <>
          <ModalHeaderStyled withBorderBottom={false} withGoBack={withGoBack} active>
            <ModalHeaderTitle />
            <ModalHeaderActions>
              <IconButton onClick={actions.handleClose}>
                <CrossIcon />
              </IconButton>
            </ModalHeaderActions>
            <ModalHeaderGoBack />
          </ModalHeaderStyled>
          <ModalAccessDenied />
        </>
      );
    }

    if (!user || showLoader) {
      return <Loading />;
    }

    return (
      <>
        <ProfileModalHeader />

        <ModalMain>
          <CollapsiblePanel>
            <ProfileModalLeftSidebar />
          </CollapsiblePanel>
          {/* TODO: get rid of deprecated CSS */}
          <ModalContent className="ProfileRightSide" sx={{ pt: 2.75, border: 'none' }}>
            {mode === ProfileModalMode.About && <ProfileModalAbout />}
            {mode === ProfileModalMode.Family &&
              userType &&
              ['child', 'student'].includes(userType) && <ProfileModalFamily />}
            {mode === ProfileModalMode.Family &&
              userType &&
              ['adult', 'parent'].includes(userType) && <ProfileModalChildren />}
            {mode === ProfileModalMode.Registrations && <ProfileModalRegistrations user={user} />}
            {mode === ProfileModalMode.EmploymentCases && (
              <ProfileModalEmploymentCases user={user} />
            )}
            {mode === ProfileModalMode.Groups && <ProfileModalGroups />}
            {mode === ProfileModalMode.Messages && <ProfileModalMessages />}
            {mode === ProfileModalMode.Assessments && <ProfileModalAssessments />}
            {mode === ProfileModalMode.Reports && <ProfileModalReports />}
            {mode === ProfileModalMode.Attendance && (
              <WithAttendanceForRelation>
                <ProfileModalAttendance />
              </WithAttendanceForRelation>
            )}
            {mode === ProfileModalMode.Conduct && (
              <WithConductForRelation>
                <ProfileModalConduct />
              </WithConductForRelation>
            )}
            {mode === ProfileModalMode.UserRoles && (
              <WithUserRoleForRelation>
                <ProfileModalUserRoles />
              </WithUserRoleForRelation>
            )}
            {mode === ProfileModalMode.PayersAndProducts && <ProfileModalPayers />}
            {mode === ProfileModalMode.DependantsAndProducts && <ProfileModalDependants />}
            {mode === ProfileModalMode.PayableFees && userType === 'parent' && user.relation_id && (
              <ProfilePayableFeesParent relationId={user.relation_id} />
            )}
            {mode === ProfileModalMode.PayableFees &&
              userType === 'student' &&
              user.relation_id && <ProfilePayableFeesStudent relationId={user.relation_id} />}

            {mode === ProfileModalMode.ConsentForms && user.relation_id && (
              <ProfileModalConsentForms relationId={user.relation_id} />
            )}
          </ModalContent>
        </ModalMain>
      </>
    );
  };

  // TODO: get rid of deprecated CSS
  return (
    <ModalLarge
      PaperProps={{ className: 'ProfileModal' }}
      onClose={actions.handleClose}
      open
      {...props}
    >
      {renderContent()}
    </ModalLarge>
  );
};
