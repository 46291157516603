import {
  AnySchoolRelation,
  getCustomFieldsValuesFoRelation,
  getEmployments,
  getEnrollments,
  getParentMembership,
  getPrivateImage,
  getStaffMembership,
  getStudentMembership,
  getUser,
  Guardian,
  SchoolUserRelationInBaseUser,
  SyncUser,
  updateSchoolMembership,
  updateSchoolUser,
  updateUser,
  UploadFile,
} from '@schooly/api';
import { ApiError, ParentSchoolRelation, SchoolUserType, UserType } from '@schooly/api';
import { useAuth, useUserContext } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { getIsEditableForProfilePicture } from '../../components/common/ProfileModal/utils';
import { MODAL_FADEOUT_DURATION_MS } from '../../components/ui/Modal';
import { ModalSidebarItem } from '../../components/ui/ModalSidebar';
import { SchoolUserRole } from '../../constants/school';
import { UserContexts } from '../../constants/userContexts';
import {
  getRelationRoleByUserType,
  getRouteModalPathname,
  isProfileUserType,
  isSchoolUserType,
} from '../../helpers/misc';
import { mapRelationToUser } from '../../helpers/school';
import useAppLocation from '../../hooks/useAppLocation';
import useFlag from '../../hooks/useFlag';
import usePrevious from '../../hooks/usePrevious';
import { useProfileSchoolContextType } from '../../hooks/useSegment';
import { clearReactQueryStorageCache } from '../../queryClient';
import { useAppDispatch } from '../../redux/hooks';
import { actions as modalActions } from '../../redux/slices/modalSlice';
import { actions as profileModalActions } from '../../redux/slices/profileModalSlice';
import IntlError from '../../utils/intlError';
import { useLastRefreshTimeContext } from '../LastRefreshTimeContext';
import { CONTEXT_NAME } from '../messages/MessageContext';
import { RouterStateContext, RouterStateContextProps } from '../router/RouterStateContext';
import { useRouter } from '../router/useRouter';
import { getDefaultMode, isHiveMembershipError, ProfileModalMode } from './helpers';
import {
  getInitialState,
  ProfileContext,
  ProfileContextProps,
  ProfileContextState,
  ProfileSchoolRelation,
} from './ProfileContext';

export interface WithProfileProps extends PropsWithChildren {
  // In case we don't want to open modal from router
  id?: string;
  userType: UserType;
  basePath?: string;
  context?: UserContexts;
  onClose?: () => void;
}

export const WithProfile: FC<WithProfileProps> = ({
  id: propId,
  userType: propUserType,
  context,
  basePath,
  onClose,
  children,
}) => {
  const dispatch = useAppDispatch();
  const { id: paramId } = useParams();
  const navigate = useNavigate();
  const location = useAppLocation();
  const { closeAndClean } = useRouter();
  const [searchParams] = useSearchParams();
  const profileSchoolContext = useProfileSchoolContextType();
  const { showError, showNotification } = useNotifications();
  const { updateLastRefreshTime } = useLastRefreshTimeContext();
  const [isDeleteModalOpen, showDeleteModal, hideDeleteModal] = useFlag();
  const [isPasswordChangeModalOpen, showPasswordChangeModal, hidePasswordChangeModal] = useFlag();
  const [isCloseAccountModalOpen, showCloseAccountModal, hideCloseAccountModal] = useFlag();
  const profileId = propId || paramId;

  const [init, setInit] = useState(false);
  const [isFetching, setIsFetching] = useState(true);
  const [isMembershipFetched, setIsMembershipFetched] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [hasAccess, setHasAccess] = useState(true);
  const { logout } = useUserContext();
  const { state, setState, setContextName } = useContext(
    RouterStateContext,
  ) as RouterStateContextProps<ProfileContextState>;
  const invalidateQueries = useInvalidateListQueriesFor(state?.userType || propUserType);

  const prevState = usePrevious(state);

  const { schoolId, currentUser, permissions } = useAuth();

  const mode = getDefaultMode(location);

  const prevUserType = usePrevious(state?.userType);
  const prevMode = usePrevious(mode);

  const suggestedChanges =
    (state?.schoolMembership as unknown as AnySchoolRelation)?.suggested_changes ?? [];

  const userContext = useMemo<UserContexts>(() => {
    if (searchParams.get('context')) {
      return Number(searchParams.get('context'));
    }

    if (context != null) {
      return context;
    }

    return UserContexts.School;
  }, [context, searchParams]);

  const isSchoolContext = userContext === UserContexts.School;
  const isIndividualContext = userContext === UserContexts.Individual;

  // Can be userId or relationId
  const actualId = state?.schoolMembership?.relation_id ?? profileId;
  const isSchoolUser = Boolean(state?.userType && isSchoolUserType(state?.userType));

  const showLoader = Boolean(
    isFetching ||
      !state?.userType ||
      (isSchoolUser && !isMembershipFetched) ||
      (isSchoolUser && !actualId),
  );

  const isChildOfCurrentUser = useMemo(() => {
    const isChildOfUser = Boolean(
      state?.user?.child_associations?.find((a) => a.user_id === currentUser?.user_id),
    );

    return state?.userType === 'child' && isChildOfUser && userContext === UserContexts.Individual;
  }, [currentUser?.user_id, state?.user?.child_associations, state?.userType, userContext]);

  const canEditProfile = Boolean(state?.user?.can_be_edited);

  const hasEditingPermission = useMemo(() => {
    if (isChildOfCurrentUser) {
      return canEditProfile;
    }

    switch (state?.userType) {
      case 'student':
        return permissions.includes('student_manager');
      case 'parent':
        return permissions.includes('parent_manager');
      case 'staff':
        return permissions.includes('staff_manager');
      case 'child':
      case 'adult':
        return permissions.includes('profile_manager');
      case 'profile':
        return true;
      default:
        return false;
    }
  }, [isChildOfCurrentUser, state?.userType, canEditProfile, permissions]);

  const hasViewerPermission = useMemo(() => {
    if (isChildOfCurrentUser) {
      return true;
    }

    switch (state?.userType) {
      case 'student':
        return permissions.includes('student_viewer');
      case 'parent':
        return permissions.includes('parent_viewer');
      case 'staff':
        return permissions.includes('staff_viewer');
      case 'child':
      case 'adult':
        return permissions.includes('profile_viewer');
      case 'profile':
        return true;
      default:
        return false;
    }
  }, [isChildOfCurrentUser, state?.userType, permissions]);

  const isContextMenuAvailable = useMemo(() => {
    if (!state?.userType) {
      return false;
    }

    if (isProfileUserType(state.userType)) {
      return canEditProfile;
    }

    return true;
  }, [state?.userType, canEditProfile]);

  const canEditProfilePicture = useMemo(() => {
    if (!state?.userType) {
      return false;
    }

    if (state?.userType === 'profile') {
      return canEditProfile;
    }

    return getIsEditableForProfilePicture(
      state.userType,
      hasEditingPermission,
      canEditProfile,
      userContext,
    );
  }, [canEditProfile, hasEditingPermission, state?.userType, userContext]);

  const hasMessageOrEventViewer =
    permissions.includes('message_viewer') || permissions.includes('event_viewer');

  const studentTabs = useMemo<ModalSidebarItem<ProfileModalMode>[]>(() => {
    const baseTabs = [
      { id: ProfileModalMode.About, title: 'profile-About' },
    ] as ModalSidebarItem<ProfileModalMode>[];

    if (permissions.includes('student_viewer')) {
      baseTabs.push({ id: ProfileModalMode.Registrations, title: 'profile-Registrations' });
    }

    baseTabs.push({ id: ProfileModalMode.Family, title: 'profile-Family' });

    if (permissions.includes('group_viewer')) {
      baseTabs.push({ id: ProfileModalMode.Groups, title: 'profile-Groups' });
    }

    if (permissions.includes('assessment_viewer')) {
      baseTabs.push(
        { id: ProfileModalMode.Assessments, title: 'profile-Assessments' },
        { id: ProfileModalMode.Reports, title: 'profile-Reports' },
      );
    }

    if (permissions.includes('attendance_viewer')) {
      baseTabs.push({ id: ProfileModalMode.Attendance, title: 'profile-Attendance' });
    }

    if (permissions.includes('conduct_viewer')) {
      baseTabs.push({ id: ProfileModalMode.Conduct, title: 'profile-Conduct' });
    }

    if (permissions.includes('payer_and_product_assignment_viewer')) {
      baseTabs.push({ id: ProfileModalMode.PayersAndProducts, title: 'profile-ProductsAndPayers' });
    }
    if (permissions.includes('payer_and_product_assignment_viewer')) {
      baseTabs.push({ id: ProfileModalMode.PayableFees, title: 'profile-PayableFees' });
    }

    if (permissions.includes('student_viewer') && hasMessageOrEventViewer) {
      baseTabs.push({ id: ProfileModalMode.ConsentForms, title: 'profile-ConsentForms' });
    }

    return baseTabs;
  }, [hasMessageOrEventViewer, permissions]);

  const staffTabs = useMemo<ModalSidebarItem<ProfileModalMode>[]>(() => {
    const baseTabs = [
      { id: ProfileModalMode.About, title: 'profile-About' },
    ] as ModalSidebarItem<ProfileModalMode>[];

    if (permissions.includes('staff_manager')) {
      baseTabs.push({ id: ProfileModalMode.EmploymentCases, title: 'profile-EmploymentCases' });
    }

    if (permissions.includes('group_viewer')) {
      baseTabs.push({ id: ProfileModalMode.Groups, title: 'profile-Groups' });
    }

    if (permissions.includes('message_viewer')) {
      baseTabs.push({ id: ProfileModalMode.Messages, title: 'profile-Messages' });
    }

    if (permissions.includes('userrole_manager') || permissions.includes('userrole_viewer')) {
      baseTabs.push({ id: ProfileModalMode.UserRoles, title: 'profile-UserRoles' });
    }

    return baseTabs;
  }, [permissions]);

  const parentTabs = useMemo<ModalSidebarItem<ProfileModalMode>[]>(() => {
    const baseTabs = [
      { id: ProfileModalMode.About, title: 'profile-About' },
      { id: ProfileModalMode.Family, title: 'profile-Family' },
    ] as ModalSidebarItem<ProfileModalMode>[];

    if (permissions.includes('message_viewer')) {
      baseTabs.push({ id: ProfileModalMode.Messages, title: 'profile-Messages' });
    }

    if (permissions.includes('payer_and_product_assignment_viewer')) {
      baseTabs.push({
        id: ProfileModalMode.DependantsAndProducts,
        title: 'profile-DependantsAndProducts',
      });
    }

    if (permissions.includes('payer_and_product_assignment_viewer')) {
      baseTabs.push({ id: ProfileModalMode.PayableFees, title: 'profile-PayableFees' });
    }

    if (permissions.includes('parent_viewer') && hasMessageOrEventViewer) {
      baseTabs.push({ id: ProfileModalMode.ConsentForms, title: 'profile-ConsentForms' });
    }

    return baseTabs;
  }, [hasMessageOrEventViewer, permissions]);

  const profileTabs = useMemo<ModalSidebarItem<ProfileModalMode>[]>(() => {
    const tabs = [
      { id: ProfileModalMode.About, title: 'profile-About' },
    ] as ModalSidebarItem<ProfileModalMode>[];

    const showFamilyTab =
      state?.userType !== 'adult' ||
      Boolean(
        (state?.user as unknown as ParentSchoolRelation)?.children?.length ||
          state?.user?.adult_associations?.length,
      );

    if (showFamilyTab) {
      tabs.push({ id: ProfileModalMode.Family, title: 'profile-Family' });
    }

    return tabs;
  }, [state?.user, state?.userType]);

  const tabs = useMemo(() => {
    if (!state?.userType) {
      return [];
    }

    switch (state.userType) {
      case 'staff':
        return staffTabs;
      case 'parent':
        return parentTabs;
      case 'student':
        return studentTabs;
      default:
        return profileTabs;
    }
  }, [parentTabs, profileTabs, staffTabs, state?.userType, studentTabs]);

  const setUser = useCallback<ProfileContextProps['actions']['setUser']>(
    (user) => {
      setState({ ...state, user });
    },
    [setState, state],
  );

  const setUserType = useCallback<ProfileContextProps['actions']['setUserType']>(
    (userType) => {
      setState({ ...state, userType });
    },
    [setState, state],
  );

  const setUserContext = useCallback<ProfileContextProps['actions']['setUserContext']>(
    (userContext) => {
      setState({ ...state, userContext });
    },
    [setState, state],
  );

  const setSchoolMembership = useCallback<ProfileContextProps['actions']['setSchoolMembership']>(
    (schoolMembership) => {
      setState({ ...state, schoolMembership });
    },
    [setState, state],
  );

  const setCustomFieldValues = useCallback<ProfileContextProps['actions']['setCustomFieldValues']>(
    (customFieldValues) => {
      setState({ ...state, customFieldValues });
    },
    [setState, state],
  );

  const setRegistrations = useCallback<ProfileContextProps['actions']['setRegistrations']>(
    (registrations) => {
      setState({ ...state, registrations });
    },
    [setState, state],
  );

  const setGroups = useCallback<ProfileContextProps['actions']['setGroups']>(
    (groups) => {
      setState({ ...state, groups });
    },
    [setState, state],
  );

  const setGroupsSchoolYear = useCallback<ProfileContextProps['actions']['setGroupsSchoolYear']>(
    (groupsSchoolYear) => {
      setState({ ...state, groupsSchoolYear });
    },
    [setState, state],
  );

  const handleClose = useCallback(() => {
    if (onClose) {
      onClose();
    } else {
      closeAndClean();
    }
  }, [closeAndClean, onClose]);

  const handleLogOut = useCallback(() => {
    handleClose();

    setTimeout(async () => {
      logout();
      clearReactQueryStorageCache();
      navigate('/');
    }, MODAL_FADEOUT_DURATION_MS);
  }, [handleClose, logout, navigate]);

  const handleUserDelete = useCallback(() => {
    dispatch(modalActions.remove());
  }, [dispatch]);

  const contextActions = useMemo<ProfileContextProps['contextActions']>(() => {
    if (state?.userType === 'profile') {
      return [
        {
          titleTextId: 'login-ChangePassword',
          handler: showPasswordChangeModal,
        },
        {
          titleTextId: 'login-CloseAccount',
          handler: showCloseAccountModal,
        },
        {
          titleTextId: 'login-Logout',
          handler: handleLogOut,
        },
      ];
    }

    if (canEditProfile && hasEditingPermission) {
      return [
        {
          titleTextId: state?.schoolMembership ? 'people-DeregisterPerson' : 'people-DeleteUser',
          handler: showDeleteModal,
        },
      ];
    }

    return [];
  }, [
    state?.userType,
    state?.schoolMembership,
    canEditProfile,
    hasEditingPermission,
    showPasswordChangeModal,
    showCloseAccountModal,
    handleLogOut,
    showDeleteModal,
  ]);

  const updateProfile = useCallback(
    async (update: Partial<SyncUser>) => {
      if (!state?.userType) {
        return;
      }

      setIsUpdating(true);
      try {
        if (userContext === UserContexts.School) {
          const actualId = isSchoolUser ? state.schoolMembership?.user_id : state.user?.user_id;

          const res = await updateSchoolUser(actualId!, schoolId!, update);
          invalidateQueries();
          setIsUpdating(false);
          return res;
        }

        const res = await updateUser(state.user!.user_id, update);

        setUser(res);
        await getPrivateImage(res.profile_picture!);
        invalidateQueries();
        setIsUpdating(false);
        return res;
      } catch (err) {
        showError(err as ApiError | IntlError);
        setIsUpdating(false);
      }

      return undefined;
    },
    [
      invalidateQueries,
      isSchoolUser,
      schoolId,
      setUser,
      showError,
      state?.schoolMembership?.user_id,
      state?.user,
      state?.userType,
      userContext,
    ],
  );

  const updateStaffProfile = useCallback(
    (update: Partial<Guardian>) => {
      if (!state?.user) {
        return;
      }

      setUser({ ...state.user, ...update });
    },
    [setUser, state?.user],
  );

  const updateAvatar = useCallback(
    async (avatar: UploadFile) => {
      if (
        !state?.userType ||
        (isSchoolUser && !state?.schoolMembership) ||
        (!['profile', 'child'].includes(state?.userType) && !state?.user)
      ) {
        console.error('Inconsistent state: Membership or User not loaded');
        return;
      }
      switch (state?.userType) {
        case 'student':
        case 'staff':
        case 'parent': {
          try {
            const res = await updateSchoolMembership(
              state?.schoolMembership!.relation_id,
              state?.userType,
              {
                avatar,
              },
            );

            const relation = (res as any)?.[
              `${state?.userType}_school_relation`
            ] as AnySchoolRelation;

            setUser(mapRelationToUser(relation));

            await getPrivateImage(relation?.profile_picture!);
            if (!res) return;

            let textId = 'confirmation-StudentAvatarEdit';
            if (state?.userType === 'staff') {
              textId = 'confirmation-StaffAvatarEdit';
            }
            if (state?.userType === 'parent') {
              textId = 'confirmation-ParentAvatarEdit';
            }
            invalidateQueries();
            showNotification({
              textId,
              type: 'success',
            });
          } catch (e) {
            showError(e as ApiError | IntlError);
          }
          break;
        }
        case 'profile':
        case 'child': {
          const res = await updateProfile({ avatar });
          if (!res) return;
          showNotification({
            textId: 'confirmation-ProfileAvatarEdit',
            type: 'success',
          });
          break;
        }
        default:
          break;
      }
    },
    [
      invalidateQueries,
      isSchoolUser,
      setUser,
      showError,
      showNotification,
      state?.schoolMembership,
      state?.user,
      state?.userType,
      updateProfile,
    ],
  );

  const getMembership = useCallback(
    (schoolUserType: SchoolUserType) =>
      (currentSchoolId: string, relationId: string): Promise<AnySchoolRelation> => {
        switch (schoolUserType) {
          case 'staff':
            return getStaffMembership(currentSchoolId, relationId);
          case 'parent':
            return getParentMembership(currentSchoolId, relationId);
          default:
            return getStudentMembership(currentSchoolId, relationId);
        }
      },
    [],
  );

  const handleMembershipUpdate = useCallback(
    async (userType?: SchoolUserType, relationId?: string) => {
      if (!schoolId || !profileId || !state) {
        return;
      }

      try {
        const schoolRelation = await getMembership(userType ?? (state.userType as SchoolUserType))(
          schoolId,
          relationId ?? state.schoolMembership?.relation_id!,
        );

        if (!schoolRelation) return;

        const schoolRelations =
          'school_relations' in schoolRelation && schoolRelation.school_relations.length > 1
            ? schoolRelation.school_relations.map((relation) => ({
                ...relation,
                userType: SchoolUserRole[relation.role].toLowerCase() as SchoolUserType,
              }))
            : undefined;

        // don't use setters to prevent state update race
        setState({
          ...state,
          userType: userType ?? state.userType,
          schoolMembership: schoolRelation as unknown as SchoolUserRelationInBaseUser,
          user: mapRelationToUser(schoolRelation),
          schoolRelations,
        });
      } catch (e) {
        if (isHiveMembershipError(e as ApiError)) {
          const userProfile = await getUser(profileId, schoolId);
          if (!userProfile) return;

          setUser(userProfile);
          showError(e as ApiError);
        }
      }

      updateLastRefreshTime();
      dispatch(profileModalActions.setHasBeenUpdated(true));
    },
    [
      schoolId,
      profileId,
      state,
      updateLastRefreshTime,
      dispatch,
      getMembership,
      setState,
      setUser,
      showError,
    ],
  );

  const handleUserUpdate = useCallback(async () => {
    if (!state?.userType || !profileId) {
      return;
    }

    setIsUpdating(true);
    invalidateQueries();
    if (state.userType === 'profile') {
      const profileUser = await getUser(profileId);

      if (!profileUser) return;

      setUser(profileUser);
      setIsUpdating(false);
      return;
    }

    if (state.userType === 'child' && userContext === UserContexts.Individual) {
      const userProfile = await getUser(profileId);
      if (!userProfile) return;
      setUser(userProfile);
      setIsUpdating(false);
      return;
    }

    if (!schoolId) {
      setIsUpdating(false);
      return;
    }

    if (isProfileUserType(state.userType)) {
      const userProfile = await getUser(profileId, schoolId);
      if (!userProfile) return;
      setUser(userProfile);
      updateLastRefreshTime();
    } else if (isSchoolUser) {
      await handleMembershipUpdate();
      setIsUpdating(false);

      return;
    }

    dispatch(profileModalActions.setHasBeenUpdated(true));
    setIsUpdating(false);
  }, [
    state?.userType,
    profileId,
    userContext,
    schoolId,
    isSchoolUser,
    invalidateQueries,
    dispatch,
    setUser,
    updateLastRefreshTime,
    handleMembershipUpdate,
  ]);

  const setMode = useCallback(
    (mode: ProfileModalMode) => {
      navigate({ hash: mode === ProfileModalMode.About ? '' : `#${mode}` });
    },
    [navigate],
  );

  const handleSelectUserType = useCallback<ProfileContextProps['actions']['handleSelectUserType']>(
    (newUserType) => {
      if (!state?.user) {
        return;
      }

      setUserType(newUserType);
      setMode(ProfileModalMode.About);

      navigate(getRouteModalPathname(newUserType, state?.user));
    },
    [navigate, setMode, setUserType, state?.user],
  );

  const request = useCallback(async () => {
    if (profileId) {
      setIsFetching(true);

      const initialState = getInitialState();

      if (
        (propUserType === 'child' || propUserType === 'profile') &&
        userContext === UserContexts.Individual
      ) {
        try {
          const userProfile = await getUser(profileId);
          if (!userProfile) throw new Error('Access denied');
          initialState.user = userProfile;
          initialState.userType = propUserType;

          setState(initialState);
        } catch (err) {
          showError(err as ApiError);
          setHasAccess(false);
        }

        setIsFetching(false);
        return;
      }

      if (!schoolId) {
        setState(initialState);
        setIsFetching(false);
        return;
      }

      if (isSchoolUserType(propUserType)) {
        initialState.userType = propUserType;

        try {
          const schoolRelation = await getMembership(propUserType as SchoolUserType)(
            schoolId,
            profileId,
          );

          // todo: add uniqueUserTypes without getUser req
          initialState.schoolMembership = schoolRelation as unknown as SchoolUserRelationInBaseUser;
          initialState.user = mapRelationToUser(schoolRelation);

          if ('school_relations' in schoolRelation && schoolRelation.school_relations.length > 1) {
            const schoolRelations: ProfileSchoolRelation[] = schoolRelation.school_relations.map(
              (rel) => ({
                ...rel,
                userType: SchoolUserRole[rel.role].toLowerCase() as SchoolUserType,
              }),
            );
            initialState.schoolRelations = schoolRelations;
          }

          if (initialState.schoolMembership) {
            initialState.customFieldValues = await getCustomFieldsValuesFoRelation(
              initialState.schoolMembership.relation_id,
            );
          }
        } catch (err) {
          setState(initialState);
          showError(err as ApiError);
          setHasAccess(false);
          setIsFetching(false);
          setIsMembershipFetched(true);
          return;
        } finally {
          setIsMembershipFetched(true);
        }
      }

      if (isProfileUserType(propUserType)) {
        try {
          const userProfile = await getUser(profileId, schoolId);

          initialState.user = userProfile;
          initialState.userType = undefined;

          let role = getRelationRoleByUserType(propUserType, profileSchoolContext);
          let currentRelation = userProfile.school_relations.find((s) => s.role === role);

          let hasStaffSegment = profileSchoolContext === SchoolUserRole.Staff;
          const hasStaffRole = userProfile.school_relations.find(
            (s) => s.role === SchoolUserRole.Staff,
          );

          if (propUserType === 'adult' && hasStaffRole && !currentRelation) {
            role = SchoolUserRole.Staff;
            currentRelation = hasStaffRole;
            hasStaffSegment = true;
          }

          const loadStaffSchoolUserRelation = hasStaffRole && hasStaffSegment && currentRelation;

          const currentUserType =
            typeof currentRelation?.role === 'number'
              ? (SchoolUserRole[currentRelation.role]?.toLowerCase() as SchoolUserType)
              : propUserType;

          if (currentRelation && currentUserType !== propUserType) {
            navigate(
              {
                pathname: getRouteModalPathname(currentUserType, currentRelation),
                hash: `#${mode}`,
              },
              { state: location.state, replace: true },
            );
          }

          initialState.userType = currentUserType;
          if (role === SchoolUserRole.Staff ? loadStaffSchoolUserRelation : currentRelation) {
            initialState.schoolMembership = (await getMembership(currentUserType as SchoolUserType)(
              schoolId,
              currentRelation!.relation_id,
            )) as unknown as SchoolUserRelationInBaseUser;
          }
        } catch (err) {
          setState(initialState);
          showError(err as ApiError);
          setHasAccess(false);
          setIsFetching(false);
          setIsMembershipFetched(true);
          return;
        } finally {
          setIsMembershipFetched(true);
        }
      }

      setState(initialState);
      setIsFetching(false);
    }
  }, [
    getMembership,
    location.state,
    mode,
    navigate,
    profileId,
    profileSchoolContext,
    propUserType,
    schoolId,
    setState,
    showError,
    userContext,
  ]);

  const requestRegistrations = useCallback(async () => {
    if (!schoolId || !state || !state?.userType || !state?.schoolMembership) {
      return;
    }

    if (state.userType === 'student') {
      setRegistrations([]);
      const { enrollments } = await getEnrollments(schoolId, state.schoolMembership.relation_id);
      setRegistrations(enrollments);
    } else {
      setRegistrations([]);
      const { employments } = await getEmployments(schoolId, state.schoolMembership.relation_id);
      setRegistrations(employments);
    }
  }, [schoolId, setRegistrations, state]);

  useEffect(() => {
    if (prevState && !state) {
      setInit(false);
    }
  }, [prevState, state]);

  /* Set initial state */
  useEffect(() => {
    // set initial state on Modal
    // TODO: the userType="profile" is not related to the Router so the state is not being
    //  dropped for it on open/close, so we can not rely on the state existence here
    //  like in other modals
    if (!init) {
      setContextName(CONTEXT_NAME);
      request();
      setInit(true);
    }
  }, [init, propUserType, request, setContextName]);

  useEffect(() => {
    if (!mode || prevMode === mode) {
      // proceed only on mode change
      return;
    }

    if (isFetching || !isMembershipFetched || !state?.userType) {
      return;
    }

    let actualId = isSchoolUser ? state.schoolMembership?.relation_id : profileId;

    // when we deregister person (in DeleteUserModal) we should change id to userId
    if (!isSchoolUser && profileId === state.schoolMembership?.user_id && state.user?.user_id) {
      actualId = state.user.user_id;
      dispatch(modalActions.remove());
    }

    const newSearchParams = new URLSearchParams(searchParams);

    if (prevUserType && isSchoolUserType(prevUserType) && !isSchoolUser) {
      switch (prevUserType) {
        case 'staff':
          newSearchParams.set('type', `${SchoolUserRole.Staff}`);
          break;
        case 'student':
          newSearchParams.set('type', `${SchoolUserRole.Student}`);
          break;
        case 'parent':
          newSearchParams.set('type', `${SchoolUserRole.Parent}`);
          break;
      }
    }

    navigate(
      {
        pathname: getRouteModalPathname(state.userType, {
          id: actualId!,
          user_id: state.user?.user_id,
          relation_id: state.schoolMembership?.relation_id,
        }),
        hash: `#${mode}`,
        search: `?${newSearchParams}`,
      },
      { state: location.state, replace: true },
    );
  }, [
    dispatch,
    isFetching,
    isMembershipFetched,
    isSchoolUser,
    location.state,
    mode,
    navigate,
    prevMode,
    prevUserType,
    profileId,
    searchParams,
    state?.schoolMembership?.relation_id,
    state?.schoolMembership?.user_id,
    state?.user?.user_id,
    state?.userType,
  ]);

  useEffect(() => {
    // reset mode on user type change
    if (!prevUserType || !state?.userType) return;

    if (prevUserType !== state.userType) {
      setMode(getDefaultMode(location));
    }
  }, [location, prevUserType, setMode, state?.userType]);

  useEffect(() => {
    // for changing hash
    if (isFetching || !isMembershipFetched || !state?.userType) return;

    navigate(
      {
        pathname: location.pathname,
        hash: `#${mode}`,
        search: location.search,
      },
      { state: location.state, replace: true },
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mode]);

  /* Load registrations for `Registrations` tab */
  useEffect(() => {
    if (!state?.registrations && mode === ProfileModalMode.Registrations) {
      requestRegistrations();
    }
  }, [mode, requestRegistrations, state?.registrations]);

  const handleSchoolRoleChange = useCallback(
    async ({ userType, ...relationData }: ProfileSchoolRelation) => {
      if (userType === state?.userType) {
        return;
      }

      setUserType(userType);
      navigate(getRouteModalPathname(userType, relationData), { state: { replace: true } });
      handleMembershipUpdate(userType, relationData.relation_id);
    },
    [handleMembershipUpdate, navigate, setUserType, state?.userType],
  );
  const resetState = () => setInit(false);

  const value = {
    ...state,
    userContext,
    mode,
    contextActions,
    suggestedChanges,
    tabs,
    studentTabs,
    parentTabs,
    staffTabs,
    profileTabs,
    isFetching,
    isMembershipFetched,
    isUpdating,
    isSchoolUser,
    isSchoolContext,
    isIndividualContext,
    showLoader,
    hasAccess,
    hasEditingPermission,
    hasViewerPermission,
    canEditProfile,
    canEditProfilePicture,
    isChildOfCurrentUser,
    isContextMenuAvailable,
    isDeleteModalOpen,
    isPasswordChangeModalOpen,
    isCloseAccountModalOpen,

    actions: {
      handleClose,
      handleSelectUserType,
      handleMembershipUpdate,
      handleUserUpdate,
      handleLogOut,
      handleUserDelete,
      setMode,
      setUser,
      setUserType,
      setUserContext,
      setSchoolMembership,
      setCustomFieldValues,
      setRegistrations,
      setGroups,
      setGroupsSchoolYear,
      resetState,
      request,
      requestRegistrations,
      updateProfile,
      updateStaffProfile,
      updateAvatar,
      showDeleteModal,
      hideDeleteModal,
      showPasswordChangeModal,
      hidePasswordChangeModal,
      showCloseAccountModal,
      hideCloseAccountModal,
      handleSchoolRoleChange,
    },
  };

  return <ProfileContext.Provider value={value}>{children}</ProfileContext.Provider>;
};
