import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Icon,
  IconButton,
  Stack,
  Tab,
  Tabs,
  TabsProps,
  Tooltip,
} from '@mui/material';
import { DropdownIcon, InformationIcon } from '@schooly/style';
import React, { FC, useCallback, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { SchoolCustomFieldsDefaultApplications } from './SchoolCustomFieldsDefaultApplications';
import { SchoolCustomFieldsDefaultParents } from './SchoolCustomFieldsDefaultParents';
import { SchoolCustomFieldsDefaultStaff } from './SchoolCustomFieldsDefaultStaff';
import { SchoolCustomFieldsDefaultStudents } from './SchoolCustomFieldsDefaultStudents';

enum TABS {
  Applications,
  Students,
  Staff,
  Parents,
}

export const SchoolCustomFieldsDefault: FC = () => {
  const { $t } = useIntl();
  const [open, setOpen] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS.Applications);

  const handleHeaderClick = useCallback(() => {
    setOpen((open) => !open);
  }, []);

  const handleTabChange = useCallback<Exclude<TabsProps['onChange'], undefined>>((event, tab) => {
    setActiveTab(tab);
  }, []);

  return (
    <Card>
      <CardHeader
        data-cy="school-settings-custom-fields-default-info"
        title={
          <Stack direction="row" alignItems="center" gap={1}>
            <FormattedMessage id="school-sections-CustomFields-Default-Title" />
            <Tooltip title={$t({ id: 'school-sections-CustomFields-Default-Hint' })}>
              <IconButton inverse>
                <InformationIcon />
              </IconButton>
            </Tooltip>
          </Stack>
        }
        onClick={handleHeaderClick}
        action={
          <Icon>
            <DropdownIcon />
          </Icon>
        }
        sx={{
          cursor: 'pointer',

          '& .MuiCardHeader-action': {
            display: 'flex',
            alignSelf: 'center',

            '& .MuiIcon-root': {
              color: open ? 'primary.main' : 'text.secondary',
              transform: open ? 'rotate(180deg)' : undefined,
            },
          },

          '&:hover': {
            '& .MuiCardHeader-action': {
              '& .MuiIcon-root': {
                color: 'primary.main',
              },
            },
          },
        }}
      />
      {open ? (
        <CardContent>
          <Tabs
            sx={(theme) => ({
              height: theme.spacing(7),
              minHeight: theme.spacing(7),
              pb: 2,

              '& .MuiTab-root': {
                ...theme.typography.h2,
                color: undefined,

                '&:not(:last-child)': {
                  mr: 5,
                },
              },
            })}
            value={activeTab}
            onChange={handleTabChange}
          >
            <Tab label={$t({ id: 'school-sections-Applications' })} />
            <Tab label={$t({ id: 'section-Students' })} />
            <Tab label={$t({ id: 'section-Staff' })} />
            <Tab label={$t({ id: 'section-Parents' })} />
          </Tabs>

          {activeTab === TABS.Applications && <SchoolCustomFieldsDefaultApplications />}
          {activeTab === TABS.Students && <SchoolCustomFieldsDefaultStudents />}
          {activeTab === TABS.Staff && <SchoolCustomFieldsDefaultStaff />}
          {activeTab === TABS.Parents && <SchoolCustomFieldsDefaultParents />}
        </CardContent>
      ) : (
        <Box pb={2.5} />
      )}
    </Card>
  );
};
