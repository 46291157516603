import { Components, Theme } from '@mui/material';

export const MuiDialog: Components<Theme>['MuiDialog'] = {
  defaultProps: {
    transitionDuration: 0, // transition between two modals produces a visual blinking
  },
  styleOverrides: {
    root: ({ theme }) => {
      const maxWidth = `calc(100vw - ${theme.spacing(5)})`;
      const maxHeight = `calc(100dvh - ${theme.spacing(5)})`;

      return {
        '& .MuiDialog-paper': {
          [theme.breakpoints.up('md')]: {
            borderRadius: theme.shape.borderRadius,
          },
          [theme.breakpoints.down('md')]: {
            borderRadius: 0,
            maxWidth: '100vw',
            maxHeight: '100vh',
            margin: 0,
          },
        },

        '& .MuiDialog-paperFullWidth': {
          maxWidth,
          maxHeight,
          width: maxWidth,
          height: maxHeight,
          margin: theme.spacing(2.5),

          [theme.breakpoints.down('lg')]: {
            maxWidth: '100vw',
            height: '100dvh',
            maxHeight: '100dvh',
            margin: 0,
            borderRadius: 0,
          },
        },
      };
    },
  },
};
