import { Box, Stack, Typography } from '@mui/material';
import { Loading, ModalSearch } from '@schooly/style';
import { DropdownYears } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { ListViewReports } from '../../../components/uikit-components/ListViewReports/ListViewReports';
import { useProfile } from '../../../context/profile/useProfile';
import { useReportsForRelation } from '../../../hooks/useReportsForRelation';
import useSchoolYears from '../../../hooks/useSchoolYears';

export const ProfileModalReports: FC = () => {
  const { formatMessage } = useIntl();
  const { schoolMembership } = useProfile();
  const {
    reports,
    filter,
    schoolYear,
    fetching,
    schoolYearsFetching,
    handleFilterChange,
    handleSchoolYearChange,
  } = useReportsForRelation({ id: schoolMembership?.relation_id });
  const { schoolYears, defaultValidity } = useSchoolYears();

  return (
    <Box className="section section-wrapper">
      <Stack direction="row" justifyContent="space-between" mb={2.5} alignItems="center" mt={-0.75}>
        <Typography variant="h2">
          <FormattedMessage id="section-Reports" />
        </Typography>
        <Stack direction="row" alignItems="center" gap={2}>
          <ModalSearch
            value={filter}
            onChange={handleFilterChange}
            placeholder={formatMessage({ id: 'people-Search' })}
          />
          <DropdownYears
            years={schoolYears}
            defaultYear={defaultValidity}
            currentYear={schoolYear}
            onYearChange={handleSchoolYearChange}
          />
        </Stack>
      </Stack>

      {fetching || schoolYearsFetching ? (
        <Loading />
      ) : (
        <Stack
          gap={2.5}
          height="100%"
          sx={(theme) => ({
            overflowY: 'scroll',
            [theme.breakpoints.down('md')]: {
              marginRight: -2.5,
            },
          })}
        >
          {!reports?.length ? (
            <NoSearchResultsFound type="small" />
          ) : (
            <ListViewReports reports={reports} id={schoolMembership?.relation_id!} />
          )}
        </Stack>
      )}
    </Box>
  );
};
