import { Button, IconButton, Stack, Typography } from '@mui/material';
import {
  Company,
  DefaultPayer,
  PayerType,
  Product,
  ProductType,
  StudentProductWithVariants,
  useGetSchoolQuery,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { CompanySelect } from '@schooly/components/filters';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  DeleteIcon,
  DoneIcon,
  ModalContent,
  ModalFooter,
  ModalPanel,
  PlusIcon,
  SimpleButton,
  Spin,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { ModalRightSidebar } from '../../../../../components/uikit-components/Modal/ModalRightSidebar';
import { StudentDefaultPayerSelect } from './StudentDefaultPayerSelect';
import { StudentProductsModalList } from './StudentProductsModalList';
import { StudentProductsModalSidebarFees } from './StudentProductsModalSidebarFees';

export type UpdateStudentProductsForm = {
  defaultPayerId: string;
  companyPayerId?: string;
  products: StudentProductWithVariants[];
};

type StudentProductsModalContentProps = {
  initialState?: StudentProductsModalState;
  relationId: string;
  schoolId: string;
  adult: DefaultPayer;
  company?: Company;
  initCompanyId?: string;
  onSubmit: (data: UpdateStudentProductsForm) => void;
  products: StudentProductWithVariants[];
  isSaving: boolean;
};

export enum StudentProductsModalState {
  SelectPayers = 'select-payers',
  AddProducts = 'add-products',
}

export const StudentProductsModalContent: FC<StudentProductsModalContentProps> = ({
  initialState = StudentProductsModalState.SelectPayers,
  schoolId,
  relationId,
  adult,
  company,
  initCompanyId,
  onSubmit,
  products,
  isSaving,
}) => {
  const { $t } = useIntl();
  const panelRef = useRef<HTMLDivElement>(null);
  const [hasRef, setRef] = useState<boolean>(false);
  const [state, setState] = useState<StudentProductsModalState>(initialState);
  const { getConfirmation } = useConfirmationDialog();

  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    enabled: !!schoolId,
  });

  const form = useForm<UpdateStudentProductsForm>({
    defaultValues: {
      defaultPayerId: adult.id,
      companyPayerId: initCompanyId,
      products: products.map((p) => ({ ...p, discount_percent: p.discount_percent || undefined })),
    },
  });

  const { append } = useFieldArray({
    control: form.control,
    name: 'products',
  });

  const handleNext = async () => {
    const isCompanyRemoved = !!company && !companyPayerId;

    if (isCompanyRemoved) {
      const confirmed = await getConfirmation({
        textId: 'profile-AreYouSureYouWantToRemoveTheCompany',
        textValues: { companyName: company.name, guardianName: getUserFullName(adult) },
      });

      if (!confirmed) return;

      form.setValue(
        'products',
        form.getValues('products').map((p) => ({ ...p, payer_type: PayerType.Default })),
      );
    }

    setState(StudentProductsModalState.AddProducts);
  };

  const handleSelectProductWithType = useCallback(
    ({ product, type }: { product: Product; type: ProductType }) => {
      const variant = type.variants[0];
      const frequency_id = variant?.prices[0].frequency_id;

      if (!variant || !frequency_id) return;

      const studentProduct: StudentProductWithVariants = {
        id: product.id,
        payer_type: PayerType.Default,
        type_name: type.name,
        variant_id: variant.id,
        obligatory: product.obligatory,
        name: product.name,
        price: variant.prices[0].price,
        frequency_id,
        available_variants: product.types
          .map((type) =>
            type.variants.map((variant) => ({
              type_id: type.id,
              type_name: type.name,
              id: variant.id,
              half_day: variant.half_day,
              prices: variant.prices,
            })),
          )
          .flat(),
      };

      append(studentProduct);
    },
    [append],
  );

  const adultId = form.watch('defaultPayerId');
  const companyPayerId = form.watch('companyPayerId');
  const studentProducts = form.watch('products');

  if (state === StudentProductsModalState.SelectPayers)
    return (
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalContent active>
            <Stack gap={1}>
              <Typography variant="h2">
                {$t({ id: 'profile-WhichParentIsResponsibleForPayments?' })}
              </Typography>
              <Stack flexDirection="row" gap={1}>
                <Stack
                  flex={1}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.default,
                    borderRadius: theme.spacing(1),
                    padding: theme.spacing(1.5),
                  })}
                >
                  <Typography variant="h3">
                    {$t({ id: 'profile-AllNewRequiredPayments' })}
                  </Typography>
                </Stack>
                <Stack
                  flex={1}
                  sx={(theme) => ({
                    backgroundColor: theme.palette.background.default,
                    borderRadius: theme.spacing(1),
                    padding: theme.spacing(1.5),
                  })}
                >
                  <Typography variant="h3">
                    {$t({ id: 'profile-IfACompanyStopsPaying' })}
                  </Typography>
                </Stack>
              </Stack>
              <Controller
                control={form.control}
                name="defaultPayerId"
                render={({ field }) => (
                  <StudentDefaultPayerSelect
                    schoolId={schoolId}
                    relationId={relationId}
                    selectedId={field.value}
                    onSelectId={field.onChange}
                  />
                )}
              />
            </Stack>
            <Stack gap={1} mt={4}>
              <Typography variant="h2">
                {$t({ id: 'profile-IsThereACompanyPayingFeesForThisStudent' })}
              </Typography>
              <Stack flexDirection="row" gap={2} alignItems="center">
                {companyPayerId === undefined ? (
                  <Stack>
                    <SimpleButton
                      startIcon={<PlusIcon />}
                      onClick={() => form.setValue('companyPayerId', '')}
                    >
                      {$t({ id: 'companies-AddCompany' })}
                    </SimpleButton>
                  </Stack>
                ) : (
                  <>
                    <Stack flex={1}>
                      <Controller
                        control={form.control}
                        name="companyPayerId"
                        render={({ field }) => (
                          <CompanySelect
                            selectedId={field.value}
                            schoolId={schoolId}
                            onSelectCompanyId={field.onChange}
                            placeholder={$t({ id: 'companies-CompanyName' })}
                            onClear={() => field.onChange('')}
                          />
                        )}
                      />
                    </Stack>
                    <IconButton inverse onClick={() => form.setValue('companyPayerId', undefined)}>
                      <DeleteIcon />
                    </IconButton>
                  </>
                )}
              </Stack>
            </Stack>
          </ModalContent>
          <ModalFooter active>
            <Button endIcon={<ArrowRightIcon />} onClick={handleNext}>
              {$t({ id: 'action-Next' })}
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    );

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <ModalPanel ref={panelRef} active>
          <Typography variant="h2">{$t({ id: 'profile-Products' })}</Typography>
          <OnLayout onLayout={setRef} />
        </ModalPanel>
        {hasRef && (
          <ModalRightSidebar open anchorEl={panelRef.current}>
            <StudentProductsModalSidebarFees
              schoolId={schoolId}
              onSelectProduct={handleSelectProductWithType}
              selectedProducts={studentProducts}
              relationId={relationId}
            />
          </ModalRightSidebar>
        )}
        <ModalContent active sx={{ paddingTop: 0, display: 'flex', flexDirection: 'column' }}>
          <StudentProductsModalList
            relationId={relationId}
            schoolId={schoolId}
            adultId={adultId}
            companyId={companyPayerId || null}
            form={form}
            currency={currentSchool?.currency}
          />
        </ModalContent>
        <ModalFooter active sx={{ justifyContent: 'space-between' }}>
          {initialState !== StudentProductsModalState.AddProducts ? (
            <Button
              variant="outlined"
              startIcon={<ArrowLeftIcon />}
              onClick={() => setState(StudentProductsModalState.SelectPayers)}
            >
              {$t({ id: 'action-Back' })}
            </Button>
          ) : (
            <div />
          )}
          <Button disabled={isSaving} startIcon={isSaving ? <Spin /> : <DoneIcon />} type="submit">
            {$t({ id: 'action-Save' })}
          </Button>
        </ModalFooter>
      </form>
    </FormProvider>
  );
};

const OnLayout: FC<{ onLayout: (v: boolean) => void }> = ({ onLayout }) => {
  useEffect(() => {
    onLayout(true);

    return () => onLayout(false);
  }, [onLayout]);

  return null;
};
