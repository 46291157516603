import { Button, DialogActions, DialogContent, DialogProps } from '@mui/material';
import { FC, ReactNode } from 'react';
import { FormattedMessage } from 'react-intl';

import { ModalConfirmation } from './ModalConfirmation.styled';

export interface ModalConfirmationDialogProps extends Omit<DialogProps, 'open'> {
  open?: boolean;
  content?: ReactNode;
  onConfirm?: VoidFunction;
  onCancel?: VoidFunction;
  onClose?: VoidFunction;
  confirmLabelId?: string;
  cancelLabelId?: string;
}

export const ModalConfirmationDialog: FC<ModalConfirmationDialogProps> = ({
  open,
  content,
  onConfirm,
  onCancel,
  confirmLabelId,
  cancelLabelId,
  onClose,
  ...props
}) => {
  return (
    <ModalConfirmation open={!!open} onClose={onClose ?? onCancel} {...props}>
      <DialogContent>{content}</DialogContent>
      <DialogActions>
        <Button variant="outlined" autoFocus onClick={onCancel}>
          <FormattedMessage id={cancelLabelId ? cancelLabelId : 'action-Cancel'} />
        </Button>
        <Button onClick={onConfirm}>
          <FormattedMessage id={confirmLabelId ? confirmLabelId : 'action-Confirm'} />
        </Button>
      </DialogActions>
    </ModalConfirmation>
  );
};
