import { Button, FormControlLabel, IconButton, Stack, Switch, Typography } from '@mui/material';
import {
  Assessment,
  AssessmentBase,
  AssessmentForSchool,
  FilterKeys,
  RecurringState,
  RepetitionType,
  SchoolYear,
  SORT_DIRECTION,
  useGetAssessmentsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { PageHeaderSearchInput } from '@schooly/components/filters';
import { RecurringExpandableCard } from '@schooly/components/recurring';
import { DATE_FORMAT_SHORT_MOMENT } from '@schooly/constants';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { EyeIcon, SkeletonRowsComponent } from '@schooly/style';
import React, { ChangeEvent, FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ReactComponent as DropdownIconSvg } from '../../../assets/images/icons/arrow_dropdown.svg';
import AccessDenied from '../../../components/common/AccessDenied';
import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { ModalPanel } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import RoundCard from '../../../components/uikit-components/RoundCard';
import useSchoolYears from '../../../hooks/useSchoolYears';
import formatDate from '../../../utils/formatDate';

export interface SelectAssessmentExternalSidebarProps {
  selectedAssessments: AssessmentBase[];
  assessmentListFilter?: (v: AssessmentBase) => void;
  onCardClick: (group: Assessment) => void;
}

type WithRecurringState<T extends { recurring_state: RecurringState | null }> = Omit<
  T,
  'recurring_state'
> & { recurring_state: RecurringState };

const PAGE_SIZE = 20;

export const SelectAssessmentExternalSidebar: React.FC<SelectAssessmentExternalSidebarProps> = ({
  selectedAssessments,
  assessmentListFilter,
  onCardClick,
}) => {
  const { $t } = useIntl();
  const { schoolId = '' } = useAuth();
  const { defaultValidity, schoolYears } = useSchoolYears();
  const [expandedSchoolYear, setExpandedSchoolYear] = useState<SchoolYear | null>(
    defaultValidity as SchoolYear,
  );
  const [showRecurringAssessments, setShowRecurringAssessments] = useState(false);

  const {
    data,
    isLoading,
    params,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    setParams,
    error,
  } = useGetAssessmentsQuery(
    {
      school_id: schoolId,
      query: '',
      page_size: PAGE_SIZE,
      sort: { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
      filters: {
        [FilterKeys.Date]: [defaultValidity?.start || '', defaultValidity?.end || ''],
        [FilterKeys.RepetitionType]: showRecurringAssessments
          ? [RepetitionType.FirstOfRecurrence, RepetitionType.NotRecurring]
          : [RepetitionType.NotRecurring],
      },
    },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const toggleYearOpen = (schoolYear: SchoolYear) => {
    setExpandedSchoolYear((y) => (y?.id === schoolYear.id ? null : schoolYear));
    setParams((p) => ({
      ...p,
      filters: {
        ...p.filters,
        [FilterKeys.Date]: [schoolYear.start, schoolYear.end],
      },
    }));
  };

  const handleSwitchToggle = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const checked = e.target.checked;
      setShowRecurringAssessments(checked);

      setParams((p) => ({
        ...p,
        filters: {
          ...p.filters,
          [FilterKeys.RepetitionType]: checked
            ? [RepetitionType.FirstOfRecurrence, RepetitionType.NotRecurring]
            : [RepetitionType.NotRecurring],
        },
      }));
    },
    [setParams],
  );

  const total = data?.pages[0]?.count;
  const loaderRef = useInfiniteScroll(
    isLoading || isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
    0,
  );

  const entries = useMemo(() => {
    const selectedAssessmentsIds = selectedAssessments.map((a) => a.id);

    return (
      data?.pages
        .reduce<AssessmentForSchool[]>((prev, curr) => [...prev, ...curr.results], [])
        // We are moving assessments with recurring state in RecurringAssessmentCard
        .filter(
          (a) =>
            a.recurring_state ||
            (!selectedAssessmentsIds.includes(a.id) &&
              (assessmentListFilter ? assessmentListFilter(a) : true)),
        ) ?? []
    );
  }, [assessmentListFilter, data?.pages, selectedAssessments]);

  if (error) {
    return <AccessDenied />;
  }

  const placeholder = entries
    ? $t(
        { id: 'reports-SearchAmongCount' },
        {
          reportsPlural: $t({ id: 'reports-Assessments' }).toLowerCase(),
        },
      )
    : undefined;

  return (
    <Stack sx={{ height: '100%' }}>
      <ModalHeader title={$t({ id: 'section-Assessments' })} active />
      <ModalPanel active sx={{ height: '100%', overflow: 'hidden', mb: -2 }}>
        <PageHeaderSearchInput
          value={params.query || ''}
          onChangeText={handleSetFiltersQuery}
          placeholder={placeholder}
        />
        <FormControlLabel
          sx={{ ml: 0, mt: 2 }}
          control={<Switch checked={showRecurringAssessments} onChange={handleSwitchToggle} />}
          label={
            <Typography variant="h3" color={showRecurringAssessments ? undefined : 'common.grey'}>
              <FormattedMessage
                id="recurring-showRecurringEntity"
                values={{
                  entityName: $t({ id: 'assessments-Assessments' }),
                }}
              />
            </Typography>
          }
        />
        <Stack
          sx={{ overflowY: 'auto', height: '80%', mr: -2, pr: 2, mt: 0.75 }}
          data-cy="assessment-list"
        >
          {schoolYears.map((schoolYear) => {
            const expanded = expandedSchoolYear?.id === schoolYear.id;

            return (
              <React.Fragment key={schoolYear.id}>
                <Stack
                  sx={{
                    py: 1.5,
                    cursor: 'pointer',
                    flexDirection: 'row',
                    justifyContent: 'space-between',

                    '.MuiIconButton-root': {
                      transition: 'all .2s',
                      transform: expanded ? 'rotate(180deg)' : undefined,
                    },
                  }}
                  onClick={() => toggleYearOpen(schoolYear)}
                >
                  <Typography color={expanded ? 'primary.main' : 'text.primary'}>
                    {schoolYear.name}
                  </Typography>
                  <IconButton inverse>
                    <DropdownIconSvg />
                  </IconButton>
                </Stack>
                {expanded && (
                  <>
                    {!isLoading && !entries.length && <NoSearchResultsFound />}
                    {entries.map((assessment) =>
                      assessment.recurring_state ? (
                        <RecurringAssessmentCard
                          key={assessment.id}
                          assessment={assessment as WithRecurringState<Assessment>}
                          schoolId={schoolId}
                          onRecurringItemClick={onCardClick}
                          selectedItems={selectedAssessments}
                        />
                      ) : (
                        <RoundCard
                          key={assessment.id}
                          withTooltip
                          generateHref={(id) => `/assessments/${id}`}
                          onClick={onCardClick}
                          name={assessment.name}
                          item={assessment}
                          prefix={formatDate(
                            assessment.assessment_date,
                            DATE_FORMAT_SHORT_MOMENT,
                          )?.toUpperCase()}
                          testId="assessment-list-card"
                        />
                      ),
                    )}
                    {isLoading && <SkeletonRowsComponent rowCount={PAGE_SIZE} />}
                    {hasNextPage && (
                      <div ref={loaderRef}>
                        <SkeletonRowsComponent
                          rowCount={Math.min(
                            PAGE_SIZE,
                            total && data ? total - data.pages.length * PAGE_SIZE : PAGE_SIZE,
                          )}
                        />
                      </div>
                    )}
                  </>
                )}
              </React.Fragment>
            );
          })}
        </Stack>
      </ModalPanel>
    </Stack>
  );
};

type RecurringAssessmentCardProps = {
  onRecurringItemClick: (a: Assessment) => void;
  schoolId: string;
  assessment: WithRecurringState<AssessmentBase>;
  selectedItems: AssessmentBase[];
};

const RecurringAssessmentCard: FC<RecurringAssessmentCardProps> = ({
  onRecurringItemClick,
  schoolId,
  assessment,
  selectedItems,
}) => {
  const [expanded, setExpanded] = useState(false);

  const { data, isFetching, isLoading, isFetchingNextPage, fetchNextPage, hasNextPage } =
    useGetAssessmentsQuery(
      {
        school_id: schoolId || '',
        page_size: PAGE_SIZE,
        filters: assessment.recurring_state.recurrence_id
          ? { [FilterKeys.RecurrenceId]: [assessment.recurring_state.recurrence_id] }
          : {},
      },
      {
        enabled: Boolean(expanded && assessment.recurring_state.recurrence_id),
        refetchOnMount: 'always',
        onError: () => setExpanded(false),
      },
    );

  const recurringItems = useMemo(
    () => data?.pages.reduce<Assessment[]>((prev, curr) => [...prev, ...curr.results], []) ?? [],
    [data?.pages],
  );

  const filteredRecurringItems = useMemo(() => {
    return recurringItems.filter((a) => !selectedItems.some((s) => 'id' in s && s.id === a.id));
  }, [recurringItems, selectedItems]);

  const isEmpty = !filteredRecurringItems.length && !isFetching && !hasNextPage;

  useEffect(() => {
    const isLoadedAssessmentsAdded = Boolean(
      recurringItems.length && !filteredRecurringItems.length && hasNextPage && !isFetchingNextPage,
    );
    if (isLoadedAssessmentsAdded) {
      fetchNextPage();
    }
  }, [
    fetchNextPage,
    filteredRecurringItems.length,
    hasNextPage,
    isFetchingNextPage,
    recurringItems.length,
  ]);

  return (
    <RecurringExpandableCard
      item={assessment}
      onToggle={() => setExpanded((e) => !e)}
      expanded={expanded}
      fetching={isFetching}
      items={recurringItems}
    >
      {Boolean(recurringItems.length && expanded) && (
        <>
          {filteredRecurringItems.map((assessment) => (
            <RoundCard
              key={assessment.id}
              withTooltip
              generateHref={(id) => `/assessments/${id}`}
              onClick={onRecurringItemClick}
              name={assessment.name}
              item={assessment}
              prefix={formatDate(
                assessment.assessment_date,
                DATE_FORMAT_SHORT_MOMENT,
              )?.toUpperCase()}
            />
          ))}

          {isEmpty && (
            <Stack mt={0.5} mb={1.25} pr={3} alignItems="center">
              <FormattedMessage id="reports-AllRecurringAssessmentsAdded" />
            </Stack>
          )}
          {hasNextPage && !isFetchingNextPage && (
            <Stack mt={1} mb={1.25} alignItems="center">
              <Button startIcon={<EyeIcon />} variant="text" onClick={() => fetchNextPage()}>
                <FormattedMessage id="action-ShowMoreButton" />
              </Button>
            </Stack>
          )}

          {(isLoading || isFetchingNextPage) && <SkeletonRowsComponent rowCount={2} mb={1.25} />}
        </>
      )}
    </RecurringExpandableCard>
  );
};
