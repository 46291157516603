import { Box, Button, IconButton, Stack, SxProps } from '@mui/material';
import { FilterValue, SelectOption } from '@schooly/api';
import { USER_TOTAL_LANGUAGES_MAX_COUNT } from '@schooly/constants';
import { DeleteIcon, PlusIcon } from '@schooly/style';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ChipSelectGroup } from 'apps/web/src/components/uikit-components/FormSelect/ChipSelectGroup';
import { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

import { CreateChildForm } from './CreateChildContent.type';

const getEmptyLanguage = () => ({
  language: undefined,
  speaking: undefined,
  writing: undefined,
});

interface LanguageFormContainerProps<T> {
  options: SelectOption<T, any>[];
  levelOptions: Array<{
    name: string;
    labelTextId: string;
    options: SelectOption<T, any>[];
  }>;
  withoutDivider?: boolean;
  gap?: number;
  sx?: SxProps;
}

export const LanguageFormContainer = <T extends FilterValue>({
  options,
  levelOptions,
  withoutDivider,
  gap,
  sx,
}: LanguageFormContainerProps<T>) => {
  const { control, getValues, watch } = useFormContext<CreateChildForm>();
  const languages = watch('language');

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'language',
  });

  const addEmptyLanguage = useCallback(() => {
    append(getEmptyLanguage());
  }, [append]);

  const isLanguagesFilled = languages.every((l) => Number.isInteger(l.language));
  const maxNumberOfLanguagesAdded = languages.length === USER_TOTAL_LANGUAGES_MAX_COUNT;

  return (
    <Stack gap={1} sx={sx}>
      <Stack gap={2}>
        {fields.map((field, index) => {
          const fieldPath = `language.${index}` as const;
          const currentField = getValues(fieldPath);
          const languageSelected = typeof currentField.language !== 'undefined';

          return (
            <Stack gap={1} key={field.id} position="relative">
              <Box
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  pointerEvents: 'none',
                  opacity: 0,
                }}
              >
                <input name="language" />
              </Box>
              <Stack direction="row" gap={1.5} alignItems="flex-start">
                <FormSelect2
                  name={`${fieldPath}.language`}
                  labelTextId={
                    index > 0 ? 'peopleDetail-AdditionalLanguage' : 'peopleDetail-PrimaryLanguage'
                  }
                  options={options}
                  rules={{ required: true }}
                />
                {index >= 1 && (
                  <IconButton
                    sx={{ paddingTop: 1.5 }}
                    inverse
                    onClick={() => {
                      remove(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                )}
              </Stack>

              {languageSelected && (
                <Stack
                  direction="row"
                  gap={gap ?? 1.5}
                  sx={(theme) => ({
                    [theme.breakpoints.down('xl')]: {
                      flexWrap: 'wrap',
                    },
                  })}
                  className="languageLevelGroups"
                >
                  {levelOptions.map(({ name, ...props }, i) => (
                    <ChipSelectGroup
                      key={name}
                      required={true}
                      name={`${fieldPath}.${name}`}
                      {...props}
                      withoutDivider={withoutDivider}
                    />
                  ))}
                </Stack>
              )}
            </Stack>
          );
        })}
      </Stack>
      <Box>
        {isLanguagesFilled && !maxNumberOfLanguagesAdded && (
          <Button variant="text" startIcon={<PlusIcon />} onClick={addEmptyLanguage}>
            <FormattedMessage id="applications-Language-AddLanguage" />
          </Button>
        )}
      </Box>
    </Stack>
  );
};
