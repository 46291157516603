import {
  AnnualPlanRecordSchoolPeriod,
  AnnualPlanRecordTypes,
  ApiError,
  GET_SCHOOL_YEARS_QUERY,
  SchoolYearPeriod,
  SchoolYearPeriodGroup,
  useUpdateSchoolYearPeriodsMutation,
} from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useNotifications } from '@schooly/components/notifications';
import { compareAsc } from 'date-fns';
import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';
import { SubmitHandler } from 'react-hook-form-lts';

import { useSchool } from '../../../../../hooks/useSchool';
import { queryClient } from '../../../../../queryClient';
import { usePeriodValidation } from '../../../../School/SchoolPeriods/SchoolPeriodsUpdate/usePeriodValidation';
import { useAnnualPlanner } from '../../../WithAnnualPlanner';
import { addSchoolPeriodGroupOption, AnnualPlannerCreateForm } from '../../scheme';
import { AnnualPlannerCreateRecordContext } from '../WithAnnualPlannerCreate';

export interface AnnualPlannerCreateSchoolPeriodContextProps {
  isSavingSchoolPeriod: boolean;
  canDeleteSchoolPeriod: boolean;
  deleteSchoolPeriod: () => Promise<void>;
  isDeletingSchoolPeriod: boolean;
  submitSchoolPeriod: SubmitHandler<AnnualPlannerCreateForm>;
}

export const AnnualPlannerCreateSchoolPeriodContext =
  createContext<AnnualPlannerCreateSchoolPeriodContextProps>({
    isSavingSchoolPeriod: false,
    canDeleteSchoolPeriod: false,
    deleteSchoolPeriod: async () => {},
    isDeletingSchoolPeriod: false,
    submitSchoolPeriod: () => {},
  });

export const WithAnnualPlannerCreateSchoolPeriod: FC<PropsWithChildren> = ({ children }) => {
  const { record, close } = useContext(AnnualPlannerCreateRecordContext);

  const { schoolId = '' } = useSchool();
  const { showError } = useNotifications();
  const { getConfirmation } = useConfirmationDialog();
  const { validatePastDate } = usePeriodValidation();
  const { schoolYear } = useAnnualPlanner();

  const updateSchoolYearPeriods = useUpdateSchoolYearPeriodsMutation();

  const [isSavingSchoolPeriod, setIsSavingSchoolPeriods] = useState(false);
  const [isDeletingSchoolPeriod, setIsDeletingSchoolPeriod] = useState(false);

  const canDeleteSchoolPeriod = Boolean(
    record &&
      record.type === AnnualPlanRecordTypes.SCHOOL_PERIOD &&
      !validatePastDate(record.start),
  );

  const deleteSchoolPeriod = useCallback<
    AnnualPlannerCreateSchoolPeriodContextProps['deleteSchoolPeriod']
  >(async () => {
    if (!schoolYear) {
      return;
    }

    const details = record?.details as AnnualPlanRecordSchoolPeriod;

    if (!details.id) {
      return;
    }

    const groups = [...(schoolYear.period_groups ?? [])];

    const groupIndex = groups.findIndex((group) => group.id === details.period_group_id);

    if (groupIndex === -1) {
      return;
    }

    let deletingPeriod: SchoolYearPeriod | undefined;

    // remove the period from the payload
    groups[groupIndex] = {
      ...groups[groupIndex],
      periods: groups[groupIndex].periods.reduce<SchoolYearPeriod[]>((prev, period) => {
        if (period.id === details.id) {
          deletingPeriod = period;
          return prev;
        }

        prev.push({ ...period, name: `${groups[groupIndex].name} ${prev.length + 1}` });
        return prev;
      }, []),
    };

    const isConfirmed = await getConfirmation({
      textId: 'annualPlanner-Popover-SchoolPeriod-Delete-Confirmation',
      textValues: { name: deletingPeriod?.name },
    });

    if (!isConfirmed) {
      return;
    }

    // can not rely on updateSchoolYearPeriods mutation state as there will be
    // getSchoolYears call afterwards, which state should also be considered
    setIsDeletingSchoolPeriod(true);

    if (!groups[groupIndex].periods.length) {
      // remove the group from the payload if it's empty
      groups.splice(groupIndex, 1);
    }

    try {
      await updateSchoolYearPeriods.mutateAsync({
        schoolId,
        schoolYearId: schoolYear.id,
        period_groups: groups,
      });

      // invalidate queries
      queryClient.invalidateQueries([GET_SCHOOL_YEARS_QUERY]);

      close();
    } catch (error) {
      showError(error as ApiError);
    }

    setIsDeletingSchoolPeriod(false);
  }, [
    close,
    getConfirmation,
    record?.details,
    schoolId,
    schoolYear,
    showError,
    updateSchoolYearPeriods,
  ]);

  const submitSchoolPeriod = useCallback<
    AnnualPlannerCreateSchoolPeriodContextProps['submitSchoolPeriod']
  >(
    async (values) => {
      if (!schoolYear) {
        return;
      }

      // can not rely on updateSchoolYearPeriods mutation state as there will be
      // getSchoolYears call afterward, which state should also be considered
      setIsSavingSchoolPeriods(true);

      let groups = [...(schoolYear.period_groups ?? [])];

      if (values.schoolPeriod.groupId === addSchoolPeriodGroupOption.value) {
        groups.push({
          name: values.schoolPeriod.groupName,
          periods: [
            {
              name: `${values.schoolPeriod.groupName} 1`,
              date_from: values.date[0],
              date_to: values.date[1],
            },
          ],
        } as SchoolYearPeriodGroup);
      } else {
        groups = groups.map((group) =>
          group.id === values.schoolPeriod.groupId
            ? ({
                ...group,
                name: values.schoolPeriod.groupName,
                periods: (values.originId
                  ? // update existing period
                    group.periods.map((period) =>
                      period.id === values.originId
                        ? {
                            ...period,
                            name: values.schoolPeriod.groupName,
                            date_from: values.date[0],
                            date_to: values.date[1],
                          }
                        : period,
                    )
                  : // add new period
                    [
                      ...group.periods,
                      {
                        name: values.schoolPeriod.groupName,
                        date_from: values.date[0],
                        date_to: values.date[1],
                      },
                    ]
                )
                  // TODO: probably need a shared utility as the similar logic is implemented in
                  //  the apps/web/src/pages/School/SchoolPeriods/SchoolPeriodsUpdate/PeriodGroup.tsx:handlePeriodsSort
                  .sort((a, b) => compareAsc(new Date(a.date_to), new Date(b.date_from)))
                  .map((p, i) => ({ ...p, name: `${values.schoolPeriod.groupName} ${i + 1}` })),
              } as SchoolYearPeriodGroup)
            : group,
        );
      }

      try {
        await updateSchoolYearPeriods.mutateAsync({
          schoolId,
          schoolYearId: schoolYear.id,
          period_groups: groups,
        });

        // invalidate queries
        queryClient.invalidateQueries([GET_SCHOOL_YEARS_QUERY]);

        close();
      } catch (error) {
        showError(error as ApiError);
      }

      setIsSavingSchoolPeriods(false);
    },
    [close, schoolId, schoolYear, showError, updateSchoolYearPeriods],
  );

  const value = useMemo<AnnualPlannerCreateSchoolPeriodContextProps>(
    () => ({
      isSavingSchoolPeriod,
      canDeleteSchoolPeriod,
      deleteSchoolPeriod,
      isDeletingSchoolPeriod,
      submitSchoolPeriod,
    }),
    [
      canDeleteSchoolPeriod,
      deleteSchoolPeriod,
      isDeletingSchoolPeriod,
      isSavingSchoolPeriod,
      submitSchoolPeriod,
    ],
  );

  return (
    <AnnualPlannerCreateSchoolPeriodContext.Provider value={value}>
      {children}
    </AnnualPlannerCreateSchoolPeriodContext.Provider>
  );
};
