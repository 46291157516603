import { Icon, Typography } from '@mui/material';
import { IncomingAttachment } from '@schooly/api';
import { DeleteIcon, Spin } from '@schooly/style';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useAppSelector } from 'apps/web/src/redux/hooks';
import React from 'react';

import {
  AttachmentCardStyled,
  AttachmentLoaderWrapper,
  AttachmentSize,
  AttachmentType,
} from './Attachments.styled';
import { formatSize, formatType } from './utils';

export interface AttachmentCardProps {
  file?: File | IncomingAttachment;
  readOnly?: boolean;
  onClick: VoidFunction;
}

export const AttachmentCard: React.FC<AttachmentCardProps> = ({ file, readOnly, onClick }) => {
  const {
    messages: { loadingAttachmentIds },
  } = useAppSelector((state) => state);

  if (!file) return null;

  const { type, name, size, id } = file as IncomingAttachment;

  const card = (
    <AttachmentCardStyled
      sx={{
        '&:hover': {
          '.deleteIcon': {
            visibility: 'visible',
          },
        },
      }}
      readOnly={readOnly}
      onClick={onClick}
    >
      {!readOnly && (
        <Icon
          className="deleteIcon"
          sx={(theme) => ({
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%,-50%)',
            zIndex: theme.zIndex.drawer,
            visibility: 'hidden',
          })}
        >
          <DeleteIcon />
        </Icon>
      )}
      <AttachmentType variant="h4" color="background.paper">
        {formatType(type, name)}
      </AttachmentType>
      <Typography
        sx={{
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          display: '-webkit-box',
          WebkitLineClamp: 2,
          WebkitBoxOrient: 'vertical',
        }}
        variant="caption"
        color="text.primary"
      >
        {name.split('.')[0]}
      </Typography>
      <AttachmentSize variant="body2" color="text.secondary">
        {`${formatSize(size)}Mb`}
      </AttachmentSize>
    </AttachmentCardStyled>
  );

  return loadingAttachmentIds.includes(id) ? (
    <AttachmentLoaderWrapper>
      <Spin size={2} />
    </AttachmentLoaderWrapper>
  ) : (
    card
  );
};
