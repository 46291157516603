import { Box, SxProps, Theme } from '@mui/material';
import {
  ApiError,
  AssessmentEntryGrade,
  AssessmentForGroup,
  AssessmentMethodGrade,
  AssessmentsGrade,
  upsertDeleteAssessmentEntry,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  DEFAULT_NOTIFICATION_DURATION_MS,
  useNotifications,
} from '@schooly/components/notifications';
import { theme } from '@schooly/style';
import React, { FC, useCallback, useMemo, useState } from 'react';

import { UpdateAssessmentEntryProps } from '../../../../pages/Assessments/AssessmentMarkbookModal/AssessmentMarkbookGrid';
import { Dropdown, DropdownOption } from '../../Dropdown/Dropdown';

export interface GradeControllerProps {
  groupId: string;
  relationId: string;
  entry?: AssessmentEntryGrade;
  assessmentForGroup: AssessmentForGroup;
  method: AssessmentMethodGrade;
  list?: AssessmentsGrade;
  onSuccess?: () => void;
  open?: boolean;
  disableEscapeKeyDown?: boolean;
  onFocus?: (event: React.FocusEvent) => void;
  noStopPropagationOnSelect?: boolean;
  disableSpaceKeyPress?: boolean;
  dropdownStyles?: SxProps<Theme>;
  updateAssessmentEntry?: (p: UpdateAssessmentEntryProps) => void;
}

export const GradeController: FC<GradeControllerProps> = ({
  groupId,
  relationId,
  entry,
  assessmentForGroup,
  method,
  list,
  onSuccess,
  open,
  disableEscapeKeyDown,
  onFocus,
  noStopPropagationOnSelect,
  disableSpaceKeyPress,
  dropdownStyles,
  updateAssessmentEntry,
}) => {
  const { showNotification } = useNotifications();
  const { permissions } = useAuth();
  const canEdit = Boolean(
    method.method_id &&
      (permissions.includes('assessment_manager') || assessmentForGroup.enterable_and_my_group),
  );

  const [localOptionId, setLocalOptionId] = useState<string>();
  const [error, setError] = useState<any>();

  const option = useMemo(() => {
    // TODO: remove temp value when BE is ready
    const optionId = localOptionId ?? entry?.select_list_option_id;
    return list?.options?.find((item) => item.id === optionId);
  }, [entry?.select_list_option_id, list?.options, localOptionId]);

  const isEmpty = !option?.id;

  const dropdownOptions = useMemo(
    () => list?.options?.map((item) => ({ value: item.id, label: item.name })) ?? [],
    [list?.options],
  );

  const handleChange = useCallback(
    async (option?: DropdownOption<string>) => {
      setError(undefined);

      try {
        setLocalOptionId(option?.value ?? '');

        const res = await upsertDeleteAssessmentEntry(method.method_id!, relationId, {
          group_id: groupId,
          select_list_option_id: option?.value || null,
        });

        if (method.method_id) {
          updateAssessmentEntry?.({
            entry: res.assessment_entry,
            assessmentId: assessmentForGroup.id,
            methodId: method.method_id,
            studentId: relationId,
          });
        }

        onSuccess?.();
      } catch (err) {
        console.error(err);

        setError(err);
        showNotification({
          message: (err as ApiError)?.reason,
          type: 'error',
        });

        setTimeout(() => {
          setError(undefined);
          setLocalOptionId(localOptionId);
        }, DEFAULT_NOTIFICATION_DURATION_MS);
      }
    },
    [
      assessmentForGroup.id,
      groupId,
      localOptionId,
      method.method_id,
      onSuccess,
      relationId,
      showNotification,
      updateAssessmentEntry,
    ],
  );

  const inputTextColor = useMemo(() => {
    if (error) {
      return 'error.main';
    }
    if (isEmpty) {
      return 'text.primary';
    }
  }, [error, isEmpty]);

  return (
    <Box
      sx={{
        backgroundColor: error ? 'error.superLight' : undefined,
      }}
    >
      {method.method_id && (
        <Dropdown
          sx={{
            '& input': {
              color: inputTextColor,
              textAlign: 'center',
              caretColor: 'transparent',
              '.GridRow:hover &, .GridRowItem:hover &, .MuiDataGrid-row.Mui-hovered &': {
                color: error ? 'error.main' : 'primary.main',
              },
            },
          }}
          dropdownStyles={{
            '.MuiPaper-root': {
              minWidth: 113,
            },
            '& .menuItemTop': {
              justifyContent: 'center',
              height: 44,
            },
            '& .MuiMenuItem-root': {
              marginX: theme.spacing(0.5),
              paddingX: theme.spacing(0.5),
            },
            '& .MuiIconButton-root': {
              top: theme.spacing(1.75),
              right: theme.spacing(1),
              fontSize: theme.spacing(2),
              paddingLeft: theme.spacing(0.5),
              borderRadius: 0,
              background: `linear-gradient(90deg, transparent ${theme.spacing(0)}, ${
                theme.palette.background.paper
              }  ${theme.spacing(0.5)} )`,
            },
            ...dropdownStyles,
          }}
          value={option?.id}
          placeholder="-"
          options={dropdownOptions}
          onChange={handleChange}
          canEdit={canEdit}
          noIcon={isEmpty}
          positioningOverride
          open={open}
          disableEscapeKeyDown={disableEscapeKeyDown}
          onFocus={onFocus}
          noStopPropagationOnSelect={noStopPropagationOnSelect}
          disableSpaceKeyPress={disableSpaceKeyPress}
        />
      )}
    </Box>
  );
};
