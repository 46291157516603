import { Box, Stack, Typography } from '@mui/material';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading, ModalSearch } from '@schooly/style';
import { DropdownYears } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { NoSearchResultsFound } from '../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { ListViewAssessments } from '../../../components/uikit-components/ListViewAssessments/ListViewAssessments';
import { MONTH_NONE } from '../../../constants/misc';
import { useProfile } from '../../../context/profile/useProfile';
import { useAssessmentsForRelation } from '../../../hooks/useAssessmentsForRelation';
import useSchoolYears from '../../../hooks/useSchoolYears';
import { ProfileModalAssessmentsExport } from './ProfileModalAssessmentsExport';
import { ProfileModalAssessmentsSubjectFilter } from './ProfileModalAssessmentsSubjectFilter';

export const ProfileModalAssessments: FC = () => {
  const { schoolMembership } = useProfile();
  const { $t } = useIntl();

  const {
    params,
    onSetSubjectIds,
    schoolId,
    totalCount,
    entriesByMonth,
    lists,
    searchText,
    setSearchText,
    isFetchingNextPage,
    isLoading,
    hasNextPage,
    selectedSchoolYear,
    handleSchoolYearChange,
    fetchNextPage,
  } = useAssessmentsForRelation(schoolMembership?.relation_id!);
  const { schoolYears, defaultValidity } = useSchoolYears();

  const loaderRef = useInfiniteScroll(
    isLoading || isFetchingNextPage || !hasNextPage,
    fetchNextPage,
  );

  return (
    <div className="section section-wrapper">
      <Stack direction="row" justifyContent="space-between" gap={2.5} mb={2.5}>
        <Typography variant="h2">
          <FormattedMessage id="section-Assessments" />
        </Typography>
        <Stack direction="row" alignItems="center" gap={2.5} mt={-0.75}>
          <Box>
            <ModalSearch
              value={searchText}
              onChange={setSearchText}
              placeholder={$t({ id: 'people-Search' })}
              withDebounce
            />
          </Box>
          {!!schoolId && (
            <ProfileModalAssessmentsSubjectFilter
              subjectIds={params.subjectIds || []}
              onSetSubjectIds={onSetSubjectIds}
              schoolId={schoolId}
            />
          )}
          <DropdownYears
            years={schoolYears}
            defaultYear={defaultValidity}
            currentYear={selectedSchoolYear}
            onYearChange={handleSchoolYearChange}
          />

          <ProfileModalAssessmentsExport />
        </Stack>
      </Stack>

      {isLoading ? (
        <Loading />
      ) : (
        <Stack
          gap={2.5}
          height="100%"
          sx={(theme) => ({
            overflowY: 'scroll',
            [theme.breakpoints.down('md')]: {
              marginRight: -2.5,
            },
          })}
        >
          {totalCount === 0 && <NoSearchResultsFound type="small" />}
          {Object.keys(entriesByMonth).map((month) => (
            <Box key={month}>
              {month !== MONTH_NONE && <Typography variant="h4">{month}</Typography>}
              <ListViewAssessments
                type="for-relation"
                assessments={entriesByMonth[month]}
                lists={lists}
                id={schoolMembership?.relation_id}
              />
            </Box>
          ))}

          {hasNextPage && (
            <Box py={3}>
              <div ref={loaderRef} />
              <Loading />
            </Box>
          )}
        </Stack>
      )}
    </div>
  );
};
