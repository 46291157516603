import {
  AssessmentForRelation,
  AssessmentsGrade,
  DATE_FORMAT_FULL_MONTH_FNS,
  IColumnSort,
  SchoolYear,
  SORT_DIRECTION,
  useGetAssessmentsForRelationQuery,
  useGetAssessmentsGradesForSchoolQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import debounce from 'lodash.debounce';
import { useCallback, useMemo } from 'react';

import useSchoolYears from './useSchoolYears';

export const SEARCH_DEBOUNCE_WAIT = 200;

const defaultSort: IColumnSort<keyof AssessmentForRelation>[] = [
  { columnTextId: 'assessment_date', direction: SORT_DIRECTION.DESC },
  { columnTextId: 'subject_order', direction: SORT_DIRECTION.ASC },
  { columnTextId: 'group_name', direction: SORT_DIRECTION.ASC },
  { columnTextId: 'assessment_name', direction: SORT_DIRECTION.ASC },
];

export const useAssessmentsForRelation = (relationId?: string) => {
  const { schoolId } = useAuth();
  const { defaultValidity, schoolYears } = useSchoolYears();

  const { data: grades, isLoading: gradesLoading } = useGetAssessmentsGradesForSchoolQuery(
    schoolId || '',
    { refetchOnMount: 'always', enabled: !!schoolId },
  );

  const {
    data,
    isLoading: assessmentsLoading,
    hasNextPage,
    params,
    setParams,
    fetchNextPage,
    isFetchingNextPage,
  } = useGetAssessmentsForRelationQuery(
    {
      sort: defaultSort,
      dateFrom: defaultValidity?.start,
      dateTo: defaultValidity?.end,
      relationId: relationId || '',
      subjectIds: [],
      query: '',
    },
    { refetchOnMount: 'always', enabled: !!relationId && !!defaultValidity },
  );

  const handleSetSubjectIds = useCallback(
    (subjectIds: string[]) => {
      setParams((params) => ({
        ...params,
        subjectIds,
      }));
    },
    [setParams],
  );

  const entriesByMonth = useMemo(() => {
    return (
      data?.pages
        .reduce<AssessmentForRelation[]>((prev, curr) => {
          prev.push(...curr.results);
          return prev;
        }, [])
        ?.reduce<Record<string, AssessmentForRelation[]>>((prev, entry) => {
          const month = format(
            newDateTimezoneOffset(entry.assessment_date),
            DATE_FORMAT_FULL_MONTH_FNS,
          ).toUpperCase();

          if (!prev[month]) {
            prev[month] = [];
          }

          prev[month].push(entry);

          return prev;
        }, {}) ?? {}
    );
  }, [data]);

  const setSearchTextDebounced = useMemo(
    () =>
      debounce((query: string) => setParams((p) => ({ ...p, query })), SEARCH_DEBOUNCE_WAIT, {
        leading: false,
        trailing: true,
      }),
    [setParams],
  );

  const lists = useMemo(() => {
    return grades?.reduce((prev, list) => {
      if (list) {
        prev[list.id] = list;
      }

      return prev;
    }, {} as Record<string, AssessmentsGrade>);
  }, [grades]);

  const handleSchoolYearChange = useCallback(
    (year: SchoolYear) => {
      setParams((p) => {
        return { ...p, dateFrom: year.start, dateTo: year.end };
      });
    },
    [setParams],
  );

  const selectedSchoolYear = useMemo(
    () => schoolYears.find((y) => y.start === params.dateFrom && y.end === params.dateTo),
    [params.dateFrom, params.dateTo, schoolYears],
  );

  return {
    params,
    schoolId,
    selectedSchoolYear,
    schoolYears,
    lists,
    entriesByMonth,
    searchText: params.query || '',
    setSearchText: setSearchTextDebounced,
    grades,
    totalCount: data?.pages[0].count,
    isLoading: gradesLoading || assessmentsLoading,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    handleSchoolYearChange,
    onSetSubjectIds: handleSetSubjectIds,
  };
};
