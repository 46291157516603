import { Box, Button, Stack, Typography } from '@mui/material';
import { EmptyFolderSvg } from '@schooly/style';
import React, { ReactNode } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { ProfileModalMode } from '../../../context/profile/helpers';
import { capitalizeFirstLetter } from '../../../helpers/misc';

import './index.scss';

interface EmptyStubProps {
  mode: ProfileModalMode;
  onButtonClick?: () => void;
  buttonIcon?: ReactNode;
  messageTextId?: string;
  buttonTextId?: string;
  tabNameTextId?: string;
  textWidth?: number;
}

const EmptyStub: React.FC<EmptyStubProps> = ({
  mode,
  messageTextId,
  onButtonClick,
  buttonTextId,
  buttonIcon,
  tabNameTextId,
  textWidth,
}) => {
  const { $t } = useIntl();

  switch (mode) {
    case ProfileModalMode.DependantsAndProducts:
    case ProfileModalMode.PayableFees:
      return (
        <>
          <Stack mb={2.5}>
            <Typography variant="h2">
              <FormattedMessage id={tabNameTextId} />
            </Typography>
          </Stack>
          <Stack mt={5} justifyContent="center" alignItems="center" position="relative">
            <Box
              mb={6}
              sx={{
                svg: {
                  width: 300,
                },
              }}
            >
              <EmptyFolderSvg />
            </Box>
            <Typography
              maxWidth={textWidth ?? 250}
              variant="h3"
              color="text.primary"
              textAlign="center"
            >
              {$t({ id: messageTextId })}
            </Typography>
            {onButtonClick && buttonTextId && (
              <Button
                startIcon={buttonIcon}
                onClick={onButtonClick}
                sx={(theme) => ({ mt: theme.spacing(3) })}
              >
                {$t({ id: buttonTextId })}
              </Button>
            )}
          </Stack>
        </>
      );
    default:
      return (
        <div className="ProfileEmptyStub">
          <h2 className="title d-flex align-items-center">{capitalizeFirstLetter(mode)}</h2>
          {mode === ProfileModalMode.Messages ? (
            <picture>
              <source srcSet="/images/inbox-stub.png, /images/inbox-stub@2x.png 2x" />
              <img className="ProfileEmptyStub__image" src="images/inbox-stub.png" alt={mode} />
            </picture>
          ) : (
            <picture>
              <source srcSet="/images/no-schools.png, /images/no-schools@2x.png 2x" />
              <img className="ProfileEmptyStub__image" src="images/no-schools.png" alt={mode} />
            </picture>
          )}
          <p className="ProfileEmptyStub__text">
            <FormattedMessage id="profile-Empty" values={{ title: mode }} />
          </p>
        </div>
      );
  }
};

export default EmptyStub;
