import { Box, Icon, Stack, Typography } from '@mui/material';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { PlusIcon } from '../../../components/ui/Icons';

interface AddFamilyMemberButtonProps {
  textId: string;
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  testId?: string;
}

export const AddFamilyMemberButton: React.FC<AddFamilyMemberButtonProps> = ({
  textId,
  onClick,
  testId,
}) => (
  <Box>
    <Box onClick={onClick} display="inline-block" data-cy={testId}>
      <Stack
        direction="row"
        alignItems="center"
        gap={1.5}
        sx={{
          cursor: 'pointer',
          color: (theme) => theme.palette.primary.main,
        }}
      >
        <Icon sx={{ display: 'flex' }}>
          <PlusIcon />
        </Icon>
        <Typography variant="h3">
          <FormattedMessage id={textId} />
        </Typography>
      </Stack>
    </Box>
  </Box>
);
