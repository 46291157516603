import { IconButton, Stack, Typography } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  DefaultSchoolYear,
  FilterKeys,
  FilterSection,
  GetGroupsQueryFilters,
  GroupArrangeBy,
  GROUPS_ARRANGE_BY_FILTER_KEYS,
  GROUPS_QUERY_FILTER_KEYS,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  AgeGroupExpandedSelect,
  DateRangeDropdown,
  filterExistingFilterOptions,
  getSelectedItemsWithGrouping,
  MoreButtonOption,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  PropertyTypeTagSelect,
  renderPropertyGroupTags,
  SelectedItem,
  SelectedItemWithGrouping,
  SubjectTagSelect,
  toggleMultipleValueArrayProperty,
  UserTagSelect,
} from '@schooly/components/filters';
import {
  ArrangeByDropdown,
  FilterDropdown,
  FiltersContainer,
  MoreButton,
  MoreButtonOptionType,
  StudentStaffPropertyTypeExpandedSelect,
  SubjectExpandedSelect,
  UserExpandedSelect,
} from '@schooly/components/filters';
import { SchoolPropertyType, SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { CloseSmallIcon } from '@schooly/style';
import { format } from 'date-fns';
import {
  FC,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { useCheckSchoolSettings } from '../../hooks/useCheckSchoolSettings';

export type GroupFilters = {
  openArrangeBy: () => void;
};
type GroupsFiltersProps = {
  arrangeBy: GroupArrangeBy | null;
  onSetArrangeBy: (v: GroupArrangeBy | null) => void;
  schoolId: string;
  filters: GetGroupsQueryFilters;
  defaultFilters: GetGroupsQueryFilters;
  onSetFilters: (v: GetGroupsQueryFilters) => void;
  notActualInitialDate?: boolean;
  defaultSchoolYear?: DefaultSchoolYear;
  defaultUserFilters: GetGroupsQueryFilters;
  defaultUserArrangeBy: GroupArrangeBy | null;
};

export const GroupsFiltersComponent: FC<GroupsFiltersProps> = (
  {
    arrangeBy,
    defaultFilters,
    filters: actualFilters,
    schoolId,
    onSetFilters,
    onSetArrangeBy,
    notActualInitialDate,
    defaultSchoolYear,
    defaultUserFilters,
    defaultUserArrangeBy,
  },
  ref: React.ForwardedRef<GroupFilters>,
) => {
  const { $t } = useIntl();
  const {
    getAgeGroupsByLevelId,
    getAgeGroupById,
    schoolLevelsWithAgeGroupsMap,
    getSchoolLevelById,
  } = useAgeGroups({
    schoolId: schoolId,
    userType: SchoolUserRole.Student,
  });
  const [dateChanged, setDateChanged] = useState(false);
  const moreButton = useRef<MoreButton | null>(null);
  const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);
  const arrangeByDropdown = useRef<ArrangeByDropdown | null>(null);
  const [draftFilters, setDraftFilters] = useState<GetGroupsQueryFilters>(actualFilters);
  const { currentStaff } = useAuth();

  const [draftArrangeBy, setDraftArrangeBy] =
    useState<GroupArrangeBy | null | undefined>(arrangeBy);

  const { hasSubjects, hasHouses } = useCheckSchoolSettings();

  const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
    switch (v) {
      case FilterKeys.StudentStatus:
        return setDraftFilters((filters) => {
          const hasValue = filters[FilterKeys.StudentStatus] !== undefined;

          return {
            ...filters,
            [FilterKeys.StudentStatus]: hasValue ? undefined : [],
            [FilterKeys.StaffStatus]: hasValue ? undefined : [],
          };
        });
      case FilterKeys.StudentHouse:
        return setDraftFilters((filters) => {
          const hasValue = filters[FilterKeys.StudentHouse] !== undefined;

          return {
            ...filters,
            [FilterKeys.StudentHouse]: hasValue ? undefined : [],
            [FilterKeys.StaffHouse]: hasValue ? undefined : [],
          };
        });
      case FilterKeys.OnlyTutorGroups:
        return setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.Subject]: undefined,
          [v]: filters[v] !== undefined ? undefined : ['1'],
        }));
      default:
        return setDraftFilters((filters) => ({
          ...filters,
          [v]: filters[v] !== undefined ? undefined : [],
        }));
    }
  }, []);

  useImperativeHandle(
    ref,
    () => ({
      openArrangeBy: () => {
        setDraftArrangeBy((arrangeBy) => (arrangeBy === null ? undefined : arrangeBy));
        setTimeout(() => arrangeByDropdown.current?.open(), 300);
      },
    }),
    [],
  );

  useEffect(() => {
    setDraftArrangeBy(arrangeBy);
    setDraftFilters(actualFilters);
  }, [actualFilters, arrangeBy]);

  const handleApply = useMemo(() => {
    // Operator != is used on purpose to properly compare against undefined and null
    // eslint-disable-next-line eqeqeq
    const arrangeByChanged = draftArrangeBy != arrangeBy;
    const filtersChanged = GROUPS_QUERY_FILTER_KEYS.some((key) => {
      const draftFiltersForKey = [...(draftFilters[key] || [])];
      const actualFiltersForKey = [...(actualFilters[key] || [])];

      return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
    });

    if (!filtersChanged && !arrangeByChanged) return undefined;

    return () => {
      filtersChanged && onSetFilters(draftFilters);
      arrangeByChanged && onSetArrangeBy(draftArrangeBy || null);
    };
  }, [onSetFilters, actualFilters, draftFilters, arrangeBy, onSetArrangeBy, draftArrangeBy]);

  const {
    onSetDate,
    onClearSubject,
    onSelectSubjectId,
    onClearOnlyTutorGroups,
    onSelectStaffId,
    onClearStaff,
    onClearAgeGroup,
    onClearHouse,
    onSelectAgeGroup,
    onSelectStaffHouseId,
    onSelectStudentHouseId,
    onClearStatus,
    onSelectStaffStatusId,
    onSelectStudentStatusId,
  } = useMemo(() => {
    const updateFilter = (key: keyof typeof actualFilters) => (id: string) => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: filters[key]?.includes(id)
          ? filters[key]?.filter((ct) => ct !== id)
          : [...(filters[key] || []), id],
      }));
    };
    const onSelectAgeGroup = (v: string[]) => {
      setDraftFilters((filters) => ({
        ...filters,
        [FilterKeys.AgeGroup]: toggleMultipleValueArrayProperty(filters[FilterKeys.AgeGroup], v),
      }));
    };
    const clearFilter = (key: keyof typeof actualFilters) => () => {
      setDraftFilters((filters) => ({
        ...filters,
        [key]: [],
      }));
    };

    return {
      onSetDate: (v: [Date, Date]) => {
        setDraftFilters((filters) => {
          const updatedDates = [
            format(v[0], DEFAULT_DATE_FORMAT_FNS),
            format(v[1], DEFAULT_DATE_FORMAT_FNS),
          ];

          if (updatedDates.join('') === filters.date?.join('')) return filters;

          setDateChanged(true);
          return {
            ...filters,
            [FilterKeys.Date]: updatedDates,
          };
        });
      },
      onSelectSubjectId: updateFilter(FilterKeys.Subject),
      onClearSubject: clearFilter(FilterKeys.Subject),
      onClearOnlyTutorGroups: () =>
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.OnlyTutorGroups]: undefined,
        })),
      onSelectStaffId: updateFilter(FilterKeys.Staff),
      onClearStaff: clearFilter(FilterKeys.Staff),
      onSelectAgeGroup,
      onClearAgeGroup: clearFilter(FilterKeys.AgeGroup),
      onSelectStaffHouseId: updateFilter(FilterKeys.StaffHouse),
      onSelectStudentStatusId: updateFilter(FilterKeys.StudentStatus),
      onSelectStaffStatusId: updateFilter(FilterKeys.StaffStatus),
      onSelectStudentHouseId: updateFilter(FilterKeys.StudentHouse),
      onClearStatus: () => {
        clearFilter(FilterKeys.StaffStatus)();
        clearFilter(FilterKeys.StudentStatus)();
      },
      onClearHouse: () => {
        clearFilter(FilterKeys.StaffHouse)();
        clearFilter(FilterKeys.StudentHouse)();
      },
    };
  }, []);

  const handleClearFilters = useCallback(() => {
    setDateChanged(false);
    setDraftArrangeBy(null);
    setDraftFilters(defaultFilters);
  }, [defaultFilters]);

  const handleToggleArrangeBy = useCallback(() => {
    setDraftArrangeBy((v) => (v === null ? undefined : null));
  }, []);

  const handleSaveFilter = useCallback(() => {
    personalFiltersDropdown.current?.saveFilter();
  }, []);

  const handleResetToDefault = useCallback(() => {
    setDraftArrangeBy(defaultUserArrangeBy);
    setDraftFilters(defaultUserFilters);
  }, [defaultUserArrangeBy, defaultUserFilters]);

  const handleOpenMoreButton = useCallback(() => {
    moreButton.current?.open();
  }, []);

  const filtersDate = draftFilters.date;
  const filtersOnlyTutorGroups = draftFilters.only_tutor_groups;
  const filtersSubject = draftFilters.subject;
  const filtersStaff = draftFilters.staff;
  const filtersStaffHouse = draftFilters.staff_house;
  const filtersStudentHouse = draftFilters.student_house;
  const filtersStaffStatus = draftFilters.staff_status;
  const filtersStudentStatus = draftFilters.student_status;
  const filtersAgeGroup = draftFilters.age_group;

  const onlyTutorGroupsLabel = $t({ id: 'groups-OnlyTutorGroups' });
  const subjectLabel = $t({ id: 'groups-GroupSubject' });
  const dateLabel = $t({ id: 'schoolProperty-Period' });
  const staffLabel = $t({ id: 'schoolProperty-Staff' });
  const ageGroupLabel = $t({ id: 'schoolProperty-AgeGroup' });

  const houseLabel = $t({
    id: 'schoolProperty-House',
  });
  const statusLabel = $t({ id: 'schoolProperty-Status' });

  const filterOptions: MoreButtonOption<keyof GetGroupsQueryFilters>[] = [
    { value: FilterKeys.Date, label: dateLabel, required: true },
    { value: FilterKeys.StudentStatus, label: statusLabel },
    { value: FilterKeys.AgeGroup, label: ageGroupLabel },
    { value: FilterKeys.StudentHouse, label: houseLabel },
    {
      value: FilterKeys.Subject,
      label: subjectLabel,
      disabled: Boolean(filtersOnlyTutorGroups),
      disabledHint: $t({ id: 'groups-GroupSubject-disabledFilter-Hint' }),
    },
    {
      value: FilterKeys.OnlyTutorGroups,
      label: onlyTutorGroupsLabel,
      type: MoreButtonOptionType.Switch,
    },
    { value: FilterKeys.Staff, label: staffLabel },
  ];

  const selectedItemsForAgeGroups: SelectedItemWithGrouping[] = useMemo(() => {
    const selectedAgeGroups =
      filtersAgeGroup?.reduce<SelectedItem[]>((acc, id) => {
        const ageGroup = getAgeGroupById(id);

        return ageGroup
          ? [
              ...acc,
              {
                id: ageGroup.id,
                groupId: ageGroup.level_id,
              },
            ]
          : acc;
      }, []) ?? [];

    return getSelectedItemsWithGrouping(selectedAgeGroups, schoolLevelsWithAgeGroupsMap);
  }, [filtersAgeGroup, schoolLevelsWithAgeGroupsMap, getAgeGroupById]);

  return (
    <FiltersContainer onApply={handleApply}>
      <PersonalFiltersDropdown
        data-cy={'filter-personal'}
        ref={personalFiltersDropdown}
        onOpenFilters={handleOpenMoreButton}
        onSaveFilter={handleSaveFilter}
        currentUser={currentStaff}
        relationId={currentStaff?.relation_id || ''}
        schoolId={schoolId}
        section={FilterSection.Group}
        filters={{ ...draftFilters, arrange_by: draftArrangeBy || undefined }}
        defaultSchoolYear={defaultSchoolYear}
        onSetFilters={(v) => {
          onSetFilters(pickOnlyParamsFromFilterKeys(GROUPS_QUERY_FILTER_KEYS, v));

          if (!v.arrange_by) {
            onSetArrangeBy(null);
            return;
          }
          for (const key of GROUPS_ARRANGE_BY_FILTER_KEYS) {
            key === v.arrange_by && onSetArrangeBy(key);
          }
        }}
      />
      {filtersDate && (
        <DateRangeDropdown
          schoolId={schoolId}
          date={filtersDate}
          dateChanged={dateChanged}
          onSetDate={onSetDate}
          notActualInitialDate={notActualInitialDate}
          openLabel={dateLabel}
          defaultSchoolYear={defaultSchoolYear}
        />
      )}
      {(filtersStaffStatus || filtersStudentStatus) && (
        <FilterDropdown
          data-cy={'filter-status'}
          onClear={() => toggleFiltersVisible(FilterKeys.StudentStatus)}
          label={statusLabel}
          tags={(open) =>
            (filtersStudentStatus || [])
              .map((id) => (
                <PropertyTypeTagSelect
                  userRole={SchoolUserRole.Student}
                  sx={{ maxWidth: 200 }}
                  schoolId={schoolId}
                  key={id}
                  id={id}
                  onClick={open}
                />
              ))
              .concat(
                (filtersStaffStatus || []).map((id) => (
                  <PropertyTypeTagSelect
                    userRole={SchoolUserRole.Staff}
                    sx={{ maxWidth: 200 }}
                    schoolId={schoolId}
                    key={id}
                    id={id}
                    onClick={open}
                  />
                )),
              )
          }
        >
          {(onClose) => (
            <StudentStaffPropertyTypeExpandedSelect
              selectedStudentIds={filtersStudentStatus || []}
              selectedStaffIds={filtersStaffStatus || []}
              schoolId={schoolId}
              onClose={onClose}
              onSelectStaffId={onSelectStaffStatusId}
              onSelectStudentId={onSelectStudentStatusId}
              onClear={onClearStatus}
              propertyType={SchoolPropertyType.Status}
            />
          )}
        </FilterDropdown>
      )}

      {filtersAgeGroup && (
        <FilterDropdown
          data-cy={'filter-age-group'}
          onClear={() => toggleFiltersVisible(FilterKeys.AgeGroup)}
          label={ageGroupLabel}
          tags={(open) =>
            renderPropertyGroupTags({
              selectedItems: selectedItemsForAgeGroups,
              onClick: open,
              getProperty: (i) =>
                i.isGroup
                  ? getSchoolLevelById(i.id)
                  : { ...getAgeGroupById(i.id), type: SchoolPropertyType.AgeGroup },
              getTooltip: (i) =>
                i.isGroup
                  ? getAgeGroupsByLevelId(i.id).map((ageGroup) => (
                      <Typography key={ageGroup.id}>{ageGroup.name}</Typography>
                    ))
                  : null,
              tagProps: {
                userRole: SchoolUserRole.Student,
                sx: { maxWidth: 200 },
              },
            })
          }
        >
          {(onClose) => (
            <AgeGroupExpandedSelect
              userRole={SchoolUserRole.Student}
              schoolId={schoolId}
              selectedIds={filtersAgeGroup}
              onSelect={onSelectAgeGroup}
              onClose={onClose}
              onClear={onClearAgeGroup}
            />
          )}
        </FilterDropdown>
      )}
      {(filtersStaffHouse || filtersStudentHouse) && (
        <FilterDropdown
          data-cy={'filter-house'}
          onClear={() => toggleFiltersVisible(FilterKeys.StudentHouse)}
          label={houseLabel}
          tags={(open) =>
            (filtersStudentHouse || [])
              .map((id) => (
                <PropertyTypeTagSelect
                  userRole={SchoolUserRole.Student}
                  sx={{ maxWidth: 200 }}
                  schoolId={schoolId}
                  key={id}
                  id={id}
                  onClick={open}
                />
              ))
              .concat(
                (filtersStaffHouse || []).map((id) => (
                  <PropertyTypeTagSelect
                    userRole={SchoolUserRole.Staff}
                    sx={{ maxWidth: 200 }}
                    schoolId={schoolId}
                    key={id}
                    id={id}
                    onClick={open}
                  />
                )),
              )
          }
        >
          {(onClose) => (
            <StudentStaffPropertyTypeExpandedSelect
              selectedStudentIds={filtersStudentHouse || []}
              selectedStaffIds={filtersStaffHouse || []}
              schoolId={schoolId}
              onClose={onClose}
              onSelectStaffId={onSelectStaffHouseId}
              onSelectStudentId={onSelectStudentHouseId}
              onClear={onClearHouse}
              propertyType={SchoolPropertyType.House}
            />
          )}
        </FilterDropdown>
      )}
      {filtersStaff && (
        <FilterDropdown
          data-cy={'filter-staff'}
          onClear={() => toggleFiltersVisible(FilterKeys.Staff)}
          label={staffLabel}
          tags={(open) =>
            filtersStaff.map((id) => (
              <UserTagSelect
                userType="staff"
                currentUser={currentStaff}
                sx={{ maxWidth: 200 }}
                schoolId={schoolId}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <UserExpandedSelect
              type="staff"
              schoolId={schoolId}
              currentUser={currentStaff}
              selectedIds={filtersStaff}
              onSelectUserId={onSelectStaffId}
              onClose={onClose}
              onClear={onClearStaff}
              filters={draftFilters}
            />
          )}
        </FilterDropdown>
      )}
      {filtersSubject && (
        <FilterDropdown
          data-cy={'filter-subject'}
          onClear={() => toggleFiltersVisible(FilterKeys.Subject)}
          label={subjectLabel}
          tags={(open) =>
            filtersSubject.map((id) => (
              <SubjectTagSelect
                schoolId={schoolId}
                sx={{ maxWidth: 200 }}
                key={id}
                id={id}
                onClick={open}
              />
            ))
          }
        >
          {(onClose) => (
            <SubjectExpandedSelect
              schoolId={schoolId}
              selectedIds={filtersSubject}
              onSelectSubjectId={onSelectSubjectId}
              onClose={onClose}
              onClear={onClearSubject}
            />
          )}
        </FilterDropdown>
      )}
      {filtersOnlyTutorGroups && (
        <Stack direction="row" gap={1} data-cy={'filter-tutor-groups'}>
          <Typography variant="h3">
            <FormattedMessage id="groups-OnlyTutorGroups" />
          </Typography>
          <IconButton
            sx={{ fontSize: (theme) => theme.spacing(2) }}
            onClick={onClearOnlyTutorGroups}
          >
            <CloseSmallIcon />
          </IconButton>
        </Stack>
      )}
      {draftArrangeBy !== null && (
        <ArrangeByDropdown
          data-cy={'filter-arrange-by'}
          ref={(r: any) => (arrangeByDropdown.current = r as ArrangeByDropdown)}
          onSelectOption={setDraftArrangeBy}
          selectedOption={draftArrangeBy}
          options={GROUPS_ARRANGE_BY_FILTER_KEYS}
          onClear={() => setDraftArrangeBy(undefined)}
          onRemove={() => setDraftArrangeBy(null)}
        />
      )}
      <MoreButton
        ref={moreButton}
        onResetToDefault={handleResetToDefault}
        onClearFilters={handleClearFilters}
        options={filterExistingFilterOptions({ filterOptions, hasHouses, hasSubjects })}
        selectedOptions={GROUPS_QUERY_FILTER_KEYS.filter((key) => !!draftFilters[key])}
        isSelectedArrangeBy={draftArrangeBy !== null}
        onToggleArrangeBy={handleToggleArrangeBy}
        onToggleOption={toggleFiltersVisible}
      />
    </FiltersContainer>
  );
};

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const GroupsFilters = forwardRef(GroupsFiltersComponent);
