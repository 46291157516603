import { Typography } from '@mui/material';
import { Assessment } from '@schooly/api';
import { Loading } from '@schooly/style';
import React, { FC, useRef } from 'react';

import { ModalContent } from '../../../components/uikit-components/Modal/Modal.styled';
import useQueryStringParams from '../../../hooks/useQueryStringParams';
import { WithRef } from '../../../hooks/useWithRef';
import { AssessmentsPreviewEntriesTable } from './AssessmentsPreviewEntries/AssessmentsPreviewEntriesTable';

interface AssessmentsPreviewModalContentProps {
  fetching: boolean;
  assessment?: Assessment;
}
export const AssessmentsPreviewModalContent: FC<AssessmentsPreviewModalContentProps> = ({
  fetching,
  assessment,
}) => {
  const { group_id } = useQueryStringParams<'group_id'>();

  const group = assessment?.groups.find((item) => item.id === group_id);
  const containerRef = useRef<HTMLDivElement>(null);

  if (fetching) {
    return <Loading />;
  }

  return (
    <ModalContent ref={containerRef} sx={{ pb: 0 }}>
      <WithRef containerRef={containerRef}>
        <Typography variant="h2" mb={2.5}>
          {group?.name}
        </Typography>
        <AssessmentsPreviewEntriesTable data={assessment} />
      </WithRef>
    </ModalContent>
  );
};
