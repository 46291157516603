import { FC, PropsWithChildren, useEffect, useRef } from 'react';

import theme from '../../../theme/theme';
import { HoverProps, TableHoverContext } from './TableHoverContext';

const DATA_HOVER_ID = 'data-hover-id';

const HOVER_CLASS_NAME = 'hover';
export const TABLE_HOVER_ICON_CLASS_NAME = 'hoverIcon';

const SELF_HOVER_CLASS_NAME = 'selfHover';
export const ROW_HOVER_ID = 'row-hover-id';

interface WithTableHoverProps extends PropsWithChildren {
  hoverColor?: string;
  hoverIconColor?: string;
}

export const WithTableHover: FC<WithTableHoverProps> = ({
  children,
  hoverColor,
  hoverIconColor,
}) => {
  const gridRef = useRef<HTMLTableSectionElement | null>(null);
  const columnRef = useRef<HTMLTableRowElement | null>(null);
  const elementRef = useRef<Element[] | null>(null);

  const onColumnMouseOver = ({ colIndex, disableHover }: Omit<HoverProps, 'cellId'>) => {
    if (disableHover) {
      return;
    }

    const rowsCollection = gridRef.current ? Array.from(gridRef.current.children) : null;
    const columnsCollection = columnRef.current ? Array.from(columnRef.current.children) : null;

    if (!rowsCollection?.length || !columnsCollection?.length) {
      return;
    }

    const cellsToHover: Element[] = [];

    const columnCellElement = columnsCollection[colIndex];

    const columnId = columnCellElement.getAttribute(DATA_HOVER_ID);

    if (!columnId) {
      return;
    }

    rowsCollection.forEach((rowEl) => {
      Array.from(rowEl.children).forEach((rowCell) => {
        const rowDataAttr = rowCell.getAttribute(DATA_HOVER_ID);
        if (!rowDataAttr) {
          return;
        }
        // If column has colspan, in rows we generate keys `columnId:someKey`
        const [rowColumnId] = rowDataAttr.split(':');

        if (rowColumnId === columnId) {
          cellsToHover.push(rowCell);
        }
      });
    });

    cellsToHover.push(columnCellElement);

    cellsToHover.forEach((c) => c.classList.add(HOVER_CLASS_NAME));
    elementRef.current = cellsToHover;
  };

  const onRowMouseOver = ({
    colIndex,
    rowIndex,
    onlyXHover,
    disableHover,
    multiRowHover,
  }: Omit<HoverProps, 'cellId'>) => {
    if (disableHover) {
      return;
    }

    if (elementRef.current) {
      onMouseLeave();
    }

    const rowsCollection = gridRef.current ? Array.from(gridRef.current.children) : null;
    const columnsCollection = columnRef.current ? Array.from(columnRef.current.children) : null;

    if (!rowsCollection) {
      return;
    }

    const currentRow = rowsCollection[rowIndex];
    const rowCells = Array.from(currentRow.children);
    const rowCellElement = rowCells[colIndex];

    if (multiRowHover) {
      const attr = currentRow.getAttribute(ROW_HOVER_ID);

      const cells = rowsCollection
        .filter((r) => r.getAttribute(ROW_HOVER_ID) === attr)
        .flatMap((rowEl) => {
          return Array.from(rowEl.children);
        });

      cells.forEach((c) => c.classList.add(HOVER_CLASS_NAME));
      elementRef.current = cells;

      return;
    }

    // First cell in row hovered
    if (colIndex === 0 || onlyXHover) {
      rowCells.forEach((c) => c.classList.add(HOVER_CLASS_NAME));
      elementRef.current = rowCells;

      return;
    }

    const rowId = rowCellElement.getAttribute(DATA_HOVER_ID);

    if (!rowId) {
      return;
    }

    const cellsToHover: Element[] = [];

    const rowPrevCells = rowCells.slice(0, colIndex);

    // Vertical cells
    rowsCollection.slice(0, rowIndex).forEach((rowEl) => {
      cellsToHover.push(Array.from(rowEl.children)[colIndex]);
    });

    // Horizontal cells
    rowPrevCells.forEach((c) => {
      cellsToHover.push(c);
    });

    // Column cell
    if (columnsCollection) {
      // If column has colspan, in rows we generate keys `columnId:someKey`
      const [id] = rowId.split(':');

      const columnCellElement = columnsCollection.find((col) => {
        const attr = col.getAttribute(DATA_HOVER_ID);
        return id === attr;
      });

      if (columnCellElement) {
        cellsToHover.push(columnCellElement);
      }
    }

    // Hovered cell
    rowCellElement.classList.add(SELF_HOVER_CLASS_NAME);

    cellsToHover.forEach((c) => {
      if (c) {
        c.classList.add(HOVER_CLASS_NAME);
      }
    });
    elementRef.current = [rowCellElement, ...cellsToHover];
  };

  const onMouseLeave = () => {
    if (elementRef.current) {
      elementRef.current.forEach((e) => {
        if (e) {
          e.classList.remove(SELF_HOVER_CLASS_NAME);
          e.classList.remove(HOVER_CLASS_NAME);
        }
      });
    }
  };

  useEffect(
    () => () => {
      onMouseLeave();
    },
    [],
  );

  const getBasedProps = (cellId: HoverProps['cellId']) => ({
    [DATA_HOVER_ID]: cellId,
    onMouseLeave,
  });

  const getRowHoverProps = ({ cellId, ...props }: HoverProps) => {
    return {
      ...getBasedProps(cellId),
      onMouseEnter: () => {
        onRowMouseOver(props);
      },
    };
  };

  const getColumnHoverProps = ({ cellId, ...props }: HoverProps) => {
    return {
      ...getBasedProps(cellId),
      onMouseEnter: () => {
        onColumnMouseOver(props);
      },
    };
  };

  const styles = {
    [`.${HOVER_CLASS_NAME}`]: {
      backgroundColor: `${hoverColor ? hoverColor : theme.palette.background.default} !important`,
      'div, p, th, a, &.MuiTableCell-root': {
        color: `${theme.palette.primary.main} !important`,
      },
      [`.${TABLE_HOVER_ICON_CLASS_NAME}`]: {
        color: `${hoverIconColor ? hoverIconColor : theme.palette.background.default} !important`,
      },
    },
    [`.${SELF_HOVER_CLASS_NAME}`]: {
      backgroundColor: `${theme.palette.common.grey7} !important`,
      'div, p, th, a, &.MuiTableCell-root': {
        color: theme.palette.primary.main,
      },
      [`.${TABLE_HOVER_ICON_CLASS_NAME}`]: {
        color: `${hoverIconColor ? hoverIconColor : theme.palette.background.default} !important`,
      },
    },
  };

  const value = {
    getRowHoverProps,
    getColumnHoverProps,
    gridRef,
    columnRef,
    styles,
  };

  return <TableHoverContext.Provider value={value}>{children}</TableHoverContext.Provider>;
};
