import { Box, Stack, Typography } from '@mui/material';
import { FilterValue, SelectOption } from '@schooly/api';
import { getControllerErrorText } from '@schooly/utils/get-controller-error-text';
import { useCallback } from 'react';
import { Controller, ControllerProps, get, useFormContext } from 'react-hook-form-lts';
import { FieldValues } from 'react-hook-form-lts/dist/types';
import { FormattedMessage, useIntl } from 'react-intl';

import { BaseFormInputProps } from '../../ui/Input/utils';
import { SelectChip } from './ChipSelectGroup.styled';

type PickedInputProps = Pick<React.InputHTMLAttributes<HTMLInputElement>, 'defaultValue'>;

interface ChipSelectGroupProps<T>
  extends Omit<ControllerProps<FieldValues>, 'name' | 'render' | 'defaultValue'>,
    BaseFormInputProps,
    PickedInputProps {
  options: SelectOption<T>[];
  withoutDivider?: boolean;
}

export const ChipSelectGroup = <T extends any = FilterValue>({
  options,
  labelTextId,
  name,
  withoutDivider,
  ...props
}: ChipSelectGroupProps<T>) => {
  const { $t } = useIntl();
  const { formState, control, watch } = useFormContext();
  const value = watch(name);
  const errorMessage = getControllerErrorText(get(formState.errors, name), props.rules, $t);
  const error = Boolean(errorMessage);
  const required = Boolean(props.required);

  const handleClick = useCallback(
    (onChange: (val?: T) => void, optionValue: T) => () => {
      onChange(optionValue);
    },
    [],
  );

  return (
    <Controller
      {...props}
      control={control}
      name={name}
      rules={{
        required: {
          value: required,
          message: $t({ id: 'applications-Language-ChooseLevel' }),
        },
      }}
      render={({ field }) => (
        <Box>
          <Stack gap={0.25}>
            {labelTextId && (
              <Typography variant="caption" color="text.secondary">
                <FormattedMessage id={labelTextId} />
              </Typography>
            )}

            <Stack direction="row" alignItems="center">
              {options.map((option, i) => {
                const isSelected = `${option.value}` === String(value);
                return (
                  <>
                    <SelectChip
                      key={`${option.value}`}
                      variant="outlined"
                      selected={isSelected}
                      error={!!error}
                      label={
                        option.labelTextId ? (
                          <FormattedMessage id={option.labelTextId} />
                        ) : (
                          option.label
                        )
                      }
                      onClick={handleClick(field.onChange, option.value)}
                      data-cy={`chip-select-${labelTextId}-${option.labelTextId}`}
                    />
                    {i !== options.length - 1 && !withoutDivider && (
                      <Box
                        key={`box_${option.value}`}
                        sx={(theme) => ({
                          height: '1px',
                          width: theme.spacing(2),
                          background: theme.palette.common.light3,
                        })}
                      />
                    )}
                  </>
                );
              })}
            </Stack>
            {errorMessage && (
              <Typography variant="caption" color="error.main">
                <FormattedMessage id={errorMessage} />
              </Typography>
            )}
          </Stack>
        </Box>
      )}
    />
  );
};
