import { acceptSuggestedChange, ApiError, SyncUser } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import { DELAY_BEFORE_HIDE_ICON_DONE, SuggestedChangeDataSet } from '@schooly/constants';
import { DoneIcon, Spin, SyncIcon } from '@schooly/style';
import React, { useCallback, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import Button from '../../../components/ui/Button';
import Popover from '../../../components/ui/Popover';
import { useProfile } from '../../../context/profile/useProfile';
import { getUserFullName } from '../../../helpers/users';
import useFlag from '../../../hooks/useFlag';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import buildClassName from '../../../utils/buildClassName';
import IntlError from '../../../utils/intlError';
import {
  getChangeValue,
  getRelatedChanges,
  getSuggestedChangeTypeId,
  getUserNotificationTextId,
  hasPermissionForAcceptChanges,
} from './utils';

import './index.scss';

export interface AcceptChangePopoverProps {
  dataSet?: SuggestedChangeDataSet;
  fieldName?: keyof SyncUser;
  className?: string;
  canShow: boolean;
}

export const AcceptChangePopover = ({
  dataSet,
  fieldName,
  className,
  canShow,
}: AcceptChangePopoverProps) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const [isPopoverOpen, openPopover, closePopover] = useFlag();
  const [isIconDoneShow, showIconDone, hideIconDone] = useFlag();
  const [isIconMainShow, showIconMain] = useFlag();
  const { schoolId, permissions } = useAuth();
  const { showError } = useNotifications();

  const { user, userType, suggestedChanges, actions } = useProfile();

  const invalidateQueries = useInvalidateListQueriesFor(userType || 'student');

  const relatedChanges = useMemo(() => {
    if (!suggestedChanges) {
      return [];
    }

    if (!userType || !hasPermissionForAcceptChanges(userType, permissions)) {
      return [];
    }
    return getRelatedChanges(suggestedChanges, dataSet, fieldName);
  }, [dataSet, fieldName, permissions, suggestedChanges, userType]);

  // For now we assume that there is always a single initiator for a set of changes
  const changeInitiator = useMemo(
    () => (relatedChanges.length ? relatedChanges[0].initiator : undefined),
    [relatedChanges],
  );

  const changeValue = useMemo(
    () => getChangeValue(dataSet, fieldName, relatedChanges, user as SyncUser),
    [dataSet, fieldName, relatedChanges, user],
  );

  const applyAcceptChange = useCallback(async () => {
    if (!relatedChanges.length || !schoolId) {
      return;
    }

    try {
      await Promise.all(relatedChanges.map(({ id }) => acceptSuggestedChange(schoolId, id)));
      await actions.handleUserUpdate();

      invalidateQueries();

      showIconMain();
      showIconDone();

      setTimeout(() => hideIconDone(), DELAY_BEFORE_HIDE_ICON_DONE);
    } catch (e) {
      showError(e as ApiError | IntlError);
    }
  }, [
    actions,
    hideIconDone,
    invalidateQueries,
    relatedChanges,
    schoolId,
    showError,
    showIconDone,
    showIconMain,
  ]);

  const [handleAcceptChange, isRequestInProgress] = useRequestWithProgress(applyAcceptChange);

  const mainClassName = buildClassName(
    'AcceptChangePopover',
    'd-flex align-items-center',
    className,
  );

  if (!canShow) {
    return null;
  }

  if (
    !userType ||
    userType === 'adult' ||
    userType === 'child' ||
    (!relatedChanges.length && !isIconDoneShow)
  ) {
    return null;
  }

  const isPopoverActive = isRequestInProgress || isIconMainShow;

  return (
    <div
      className={mainClassName}
      onMouseEnter={openPopover}
      onMouseLeave={closePopover}
      ref={popoverRef}
    >
      {!isIconMainShow && (
        <>
          {isRequestInProgress ? (
            <Spin className="spinner-icon" />
          ) : (
            <SyncIcon className="sync-icon mr-0 cursor-pointer" />
          )}
        </>
      )}
      {isIconDoneShow && <DoneIcon className="done-icon" />}
      {!!relatedChanges.length && (
        <Popover
          open={isPopoverOpen && !isPopoverActive}
          onMouseEnter={openPopover}
          onMouseLeave={closePopover}
          anchorRef={popoverRef}
        >
          <div
            role="button"
            tabIndex={0}
            className="AcceptChangePopover-popover"
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <div className="AcceptChangePopover-info">
              <FormattedMessage
                id={getUserNotificationTextId(
                  (user as SyncUser).user_id === changeInitiator?.user_id,
                  changeValue,
                )}
                values={{
                  username: getUserFullName(changeInitiator!),
                  typeOfChange: (
                    <span className="text-lowercase">
                      <FormattedMessage id={getSuggestedChangeTypeId(dataSet, fieldName)} />
                    </span>
                  ),
                  newValue: <span className="text-primary font-weight-bold">{changeValue}</span>,
                }}
              />
            </div>
            <Button className="AcceptChangePopover-button" onClick={handleAcceptChange}>
              <FormattedMessage id="action-AcceptChange" />
            </Button>
          </div>
        </Popover>
      )}
    </div>
  );
};
