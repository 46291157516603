import { Box, Stack, Typography } from '@mui/material';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading } from '@schooly/style';
import React, { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import EmptyStub from '../../../components/common/ProfileModal/EmptyStub';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { ListViewMessages } from '../../../components/uikit-components/ListViewMessages/ListViewMessages';
import { ListViewMessagesRowVariant } from '../../../components/uikit-components/ListViewMessagesRow/ListViewMessagesRow';
import { MONTH_NONE } from '../../../constants/misc';
import { ProfileModalMode } from '../../../context/profile/helpers';
import { useProfile } from '../../../context/profile/useProfile';
import { useMessagesForRelation } from '../../../hooks/useMessagesForRelation';
import buildClassName from '../../../utils/buildClassName';

export const ProfileModalMessages: FC = () => {
  const { schoolMembership } = useProfile();
  const { messagesByMonth, count, isFetching, canShowMore, handleShowMore } =
    useMessagesForRelation(schoolMembership?.relation_id!);
  const loaderRef = useInfiniteScroll(isFetching, handleShowMore);

  const isEmpty = !isFetching && !count;

  if (isEmpty) {
    return <EmptyStub mode={ProfileModalMode.Messages} />;
  }

  return (
    <Box flex="1" className={buildClassName('section', isEmpty && 'ProfileEmptyStub')}>
      <Stack direction="row" alignItems="center" mb={2.5}>
        <Typography variant="h2">
          <FormattedMessage id="profile-Messages" />
        </Typography>
        {!!count && <Counter>{count}</Counter>}
      </Stack>

      {!canShowMore && isFetching ? (
        <Loading />
      ) : (
        <Stack gap={2.5}>
          {Object.keys(messagesByMonth).map((month) => (
            <Box key={month}>
              {month !== MONTH_NONE && <Typography variant="h4">{month}</Typography>}

              <ListViewMessages
                messages={messagesByMonth[month]}
                variant={ListViewMessagesRowVariant.Profile}
              />
            </Box>
          ))}

          {canShowMore && (
            <Box py={3}>
              {isFetching && <Loading />}
              <div ref={loaderRef} />
            </Box>
          )}
        </Stack>
      )}
    </Box>
  );
};
