import { Icon, Stack, Typography } from '@mui/material';
import { useGetSchoolAccounts } from '@schooly/api';
import {
  DropdownSelect,
  DropdownSelectProps,
  SelectContentSkeleton,
  SelectSearchInput,
} from '@schooly/components/filters';
import { DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import { FC, PropsWithChildren, useCallback, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { renderAccountName } from '../helpers';

type AccountSelectProps = PropsWithChildren<{
  schoolId: string;
  selectedAccountId?: string;
  isAdmin?: boolean;
  onSelectAccountId: (v: string) => void;
}> &
  Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const AccountSelect: FC<AccountSelectProps> = ({
  schoolId,
  selectedAccountId,
  onSelectAccountId,
  isAdmin,
  placeholder,
  ...dropdownProps
}) => {
  const [query, setQuery] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<DropdownSelect | null>(null);
  const { data: accounts } = useGetSchoolAccounts(schoolId, {
    refetchOnMount: true,
  });

  const selectedAccount = accounts?.find((g) => g.id === selectedAccountId);

  const renderContent = useCallback(() => {
    if (!accounts) return <SelectContentSkeleton />;

    const filteredAccounts = accounts.filter((a) =>
      renderAccountName(a).toLowerCase().includes(query.toLowerCase()),
    );

    if (!filteredAccounts.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {filteredAccounts.map((account) => {
          const isSelected = selectedAccountId === account.id;

          return (
            <Stack
              key={account.id}
              onClick={() => {
                onSelectAccountId(account.id);
                dropdownRef.current?.close();
              }}
              flexDirection="row"
              alignItems="center"
              px={1}
              gap={2}
              py={0.5}
              sx={(theme) => ({
                cursor: 'pointer',
                '&:hover': {
                  backgroundColor: theme.palette.background.default,
                },
              })}
            >
              <TypographyWithOverflowHint
                flex={1}
                color={!isSelected ? 'common.grey2' : undefined}
                variant="h3"
                noWrap
              >
                {renderAccountName(account)}
              </TypographyWithOverflowHint>

              <Icon
                sx={{
                  visibility: isSelected ? 'visible' : 'hidden',
                }}
              >
                <DoneIcon />
              </Icon>
            </Stack>
          );
        })}
      </>
    );
  }, [accounts, onSelectAccountId, selectedAccountId, query]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdownRef}
      hasValues={!!selectedAccountId}
      renderContent={renderContent}
      placeholder=""
    >
      {(opened) => (
        <>
          {selectedAccount ? (
            <Typography variant="h3">{renderAccountName(selectedAccount)}</Typography>
          ) : opened ? (
            <SelectSearchInput
              placeholder={placeholder || undefined}
              ref={inputRef}
              autoFocus={opened}
              value={query}
              width="100%"
              onChangeText={setQuery}
            />
          ) : (
            <Typography variant="h3" color="common.grey">
              {placeholder}
            </Typography>
          )}
        </>
      )}
    </DropdownSelect>
  );
};
