import { Button, IconButton, Stack } from '@mui/material';
import { Currencies } from '@schooly/constants';
import { ArrowLeftIcon, CrossIcon, ModalFooter, ModalHeader, ModalLarge } from '@schooly/style';
import React, { FC, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { LegalEntityProductsContent } from './LegalEntityProductsContent';

type LegalEntityProductsModalProps = {
  legalEntityId: string;
  schoolId: string;
  onGoBack: () => void;
  onClose: () => void;
  onReplace: () => void;
  canDelete?: boolean;
  currency?: Currencies;
  title: ReactNode;
  onRemove: () => void;
};

export const LegalEntityProductsModal: FC<LegalEntityProductsModalProps> = ({
  onGoBack,
  onClose,
  legalEntityId,
  schoolId,
  canDelete,
  currency,
  title,
  onReplace,
  onRemove,
}) => {
  const { $t } = useIntl();

  return (
    <ModalLarge open onClose={onClose}>
      <ModalHeader
        sx={{
          py: 1.75,
        }}
        active
        title={title}
      >
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>

      <LegalEntityProductsContent
        legalEntityId={legalEntityId}
        schoolId={schoolId}
        currency={currency}
      />

      <ModalFooter active sx={{ justifyContent: 'space-between' }}>
        <Button
          variant="outlined"
          onClick={onGoBack}
          startIcon={<ArrowLeftIcon />}
          sx={{ alignSelf: 'flex-start' }}
        >
          {$t({ id: 'action-Back' })}
        </Button>
        <Stack gap={1.25} direction="row">
          <Button
            variant="outlined"
            //TR-6076 Archiving without replacing will be available after product archiving is done
            disabled={!canDelete}
            onClick={onRemove}
          >
            {$t({
              id: canDelete ? 'action-DeleteWithoutReplacing' : 'action-ArchiveWithoutReplacing',
            })}
          </Button>
          <Button onClick={onReplace}>{$t({ id: 'action-Replace' })}</Button>
        </Stack>
      </ModalFooter>
    </ModalLarge>
  );
};
