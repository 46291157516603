import { Product, SchoolYear, useGetSchoolQuery } from '@schooly/api';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { ModalContent, ModalLarge } from '@schooly/style';
import { FC } from 'react';

import { EmptyTypes } from '../EmptyTypes';
import { SchoolProductModalHeader } from '../SchoolProductModalHeader';
import { SchoolProductModalVariants } from './SchoolProductModalVariants';

type SchoolProductModalContentProps = {
  product: Product;
  schoolId: string;
  onEdit?: () => void;
  onEditVariants?: (yearId?: SchoolYear['id']) => void;
  onClose: () => void;
};

export const SchoolProductModalContent: FC<SchoolProductModalContentProps> = ({
  product,
  schoolId,
  onClose,
  onEdit,
  onEditVariants,
}) => {
  const { name, obligatory, trigger, account, tax_type } = product;
  const { propertiesMap } = useSchoolProperties({ schoolId, userType: SchoolUserRole.Student });
  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    enabled: !!schoolId,
  });

  return (
    <ModalLarge open onClose={onClose}>
      <SchoolProductModalHeader
        name={name}
        obligatory={obligatory}
        taxType={tax_type}
        statusName={propertiesMap.status.find((s) => s.id === trigger.extra_data.status)?.name}
        account={account}
        onClose={onClose}
        onEdit={onEdit}
      />
      <ModalContent display="flex" flexDirection="column">
        {product.types.length ? (
          <SchoolProductModalVariants
            product={product}
            schoolId={schoolId}
            onEdit={onEditVariants}
            currency={currentSchool?.currency}
          />
        ) : (
          <EmptyTypes onAdd={onEditVariants} productName={product.name} />
        )}
      </ModalContent>
    </ModalLarge>
  );
};
