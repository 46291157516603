import { Button, Icon, IconButton, Stack, Tab, Tabs, Tooltip, Typography } from '@mui/material';
import {
  ConsentForm,
  ConsentFormItemShort,
  ConsentFormItemStatus,
  SHORT_FORMATTED_DATE_FORMAT_FNS,
  useDownloadConsentFormItemsMutation,
  useGetConsentFormItemsQuery,
} from '@schooly/api';
import { RichText } from '@schooly/components/form-rich-text';
import { useNotifications } from '@schooly/components/notifications';
import {
  Counter,
  CrossIcon,
  EditIcon,
  EmptySchoolSvg,
  EyeIcon,
  Grid,
  GridBody,
  LockIcon,
  ModalContent,
  ModalMain,
  ModalSearch,
  ReadMore,
} from '@schooly/style';
import { IntlError } from '@schooly/utils/intl-error';
import { format } from 'date-fns';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';

import { ConsentItemsSkeletonRows } from '../../components/common/ConsentForms/ConsentFormPreview';
import { ModalHeaderV2 } from '../../components/uikit-components/Modal/ModalHeaderV2';
import { useRouter } from '../../context/router/useRouter';
import { downloadFile } from '../../utils/downloadFile';
import { ConsentFormItemsDownload } from './ConsentFormItemsDownload';
import { ConsentItemRow } from './ConsentItemRow';
import { useReferenceInfo } from './hooks';

const PAGE_SIZE = 10;

type ConsentFormPreviewContentProps = {
  consentForm: ConsentForm;
};

export const ConsentFormPreviewContent: FC<ConsentFormPreviewContentProps> = ({ consentForm }) => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState<ConsentFormItemStatus>('agreed');
  const { showError } = useNotifications();
  const { closeAndClean } = useRouter();

  const { reference } = consentForm;

  const { date, icon, path, name, title, canEditConsentForm, canRemoveConsentForm } =
    useReferenceInfo(reference);

  const tabItems =
    consentForm.type === 'published'
      ? [
          { labelId: 'consentForms-agreed', count: consentForm.statuses.agreed, value: 'agreed' },
          {
            labelId: 'consentForms-declined',
            count: consentForm.statuses.declined,
            value: 'declined',
          },
          {
            labelId: 'consentForms-noStatus',
            count: consentForm.statuses.no_status,
            value: 'no_status',
          },
        ]
      : [];

  const hasCount = consentForm.type === 'published' ? consentForm.statuses[activeTab] : 0;

  const {
    data,
    params,
    isLoading: isItemsLoading,
    setParams,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
  } = useGetConsentFormItemsQuery(
    {
      id: consentForm.id,
      statuses: [activeTab],
      search_query: '',
    },
    { enabled: !!hasCount },
  );

  const isLoading = !!hasCount && isItemsLoading;

  const downloadConsentFormItems = useDownloadConsentFormItemsMutation();

  const handleTabChange = useCallback(
    (_: React.SyntheticEvent, newValue: ConsentFormItemStatus) => {
      setParams((p) => ({ ...p, statuses: [newValue] }));
      setActiveTab(newValue);
    },
    [setParams],
  );

  const handleQueryChange = useCallback(
    (search_query: string) => {
      setParams((p) => ({ ...p, search_query }));
    },
    [setParams],
  );

  const handleDownloadItems = useCallback(
    async (statuses: ConsentFormItemStatus[]) => {
      downloadConsentFormItems.mutateAsync(
        { id: consentForm.id, statuses },
        {
          onSuccess: (data) => {
            try {
              downloadFile(
                data,
                `${reference.data.title}-${$t({
                  id: 'consentForms-title',
                }).toLowerCase()}.pdf`,
              );
            } catch (err) {
              showError(err as IntlError);
            }
          },
          onError: showError,
        },
      );
    },
    [$t, consentForm.id, downloadConsentFormItems, reference.data.title, showError],
  );

  const entries = useMemo(
    () =>
      data?.pages.reduce<ConsentFormItemShort[]>((prev, curr) => [...prev, ...curr.results], []) ??
      [],

    [data?.pages],
  );

  const statusCount = consentForm.type === 'published' ? consentForm.statuses[activeTab] : 0;

  const count = data?.pages.length ? data.pages[0].count : statusCount;

  const isPublished = consentForm.type === 'published';
  const isEmpty = !isPublished || !entries.length;

  const renderConsentItemList = () => {
    if (!isLoading && isEmpty)
      return (
        <Stack pt={6.5} gap={2.5} alignItems="center" justifyContent="center">
          <EmptySchoolSvg />
          {
            <Typography variant="h3" color="common.grey2" textAlign="center" maxWidth={280}>
              {$t(
                {
                  id: isPublished
                    ? 'consentForms-emptyConsentList'
                    : 'consentForms-emptyConsentListNotPublished',
                },
                { reference: name.toLowerCase() },
              )}
            </Typography>
          }
        </Stack>
      );

    return (
      <>
        <Grid
          sx={(theme) => ({
            overflow: 'hidden',
            borderRadius: theme.spacing(1),
            '& .MuiTableRow-root': {
              height: 44,
            },
            mt: 3,
          })}
        >
          <GridBody>
            {entries.map((e) => (
              <ConsentItemRow key={e.signed_for.relation_id} consentItem={e} />
            ))}
          </GridBody>
        </Grid>

        {(isLoading || isFetchingNextPage) && (
          <ConsentItemsSkeletonRows
            withAvatar
            widths={['34px', '35%', '15%', '15%', '30%']}
            rowCount={Math.min(PAGE_SIZE, count - (entries?.length || 0))}
          />
        )}

        {hasNextPage && !isFetchingNextPage && (
          <Stack alignItems="center" alignSelf="center">
            <Button startIcon={<EyeIcon />} variant="text" onClick={() => fetchNextPage()}>
              {$t({ id: 'action-ShowMoreButton' })}
            </Button>
          </Stack>
        )}
      </>
    );
  };

  const showEditButton = canEditConsentForm || canRemoveConsentForm;

  return (
    <>
      <ModalHeaderV2
        active
        withGoBack
        subHeader={
          <Stack overflow="hidden" minHeight={0} width="100%" direction="row" gap={5}>
            <Typography width={100}>{`${
              reference.type === 'signup' ? $t({ id: 'till' }) : ''
            } ${format(new Date(date), SHORT_FORMATTED_DATE_FORMAT_FNS)}`}</Typography>

            <Stack
              component={Link}
              to={path}
              direction="row"
              gap={0.5}
              sx={{
                '.svg-icon': {
                  color: 'common.grey',
                },
                ':hover': {
                  '*': {
                    color: 'primary.main',
                    textDecoration: 'underline',
                  },
                },
              }}
            >
              <Icon fontSize="small">{icon}</Icon>
              <Typography color="common.grey2">{title}</Typography>
            </Stack>
          </Stack>
        }
        title={$t({ id: 'consentForms-title' })}
      >
        <Stack direction="row" gap={2.5}>
          {showEditButton ? (
            <IconButton onClick={() => navigate('edit')}>
              <EditIcon />
            </IconButton>
          ) : (
            <Tooltip
              title={$t({ id: 'consentForms-cantEdit' })}
              PopperProps={{
                sx: {
                  '& .MuiTooltip-tooltip': {
                    p: 1.25,
                  },
                },
              }}
            >
              <IconButton inverse>
                <LockIcon />
              </IconButton>
            </Tooltip>
          )}
          <IconButton onClick={closeAndClean}>
            <CrossIcon />
          </IconButton>
        </Stack>
      </ModalHeaderV2>

      <ModalMain>
        <ModalContent>
          <ReadMore text={consentForm.description} rows={3}>
            <RichText value={consentForm.description} readOnly />
          </ReadMore>
          <Stack
            pt={4}
            direction="row"
            overflow="hidden"
            justifyContent="space-between"
            alignItems="center"
          >
            <Typography variant="h2">{$t({ id: 'userType-student-plural' })}</Typography>
            {isPublished && (
              <Stack direction="row" gap={2} alignItems="center">
                <ModalSearch
                  value={params.search_query ?? ''}
                  onChange={handleQueryChange}
                  placeholder={$t({ id: 'people-Search' })}
                  withDebounce
                />
                <ConsentFormItemsDownload
                  onDownload={handleDownloadItems}
                  isDownloading={downloadConsentFormItems.isLoading}
                />
              </Stack>
            )}
          </Stack>
          <Stack>
            {isPublished && (
              <Stack direction="row" justifyContent="space-between" alignItems="center" pt={1.75}>
                <Tabs
                  value={activeTab}
                  onChange={handleTabChange}
                  sx={{
                    minHeight: 0,
                    '.MuiTab-root:not(.Mui-selected)': {
                      '& .counter': {
                        backgroundColor: 'common.grey2',
                      },
                    },
                    '& .MuiTab-root': {
                      minHeight: 0,
                      paddingBottom: 0.5,
                      '& .text': {
                        color: 'common.main3',
                      },
                      '&.Mui-selected .text': { color: 'primary.main' },

                      '&:not(:last-child)': {
                        mr: 5,
                      },
                    },
                  }}
                >
                  {tabItems.map(({ count, labelId, value }) => (
                    <Tab
                      key={labelId}
                      value={value}
                      label={
                        <Typography className="text" variant="h3">
                          {$t({ id: labelId })}
                        </Typography>
                      }
                      icon={count ? <Counter className="counter">{count}</Counter> : undefined}
                      iconPosition="end"
                    />
                  ))}
                </Tabs>
              </Stack>
            )}

            {renderConsentItemList()}
          </Stack>
        </ModalContent>
      </ModalMain>
    </>
  );
};
