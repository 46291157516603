import { joiResolver } from '@hookform/resolvers';
import { Grid } from '@mui/material';
import { ApiError } from '@schooly/api';
import { User } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { TITLE_OPTIONS } from '@schooly/constants';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  getSchema,
  pickFormFieldsFromUser,
  UserFormData,
} from '../../../components/common/ProfileModal/profileEditSchema';
import { FormInput, FormSelect } from '../../../components/ui/Input';
import { useProfile } from '../../../context/profile/useProfile';
import IntlError from '../../../utils/intlError';
import { PropertyModal } from '../properties/PropertyModal';

interface NameModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const getNameData = ({ given_name, last_name, title }: UserFormData | Partial<User>) => ({
  given_name,
  last_name,
  title: title ? Number(title) : title,
});

const NameModal = ({ onClose, isOpen }: NameModalProps) => {
  const { showNotification, showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);

  const { user, userType, actions } = useProfile();

  const schema = useMemo(() => userType && getSchema(userType), [userType]);

  const initialFormData = useMemo(() => user && getNameData(pickFormFieldsFromUser(user)), [user]);

  const form = useForm<UserFormData>({
    mode: 'onChange',
    resolver: joiResolver(schema),
    defaultValues: initialFormData,
  });

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      setIsUpdating(true);

      try {
        const res = await actions.updateProfile(getNameData(data));
        if (!res) return;

        await actions.handleUserUpdate();

        showNotification({
          textId: 'confirmation-Name',
          type: 'success',
        });
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, showNotification, showError, onClose],
  );

  const {
    reset,
    formState: { isValid },
  } = form;

  useEffect(() => {
    reset(initialFormData);
  }, [initialFormData, reset]);

  return (
    <PropertyModal
      isOpen={isOpen}
      user={user}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
      submitDisabled={!isValid}
    >
      <Grid container columnSpacing={2}>
        {userType !== 'student' && userType !== 'child' && (
          <Grid item sm={3}>
            <FormSelect
              name="title"
              labelTextId="peopleDetail-Title"
              options={TITLE_OPTIONS}
              noRequiredLabel
            />
          </Grid>
        )}
        <Grid item sm={userType !== 'student' && userType !== 'child' ? 9 : 12}>
          <FormInput name="given_name" type="text" labelTextId="peopleDetail-GivenNames" required />
        </Grid>
      </Grid>

      <FormInput name="last_name" type="text" labelTextId="peopleDetail-FamilyName" required />
    </PropertyModal>
  );
};

export default NameModal;
