import { joiResolver } from '@hookform/resolvers';
import {
  checkUniqueRelationNumber,
  updateParentMembership,
  updateStaffMembership,
  updateStudentMembership,
} from '@schooly/api';
import { ApiError, SchoolUserType, StudentSchoolRelationUpdate } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { ErrorOption, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';

import {
  getUserNumberKey,
  getUserNumberSchema,
} from '../../../components/common/ProfileModal/utils';
import { FormInputWithGenerator } from '../../../components/ui/Input';
import { useProfile } from '../../../context/profile/useProfile';
import { capitalizeFirstLetter, getUserTypeTextId } from '../../../helpers/misc';
import { useDebounce } from '../../../hooks/useDebounce';
import { PropertyModal } from './PropertyModal';

interface MembershipNumberModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const MembershipNumberModal = ({ isOpen, onClose }: MembershipNumberModalProps) => {
  const { showNotification } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);
  const { schoolId } = useAuth();
  const { formatMessage } = useIntl();

  const { user, userType, schoolMembership, actions } = useProfile();

  const currentNumberValue = useMemo(() => {
    if (!schoolMembership) return '';

    return schoolMembership.number;
  }, [schoolMembership]);

  const schema = useMemo(() => getUserNumberSchema(userType!), [userType]);
  const currentUserNumberKey = useMemo(() => getUserNumberKey(userType!), [userType]);

  const errorUserNumberExists = useMemo(
    () => ({
      messageTextId: `error-${capitalizeFirstLetter(userType!)}NumberExists`,
      messageValues: { user: formatMessage({ id: getUserTypeTextId(userType!) }) },
    }),
    [formatMessage, userType],
  );

  const form = useForm<StudentSchoolRelationUpdate>({
    mode: 'onChange',
    resolver: joiResolver(schema),
    defaultValues: {
      [currentUserNumberKey]: currentNumberValue,
    },
  });

  const {
    reset,
    formState: { isValid },
    watch,
    setError,
  } = form;

  const userNumber: string = watch(currentUserNumberKey);

  const checkRelationNumber = useCallback(
    async (newValue: string) => {
      if (!userType || !schoolId || !newValue || newValue === currentNumberValue) {
        return;
      }

      setIsUpdating(true);

      try {
        const response = await checkUniqueRelationNumber(
          schoolId,
          newValue,
          userType as SchoolUserType,
        );

        if (!response) {
          return;
        }

        if (response.exists) {
          setError(`${currentUserNumberKey}` as any, errorUserNumberExists as ErrorOption);
        }
      } catch (err) {
      } finally {
        setIsUpdating(false);
      }
    },
    [currentUserNumberKey, errorUserNumberExists, schoolId, setError, userType, currentNumberValue],
  );

  const debouncedUserNumber = useDebounce(userNumber, 300);

  useEffect(() => {
    checkRelationNumber(debouncedUserNumber);
  }, [checkRelationNumber, debouncedUserNumber]);

  const handleSubmit = async (data: StudentSchoolRelationUpdate) => {
    if (!schoolMembership || !schoolId) {
      return;
    }

    // @ts-ignore
    if (data[currentUserNumberKey] === currentNumberValue) {
      onClose();
      return;
    }

    setIsUpdating(true);

    try {
      if (userType === 'student') {
        await updateStudentMembership(schoolMembership.relation_id, data);
      } else if (userType === 'staff') {
        await updateStaffMembership(schoolMembership.relation_id, data);
      } else if (userType === 'parent') {
        await updateParentMembership(schoolMembership.relation_id, data);
      }
      actions.handleMembershipUpdate();

      showNotification({
        textId: `confirmation-${userType}Number`,
        type: 'success',
      });

      onClose();
    } catch (e) {
      if (/exist/g.test((e as ApiError)?.reason?.toLowerCase() ?? '')) {
        // @ts-ignore
        form.setError(currentUserNumberKey, errorUserNumberExists);
      }
    } finally {
      setIsUpdating(false);
    }
  };

  useEffect(() => {
    reset({
      [currentUserNumberKey]: currentNumberValue,
    });
  }, [currentUserNumberKey, reset, currentNumberValue]);

  const getLabel = () => {
    switch (userType) {
      case 'student':
        return 'peopleDetail-StudentNumber';
      case 'staff':
        return 'peopleDetail-StaffMemberNumber';
      case 'parent':
        return 'peopleDetail-ParentNumber';
      default:
        return '';
    }
  };

  return (
    <PropertyModal
      isOpen={isOpen}
      user={user}
      submitDisabled={!isValid}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <FormInputWithGenerator
        name={currentUserNumberKey}
        type="text"
        labelTextId={getLabel()}
        generateName="should_generate_number"
        generateLabelTextId="memberships-GenerateRelationNumber"
        manualLabelTextId="memberships-EnterRelationNumber"
        generatedValueTextId="memberships-GeneratedOnSave"
      />
    </PropertyModal>
  );
};

export default MembershipNumberModal;
