import { joiResolver } from '@hookform/resolvers';
import { getCustomFieldsValuesFoRelation, setCustomFieldValueForRelation } from '@schooly/api';
import { ApiError, CustomField, CustomFieldValue } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import Joi from 'joi';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { FormInput } from '../../../../components/ui/Input';
import { useProfile } from '../../../../context/profile/useProfile';
import IntlError from '../../../../utils/intlError';
import emptyNumber from '../../../../utils/joi/emptyNumber';
import { PropertyModal } from '../PropertyModal';

export interface CustomFieldStringModalProps {
  onClose: () => void;
  isOpen: boolean;
  field: CustomField;
  value?: CustomFieldValue;
}

interface CustomFieldFormNumberData {
  value?: number;
}

const getSchema = (field: CustomField) =>
  Joi.object<CustomFieldFormNumberData>({
    value: emptyNumber(field.required),
  });

export const CustomFieldNumberModal: FC<CustomFieldStringModalProps> = ({
  isOpen,
  onClose,
  field,
  value,
}) => {
  const { showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);

  const { user, schoolMembership, actions } = useProfile();

  const numberValue = value?.value as number;

  const form = useForm<CustomFieldFormNumberData>({
    mode: 'onChange',
    resolver: joiResolver(getSchema(field)),
    defaultValues: {
      value: numberValue,
    },
  });

  const {
    reset,
    formState: { isValid },
  } = form;

  const handleSubmit: SubmitHandler<CustomFieldFormNumberData> = useCallback(
    async ({ value: newValue }) => {
      if (!schoolMembership) {
        return;
      }

      setIsUpdating(true);

      try {
        const res = await setCustomFieldValueForRelation(
          schoolMembership.relation_id,
          field,
          // empty value is passed as `true` by any reason, looks like react-hook-forms bug
          newValue == null || (newValue as unknown as boolean) === true ? null : newValue,
        );

        if (!res) return;

        const newCustomFieldValues = await getCustomFieldsValuesFoRelation(
          schoolMembership.relation_id,
        );
        actions.setCustomFieldValues(newCustomFieldValues);
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, field, onClose, schoolMembership, showError],
  );

  useEffect(() => {
    reset({ value: numberValue });
  }, [reset, numberValue]);

  return (
    <PropertyModal
      isOpen={isOpen}
      user={user}
      submitDisabled={!isValid}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <FormInput name="value" type="text" labelText={field.label} required={field.required} />
    </PropertyModal>
  );
};
