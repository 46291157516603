import { Box, Icon, Stack, Typography } from '@mui/material';
import { Child, StudentSchoolRelation } from '@schooly/api';
import { RELATIONSHIP_TYPE_OPTIONS } from '@schooly/constants';
import { DeleteFilledIcon, DeleteIcon, Loading, MinusIcon, StarIcon } from '@schooly/style';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FormTagSelect } from 'apps/web/src/components/ui/Input/FormTagSelect';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { isTempUserId } from '../CreatePersonForm/utils';
import PersonCardSelectable from '../PersonCard/PersonCardSelectable';
import { PersonCardIconButton } from '../PersonCard/PersonCardSelectable.styled';
import { EditFamilyCardWrapper } from './EditFamilyContent.styled';
import { EditFamilyForm } from './EditFamilyModal';

interface EditFamilyContentProps {
  user?: Child | StudentSchoolRelation;
  parentForm: EditFamilyForm[];
  isLoading?: boolean;
  onPrimaryContactIdChange: (index: number) => void;
  onParentDelete: (index: number, isPrimary: boolean) => void;
}

export const EditFamilyContent: React.FC<EditFamilyContentProps> = ({
  user,
  parentForm,
  onParentDelete,
  isLoading,
  onPrimaryContactIdChange,
}) => {
  function renderParents() {
    if (isLoading) return <Loading />;
    if (!parentForm.length) {
      return (
        // TODO remake with mui
        <div className="p-3">
          <p className="text-center text-muted w-50 mx-auto">
            <FormattedMessage id="people-SelectOrCreateAdults" />
          </p>
        </div>
      );
    }

    const canBeEdited = Boolean((user as StudentSchoolRelation)?.can_be_edited);

    return (
      <Stack>
        {parentForm.map((data, i) => {
          const isPrimaryContact = data.primary;
          const createdParent = isTempUserId(data.parent.user_id);

          return (
            <EditFamilyCardWrapper key={data.parent.user_id}>
              <PersonCardSelectable
                user={data.parent}
                userType="adult"
                isUsernameLinkView
                isUsernameClickable={!createdParent}
                renderCustomIcon={
                  canBeEdited ? (
                    <PersonCardIconButton
                      sx={{
                        '.PersonCardSelectable__icon_delete-filled': {
                          display: 'none',
                        },
                        '&:hover': {
                          '.PersonCardSelectable__icon_delete-filled': {
                            display: 'inherit',
                          },
                          '.PersonCardSelectable__icon_delete': {
                            display: 'none',
                          },
                        },
                      }}
                      onClick={() => onParentDelete(i, Boolean(data.primary))}
                    >
                      {createdParent ? (
                        <Box>
                          <DeleteIcon className="PersonCardSelectable__icon_delete" />
                          <DeleteFilledIcon className="PersonCardSelectable__icon_delete-filled" />
                        </Box>
                      ) : (
                        <MinusIcon />
                      )}
                    </PersonCardIconButton>
                  ) : (
                    <></>
                  )
                }
                isListItem
              >
                <Stack direction="row" gap={1} flex="1 1 200%" mr={1}>
                  <Stack direction="row" alignItems="center" flex="1 0 35%">
                    <FormTagSelect
                      name={`parentsForm.${i}.relation_type`}
                      options={RELATIONSHIP_TYPE_OPTIONS}
                    />
                  </Stack>

                  <Stack
                    direction="row"
                    alignItems="center"
                    className="primaryContact"
                    flex="1 1 30%"
                    gap={0.5}
                    sx={(theme) => ({
                      visibility: isPrimaryContact ? 'visible' : 'hidden',
                      cursor: 'pointer',
                      color: isPrimaryContact
                        ? theme.palette.common.grey2
                        : theme.palette.common.grey,

                      '&:hover': {
                        color: theme.palette.common.grey2,
                      },
                    })}
                    onClick={() => {
                      onPrimaryContactIdChange(i);
                    }}
                  >
                    <Icon fontSize="small">
                      <StarIcon />
                    </Icon>
                    <Typography
                      sx={{
                        display: '-webkit-box',
                        '-webkit-box-orient': 'vertical',
                        '-webkit-line-clamp': '2',
                        overflowX: 'hidden',
                        textOverflow: 'ellipsis',
                      }}
                    >
                      {isPrimaryContact ? (
                        <FormattedMessage id="people-PrimaryContact" />
                      ) : (
                        <FormattedMessage id="people-MakePrimaryContact" />
                      )}
                    </Typography>
                  </Stack>
                </Stack>
              </PersonCardSelectable>
            </EditFamilyCardWrapper>
          );
        })}
      </Stack>
    );
  }

  return renderParents();
};
