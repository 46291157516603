import { removeSchoolRelation, removeUser } from '@schooly/api';
import { SchoolRelation } from '@schooly/api';
import { BaseUser } from '@schooly/api';
import { ApiError, UserType } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { ConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import React, { useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { useLastRefreshTimeContext } from '../../../context/LastRefreshTimeContext';
import {
  getRouteModalPathname,
  getUserTypeWithoutHiveMembership,
  noop,
} from '../../../helpers/misc';
import { getUserFullName } from '../../../helpers/users';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import useUserCounts from '../../../hooks/useUserCounts';
import addHighlight from '../../../utils/addHighlight';
import IntlError from '../../../utils/intlError';
import { MODAL_FADEOUT_DURATION_MS } from '../../ui/Modal';

import './index.scss';

interface DeleteUserModalProps {
  isOpen: boolean;
  onClose: () => void;
  user: BaseUser;
  schoolMembership?: SchoolRelation;
  onUserDelete?: (userId: string) => void;
  userType: UserType;
  setUserType?: (userType?: UserType) => void;
  closeProfileModal?: VoidFunction;
}

const getRemoveWarningTextId = (isDeletingHiveMemberRelation: boolean) =>
  `peopleDetail-${
    isDeletingHiveMemberRelation ? 'RemoveHiveMemberRelationWarning' : 'RemoveWarning'
  }`;

const DeleteUserModal: React.FC<DeleteUserModalProps> = ({
  isOpen,
  onClose,
  user,
  userType,
  setUserType,
  onUserDelete,
  closeProfileModal = noop,
  schoolMembership,
}) => {
  const navigate = useNavigate();
  const { schoolId } = useAuth();
  const { updateLastRefreshTime } = useLastRefreshTimeContext();
  const [isContentLoading, setIsContentLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { formatMessage } = useIntl();
  const { updateCounts } = useUserCounts();
  const { showNotification } = useNotifications();

  const invalidateQueries = useInvalidateListQueriesFor(userType);

  const handleClose = useCallback(() => {
    onClose();
    setTimeout(() => {
      if (onUserDelete) {
        onUserDelete(user.user_id);
      }
      setErrorMessage('');
      // Extra delay added here to prevent updates on unmounted component
    }, MODAL_FADEOUT_DURATION_MS + 100);
  }, [onClose, onUserDelete, user.user_id]);

  const deleteUser = useCallback(async () => {
    if (!schoolId) {
      throw new IntlError('error-NoSchoolId');
    }

    setIsContentLoading(true);

    try {
      if (schoolMembership) {
        await removeSchoolRelation(schoolId, schoolMembership.relation_id);
      } else {
        await removeUser(user.user_id, schoolId);
      }

      showNotification({
        textId: schoolMembership ? 'confirmation-UserDeregistered' : 'confirmation-UserDelete',
        values: { user: getUserFullName(user) },
        type: 'success',
      });

      handleClose();
      updateCounts();
      updateLastRefreshTime();

      invalidateQueries();

      if (schoolMembership) {
        const newUserType = getUserTypeWithoutHiveMembership(userType);

        setUserType?.(newUserType);

        navigate(getRouteModalPathname(newUserType, user), {
          state: { replace: true },
        });
      } else {
        setTimeout(() => {
          closeProfileModal();
        }, MODAL_FADEOUT_DURATION_MS + 100);
      }
    } catch (e) {
      setErrorMessage((e as ApiError)?.reason || '');
    } finally {
      setIsContentLoading(false);
    }
  }, [
    schoolId,
    schoolMembership,
    showNotification,
    user,
    handleClose,
    updateCounts,
    updateLastRefreshTime,
    invalidateQueries,
    userType,
    setUserType,
    navigate,
    closeProfileModal,
  ]);

  const [handleUserDeleteConfirm, isDeleteInProgress] = useRequestWithProgress(deleteUser, onClose);

  const defaultMessage = formatMessage(
    { id: getRemoveWarningTextId(!!schoolMembership) },
    { userName: <u key={user.user_id}>{getUserFullName(user)}</u> },
  );

  const message = useMemo(() => {
    if (errorMessage && schoolMembership) {
      return errorMessage;
    }

    if (errorMessage.toLowerCase().includes("you can't delete this")) {
      return errorMessage;
    }

    return errorMessage
      ? addHighlight(errorMessage, ['parent', 'staff member', 'student'])
      : defaultMessage;
  }, [errorMessage, schoolMembership, defaultMessage]);

  return (
    <ConfirmationDialog
      isOpen={isOpen}
      className="DeleteUserModal"
      confirmTextId="yes"
      cancelTextId={errorMessage ? 'ok' : 'no'}
      message={message}
      isLoading={isDeleteInProgress}
      onClose={handleClose}
      isContentLoading={isContentLoading}
      onlyCancelButton={!!errorMessage}
      onConfirm={handleUserDeleteConfirm}
    />
  );
};

export default DeleteUserModal;
