import { Box, IconButton, Stack, Typography } from '@mui/material';
import { SchoolYear } from '@schooly/api';
import { getGroupsForRelation, GroupForRelationRequest } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { Loading, ModalSearch } from '@schooly/style';
import { EditIcon } from '@schooly/style';
import { DropdownYears } from '@schooly/style';
import debounce from 'lodash.debounce';
import moment from 'moment';
import React, { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import { ProfileAccordion } from '../../../components/common/ProfileAccordion/ProfileAccordion';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { ListViewGroupRow } from '../../../components/uikit-components/ListViewGroupRow/ListViewGroupRow';
import { ModalEmptyGroupArea } from '../../../components/uikit-components/Modal/ModalEmptyGroupArea';
import { DEFAULT_DATE_FORMAT } from '../../../config';
import { useProfile } from '../../../context/profile/useProfile';
import { getRouteModalPathname } from '../../../helpers/misc';
import { getUserFullName } from '../../../helpers/users';
import useAppLocation from '../../../hooks/useAppLocation';
import usePrevious from '../../../hooks/usePrevious';
import useRequestWithProgress from '../../../hooks/useRequestWithProgress';
import useSchoolYears from '../../../hooks/useSchoolYears';
import getIsAccessDeniedError from '../../../utils/getIsAccessDeniedError';

export const DEBOUNCE_TIME = 300;

enum GroupAccordionTitle {
  Active = 'active',
  NotActive = 'not active',
}

export const ProfileModalGroups: FC = () => {
  const [filter, setFilter] = useState('');

  const { schoolId, permissions } = useAuth();
  const { isUpdating, schoolMembership, groups, groupsSchoolYear, actions, user, userType } =
    useProfile();
  const { formatMessage } = useIntl();
  const { defaultValidity, schoolYears } = useSchoolYears();
  const isGroupManager = permissions.includes('group_manager');
  const hasPermission = permissions.includes('group_viewer');
  const [fetching, setFetching] = useState(false);
  const [requestedFilter, setRequestedFilter] = useState('');
  const location = useAppLocation();
  const navigate = useNavigate();

  const schoolYear = useMemo(
    () => groupsSchoolYear ?? defaultValidity,
    [defaultValidity, groupsSchoolYear],
  );

  const prevGroups = usePrevious(groups);
  const prevSchoolYear = usePrevious(schoolYear);

  const fetchGroups = useCallback(async () => {
    if (!schoolId || !schoolMembership?.relation_id || !schoolYear) return;

    let params: GroupForRelationRequest = {
      relationId: schoolMembership.relation_id,
      date_from: schoolYear.start,
      date_to: schoolYear.end,
    };

    if (filter) {
      params = { ...params, search_query: filter };
    }

    setFetching(true);
    const { groups: relationGroups } = await getGroupsForRelation(params);

    setFetching(false);

    actions.setGroups(relationGroups);
  }, [actions, filter, schoolId, schoolMembership?.relation_id, schoolYear]);

  const [handleGetGroups, isLoading, error] = useRequestWithProgress(
    fetchGroups,
    undefined,
    true,
    Boolean(groups?.length),
  );

  useEffect(() => {
    if ((!prevGroups && groups) || prevSchoolYear !== schoolYear) {
      handleGetGroups();
    }
  }, [groups, handleGetGroups, prevGroups, prevSchoolYear, schoolYear]);

  const { activeGroups, notActiveGroups } = useMemo(() => {
    const activeGroups = groups?.filter((group) => {
      const currentDateTime = moment(moment().format(DEFAULT_DATE_FORMAT)).valueOf();

      return group.memberships.some(({ end, start }) => {
        const memberStartDateTime = moment(start).valueOf();
        const memberEndDateTime = moment(end).valueOf();

        return memberEndDateTime >= currentDateTime && memberStartDateTime <= currentDateTime;
      });
    });

    return {
      activeGroups: activeGroups || [],
      notActiveGroups:
        groups?.filter((gr) => !activeGroups?.some((group) => group.id === gr.id)) || [],
    };
  }, [groups]);

  const handleSchoolYearChange = useCallback(
    (year: SchoolYear) => {
      actions.setGroupsSchoolYear(year);
    },
    [actions],
  );

  const debouncedFetchResults = useMemo(
    () =>
      debounce(() => {
        fetchGroups();
        setRequestedFilter(filter);
      }, DEBOUNCE_TIME),
    [fetchGroups, filter],
  );

  React.useEffect(() => {
    if (!fetching && requestedFilter !== filter) {
      debouncedFetchResults();

      return () => {
        debouncedFetchResults.cancel();
      };
    }
  }, [debouncedFetchResults, fetching, filter, requestedFilter]);

  const showEditButton = useMemo(() => {
    if (!schoolYear?.end || !isGroupManager) {
      return false;
    }
    const currentDateTime = moment(moment().format(DEFAULT_DATE_FORMAT)).valueOf();

    const schoolYearEndDateTime = moment(schoolYear.end).valueOf();

    return schoolYearEndDateTime >= currentDateTime;
  }, [isGroupManager, schoolYear?.end]);

  const handleManageGroupClick = useCallback(() => {
    if (!user || !userType) return;
    const path = getRouteModalPathname(userType, user);

    navigate(`${path}/groups?schoolYearId=${schoolYear?.id}`, {
      state: { backgroundLocation: location },
    });
  }, [location, navigate, schoolYear?.id, user, userType]);

  if (getIsAccessDeniedError(error) || !hasPermission || !user) {
    return <AccessDenied />;
  }

  const userFullName = getUserFullName(user);

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5} mt={-0.75}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="profile-Groups" />
          </Typography>
          {!!groups?.length && <Counter>{Number(groups?.length)}</Counter>}
        </Stack>
        <Stack direction="row" alignItems="center" gap={2.5}>
          <ModalSearch
            value={filter}
            onChange={setFilter}
            placeholder={formatMessage({ id: 'people-Search' })}
          />
          <DropdownYears
            years={schoolYears}
            defaultYear={defaultValidity}
            currentYear={schoolYear}
            onYearChange={handleSchoolYearChange}
          />
          {showEditButton && (
            <IconButton onClick={handleManageGroupClick} inverse>
              <EditIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>

      {!isLoading && !isUpdating && !groups?.length ? (
        <Box pt="17px">
          <ModalEmptyGroupArea label={userFullName} onClick={handleManageGroupClick} />
        </Box>
      ) : null}

      {isUpdating || isLoading || fetching ? (
        <Loading className="pt-2 pb-2" />
      ) : (
        <Stack gap={2.5}>
          {!!activeGroups.length && schoolYear && (
            <ProfileAccordion title={GroupAccordionTitle.Active}>
              {activeGroups.map((group) => (
                <ListViewGroupRow active schoolYear={schoolYear} key={group.id} group={group} />
              ))}
            </ProfileAccordion>
          )}
          {!!notActiveGroups.length && schoolYear && (
            <ProfileAccordion
              title={GroupAccordionTitle.NotActive}
              expandable
              expandedValue={false}
            >
              {notActiveGroups.map((group) => (
                <ListViewGroupRow schoolYear={schoolYear} key={group.id} group={group} />
              ))}
            </ProfileAccordion>
          )}
        </Stack>
      )}
    </>
  );
};
