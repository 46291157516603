import { FC } from 'react';

import { WithFilters } from '../../../context/filters/WithFilters';
import { WithManageRelationGroup } from './context/WithManageRelationGroup';
import ManageGroupModal from './ManageGroupModal';

export const ManageRelationGroups: FC = () => {
  return (
    <WithManageRelationGroup>
      <WithFilters type="sidebar-relation-groups">
        <ManageGroupModal />
      </WithFilters>
    </WithManageRelationGroup>
  );
};
