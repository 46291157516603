import { Icon, IconButton, Stack, Typography } from '@mui/material';
import { SchoolHistory } from '@schooly/api';
import { Countries, Languages } from '@schooly/constants';
import { EditIcon, StaffIcon, theme } from '@schooly/style';
import { formatApplicationDate } from '@schooly/utils/application-helpers';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { GridRowName } from 'apps/web/src/components/uikit-components/Grid/Grid';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { LongNameWithVerticalTooltip } from 'apps/web/src/components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import { FC, useMemo } from 'react';

import { ApplicationPreviewRow } from './ApplicationPreviewRow';

interface SchoolHistoryRowProps {
  schoolHistory: SchoolHistory;
  editable?: boolean;
  editIconName?: string;
  onEdit?: () => void;
  updating?: boolean;
  updatingIcon?: React.ReactNode;
  readonlyIcon?: React.ReactNode;
  withoutActionBackground?: boolean;
}

export const SchoolHistoryRow: FC<SchoolHistoryRowProps> = ({
  schoolHistory,
  editable,
  editIconName,
  onEdit,
  updating,
  updatingIcon,
  readonlyIcon,
  withoutActionBackground,
}) => {
  const editIcon = useMemo(() => {
    if (!editable) {
      return readonlyIcon;
    }
    if (updating) {
      return updatingIcon;
    }

    return (
      <Stack>
        <IconButton className={editIconName} inverse sx={{ visibility: 'hidden' }}>
          <EditIcon />
        </IconButton>
      </Stack>
    );
  }, [editIconName, editable, readonlyIcon, updating, updatingIcon]);

  return (
    <ApplicationPreviewRow
      height={70}
      onClick={onEdit}
      sxProps={{
        ...(withoutActionBackground && {
          '&:hover': {
            background: theme.palette.background.paper,
            '& .MuiTypography-root': {
              color: `${theme.palette.common.grey2} !important`,
            },
          },
        }),
        ...(editable && { cursor: 'pointer' }),
        '& :hover': {
          [`.${editIconName}`]: {
            visibility: 'visible',
          },
        },
      }}
    >
      <Stack gap={0.5} overflow="hidden" flex="1 1 100%">
        <Stack direction="row" gap={1} justifyContent="space-between" alignItems="center">
          <Stack direction="row" alignItems="center" overflow="hidden">
            <Icon>
              <StaffIcon />
            </Icon>

            <GridRowName
              sx={{
                minWidth: 0,
              }}
            >
              <LongNameWithVerticalTooltip>{schoolHistory.school_name}</LongNameWithVerticalTooltip>
            </GridRowName>
          </Stack>
          {editIcon}
        </Stack>
        <Stack direction="row" px={3.5} gap={5} color="text.primary" sx={{ whiteSpace: 'nowrap' }}>
          <Stack direction="row" gap={4} color="text.primary">
            <Typography>{`${formatApplicationDate(
              schoolHistory.start_date,
            )} - ${formatApplicationDate(schoolHistory.end_date)}`}</Typography>
            <Typography>{Countries[schoolHistory.country]}</Typography>
          </Stack>
          <Typography>{Languages[schoolHistory.language]}</Typography>
        </Stack>
      </Stack>
    </ApplicationPreviewRow>
  );
};
