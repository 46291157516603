import { DATE_FORMAT_FULL_MONTH_FNS } from '@schooly/api';
import { SORT_DIRECTION } from '@schooly/api';
import { ConductEntry } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format } from 'date-fns';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { MONTH_NONE } from '../../constants/misc';
import useSchoolYears from '../../hooks/useSchoolYears';
import { ConductForRelationContext } from './ConductForRelationContext';
import { useConductEntries, UseConductEntriesProps } from './useConductEntries';

/**
 * Context wrapper for Conduct tab in Profile modal
 *
 * @param children
 * @constructor
 */
export const WithConductForRelation: FC<PropsWithChildren> = ({ children }) => {
  const { id } = useParams<'id'>();
  const { defaultValidity, getSchoolYearById, isLoading: schoolYearsFetching } = useSchoolYears();
  const { permissions } = useAuth();

  const [schoolYear, setSchoolYear] = useState(defaultValidity);
  const [filter, setFilter] = useState('');

  const params = useMemo<UseConductEntriesProps>(
    () => ({
      sort: [
        { columnTextId: 'date', direction: SORT_DIRECTION.DESC },
        { columnTextId: 'conduct_type', direction: SORT_DIRECTION.ASC },
      ],
      filters: {
        student: [id!],
        date: schoolYear ? [schoolYear.start, schoolYear.end] : undefined,
      },
      query: filter,
    }),
    [filter, id, schoolYear],
  );

  const conductEntries = useConductEntries(params);

  const canView = permissions.includes('conduct_viewer');
  const canEdit = permissions.includes('conduct_manager');

  const setSchoolYearId = useCallback(
    (schoolYearId?: string) => {
      setSchoolYear(getSchoolYearById(schoolYearId));
    },
    [getSchoolYearById],
  );

  const entriesByMonth = useMemo(() => {
    return (
      conductEntries.entries?.reduce<Record<string, ConductEntry[]>>((prev, entry) => {
        const month = entry.date
          ? format(newDateTimezoneOffset(entry.date), DATE_FORMAT_FULL_MONTH_FNS).toUpperCase()
          : MONTH_NONE;

        if (!prev[month]) {
          prev[month] = [];
        }

        prev[month].push(entry);

        return prev;
      }, {}) ?? {}
    );
  }, [conductEntries.entries]);

  useEffect(() => {
    if (defaultValidity) {
      setSchoolYear(defaultValidity);
    }
  }, [defaultValidity]);

  const value = {
    entriesByMonth,
    schoolYearsFetching,
    schoolYear,
    setSchoolYear,
    setSchoolYearId,
    filter,
    setFilter,
    canView,
    canEdit,
    ...conductEntries,
  };

  return (
    <ConductForRelationContext.Provider value={value}>
      {children}
    </ConductForRelationContext.Provider>
  );
};
