export const EmptySvg = () => (
  <svg
    width="300"
    height="300"
    viewBox="0 0 300 300"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_17641_71953)">
      <path
        d="M111.752 161.208C111.752 162.027 112.252 162.764 113.014 163.067L276.503 227.962V109.878C276.503 109.058 276.003 108.322 275.242 108.019L172.647 67.2839C172.177 67.0975 171.796 66.7402 171.579 66.2839L161.837 45.7959C161.62 45.3397 161.239 44.9825 160.769 44.796L114.49 26.4147C113.177 25.8932 111.752 26.8606 111.752 28.2735V161.208Z"
        fill="#24275B"
      />
      <path
        d="M93.7217 207.735L258.426 273.131V121.88L93.7217 56.4834V207.735Z"
        fill="white"
        stroke="#E7E9F4"
        strokeMiterlimit="10"
      />
      <path
        d="M93.7217 174.567C93.7217 175.387 94.2218 176.124 94.9836 176.426L258.426 241.321V123.238C258.426 122.418 257.926 121.681 257.164 121.379L154.617 80.6901C154.147 80.5037 153.765 80.1463 153.548 79.6899L143.807 59.2022C143.59 58.7459 143.208 58.3887 142.739 58.2022L96.4599 39.821C95.1469 39.2995 93.7217 40.2669 93.7217 41.6797V174.567Z"
        fill="white"
        stroke="#E7E9F4"
        strokeMiterlimit="10"
      />
      <path
        d="M93.3784 208.603C93.6023 208.921 93.914 209.166 94.2753 209.31L258.426 274.486L218.348 217.591L188.702 175.524C188.478 175.206 188.167 174.961 187.805 174.817L29.8941 112.119C28.0464 111.385 26.376 113.504 27.5211 115.129L93.3784 208.603Z"
        fill="#E7E9F4"
      />
      <path
        d="M132.772 191.48C132.772 191.48 108.622 164.714 107.782 174.664C107.221 181.11 112.313 185.968 114.882 187.042C117.451 188.117 117.965 179.615 100.915 169.526C83.8653 159.436 93.8616 178.915 96.7577 179.569C99.6538 180.223 101.662 170.787 79.4277 161.071"
        stroke="#24275B"
        strokeMiterlimit="10"
      />
      <path
        d="M164.209 219.367C164.209 219.367 147.019 200.402 143.189 200.916C139.405 201.383 148.141 208.436 144.871 209.464C141.601 210.492 128.989 193.816 124.318 194.376C119.647 194.937 129.783 202.224 123.664 200.822C117.545 199.421 107.595 187.509 101.242 187.276"
        stroke="#24275B"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <clipPath id="clip0_17641_71953">
        <rect width="253.737" height="250" fill="white" transform="translate(23 25)" />
      </clipPath>
    </defs>
  </svg>
);
