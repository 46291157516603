import {
  DEFAULT_DATE_FORMAT_FNS,
  DefaultSchoolYear,
  FilterKeys,
  FilterSection,
  GetMessagesQueryFilters,
  MESSAGE_ARRANGE_BY_FILTER_KEYS,
  MessageArrangeBy,
  MESSAGES_FILTER_KEYS,
  MessageStatus,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  ArrangeByDropdown,
  DateRangeDropdown,
  MessageStatusTagSelect,
  PersonalFiltersDropdown,
  pickOnlyParamsFromFilterKeys,
  UserRoleTagSelect,
  UserTagSelect,
} from '@schooly/components/filters';
import {
  FilterDropdown,
  FiltersContainer,
  MessageStatusExpandedSelect,
  MoreButton,
  UserExpandedSelect,
  UserRoleExpandedSelect,
} from '@schooly/components/filters';
import { PROPERTIES_TEXT_IDS, SchoolUserRole } from '@schooly/constants';
import { format } from 'date-fns';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useIntl } from 'react-intl';

type MessagesFiltersProps = {
  schoolId: string;
  filters: GetMessagesQueryFilters;
  defaultFilters: GetMessagesQueryFilters;
  onSetFilters: (v: GetMessagesQueryFilters) => void;
  notActualInitialDate?: boolean;
  defaultSchoolYear?: DefaultSchoolYear;
  arrangeBy: MessageArrangeBy | null;
  onSetArrangeBy: (v: MessageArrangeBy | null) => void;
  defaultUserFilters: GetMessagesQueryFilters;
  defaultUserArrangeBy: MessageArrangeBy | null;
};

export type MessageFilters = {
  openArrangeBy: () => void;
};

export const MessagesFilters = forwardRef<MessageFilters, MessagesFiltersProps>(
  (
    {
      defaultFilters,
      filters: actualFilters,
      schoolId,
      onSetFilters,
      notActualInitialDate,
      defaultSchoolYear,
      arrangeBy,
      onSetArrangeBy,
      defaultUserFilters,
      defaultUserArrangeBy,
    },
    ref,
  ) => {
    const { $t } = useIntl();
    const moreButton = useRef<MoreButton | null>(null);
    const personalFiltersDropdown = useRef<PersonalFiltersDropdown | null>(null);
    const { currentStaff } = useAuth();
    const [draftFilters, setDraftFilters] = useState<GetMessagesQueryFilters>(actualFilters);
    const arrangeByDropdown = useRef<ArrangeByDropdown | null>(null);
    const toggleFiltersVisible = useCallback((v: keyof typeof actualFilters) => {
      setDraftFilters((filters) => ({
        ...filters,
        [v]: filters[v] !== undefined ? undefined : [],
      }));
    }, []);
    const [dateChanged, setDateChanged] = useState(false);

    const [draftArrangeBy, setDraftArrangeBy] =
      useState<MessageArrangeBy | null | undefined>(arrangeBy);

    useEffect(() => {
      setDraftArrangeBy(arrangeBy);
      setDraftFilters(actualFilters);
    }, [actualFilters, arrangeBy]);

    const handleApply = useMemo(() => {
      // Operator != is used on purpose to properly compare against undefined and null
      // eslint-disable-next-line eqeqeq
      const arrangeByChanged = draftArrangeBy != arrangeBy;
      const filtersChanged = MESSAGES_FILTER_KEYS.some((key) => {
        const draftFiltersForKey = [...(draftFilters[key] || [])];
        const actualFiltersForKey = [...(actualFilters[key] || [])];

        return draftFiltersForKey.sort().join('') !== actualFiltersForKey.sort().join('');
      });

      if (!filtersChanged && !arrangeByChanged) return undefined;

      return () => {
        filtersChanged && onSetFilters(draftFilters);

        arrangeByChanged && onSetArrangeBy(draftArrangeBy || null);
      };
    }, [draftArrangeBy, arrangeBy, draftFilters, actualFilters, onSetFilters, onSetArrangeBy]);

    useImperativeHandle(
      ref,
      () => ({
        openArrangeBy: () => {
          setDraftArrangeBy((arrangeBy) => (arrangeBy === null ? undefined : arrangeBy));
          setTimeout(() => arrangeByDropdown.current?.open(), 300);
        },
      }),
      [],
    );

    const {
      onSetDate,
      onClearCreator,
      onClearMessageStatus,
      onClearRecipientType,
      onSelectCreatorId,
      onSelectMessageStatus,
      onSelectRecipientType,
    } = useMemo(() => {
      const updateFilter = (key: FilterKeys.Creator) => (id: string) => {
        setDraftFilters((filters) => ({
          ...filters,
          [key]: filters[key]?.includes(id)
            ? filters[key]?.filter((ct) => ct !== id)
            : [...(filters[key] || []), id],
        }));
      };
      const clearFilter = (key: keyof typeof actualFilters) => () => {
        setDraftFilters((filters) => ({
          ...filters,
          [key]: [],
        }));
      };

      const onSelectMessageStatus = (v: MessageStatus) => {
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.MessagesStatus]: filters[FilterKeys.MessagesStatus]?.includes(v)
            ? filters[FilterKeys.MessagesStatus]?.filter((ct) => ct !== v)
            : [...(filters[FilterKeys.MessagesStatus] || []), v],
        }));
      };
      const onSelectRecipientType = (v: SchoolUserRole) => {
        setDraftFilters((filters) => ({
          ...filters,
          [FilterKeys.RecipientType]: filters[FilterKeys.RecipientType]?.includes(v)
            ? filters[FilterKeys.RecipientType]?.filter((ct) => ct !== v)
            : [...(filters[FilterKeys.RecipientType] || []), v],
        }));
      };

      const onSetDate = (v: [Date, Date]) =>
        setDraftFilters((filters) => {
          const updatedDates = [
            format(v[0], DEFAULT_DATE_FORMAT_FNS),
            format(v[1], DEFAULT_DATE_FORMAT_FNS),
          ];

          if (updatedDates.join('') === filters.date?.join('')) return filters;

          setDateChanged(true);
          return {
            ...filters,
            [FilterKeys.Date]: updatedDates,
          };
        });

      return {
        onSetDate,
        onSelectMessageStatus,
        onSelectRecipientType,
        onSelectCreatorId: updateFilter(FilterKeys.Creator),
        onClearMessageStatus: clearFilter(FilterKeys.MessagesStatus),
        onClearRecipientType: clearFilter(FilterKeys.RecipientType),
        onClearCreator: clearFilter(FilterKeys.Creator),
      };
    }, []);

    const handleClearFilters = useCallback(() => {
      setDateChanged(false);
      setDraftArrangeBy(null);
      setDraftFilters(defaultFilters);
    }, [defaultFilters]);

    const handleResetToDefault = useCallback(() => {
      setDraftFilters(defaultUserFilters);
      setDraftArrangeBy(defaultUserArrangeBy);
    }, [defaultUserArrangeBy, defaultUserFilters]);

    const handleSaveFilter = useCallback(() => {
      personalFiltersDropdown.current?.saveFilter();
    }, []);

    const handleOpenMoreButton = useCallback(() => {
      moreButton.current?.open();
    }, []);

    const handleToggleArrangeBy = useCallback(() => {
      setDraftArrangeBy((v) => (v === null ? undefined : null));
    }, []);

    const filtersCreator = draftFilters.creator;
    const filtersStatus = draftFilters.message_status;
    const filtersRecipient = draftFilters.recipient_type;
    const filtersDate = draftFilters.date;

    const creatorLabel = $t({ id: 'messages-From' });
    const statusLabel = $t({ id: 'messages-Status' });
    const recipientsLabel = `${$t({ id: 'messages-Recipients' })}`;
    const dateLabel = $t({ id: 'schoolProperty-Period' });

    return (
      <FiltersContainer onApply={handleApply}>
        <PersonalFiltersDropdown
          ref={personalFiltersDropdown}
          onOpenFilters={handleOpenMoreButton}
          onSaveFilter={handleSaveFilter}
          currentUser={currentStaff}
          relationId={currentStaff?.relation_id || ''}
          schoolId={schoolId}
          section={FilterSection.Message}
          filters={{ ...draftFilters, arrange_by: draftArrangeBy || undefined }}
          defaultSchoolYear={defaultSchoolYear}
          onSetFilters={(v) => {
            onSetFilters(pickOnlyParamsFromFilterKeys(MESSAGES_FILTER_KEYS, v));
            if (!v.arrange_by) {
              onSetArrangeBy(null);
              return;
            }
            for (const key of MESSAGE_ARRANGE_BY_FILTER_KEYS) {
              key === v.arrange_by && onSetArrangeBy(key);
            }
          }}
          getOptionLabelId={(o) =>
            o === FilterKeys.Creator ? 'messages-Sender' : PROPERTIES_TEXT_IDS[o]
          }
        />
        {filtersDate && (
          <DateRangeDropdown
            schoolId={schoolId}
            date={filtersDate}
            dateChanged={dateChanged}
            onSetDate={onSetDate}
            notActualInitialDate={notActualInitialDate}
            openLabel={dateLabel}
            defaultSchoolYear={defaultSchoolYear}
          />
        )}
        {filtersStatus && (
          <FilterDropdown
            onClear={() => toggleFiltersVisible(FilterKeys.Creator)}
            label={statusLabel}
            tags={(open) =>
              filtersStatus.map((status) => (
                <MessageStatusTagSelect key={status} status={status} onClick={open} />
              ))
            }
          >
            {(onClose) => (
              <MessageStatusExpandedSelect
                selectedStatuses={filtersStatus}
                onSelectMessageStatus={onSelectMessageStatus}
                onClose={onClose}
                onClear={onClearMessageStatus}
              />
            )}
          </FilterDropdown>
        )}
        {filtersRecipient && (
          <FilterDropdown
            onClear={() => toggleFiltersVisible(FilterKeys.RecipientType)}
            label={recipientsLabel}
            tags={(open) =>
              filtersRecipient.map((role) => (
                <UserRoleTagSelect key={role} role={role} onClick={open} />
              ))
            }
          >
            {(onClose) => (
              <UserRoleExpandedSelect
                options={[SchoolUserRole.Staff, SchoolUserRole.Parent]}
                selectedRoles={filtersRecipient}
                onSelectUserRole={onSelectRecipientType}
                onClose={onClose}
                onClear={onClearRecipientType}
              />
            )}
          </FilterDropdown>
        )}
        {filtersCreator && (
          <FilterDropdown
            onClear={() => toggleFiltersVisible(FilterKeys.Creator)}
            label={creatorLabel}
            tags={(open) =>
              filtersCreator.map((id) => (
                <UserTagSelect
                  userType="staff"
                  currentUser={currentStaff}
                  sx={{ maxWidth: 200 }}
                  schoolId={schoolId}
                  key={id}
                  id={id}
                  onClick={open}
                />
              ))
            }
          >
            {(onClose) => (
              <UserExpandedSelect
                type="staff"
                schoolId={schoolId}
                currentUser={currentStaff}
                selectedIds={filtersCreator}
                onSelectUserId={onSelectCreatorId}
                onClose={onClose}
                onClear={onClearCreator}
              />
            )}
          </FilterDropdown>
        )}
        {draftArrangeBy !== null && (
          <ArrangeByDropdown
            data-cy={'filter-arrange-by'}
            ref={(r: any) => (arrangeByDropdown.current = r as ArrangeByDropdown)}
            onSelectOption={setDraftArrangeBy}
            selectedOption={draftArrangeBy}
            options={MESSAGE_ARRANGE_BY_FILTER_KEYS}
            onClear={() => setDraftArrangeBy(undefined)}
            onRemove={() => setDraftArrangeBy(null)}
            getOptionLabelId={(o) =>
              o === FilterKeys.Creator ? 'messages-Sender' : PROPERTIES_TEXT_IDS[o]
            }
          />
        )}
        <MoreButton
          ref={moreButton}
          onResetToDefault={handleResetToDefault}
          onClearFilters={handleClearFilters}
          options={[
            { value: FilterKeys.Date, label: dateLabel, required: true },
            { value: FilterKeys.MessagesStatus, label: statusLabel },
            { value: FilterKeys.RecipientType, label: recipientsLabel },
            { value: FilterKeys.Creator, label: creatorLabel },
          ]}
          selectedOptions={MESSAGES_FILTER_KEYS.filter((key) => !!draftFilters[key])}
          onToggleOption={toggleFiltersVisible}
          onToggleArrangeBy={handleToggleArrangeBy}
          isSelectedArrangeBy={draftArrangeBy !== null}
        />
      </FiltersContainer>
    );
  },
);
