import { SxProps } from '@mui/material';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { GridRowItem, GridRowStyled } from 'apps/web/src/components/uikit-components/Grid/Grid';
import { FC, PropsWithChildren } from 'react';

interface ApplicationPreviewRowProps extends PropsWithChildren {
  sxProps?: SxProps;
  height?: number;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export const ApplicationPreviewRow: FC<ApplicationPreviewRowProps> = ({
  sxProps,
  children,
  height = 44,
  onClick,
}) => {
  return (
    <GridRowStyled
      sx={{
        ...sxProps,
      }}
      onClick={onClick}
    >
      <GridRowItem
        noVerticalPadding
        gap={1}
        sx={{ py: 1.3, color: 'text.secondary', height }}
        flexGrow={1}
      >
        {children}
      </GridRowItem>
    </GridRowStyled>
  );
};
