import { FC } from 'react';

import { WithUserRoleForRelation } from '../../../context/userRoles/WithUserRoleForRelation';
import { ManageUserRoleModal } from './ManageUserRoleModal';

export const ManageUserRole: FC = () => {
  return (
    <WithUserRoleForRelation>
      <ManageUserRoleModal />
    </WithUserRoleForRelation>
  );
};
