import { Box, Icon, IconButton, Link, Stack, Typography } from '@mui/material';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  CreateParentForm,
  CustomFieldsFormContainer,
  ParentInfoFormContainer,
  PreviewFormType,
  RowsContainer,
} from '@schooly/components/applications';
import { ControlTextField } from '@schooly/components/form-text-field';
import {
  AREA_CODE_OPTIONS,
  GENDER_OPTIONS,
  Genders,
  getLabelIdForOption,
  LANGUAGE_OPTIONS,
  Languages,
  NATIONALITY_OPTIONS,
  RELATIONSHIP_TYPE_OPTIONS,
  RelationsToChild,
  USER_NATIONALITIES_MAX_COUNT,
  VALID_EMAIL_REGEXP,
} from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  EditIcon,
  EmailIcon,
  FemaleIcon,
  FlagIcon,
  Language3Icon,
  MaleIcon,
  PhoneIcon,
  ProfessionIcon,
  Tag,
} from '@schooly/style';
import { convertParentFormToApplicationParent } from '@schooly/utils/application-helpers';
import { getNationalitiesLabelIds } from '@schooly/utils/get-nationalities-label-ids';
import { getUserFullNameWithTitle } from '@schooly/utils/get-user-full-name-with-title';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FormRadioGroup } from 'apps/web/src/components/uikit-components/FormCheckbox/FormRadioGroup';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { GridRowName } from 'apps/web/src/components/uikit-components/Grid/Grid';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { LongNameWithVerticalTooltip } from 'apps/web/src/components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
import { FC, useEffect, useMemo, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { getCustomFieldsToShow } from '../../utils';
import { ApplicationAdditionalInformationPreview } from '../ApplicationPreviewCommon/ApplicationAdditionalInformationPreview';
import { ApplicationPreviewInfoRow } from '../ApplicationPreviewCommon/ApplicationPreviewInfoRow';
import { EditDialog } from './EditDialog';

export interface ApplicationEditablePreviewParentProps {
  parent: CreateParentForm;
  onSubmit: (v: CreateParentForm, type: string) => void;
  canEdit: boolean;
  updating?: Record<string, boolean>;
  updatingIcon?: React.ReactNode;
  readonlyIcon?: React.ReactNode;
  withoutActionBackground?: boolean;
}

export const ApplicationEditablePreviewParent: FC<ApplicationEditablePreviewParentProps> = ({
  parent,
  onSubmit,
  canEdit,
  updating,
  updatingIcon,
  readonlyIcon,
  withoutActionBackground,
}) => {
  const [previewType, setPreviewType] = useState<PreviewFormType | null>(null);

  const { formatMessage } = useIntl();

  const [isFormOpen, showFormModal, hideFormModal] = useFlag();

  useEffect(() => {
    if (previewType && !isFormOpen) {
      setPreviewType(null);
    }
  }, [previewType, isFormOpen]);

  const form = useForm<CreateParentForm>({
    defaultValues: parent,
    mode: 'onChange',
  });

  const onEditClick = (editType: PreviewFormType) => {
    setPreviewType(editType);
    showFormModal();
  };

  const nationalities = form.watch('nationalities') ?? [];

  const formContent = useMemo(() => {
    switch (previewType) {
      case 'telephone':
        return (
          <Stack direction="row" gap={1.25}>
            <Box flex="0 0 250px">
              <FormSelect2
                name="phoneCode"
                labelTextId="AreaCode"
                options={AREA_CODE_OPTIONS}
                noRequiredLabel
                showSelectedValue
                optionClassName="CountryCodeSelectOption"
              />
            </Box>

            <ControlTextField
              name="telephone"
              control={form.control}
              label={formatMessage({ id: 'peopleDetail-PhoneNumber' })}
              canClear
              fullWidth
            />
          </Stack>
        );
      case 'email': {
        return (
          <ControlTextField
            name="email"
            control={form.control}
            label={formatMessage({ id: 'peopleDetail-EmailAddressPersonal' })}
            canClear
            fullWidth
            //TODO: Refactor email validation - TR-4353
            rules={{
              required: true,
              pattern: {
                value: VALID_EMAIL_REGEXP,
                message: formatMessage({
                  id: 'input-ErrorInvalidEmail',
                }),
              },
            }}
          />
        );
      }
      case 'language': {
        return (
          <FormSelect2
            name="language"
            labelTextId="peopleDetail-PrimaryLanguage"
            options={LANGUAGE_OPTIONS}
          />
        );
      }
      case 'gender': {
        return (
          <FormRadioGroup
            options={[...GENDER_OPTIONS].reverse()}
            name="gender"
            rules={{ required: true }}
          />
        );
      }
      case 'nationalities': {
        return (
          <FormSelect2
            name="nationalities"
            labelTextId={
              nationalities.length <= 1
                ? 'peopleDetail-Nationality'
                : 'peopleDetail-Nationality-plural'
            }
            options={NATIONALITY_OPTIONS}
            rules={{ required: true }}
            multiple
            maxCount={USER_NATIONALITIES_MAX_COUNT}
          />
        );
      }
      case 'profession': {
        return (
          <ControlTextField
            name="profession"
            control={form.control}
            label={formatMessage({ id: 'peopleDetail-Profession' })}
            canClear
            fullWidth
          />
        );
      }
      case 'userInfo': {
        return <ParentInfoFormContainer />;
      }

      case 'relationshipType': {
        return (
          <FormSelect2
            name="relationship_type"
            labelTextId="relationship"
            options={RELATIONSHIP_TYPE_OPTIONS}
          />
        );
      }

      case 'customFields': {
        return <CustomFieldsFormContainer />;
      }

      default:
        return null;
    }
  }, [previewType, form.control, formatMessage, nationalities.length]);

  useEffect(() => {
    if (!form.formState.isValid) {
      form.trigger();
    }
  }, [form]);

  const nationalitiesString = useMemo(() => {
    if (!parent.nationalities?.length) return '';
    return getNationalitiesLabelIds(parent.nationalities)
      .map((id) => formatMessage({ id }))
      .join(', ');
  }, [formatMessage, parent.nationalities]);

  const normalizedParent = convertParentFormToApplicationParent(parent);

  const customFieldsToShow = useMemo(
    () => getCustomFieldsToShow(normalizedParent.custom_fields ?? []),
    [normalizedParent.custom_fields],
  );

  const parentHasCustomFields = !!customFieldsToShow.length;

  return (
    <>
      <FormProvider {...form}>
        <form>
          <Stack>
            <Stack direction="row" alignItems="center" gap={1}>
              <Box
                sx={{
                  '& :hover': {
                    '.userInfo': {
                      display: 'inherit',
                    },
                  },
                }}
              >
                <Stack direction="row" gap={1} alignItems="center">
                  <Typography variant="h2">{getUserFullNameWithTitle(parent)}</Typography>
                  <Stack>
                    <IconButton
                      className="userInfo"
                      inverse
                      sx={{ display: 'none' }}
                      onClick={() => canEdit && onEditClick('userInfo')}
                    >
                      {canEdit ? <EditIcon /> : readonlyIcon}
                    </IconButton>
                  </Stack>
                </Stack>
              </Box>
              {Number.isInteger(parent.relationship_type) && (
                <Stack
                  sx={{
                    direction: 'row',
                    '& :hover': {
                      '.relationshipType': {
                        visibility: 'visible',
                      },
                    },
                  }}
                >
                  <Box>
                    <Stack
                      direction="row"
                      gap={0.75}
                      sx={(theme) => ({
                        '.UIKit-Tag': {
                          backgroundColor: theme.palette.background.paper,
                          borderColor: theme.palette.common.light2,
                        },
                      })}
                    >
                      <Tag>
                        <Typography color="primary.main">
                          <FormattedMessage
                            id={`relationship-Type-${RelationsToChild[parent.relationship_type!]}`}
                          />
                        </Typography>
                      </Tag>

                      <IconButton
                        className="relationshipType"
                        inverse
                        sx={{ visibility: 'hidden' }}
                        onClick={() => canEdit && onEditClick('relationshipType')}
                      >
                        {canEdit ? <EditIcon /> : readonlyIcon}
                      </IconButton>
                    </Stack>
                  </Box>
                </Stack>
              )}
            </Stack>

            <Stack direction="row" gap={2}>
              <Stack
                flex="1 1 50%"
                direction={!parentHasCustomFields ? 'row' : 'column'}
                spacing={3}
                pt={2}
              >
                <RowsContainer
                  stretch={!parentHasCustomFields}
                  containerTitle="applications-PersonalInformation"
                >
                  {Number.isInteger(parent.gender) && (
                    <ApplicationPreviewInfoRow
                      onEdit={() => canEdit && onEditClick('gender')}
                      editable={canEdit}
                      editIconName="gender"
                      updating={updating?.['gender']}
                      updatingIcon={updatingIcon}
                      readonlyIcon={readonlyIcon}
                      withoutActionBackground={withoutActionBackground}
                    >
                      <Icon>{parent.gender === Genders.Male ? <MaleIcon /> : <FemaleIcon />}</Icon>
                      <GridRowName>
                        {formatMessage({
                          id: getLabelIdForOption({
                            key: Genders[parent.gender!],
                            optionsKey: 'gender',
                          }),
                        })}
                      </GridRowName>
                    </ApplicationPreviewInfoRow>
                  )}
                  {!!parent.nationalities?.length && (
                    <ApplicationPreviewInfoRow
                      onEdit={() => canEdit && onEditClick('nationalities')}
                      editable={canEdit}
                      editIconName="nationalities"
                      updating={updating?.['nationalities']}
                      updatingIcon={updatingIcon}
                      readonlyIcon={readonlyIcon}
                      withoutActionBackground={withoutActionBackground}
                    >
                      <Icon>
                        <FlagIcon />
                      </Icon>
                      <GridRowName>{nationalitiesString}</GridRowName>
                    </ApplicationPreviewInfoRow>
                  )}
                  {Number.isInteger(parent.language) && (
                    <ApplicationPreviewInfoRow
                      onEdit={() => canEdit && onEditClick('language')}
                      editable={canEdit}
                      editIconName="language"
                      updating={updating?.['language']}
                      updatingIcon={updatingIcon}
                      readonlyIcon={readonlyIcon}
                      withoutActionBackground={withoutActionBackground}
                    >
                      <Icon>
                        <Language3Icon className="reset-svg-currentColor" />
                      </Icon>

                      <Stack direction="row" alignItems="center" flexGrow={1}>
                        <GridRowName>
                          {formatMessage({
                            id: getLabelIdForOption({
                              key: Languages[parent.language!],
                              optionsKey: 'language',
                            }),
                          })}
                        </GridRowName>
                      </Stack>
                    </ApplicationPreviewInfoRow>
                  )}
                  {parent.profession && (
                    <ApplicationPreviewInfoRow
                      onEdit={() => canEdit && onEditClick('profession')}
                      editable={canEdit}
                      editIconName="profession"
                      updating={updating?.['profession']}
                      updatingIcon={updatingIcon}
                      readonlyIcon={readonlyIcon}
                      withoutActionBackground={withoutActionBackground}
                    >
                      <Stack direction="row" alignItems="center" gap={1} maxWidth={400}>
                        <Icon>
                          <ProfessionIcon />
                        </Icon>
                        <GridRowName
                          maxWidth="90%"
                          sx={(theme) => ({
                            '& .professionTooltip': {
                              '.UIKit-LongNameWithVerticalTooltip__info': {
                                paddingX: 0,
                              },
                              '&.UIKit-LongNameWithVerticalTooltip_tooltip': {
                                ':hover': {
                                  '.UIKit-LongNameWithVerticalTooltip__info': {
                                    backgroundColor: theme.palette.common.white,
                                    paddingX: theme.spacing(1),
                                  },
                                },
                              },
                            },
                          })}
                        >
                          <LongNameWithVerticalTooltip className="professionTooltip">
                            {parent.profession}
                          </LongNameWithVerticalTooltip>
                        </GridRowName>
                      </Stack>
                    </ApplicationPreviewInfoRow>
                  )}
                </RowsContainer>

                <RowsContainer
                  stretch={!parentHasCustomFields}
                  containerTitle="applications-ContactInformation"
                >
                  {parent.email && (
                    <ApplicationPreviewInfoRow
                      onEdit={() => canEdit && onEditClick('email')}
                      editable={canEdit}
                      editIconName="email"
                      updating={updating?.['email']}
                      updatingIcon={updatingIcon}
                      readonlyIcon={readonlyIcon}
                      withoutActionBackground={withoutActionBackground}
                    >
                      <Stack direction="row" alignItems="center" gap={1} maxWidth={400}>
                        <Icon>
                          <EmailIcon />
                        </Icon>
                        <GridRowName
                          maxWidth="100%"
                          sx={(theme) => ({
                            '& .emailTooltip': {
                              '.UIKit-LongNameWithVerticalTooltip__info': {
                                paddingX: 0,
                              },
                              '&.UIKit-LongNameWithVerticalTooltip_tooltip': {
                                ':hover': {
                                  '.UIKit-LongNameWithVerticalTooltip__info': {
                                    backgroundColor: theme.palette.common.white,
                                    paddingX: theme.spacing(1),
                                  },
                                },
                              },
                            },
                          })}
                        >
                          <LongNameWithVerticalTooltip className="emailTooltip">
                            <Link
                              sx={{
                                transition: 'none',
                              }}
                              underline="hover"
                              href={`mailto:${parent.email}`}
                              target="_blank"
                            >
                              {parent.email}
                            </Link>
                          </LongNameWithVerticalTooltip>
                        </GridRowName>
                      </Stack>
                    </ApplicationPreviewInfoRow>
                  )}
                  {(parent.telephone || parent.phoneCode) && (
                    <ApplicationPreviewInfoRow
                      onEdit={() => canEdit && onEditClick('telephone')}
                      editable={canEdit}
                      editIconName="telephone"
                      updating={updating?.['telephone']}
                      updatingIcon={updatingIcon}
                      readonlyIcon={readonlyIcon}
                      withoutActionBackground={withoutActionBackground}
                    >
                      <Icon>
                        <PhoneIcon />
                      </Icon>
                      <GridRowName>{`${parent.phoneCode ?? ''}${' '}${
                        parent.telephone
                      }`}</GridRowName>
                    </ApplicationPreviewInfoRow>
                  )}
                </RowsContainer>
              </Stack>

              {parentHasCustomFields && (
                <Stack flex="1 1 50%" spacing={3} pt={2}>
                  <ApplicationAdditionalInformationPreview
                    customFields={customFieldsToShow}
                    editable={canEdit}
                    updating={updating}
                    updatingIcon={updatingIcon}
                    readonlyIcon={readonlyIcon}
                    withoutActionBackground={withoutActionBackground}
                    onEdit={() => canEdit && onEditClick('customFields')}
                  />
                </Stack>
              )}
            </Stack>
            <EditDialog
              name={getUserFullNameWithTitle(parent)}
              isOpen={isFormOpen}
              onClose={() => {
                form.reset(parent);
                hideFormModal();
              }}
              onConfirm={async () => {
                const isValid = await form.trigger();

                if (!isValid) {
                  return;
                }

                onSubmit(form.getValues(), previewType ?? '');
                hideFormModal();
              }}
            >
              {formContent}
            </EditDialog>
          </Stack>
        </form>
      </FormProvider>
    </>
  );
};
