import React, { FC } from 'react';

import { useTableHover } from '../../../context/table/tableHover/useTableHover';
import { ROW_HOVER_ID } from '../../../context/table/tableHover/WithTableHover';
import { GridBody, GridRow } from '../Grid/Grid';
import { TableRow } from './Table';

interface TableBodyProps {
  rows: TableRow[];
  withoutDefaultHover?: boolean;
}

export const TableBody: FC<TableBodyProps> = ({ rows, withoutDefaultHover }) => {
  const { gridRef, getRowHoverProps, styles } = useTableHover();
  return (
    <GridBody ref={gridRef} sx={{ ...styles }} data-cy="table-body">
      {rows.map((row, rowIndex) => {
        const multiRowHover = row.multiRowHoverId ? { [ROW_HOVER_ID]: row.multiRowHoverId } : {};
        const { sx, ...restProps } = row.props?.(row) ?? {};

        const sxProps = sx ?? {};

        return (
          <GridRow
            data-cy="table-row"
            key={row.id}
            withoutDefaultHover={withoutDefaultHover}
            {...multiRowHover}
            sx={sxProps}
            {...restProps}
          >
            {row.cells.map((cell, colIndex) => {
              const props = getRowHoverProps({
                cellId: cell.id,
                colIndex,
                rowIndex,
                multiRowHover: !!row.multiRowHoverId,
                ...cell.hoverProps,
              });

              return <React.Fragment key={cell.id}>{cell.renderContent(props)}</React.Fragment>;
            })}
          </GridRow>
        );
      })}
    </GridBody>
  );
};
