import { Link, Stack, styled } from '@mui/material';
import { FC } from 'react';

// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Links } from '../../../../landing/src/constants';
import AppStoreSvg from './appstore.svg';
import GooglePlaySvg from './google-play.svg';

export const DemoSchoolAppLinks: FC = () => {
  return (
    <AppLinksContainer>
      <Stack
        sx={(theme) => ({
          flex: 1,
          borderRadius: theme.spacing(1),
          textAlign: 'center',
          border: `1px solid ${theme.palette.primary.main}`,
        })}
      >
        <Link href={Links.iosApp}>
          <img className="app-store" src={AppStoreSvg} alt={Links.iosApp} />
        </Link>
      </Stack>

      <Stack
        sx={(theme) => ({
          flex: 1,
          borderRadius: theme.spacing(1),
          border: `1px solid ${theme.palette.primary.main}`,
        })}
      >
        <Link href={Links.androidApp}>
          <img className="google-play" src={GooglePlaySvg} alt={Links.androidApp} />
        </Link>
      </Stack>
    </AppLinksContainer>
  );
};

const AppLinksContainer = styled(Stack)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',

  marginBottom: theme.spacing(3),
  gap: theme.spacing(2),

  '.app-store': {
    width: '88%',
    alignSelf: 'center',
  },
  '.google-play': {
    width: '100%',
  },

  [theme.breakpoints.up('sm')]: {
    display: 'none',
  },
}));
