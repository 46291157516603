import { Stack, TableRow, Typography } from '@mui/material';
import { FeeItem } from '@schooly/api';
import { GridCell, TypographyWithOverflowHint } from '@schooly/style';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';

type PayableFeeItemRowProps = {
  item: FeeItem;
  borderBottom: boolean;
  currencySymbol: string;
};

export const PayableFeeItemRow: FC<PayableFeeItemRowProps> = ({
  item,
  borderBottom,
  currencySymbol,
}) => {
  return (
    <TableRow
      sx={(theme) => ({
        '&:hover': {
          '.MuiTypography-root': {
            color: theme.palette.primary.main,
          },
          'td.MuiTableCell-root': {
            backgroundColor: theme.palette.background.default,
          },
        },
      })}
    >
      <GridCell borderBottom={borderBottom} noVerticalPadding colSpan={2} pr={0}>
        <Stack
          alignItems="center"
          direction="row"
          gap={1}
          sx={{
            maxWidth: 390,
          }}
        >
          <TypographyWithOverflowHint sx={{ '&:hover': { textDecoration: 'underline' } }}>
            <Typography
              component={Link}
              to={{ pathname: `/settings/products/${item.product_id}` }}
              variant="h3"
              color="text.primary"
            >
              {item.label}
            </Typography>
          </TypographyWithOverflowHint>
          {/* Will be needed in the future when BE supports item_current and item_total */}
          {/* <Box
            sx={(theme) => ({
              border: `1px solid ${theme.palette.common.main5}`,
              borderRadius: 0.25,
              backgroundColor: theme.palette.common.lightBg,
            })}
          >
            <Typography
              color="text.primary"
              sx={{
                px: 0.5,
                py: 0.25,
              }}
            >
              {`${item.item_current}/${item.item_total}`}
            </Typography>
          </Box> */}
        </Stack>
      </GridCell>

      <GridCell borderBottom={borderBottom} noVerticalPadding>
        <TypographyWithOverflowHint maxWidth={180}>
          <Typography color="text.primary" display="inline">
            {`${currencySymbol} ${item.price}`}
          </Typography>
        </TypographyWithOverflowHint>
      </GridCell>

      <GridCell borderBottom={borderBottom} noVerticalPadding />
      <GridCell borderBottom={borderBottom} noVerticalPadding />
      <GridCell borderBottom={borderBottom} noVerticalPadding />
    </TableRow>
  );
};
