import {
  Icon,
  IconButton,
  Skeleton,
  Stack,
  TableHeadProps,
  TableRow,
  Theme,
  Typography,
} from '@mui/material';
import { PaymentFrequencyType, Product, useGetSchoolPaymentFrequencies } from '@schooly/api';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  ArrowAngleIcon,
  DropdownIcon,
  GridCell,
  GridHead,
  randomInt,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { FC, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import { getVariantPrice } from '../../SchoolProducts/helpers';
import { ProductApplicable } from '../../SchoolProducts/ProductsApplicable';

const FREQUENCIES_FOR_LEGAL_ENTITY_PRODUCTS = [
  PaymentFrequencyType.Weekly,
  PaymentFrequencyType.Monthly,
  PaymentFrequencyType.Termly,
  PaymentFrequencyType.Biannually,
  PaymentFrequencyType.Annually,
];

export const LegalEntityProductsHeader: FC<TableHeadProps> = ({ ...rest }) => {
  const { $t } = useIntl();

  return (
    <GridHead borderBottom {...rest}>
      <GridCell width="10px" />
      <GridCell>{$t({ id: 'products-Name' })}</GridCell>
      <GridCell width="40px" />
      <GridCell width="165px" />
      {FREQUENCIES_FOR_LEGAL_ENTITY_PRODUCTS.map((f) => (
        <GridCell width="115px">{$t({ id: `products-Frequency-${f}` })}</GridCell>
      ))}
      <GridCell width="50px" />
    </GridHead>
  );
};

type ProductRowProps = {
  product: Product;
  schoolId: string;
  isSelected?: boolean;
  onSelect?: (v: string) => void;
  currencySymbol: string;
};

export const LegalEntityProductRow: FC<ProductRowProps> = ({
  product,
  schoolId,
  currencySymbol,
}) => {
  const ref = useRef<HTMLAnchorElement | null>(null);
  const [isExpanded, expand, collapse] = useFlag();
  const { data, isLoading } = useGetSchoolPaymentFrequencies(schoolId);
  const [rowHovered, setRowHovered] = useState(false);

  const pricesSkeletonWidths = useRef(
    FREQUENCIES_FOR_LEGAL_ENTITY_PRODUCTS.map(() => randomInt(50, 100)),
  ).current;

  const { name, id, types } = product;

  const hoverProps = {
    onMouseEnter: () => setRowHovered(true),
    onMouseLeave: () => setRowHovered(false),
    sx: (theme: Theme) => ({
      ...(rowHovered && {
        'td.MuiTableCell-root': {
          backgroundColor: theme.palette.background.default,
          '.MuiTypography-root': {
            color: theme.palette.primary.main,
          },
        },
      }),
    }),
  };

  const borderBottomOfFirstRow = isExpanded ? false : undefined;

  return (
    <>
      <TableRow
        ref={ref}
        component={Link}
        to={`/settings/products/${id}`}
        target="_blank"
        {...hoverProps}
      >
        <GridCell borderBottom={borderBottomOfFirstRow} />
        <GridCell borderBottom={borderBottomOfFirstRow} colSpan={8}>
          <TypographyWithOverflowHint variant="h3" color="text.primary">
            {name}
          </TypographyWithOverflowHint>
        </GridCell>

        <GridCell
          borderBottom={borderBottomOfFirstRow}
          onClick={(e) => {
            e.preventDefault();
            isExpanded ? collapse() : expand();
          }}
        >
          {!!types.length && (
            <IconButton
              inverse
              sx={(theme) => ({
                transform: isExpanded ? 'rotate(180deg)' : undefined,
                color: rowHovered ? theme.palette.common.grey2 : undefined,
              })}
            >
              <DropdownIcon />
            </IconButton>
          )}
        </GridCell>
      </TableRow>
      <>
        {isExpanded &&
          types.map((type, typeIndex, types) => {
            const isLastType = typeIndex === types.length - 1;
            const isFirstType = typeIndex === 0;
            return (
              <>
                <TableRow
                  key={type.id}
                  component={Link}
                  to={`/settings/products/${id}`}
                  {...hoverProps}
                >
                  <GridCell borderBottom={false} />
                  <GridCell borderBottom={false} p={0} pl={1} colSpan={8}>
                    <Stack direction="row" gap={1} alignItems="center">
                      <Icon
                        sx={(theme) => ({
                          color: rowHovered
                            ? theme.palette.common.grey2
                            : theme.palette.common.grey,
                        })}
                      >
                        <ArrowAngleIcon />
                      </Icon>
                      <Stack
                        width="100%"
                        py={2}
                        sx={(theme) => ({
                          borderTop: `1px solid ${theme.palette.divider}`,
                        })}
                      >
                        <Typography variant="h3" color="text.primary">
                          {type.name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </GridCell>
                  <GridCell borderBottom={false} borderTop={isExpanded && !isFirstType} />
                </TableRow>
                {type.variants.map((variant, index, variants) => {
                  const isLastVariant = index === variants.length - 1;

                  return (
                    <TableRow
                      key={variant.id}
                      component={Link}
                      to={`/settings/products/${id}`}
                      {...hoverProps}
                    >
                      <GridCell
                        colSpan={2}
                        borderBottom={isLastVariant && isLastType ? undefined : false}
                      />
                      <GridCell borderBottom={!isLastType && isLastVariant ? false : undefined} />
                      <GridCell
                        borderBottom={!isLastType && isLastVariant ? false : undefined}
                        sx={(theme) => ({
                          '&.MuiTableCell-root': { lineHeight: theme.spacing(2.25) },
                        })}
                      >
                        <ProductApplicable
                          pt={0.25}
                          color="text.primary"
                          schoolId={schoolId}
                          selectedAgeGroupIds={variant.age_groups}
                          selectedSubjectIds={variant.subjects}
                        />
                      </GridCell>
                      {FREQUENCIES_FOR_LEGAL_ENTITY_PRODUCTS.map((f, i) => {
                        const price = getVariantPrice(
                          variant,
                          currencySymbol,
                          data?.find(({ frequency_type }) => frequency_type === f)?.id,
                        );
                        return (
                          <GridCell
                            key={f}
                            borderBottom={!isLastType && isLastVariant ? false : undefined}
                            sx={(theme) => ({
                              '&.MuiTableCell-root': { lineHeight: theme.spacing(2.25) },
                            })}
                          >
                            {isLoading ? (
                              <Skeleton width={`${pricesSkeletonWidths[i]}%`} />
                            ) : (
                              <Typography
                                color="text.primary"
                                sx={{
                                  ...(!price && {
                                    '&&&.MuiTypography-root': {
                                      color: 'text.secondary',
                                    },
                                  }),
                                }}
                              >
                                {price || '-'}
                              </Typography>
                            )}
                          </GridCell>
                        );
                      })}
                      <GridCell borderBottom={isLastVariant && isLastType ? undefined : false} />
                    </TableRow>
                  );
                })}
              </>
            );
          })}
      </>
    </>
  );
};
