import { Icon, IconButton, Stack, Typography } from '@mui/material';
import {
  CrossIcon,
  ModalContent,
  ModalHeader,
  ModalMain,
  ReportsIcon,
  Spin,
  StudentIcon,
} from '@schooly/style';
import { FC, useMemo } from 'react';
import { FormattedMessage } from 'react-intl';

import { CreateChildForm } from '../CreateChildContent.type';
import { CreateCustomField } from '../CreateCustomField.type';
import { CreateParentForm } from '../CreateParentContent.type';
import { getCustomFieldsToShow } from '../utils';
import { ApplicationEditablePreviewChild } from './ApplicationEditablePreview/ApplicationEditablePreviewChild';
import { ApplicationEditablePreviewGeneralCustomFields } from './ApplicationEditablePreview/ApplicationEditablePreviewGeneralCustomFields';
import { ApplicationEditablePreviewParent } from './ApplicationEditablePreview/ApplicationEditablePreviewParent';

export type PreviewFormType =
  | 'language'
  | 'schoolHistory'
  | 'dateOfBirth'
  | 'userInfo'
  | 'customFields'
  | 'startedDate'
  | 'nationalities'
  | 'gender'
  | 'email'
  | 'telephone'
  | 'profession'
  | 'relationshipType';

export interface ApplicationUpdateForm {
  children: CreateChildForm[];
  parents: CreateParentForm[];
  generalCustomFields?: CreateCustomField[];
}

interface ApplicationEditablePreviewContentProps {
  canEdit: boolean;
  headerTitle: React.ReactNode;
  footerActions: React.ReactNode;
  formData: ApplicationUpdateForm;
  withNotes?: boolean;
  parentUpdating?: Record<string, boolean>;
  childUpdating?: Record<string, boolean>;
  onClose?: () => void;
  onChildSubmit: (data: CreateChildForm, type: string) => void;
  onParentSubmit: (data: CreateParentForm, type: string) => void;
  onGeneralCustomFieldsSubmit: (data: CreateCustomField[], type: string) => void;
}

export const ApplicationEditablePreviewContent: FC<ApplicationEditablePreviewContentProps> = ({
  canEdit,
  headerTitle,
  footerActions,
  formData,
  withNotes,
  childUpdating,
  parentUpdating,
  onClose,
  onChildSubmit,
  onParentSubmit,
  onGeneralCustomFieldsSubmit,
}) => {
  const updatingIcon = (
    <Stack
      sx={(theme) => ({
        paddingRight: theme.spacing(0.5),
        color: theme.palette.primary.main,
      })}
    >
      <Icon fontSize="small">
        <Spin />
      </Icon>
    </Stack>
  );

  const generalCustomFieldsToShow = useMemo(
    () => getCustomFieldsToShow(formData.generalCustomFields ?? []),
    [formData.generalCustomFields],
  );

  return (
    <>
      <ModalHeader title={headerTitle}>
        <IconButton onClick={onClose} disabled={!onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>

      <ModalMain>
        <ModalContent withBorderBottom flat>
          {!!formData.parents.length && (
            <Stack
              px={2.5}
              pb={4}
              sx={(theme) => ({
                borderBottom: theme.mixins.borderValue(),
              })}
              gap={4.5}
            >
              {formData.parents.map((parent) => {
                return (
                  <ApplicationEditablePreviewParent
                    key={parent.id}
                    parent={parent}
                    onSubmit={onParentSubmit}
                    canEdit={canEdit && Boolean(parent.can_be_edited)}
                    // After discussion https://schooly.atlassian.net/browse/TR-4452
                    // canEdit={false}
                    updating={parentUpdating}
                    updatingIcon={updatingIcon}
                    withoutActionBackground
                    // readonlyIcon={
                    //   <ManageGroupModalTooltip
                    //     text={
                    //       <Typography color="primary.main">
                    //         <FormattedMessage id="applications-ReadonlyAdult" />
                    //       </Typography>
                    //     }
                    //     sx={{
                    //       border: theme.mixins.borderValue(),
                    //       padding: theme.spacing(1, 1.25),
                    //       width: 200,
                    //     }}
                    //     arrow
                    //   >
                    //     <EyeIcon />
                    //   </ManageGroupModalTooltip>
                    // }
                  />
                );
              })}
            </Stack>
          )}

          {!!formData.children.length && (
            <Stack px={2.5} pt={2.5}>
              <Stack direction="row" alignItems="center" gap={1.5} pl={1.5} pb={3}>
                <Icon color="primary">
                  <StudentIcon />
                </Icon>

                <Typography variant="h1">
                  <FormattedMessage id="userType-child-plural" />
                </Typography>
              </Stack>

              {formData.children.map((child) => {
                return (
                  <ApplicationEditablePreviewChild
                    key={child.id}
                    child={child}
                    onSubmit={onChildSubmit}
                    canEdit={canEdit}
                    withNotes={withNotes}
                    updating={childUpdating}
                    updatingIcon={updatingIcon}
                  />
                );
              })}
            </Stack>
          )}

          {!!generalCustomFieldsToShow.length && (
            <Stack px={2.5} pt={2.5}>
              <Stack direction="row" alignItems="center" gap={1.5} pl={1.5} pb={3}>
                <Icon color="primary">
                  <ReportsIcon />
                </Icon>

                <Typography variant="h1">
                  <FormattedMessage id="userType-general-information" />
                </Typography>
              </Stack>

              <ApplicationEditablePreviewGeneralCustomFields
                generalCustomFields={generalCustomFieldsToShow}
                onSubmit={onGeneralCustomFieldsSubmit}
                canEdit={canEdit}
                withNotes={withNotes}
                updating={childUpdating}
                updatingIcon={updatingIcon}
              />
            </Stack>
          )}
        </ModalContent>
      </ModalMain>
      {footerActions}
    </>
  );
};
