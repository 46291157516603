import { Skeleton, Stack, Typography, TypographyProps } from '@mui/material';
import { useGetGroupSubjectsQuery } from '@schooly/api';
import { getSelectedItemsWithGrouping, SelectedItem } from '@schooly/components/filters';
import { SchoolUserRole } from '@schooly/constants';
import { useAgeGroups } from '@schooly/hooks/use-school-properties';
import { FC, useMemo } from 'react';

type ProductApplicableProps = {
  schoolId: string;
  selectedSubjectIds: string[];
  selectedAgeGroupIds: string[];
} & TypographyProps;
export const ProductApplicable: FC<ProductApplicableProps> = ({
  schoolId,
  selectedAgeGroupIds,
  selectedSubjectIds,
  ...props
}) => {
  const {
    getAgeGroupById,
    getSchoolLevelById,
    schoolLevelsWithAgeGroupsMap,
    isLoading: isLoadingAgeGroups,
  } = useAgeGroups({
    schoolId,
    userType: SchoolUserRole.Student,
  });

  const { data: subjectsData, isLoading: isLoadingSubjects } = useGetGroupSubjectsQuery({
    schoolId,
  });

  const isLoading = isLoadingAgeGroups || isLoadingSubjects;

  const label = useMemo(() => {
    const selectedAgeGroups =
      selectedAgeGroupIds?.reduce<SelectedItem[]>((acc, id) => {
        const ageGroup = getAgeGroupById(id);

        return ageGroup
          ? [
              ...acc,
              {
                id: ageGroup.id,
                groupId: ageGroup.level_id,
              },
            ]
          : acc;
      }, []) ?? [];

    const selectedItemsWithGrouping = getSelectedItemsWithGrouping(
      selectedAgeGroups,
      schoolLevelsWithAgeGroupsMap,
    );

    return [
      ...selectedSubjectIds.map(
        (id) => subjectsData?.subjects.find((s) => s.id === id)?.name || '',
      ),
      ...selectedItemsWithGrouping.map((item) => {
        return item.isGroup
          ? getSchoolLevelById(item.id)?.name || ''
          : getAgeGroupById(item.id)?.name || '';
      }),
    ]
      .filter(Boolean)
      .join(', ');
  }, [
    getAgeGroupById,
    getSchoolLevelById,
    schoolLevelsWithAgeGroupsMap,
    selectedAgeGroupIds,
    selectedSubjectIds,
    subjectsData?.subjects,
  ]);

  if (isLoading)
    return (
      <Stack px={1} flexDirection="row" gap={1}>
        <Skeleton width={120} /> <Skeleton width={60} />
      </Stack>
    );

  return <Typography {...props}>{label}</Typography>;
};
