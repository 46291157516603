import { Button, Stack, Typography } from '@mui/material';
import { useAuth } from '@schooly/components/authentication';
import { useFlag } from '@schooly/hooks/use-flag';
import { Spin } from '@schooly/style';
import React, { FC, useCallback, useEffect } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useSearchParams } from 'react-router-dom';

import SchoolIncompleteImg from '../../../assets/images/school_incomplete.svg';
import Header from '../../../components/ui/Header';
import MainLayout from '../../../components/uikit-components/MainLayout/MainLayout';

const forceReactInputOnChange = (input: HTMLInputElement | HTMLTextAreaElement) => {
  if ((input as any)._valueTracker) {
    (input as any)._valueTracker.setValue('');
    input.dispatchEvent(
      new Event('input', {
        bubbles: true,
      }),
    );
  }
};

export const SchoolDataImport: FC = () => {
  const { $t } = useIntl();
  const [searchParams] = useSearchParams();
  const { currentUser, currentSchool } = useAuth();
  const [isLoadingWidget, startLoading, endLoading] = useFlag(false);

  const handleClick = useCallback(async () => {
    startLoading();
    await fullfillJSDWidget('Data import', {
      summary: 'Data import',
      description: currentSchool?.name,
      email: currentUser?.account_email,
    });
    endLoading();
  }, [currentSchool?.name, currentUser?.account_email, endLoading, startLoading]);

  useEffect(() => {
    if (searchParams.has('submit')) {
      setTimeout(handleClick, 1000);
    }
  }, [handleClick, searchParams]);

  return (
    <MainLayout>
      <Header pageTitleTextId="school-sections-DataImport" />

      <Stack justifyContent="center" alignItems="center" flex={1}>
        <img
          width={300}
          height={300}
          src={SchoolIncompleteImg}
          alt={$t({ id: 'school-sections-DataImport' })}
        />

        <Typography variant="h1" mt={2.5} textAlign="center" whiteSpace="pre-wrap">
          <FormattedMessage id="school-sections-DataImport-message" />
        </Typography>

        <Typography
          variant="h3"
          color="text.primary"
          mt={1}
          textAlign="center"
          whiteSpace="pre-wrap"
        >
          <FormattedMessage id="school-sections-DataImport-submessage" />
        </Typography>

        <Button
          sx={{ mt: 3 }}
          onClick={handleClick}
          disabled={isLoadingWidget}
          startIcon={isLoadingWidget ? <Spin /> : undefined}
        >
          <FormattedMessage id="action-SubmitRequest" />
        </Button>
      </Stack>
    </MainLayout>
  );
};

/**
 * Opens JSD widget with pre-populated data. As the JSD doesn't provide any API out of the box,
 * will be making dirty hacks to simulate the widget's flow.
 */

const fullfillJSDWidget = async (
  // searchRequest must contain request name
  searchRequest: string,
  {
    summary,
    description,
    email,
  }: {
    summary?: string;
    description?: string;
    email?: string;
  },
) => {
  const widgetDoc = await performWithTimeout(
    () => (document.getElementById('jsd-widget') as HTMLIFrameElement | undefined)?.contentDocument,
    1000,
  );

  if (!widgetDoc) return;

  const button = await performWithTimeout(() => widgetDoc.getElementById('help-button'), 1000);

  if (button) {
    button.click();
  }

  const searchContainer = await performWithTimeout(() =>
    widgetDoc.getElementById('search-container'),
  );

  if (searchContainer) {
    // temporary hide search container
    searchContainer.style.opacity = '0';

    const input = (await performWithTimeout(() =>
      searchContainer.querySelector('[data-ds--text-field--input]'),
    )) as HTMLInputElement | null;
    const searchButton = (await performWithTimeout(() =>
      searchContainer.querySelector('button[type=submit]'),
    )) as HTMLButtonElement | null;

    if (!input || !searchButton) return;

    input.value = searchRequest;
    forceReactInputOnChange(input);

    searchButton.click();

    const requestsListFirstEl = (await performWithTimeout(
      () => {
        // Ensure that the right request is chosen
        const el = searchContainer.querySelector('.search-results ul li p.name');
        if (el?.textContent?.toLowerCase().includes(searchRequest.toLowerCase())) {
          return el;
        }
        return null;
      },

      1000,
    )) as HTMLElement | null;
    if (!requestsListFirstEl) return;

    requestsListFirstEl.click();
  }

  const summaryInput = (await performWithTimeout(() =>
    widgetDoc.querySelector('.form-container input#summary'),
  )) as HTMLInputElement | null;

  const descriptionInput = (await performWithTimeout(() =>
    widgetDoc.querySelector('.form-container textarea#description'),
  )) as HTMLTextAreaElement | null;

  const emailInput = (await performWithTimeout(() =>
    widgetDoc.querySelector('.form-container input#email'),
  )) as HTMLInputElement | null;

  if (summaryInput) {
    summaryInput.value = summary || '';
    forceReactInputOnChange(summaryInput);
  }

  if (descriptionInput) {
    descriptionInput.value = description ?? '';
    forceReactInputOnChange(descriptionInput);
  }

  if (emailInput) {
    emailInput.value = email ?? '';
    forceReactInputOnChange(emailInput);
  }
};

const performWithTimeout = async <T extends unknown>(
  request: () => T,
  maxTimeout = 100,
  retry = 0,
): Promise<T | null> => {
  const nextTimeout = retry * 100;

  if (nextTimeout > maxTimeout) return null;

  const el = request();

  if (!el) {
    await awaitTimeout(nextTimeout);
    return await performWithTimeout(request, maxTimeout, retry + 1);
  }

  return el;
};

const awaitTimeout = (v = 0) =>
  new Promise((res) => {
    if (!v) {
      res(true);
      return;
    }

    setTimeout(res, v);
  });
