import { ApiError, getAttachment, InboxMessage, Message } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { actions } from 'apps/web/src/redux/slices/messagesSlice';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { dispatch } from 'apps/web/src/redux/store';
import { triggerBase64Download } from 'common-base64-downloader-react';
import { useCallback } from 'react';

import { getAttachmentName, getBase64Href } from './utils';

export const useFetchAttachment = (message?: Message | InboxMessage) => {
  const { showError } = useNotifications();

  const fetchAttachment = useCallback(
    async (attachmentId?: string) => {
      if (!message || !attachmentId) return;

      dispatch(actions.setLoadingAttachmentId(attachmentId));
      try {
        const data = await getAttachment(attachmentId, message.id);
        triggerBase64Download(getBase64Href(data), getAttachmentName(data));
      } catch (err) {
        showError(err as ApiError);
      } finally {
        dispatch(actions.removeLoadingAttachmentId(attachmentId));
      }
    },
    [message, showError],
  );

  return fetchAttachment;
};
