import { GlobalStyles, useTheme } from '@mui/material';
import React, { CSSProperties, FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { Outlet } from 'react-router-dom';

export const JiraServiceWidget: FC = () => {
  const { $t } = useIntl();
  const theme = useTheme();

  useEffect(() => {
    const container = document.getElementsByTagName('body')[0];
    const scriptEl = document.createElement('script');

    scriptEl.setAttribute('data-jsd-embedded', '');
    scriptEl.setAttribute('data-key', 'd4234196-9ffa-44b2-8aee-10328ef01642');
    scriptEl.setAttribute('data-base-url', 'https://jsd-widget.atlassian.com');
    scriptEl.setAttribute('src', 'https://jsd-widget.atlassian.com/assets/embed.js');
    scriptEl.onload = () => {
      window.document.dispatchEvent(
        new Event('DOMContentLoaded', {
          bubbles: true,
          cancelable: true,
        }),
      );
      setTimeout(restyleJSD);
    };

    container.appendChild(scriptEl);

    const restyleJSD = () => {
      const widget = document.querySelector<HTMLIFrameElement>('#jsd-widget');

      if (!widget?.contentDocument) {
        setTimeout(restyleJSD, 1000);
        return;
      }

      const style2string = (obj: CSSProperties) => {
        const p = document.createElement('p');
        Object.assign(p.style, obj);

        return p.getAttribute('style');
      };

      const style = widget.contentDocument.createElement('style');
      style.innerHTML = `
          #react-root #button-container {${style2string({
            padding: 0,
          })}}

          #react-root #button-container #help-button.icon {${style2string({
            ...theme.typography.caption,
            fontSize: '12px',
            width: theme.spacing(2.5),
            height: 'auto',
            padding: theme.spacing(1, 0),
            borderTopLeftRadius: theme.spacing(0.5),
            borderBottomLeftRadius: theme.spacing(0.5),
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          })}}

          #react-root #button-container #help-button:before {${style2string({
            content: `"${$t({ id: 'jsd-Label' })}"`,
            display: 'inline-block',
            transform: 'rotate(180deg)',
            writingMode: 'vertical-rl',
            whiteSpace: 'nowrap',
          })}}

          #react-root #button-container #help-button .help-button-contents {${style2string({
            display: 'none',
          })}}
          `;

      widget.contentDocument.head.appendChild(style);
    };

    return () => {
      // remove script element
      scriptEl && container.removeChild(scriptEl);

      // detect script's iframe and remove it
      // as it doesn't have any built-in mechanism for that
      const widget = document.getElementById('jsd-widget');
      const parentNode = widget?.parentNode;

      if (
        parentNode &&
        'removeChild' in parentNode &&
        typeof parentNode.removeChild === 'function'
      ) {
        widget?.parentNode.removeChild(widget);
      }
    };
  }, [$t, theme]);

  return (
    <>
      <GlobalStyles styles={{ '#jsd-widget': { right: '-10px !important' } }} />
      <Outlet />
    </>
  );
};
