import { Stack, SxProps, Theme, Typography, TypographyTypeMap } from '@mui/material';
import { EditIcon, LockIcon, PlusIcon } from '@schooly/style';
import React, {
  FC,
  PropsWithChildren,
  ReactNode,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { FormattedMessage } from 'react-intl';

import { Counter } from '../../uikit/Counter/Counter.styled';
import { SimpleButton } from '../../uikit/SimpleButton/SimpleButton';
import { ModalPanel } from './Modal.styled';
import { ModalRightSidebar } from './ModalRightSidebar';

interface ModalPeopleExtensionPanelProps extends PropsWithChildren {
  titleId?: string;
  addActionId: string;
  editActionId: string;
  active: boolean;
  disabled?: boolean;
  locked?: boolean;
  count?: number;
  onAddClick?: (e: SyntheticEvent) => void;
  errorId?: string;
  errorText?: string;
  sidebarContent?: ReactNode;
  headerMode?: boolean;
  titleIcon?: React.ReactNode;
  variant?: TypographyTypeMap['props']['variant'];
  editActionIcon?: React.ReactNode;
  infoNode?: React.ReactNode;
  withoutGap?: boolean;
  withoutPadding?: boolean;
  showActionIcon?: boolean;
  forceShowEditButton?: boolean;
  activePanelDetails?: React.ReactNode;
  activePanelStyleProps?: SxProps;
  testId?: string;
  actionButton?: React.ReactNode;
}

export const ModalPeopleExtensionPanel: FC<ModalPeopleExtensionPanelProps> = ({
  titleId,
  addActionId,
  editActionId,
  count,
  active,
  disabled,
  locked,
  onAddClick,
  errorId,
  errorText,
  sidebarContent,
  children,
  headerMode = false,
  variant = 'h2',
  editActionIcon,
  infoNode,
  withoutGap,
  withoutPadding,
  showActionIcon = false,
  forceShowEditButton,
  activePanelDetails,
  activePanelStyleProps,
  testId,
  actionButton,
}) => {
  const panelRef = useRef<HTMLDivElement>(null);
  const [hasRef, setHasRef] = useState(false);
  const disabledStyles = {
    ...(disabled && { color: (theme: Theme) => theme.palette.common.grey }),
  };
  const showEdit = Boolean(forceShowEditButton || count);

  useEffect(() => {
    setHasRef(true);
  }, []);

  const editIcon = useMemo(() => {
    if (editActionIcon) {
      return editActionIcon;
    }

    return showEdit ? <EditIcon /> : <PlusIcon />;
  }, [showEdit, editActionIcon]);

  const showActionButton = showActionIcon || (!active && !locked);

  return (
    <>
      <ModalPanel
        active={active}
        withBorderBottom={!active}
        sx={{
          pt: !!withoutPadding ? 0 : 'undefined',
          ...((active && activePanelStyleProps) ?? {}),
          position: 'relative',
        }}
        disabled={disabled}
        flat={withoutPadding}
        ref={panelRef}
      >
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Stack direction="row" gap={withoutGap ? 0 : 1} alignItems="center">
            {!!titleId && (
              <Typography
                variant={variant}
                color={disabled ? 'text.secondary' : undefined}
                data-cy={`modal-people-header-${titleId}`}
              >
                <FormattedMessage id={titleId} />
              </Typography>
            )}
            {!!count && (
              <Counter sx={{ minWidth: 20, minHeight: 20, textAlign: 'center' }}>{count}</Counter>
            )}
            {!!infoNode && infoNode}
            {locked && <SimpleButton startIcon={<LockIcon />} sx={{ opacity: 0.5 }} />}
          </Stack>

          {(errorText || errorId) && (
            <Typography variant="body2" color="error">
              {errorText || <FormattedMessage id={errorId} />}
            </Typography>
          )}

          {active && activePanelDetails}

          {actionButton
            ? actionButton
            : showActionButton && (
                <SimpleButton
                  data-cy={testId}
                  startIcon={editIcon}
                  onClick={onAddClick}
                  sx={{
                    ':hover': {
                      '& .MuiIconButton-root': { color: 'inherit' },
                    },
                    ':active': {
                      '& .MuiIconButton-root': { color: 'inherit' },
                    },
                    ...disabledStyles,
                  }}
                >
                  <Typography variant="h3" color="inherit">
                    <FormattedMessage id={showEdit ? editActionId : addActionId} />
                  </Typography>
                </SimpleButton>
              )}
        </Stack>
      </ModalPanel>

      {sidebarContent && hasRef && (
        <ModalRightSidebar open={active && !headerMode} anchorEl={panelRef.current}>
          {sidebarContent}
        </ModalRightSidebar>
      )}

      {active && children}
    </>
  );
};
