import { Comment } from '@schooly/api';
import { useCallback, useState } from 'react';

type EntryComments = Record<string, Comment[]>;

export const useEntryComments = <T extends { id: string; comments: Comment[] }>() => {
  const [entriesComments, setEntriesComments] = useState<EntryComments>({});

  const addNewComments = useCallback((results: T[]) => {
    setEntriesComments((old) => {
      const newData = { ...old };

      results.forEach((e) => {
        if (old[e.id]) {
          return;
        }

        newData[e.id] = e.comments;
      });
      return newData;
    });
  }, []);

  const addComment = useCallback((entryId: T['id'], commentData: Comment) => {
    setEntriesComments((old) => {
      const oldEntry = old[entryId];
      return {
        ...old,
        [entryId]: [...oldEntry, commentData],
      };
    });
  }, []);

  const changeComment = useCallback((entryId: T['id'], commentData: Comment) => {
    setEntriesComments((old) => {
      const oldEntry = old[entryId];

      const newComments = !commentData.comment
        ? oldEntry.filter((c) => c.id !== commentData.id)
        : oldEntry.map((c) => (c.id === commentData.id ? commentData : c));

      return {
        ...old,
        [entryId]: newComments,
      };
    });
  }, []);

  return { addNewComments, entriesComments, addComment, changeComment };
};
