import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { scrollToErrorInput } from '@schooly/components/filters';
import { ControlTextField } from '@schooly/components/form-text-field';
import {
  AREA_CODE_OPTIONS,
  LANGUAGE_OPTIONS,
  RELATIONSHIP_TYPE_OPTIONS,
  USER_NATIONALITIES_MAX_COUNT,
  VALID_EMAIL_REGEXP,
} from '@schooly/constants';
import { GENDER_OPTIONS, NATIONALITY_OPTIONS, TITLE_OPTIONS } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import {
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalMain,
} from '@schooly/style';
import { getUserFullNameWithTitle } from '@schooly/utils/get-user-full-name-with-title';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FormRadioGroup } from 'apps/web/src/components/uikit-components/FormCheckbox/FormRadioGroup';
import { FC, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';
import { v4 as uuidv4 } from 'uuid';

import { CreateParentForm } from './CreateParentContent.type';
import { CustomFieldsFormContainer } from './CustomFieldsFormContainer';
import { RemovePersonConfirmDialog } from './RemovePersonConfirmDialog';
import { RowsContainer } from './RowsContainer';

interface CreateParentContentProps {
  onSubmit: (parent: CreateParentForm) => void;
  onRemoveParent: (parentId: string) => void;
  parent?: CreateParentForm;
  formCustomFields: CreateParentForm['custom_fields'];
  onClose: () => void;
}

export const CreateParentContent: FC<CreateParentContentProps> = ({
  onSubmit,
  onRemoveParent,
  parent,
  formCustomFields,
  onClose,
}) => {
  const { $t } = useIntl();
  const [isConfirmationDialogOpen, showConfirmationDialog, hideConfirmationDialog] = useFlag();

  const defaultValues: CreateParentForm = parent ?? {
    id: uuidv4(),
    relationship_type: undefined,
    profile_id: null,
    profession: '',
    last_name: '',
    given_name: '',
    email: '',
    nationalities: [],
    telephone: '',
    phoneCode: '',
    can_be_edited: true,
    primary: false,
    custom_fields: formCustomFields,
  };

  const form = useForm<CreateParentForm>({ defaultValues, shouldFocusError: false });

  const title = form.watch('title');
  const givenName = form.watch('given_name');
  const lastName = form.watch('last_name');
  const nationalities = form.watch('nationalities') ?? [];

  const modalHeaderTitle = useMemo(() => {
    if (!title && !givenName && !lastName) {
      return $t({
        id: 'applications-Parent-AddParentInfo',
      });
    }
    return getUserFullNameWithTitle({ title, last_name: lastName ?? '', given_name: givenName });
  }, [$t, givenName, lastName, title]);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit, scrollToErrorInput)}>
        <ModalHeader active title={modalHeaderTitle}>
          <IconButton onClick={onClose}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalMain>
          <ModalContent active>
            <Stack gap={4}>
              <Stack gap={2}>
                <Stack pb={1}>
                  <FormSelect2
                    rules={{ required: true }}
                    name="relationship_type"
                    labelTextId="relationship"
                    options={RELATIONSHIP_TYPE_OPTIONS}
                  />
                </Stack>

                <Typography variant="h4" color="text.secondary">
                  <FormattedMessage id="applications-PersonalInformation" />
                </Typography>

                <Stack direction="row" gap={1.25}>
                  <Box flex={1}>
                    <FormSelect2
                      name="title"
                      labelTextId="peopleDetail-Title"
                      options={TITLE_OPTIONS}
                    />
                  </Box>

                  <Box flex={2}>
                    <ControlTextField
                      name="given_name"
                      control={form.control}
                      rules={{ required: true }}
                      label={$t({ id: 'peopleDetail-GivenNames' })}
                      canClear
                      fullWidth
                      data-cy="parent-given-name-block"
                    />
                  </Box>
                </Stack>

                <ControlTextField
                  name="last_name"
                  control={form.control}
                  rules={{ required: true }}
                  label={$t({ id: 'peopleDetail-FamilyName' })}
                  canClear
                  fullWidth
                  data-cy="parent-last-name-block"
                />

                <FormRadioGroup options={[...GENDER_OPTIONS].reverse()} name="gender" />

                <FormSelect2
                  name="nationalities"
                  labelTextId={
                    nationalities.length <= 1
                      ? 'peopleDetail-Nationality'
                      : 'peopleDetail-Nationality-plural'
                  }
                  options={NATIONALITY_OPTIONS}
                  rules={{ required: true }}
                  multiple
                  maxCount={USER_NATIONALITIES_MAX_COUNT}
                />

                <FormSelect2
                  name="language"
                  labelTextId="peopleDetail-PrimaryLanguage"
                  options={LANGUAGE_OPTIONS}
                />

                <ControlTextField
                  name="profession"
                  control={form.control}
                  label={$t({ id: 'peopleDetail-Profession' })}
                  canClear
                  fullWidth
                />
              </Stack>

              <Stack gap={2}>
                <Typography variant="h4" color="text.secondary">
                  <FormattedMessage id="applications-ContactInformation" />
                </Typography>

                <Stack direction="row" gap={1.25}>
                  <Box flex={1}>
                    <FormSelect2
                      name="phoneCode"
                      labelTextId="AreaCode"
                      options={AREA_CODE_OPTIONS}
                      noRequiredLabel
                      showSelectedValue
                      optionClassName="CountryCodeSelectOption"
                    />
                  </Box>

                  <Box flex={2}>
                    <ControlTextField
                      name="telephone"
                      control={form.control}
                      label={$t({ id: 'peopleDetail-PhoneNumber' })}
                      canClear
                      fullWidth
                    />
                  </Box>
                </Stack>

                <ControlTextField
                  name="email"
                  control={form.control}
                  label={$t({ id: 'peopleDetail-EmailAddressPersonal' })}
                  canClear
                  fullWidth
                  //TODO: Refactor email validation - TR-4353
                  rules={{
                    required: true,
                    pattern: {
                      value: VALID_EMAIL_REGEXP,
                      message: $t({
                        id: 'input-ErrorInvalidEmail',
                      }),
                    },
                  }}
                  data-cy="parent-email-block"
                />
              </Stack>

              {!!formCustomFields?.length && (
                <Stack pt={1}>
                  <RowsContainer
                    color="text.secondary"
                    containerTitle="applications-AdditionalInformation"
                  >
                    <CustomFieldsFormContainer />
                  </RowsContainer>
                </Stack>
              )}
            </Stack>
          </ModalContent>
        </ModalMain>

        <ModalFooter active sx={{ justifyContent: parent ? 'space-between' : 'flex-end' }}>
          {parent && (
            <Button variant="outlined" startIcon={<DeleteIcon />} onClick={showConfirmationDialog}>
              <FormattedMessage id="action-Delete" />
            </Button>
          )}
          <Button type="submit" endIcon={<CheckIcon />}>
            <FormattedMessage id="action-Save" />
          </Button>
        </ModalFooter>

        <RemovePersonConfirmDialog
          isOpen={isConfirmationDialogOpen}
          onClose={hideConfirmationDialog}
          onConfirm={() => parent && onRemoveParent(parent.id)}
          personName={parent ? getUserFullNameWithTitle(parent) : ''}
        />
      </form>
    </FormProvider>
  );
};
