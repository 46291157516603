import {
  addEntryComment,
  ApiError,
  ConductEntriesRequest,
  ConductEntry,
  editEntryComment,
  FilterKeys,
  getConductEntries,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import _ from 'lodash';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import usePagedApiResourceWithFilter, {
  GetResourceFunction,
} from '../../hooks/usePagedApiResourceWithFilter';
import updateCancelToken from '../../utils/updateCancelToken';
import { FiltersFiltersState } from '../filters/FiltersContext';
import { useEntryComments } from './useEntriesComments';

const cancelTokenSource = {};

export interface UseConductEntriesProps {
  filters?: Partial<FiltersFiltersState['applied']>;
  query?: FiltersFiltersState['appliedQuery'];
  groupBy?: FiltersFiltersState['appliedGroupBy'];
  sort?: ConductEntriesRequest['sort'];
}

export const useConductEntries = ({ filters, query, groupBy, sort }: UseConductEntriesProps) => {
  const { showError } = useNotifications();
  const { schoolId, currentStaff, permissions } = useAuth();

  const isConductViewer = permissions.includes('conduct_viewer');

  const [results, setResults] = useState<ConductEntry[] | undefined>();
  const prevParams = useRef<Omit<ConductEntriesRequest, 'token'>>();
  const [pendingEntriesId, setPendingEntriesId] = useState<ConductEntry['id'][]>([]);

  const { addNewComments, entriesComments, addComment, changeComment } = useEntryComments();

  /**
   * Retrieves conduct entries list for Conduct page
   */
  const request = useCallback<GetResourceFunction<ConductEntry>>(
    async ({ pageSize, pageNumber, forceUpdate }) => {
      if (!schoolId || !isConductViewer) {
        return undefined;
      }

      if (!filters?.date) {
        return undefined;
      }

      const params: Omit<ConductEntriesRequest, 'token'> = {
        schoolId,
        pageSize,
        pageNumber,
        query,
        filters,
        sort,
      };
      // TODO This is temporary fix, the problem of double requests is present in most hooks.
      // Maybe as solution we need some pre-fetch services, when all the necessary data is fetched, we render component.
      if (_.isEqual(params, prevParams.current) && !forceUpdate) {
        return undefined;
      }

      prevParams.current = params;

      return await getConductEntries({
        ...params,
        token: updateCancelToken(cancelTokenSource),
      });
    },
    [schoolId, isConductViewer, filters, query, sort],
  );

  const { displayedList, loadResource, ...other } = usePagedApiResourceWithFilter<ConductEntry>({
    getResource: request,
  });

  /**
   * store results to a middleware array to be able to mutate it on comments change
   */
  useEffect(() => {
    setResults(displayedList?.results);
    addNewComments(displayedList?.results ?? []);
  }, [addNewComments, displayedList?.results]);

  const getEntryData = useCallback(
    (entry: ConductEntry) => {
      const data = { ...entry, comments: entriesComments[entry.id] };

      return data;
    },
    [entriesComments],
  );

  const entries = useMemo(() => {
    // consider saving (pending) comments
    return results?.map((entry) => getEntryData(entry));
  }, [getEntryData, results]);

  const groupedEntries = useMemo(() => {
    if (groupBy === FilterKeys.Student) {
      // group by students
      return entries?.reduce<Array<Array<ConductEntry>>>((prev, entryData, index) => {
        const entry = getEntryData(entryData);
        if (
          prev.length > 0 &&
          prev[prev.length - 1]?.[0]?.student.relation_id === entry.student.relation_id
        ) {
          prev[prev.length - 1].push(entry);
        } else {
          prev.push([entry]);
        }

        return prev;
      }, []);
    } else {
      // convert to ConductEntry[][]
      return results?.map((entry) => [getEntryData(entry)]);
    }
  }, [entries, getEntryData, groupBy, results]);

  const setEntryComment = useCallback<
    (entryId: string, relationId?: string, comment?: string) => void
  >(
    async (entryId, relationId, comment) => {
      if (!currentStaff || typeof comment === 'undefined') {
        return;
      }

      const entry = entries?.find((entry) => entry.id === entryId);

      if (!entry) {
        return;
      }

      const existingComment = relationId
        ? entry.comments.find((comment) => comment.creator_relation_id === relationId)
        : undefined;

      if (existingComment && existingComment.comment === comment) {
        return;
      }

      setPendingEntriesId((old) => [...old, entryId]);

      try {
        if (existingComment) {
          await editEntryComment(entryId, existingComment.id, {
            comment,
            creator_relation_id: existingComment.creator_relation_id,
          });

          changeComment(entryId, { ...existingComment, comment });
        } else {
          const response = await addEntryComment(entryId, {
            comment,
            creator_relation_id: currentStaff.relation_id,
          });

          const id = response.comment_id;

          addComment(entryId, {
            id,
            comment,
            creator_relation_id: currentStaff.relation_id,
            creator_title: currentStaff.title,
            creator_given_name: currentStaff.given_name,
            creator_last_name: currentStaff.last_name,
            creator_known_as: currentStaff.known_as,
          });
        }

        setPendingEntriesId((old) => old.filter((id) => id !== entryId));
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        showError(err as ApiError);
        setPendingEntriesId((old) => old.filter((id) => id !== entryId));
      }
    },
    [addComment, changeComment, currentStaff, entries, showError],
  );

  return {
    entries,
    groupedEntries,
    loadResource,
    setEntryComment,
    pendingEntriesId,
    ...other,
  };
};
