import {
  ApiError,
  AvailableRelationGroupRequest,
  FilterValue,
  getGroupsForRelation,
  Group,
  GroupType,
  RelationGroup,
  SchoolYear,
  updateGroupsForRelation,
  useGetAvailableRelationGroupsQuery,
} from '@schooly/api';
import { useInvalidateListQueriesFor } from '@schooly/components/filters';
import { useNotifications } from '@schooly/components/notifications';
import moment from 'moment';
import {
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

import { DEFAULT_DATE_FORMAT } from '../../../../config';
import { ProfileModalMode } from '../../../../context/profile/helpers';
import { useProfile } from '../../../../context/profile/useProfile';
import {
  RouterStateContext,
  RouterStateContextProps,
} from '../../../../context/router/RouterStateContext';
import { useRouter } from '../../../../context/router/useRouter';
import { getRouteModalPathname } from '../../../../helpers/misc';
import usePrevious from '../../../../hooks/usePrevious';
import useQueryStringParams from '../../../../hooks/useQueryStringParams';
import useSchoolYears from '../../../../hooks/useSchoolYears';
import {
  CONTEXT_NAME,
  ManageGroupModalMode,
  ManageRelationGroupContext,
  ManageRelationGroupContextState,
} from './ManageRelationGroupContext';

export const WithManageRelationGroup: FC<PropsWithChildren> = ({ children }) => {
  // TODO remove profile context, get userType from props and user from endpoint
  const { user, userType } = useProfile();
  const { showError } = useNotifications();

  const invalidateUserQueries = useInvalidateListQueriesFor(userType || 'student');
  const invalidateGroupQueries = useInvalidateListQueriesFor('group');

  const { defaultValidity, getSchoolYearById } = useSchoolYears();

  const { schoolYearId = defaultValidity?.id } = useQueryStringParams();

  const [mode, setMode] = useState(ManageGroupModalMode.Individual);
  const [init, setInit] = useState(false);
  const [filterFetched, setFilterFetched] = useState(false);
  const { clean } = useRouter();

  const schoolYear = useMemo(
    () => getSchoolYearById(schoolYearId),
    [getSchoolYearById, schoolYearId],
  );

  const { state, setState, setContextState, setContextName, contextName } = useContext(
    RouterStateContext,
  ) as RouterStateContextProps<ManageRelationGroupContextState>;

  const prevState = usePrevious(state);

  const [fetching, setFetching] = useState(false);
  const [savingGroups, setSavingGroups] = useState(false);
  const [active, setActive] = useState(true);
  const navigate = useNavigate();

  const setModalMode = useCallback((mode: ManageGroupModalMode) => {
    setMode(mode);
  }, []);

  const [relatedGroupsParams, setRelatedGroupsParams] = useState<AvailableRelationGroupRequest>();

  const {
    data: relatedGroupsData,
    error: relatedGroupsError,
    isFetching: isRelatedGroupsFetching,
  } = useGetAvailableRelationGroupsQuery(relatedGroupsParams!, {
    enabled: Boolean(relatedGroupsParams),
  });

  useEffect(() => {
    if (!state?.schoolMembership?.relation_id || !schoolYear || !state?.filterRelatedGroupsParams) {
      return;
    }

    const { subjects, onlyTutorGroups, searchQuery } = state.filterRelatedGroupsParams;

    let params: AvailableRelationGroupRequest = {
      relationId: state.schoolMembership.relation_id,
      date_from: schoolYear.start,
      date_to: schoolYear.end,
    };

    if (onlyTutorGroups?.join('') === '1') {
      params = { ...params, group_type: GroupType.TutorGroup };
    } else if (subjects?.length) {
      params = { ...params, subject_ids: subjects?.join(',') };
    }

    if (!!searchQuery) {
      params = { ...params, search_query: searchQuery };
    }

    setRelatedGroupsParams(params);
  }, [schoolYear, state?.filterRelatedGroupsParams, state?.schoolMembership?.relation_id]);

  useEffect(() => {
    setContextState({ relatedGroups: relatedGroupsData?.groups ?? [] });
  }, [relatedGroupsData?.groups, setContextState]);

  useEffect(() => {
    if (relatedGroupsError) {
      showError(relatedGroupsError);
    }
  }, [relatedGroupsError, showError]);

  const fetchInitialData = useCallback(
    async (schoolYear: SchoolYear, relation_id: string) => {
      if (fetching || !defaultValidity) {
        return;
      }
      setFetching(true);

      const { groups } = await getGroupsForRelation({
        relationId: relation_id,
        date_from: schoolYear.start,
        date_to: schoolYear.end,
      });

      const activeGroups = groups?.filter((group) => {
        const currentDateTime = moment(moment().format(DEFAULT_DATE_FORMAT)).valueOf();
        const currentSchoolYearStartTime = moment(schoolYear.start).valueOf();
        const defaultSchoolYearStartTime = moment(defaultValidity.start).valueOf();

        const futureYear = currentSchoolYearStartTime > defaultSchoolYearStartTime;

        return group.memberships.some(({ end, start }) => {
          const memberStartDateTime = moment(start).valueOf();
          const memberEndDateTime = moment(end).valueOf();

          return futureYear
            ? memberStartDateTime > currentDateTime
            : memberStartDateTime <= currentDateTime && memberEndDateTime >= currentDateTime;
        });
      });

      setState({ ...state, currentGroups: activeGroups, removedGroups: [] });

      setInit(true);
      setFetching(false);
    },
    [defaultValidity, fetching, setState, state],
  );
  useEffect(() => {
    if (!fetching && !init && schoolYear && state?.schoolMembership?.relation_id) {
      setContextName(CONTEXT_NAME);
      fetchInitialData(schoolYear, state.schoolMembership.relation_id);
    }
  }, [
    contextName,
    fetchInitialData,
    fetching,
    init,
    schoolYear,
    setContextName,
    setState,
    state,
    state?.currentGroups?.length,
    state?.schoolMembership?.relation_id,
  ]);

  const closeModal = useCallback(async () => {
    if (!state?.userType || !state?.user || !state?.schoolMembership?.relation_id) {
      return;
    }

    clean();
    setActive(false);

    navigate({
      pathname: getRouteModalPathname(state.userType, state.user),
      hash: `#${ProfileModalMode.Groups}`,
    });
  }, [state?.userType, state?.user, state?.schoolMembership?.relation_id, navigate, clean]);

  const removeGroup = useCallback(
    ({ memberships, ...group }: RelationGroup) => {
      setState({
        ...state,
        currentGroups: state.currentGroups.filter((g) => g.id !== group.id),
        removedGroups: [...state.removedGroups, group as Group],
      });
    },
    [setState, state],
  );

  const addGroup = useCallback(
    (addedGroup: Group) => {
      const group = {
        ...addedGroup,
        memberships: [],
      };
      setState({
        ...state,
        currentGroups: state?.currentGroups ? [...state.currentGroups, group] : [group],
        removedGroups: state?.removedGroups?.filter((g) => g.id !== group.id) ?? [],
      });
    },
    [setState, state],
  );

  const saveGroups = useCallback(
    async (groups: RelationGroup[]) => {
      if (!state.currentGroups || !state.schoolMembership || !schoolYear) {
        return;
      }

      setSavingGroups(true);

      try {
        await updateGroupsForRelation({
          relationId: state.schoolMembership.relation_id,
          date_from: schoolYear.start,
          date_to: schoolYear.end,
          group_ids: groups.map((g) => g.id),
        });
        invalidateUserQueries();
        invalidateGroupQueries();
        closeModal();
      } catch (err) {
        showError(err as ApiError);
      } finally {
        setSavingGroups(false);
      }
    },
    [
      closeModal,
      invalidateGroupQueries,
      invalidateUserQueries,
      schoolYear,
      showError,
      state?.currentGroups,
      state?.schoolMembership,
    ],
  );

  const filterRelatedGroups = useCallback(
    (filterRelatedGroupsParams: ManageRelationGroupContextState['filterRelatedGroupsParams']) => {
      setContextState({ filterRelatedGroupsParams });
    },
    [setContextState],
  );

  const setDefaultFilter = useCallback(
    async (subjects: FilterValue[], onlyTutorGroups: FilterValue[]) => {
      if (
        filterFetched ||
        isRelatedGroupsFetching ||
        (!subjects.length && !onlyTutorGroups.length)
      ) {
        return;
      }

      setFilterFetched(true);
      setFetching(true);
      await filterRelatedGroups({ subjects, onlyTutorGroups });
      setFetching(false);
    },
    [filterFetched, filterRelatedGroups, isRelatedGroupsFetching],
  );

  useEffect(() => {
    // TO prevent current groups update
    if (prevState?.currentGroups?.length && !state?.currentGroups) {
      setState({ ...state, ...prevState });
    }
  }, [prevState, setState, state]);

  const value = {
    ...state,
    schoolYear,
    mode,
    isFetching: fetching && !init,
    isRelatedGroupsFetching,
    user,
    userType,
    isSaving: savingGroups,
    active,
    init,
    actions: {
      addGroup,
      removeGroup,
      closeModal,
      setMode: setModalMode,
      filterRelatedGroups,
      saveGroups,
      setDefaultFilter,
    },
  };

  return (
    <ManageRelationGroupContext.Provider value={value}>
      {children}
    </ManageRelationGroupContext.Provider>
  );
};
