import { Controller, ControllerProps, useFormContext } from 'react-hook-form-lts';
import { FieldValues } from 'react-hook-form-lts/dist/types';
import { FieldPath, FieldPathValue } from 'react-hook-form-lts/dist/types/path';
import { useIntl } from 'react-intl';

import { getControllerErrorText } from '../../ui/Input/utils';
import { FormSchoolLogo, FormSchoolLogoProps } from './FormSchoolLogo';

export type ControlSchoolLogoProps<TFieldValues extends FieldValues = FieldValues> = Omit<
  ControllerProps<TFieldValues>,
  'render'
> &
  FormSchoolLogoProps;
export const ControlSchoolLogo = <TFieldValues extends FieldValues = FieldValues>({
  control: propsControl,
  ...props
}: ControlSchoolLogoProps<TFieldValues>) => {
  const { $t } = useIntl();
  const { control: contextControl, formState, setValue } = useFormContext<TFieldValues>();
  const control = propsControl ?? contextControl;

  return (
    <Controller
      control={control}
      render={({ field }) => {
        const hasError = Boolean(formState.errors[field.name]);
        const errorMessage = getControllerErrorText(formState.errors[field.name], $t);

        const handleChange = (file?: File) => {
          setValue(field.name, file as FieldPathValue<TFieldValues, FieldPath<TFieldValues>>);
        };

        return (
          <FormSchoolLogo
            {...props}
            onChange={handleChange}
            error={hasError}
            helperText={errorMessage}
          />
        );
      }}
      {...props}
    />
  );
};
