import {
  AssessmentEntryComment,
  AssessmentEntryGrade,
  AssessmentEntryScore,
  AssessmentForGroup,
  AssessmentMethod,
  AssessmentMethodType,
  AssessmentsGrade,
} from '@schooly/api';
import React, { FC, useMemo } from 'react';

import { UpdateAssessmentEntryProps } from '../../../pages/Assessments/AssessmentMarkbookModal/AssessmentMarkbookGrid';
import { CommentController, CommentControllerProps } from './methods/CommentController';
import { GradeController, GradeControllerProps } from './methods/GradeController';
import { ScoreController, ScoreControllerProps } from './methods/ScoreController';

import './AssessmentEntryMethods.scss';

export interface AssessmentEntryMethodProps {
  relationId: string;
  groupId: string;
  method: AssessmentMethod;
  assessmentForGroup?: AssessmentForGroup;
  lists?: Record<string, AssessmentsGrade>;
  onSuccess?: () => void;
  commentControllerProps?: Partial<CommentControllerProps>;
  gradeControllerProps?: Partial<GradeControllerProps>;
  scoreControllerProps?: Partial<ScoreControllerProps>;
  updateAssessmentEntry?: (p: UpdateAssessmentEntryProps) => void;
}

export function getAssessmentParams(
  relationId: string,
  method: AssessmentMethod,
  assessmentForGroup?: AssessmentForGroup,
) {
  const relation = assessmentForGroup?.relations.find(
    (item) => item.recipient_relation_id === relationId,
  );

  if (!method || !assessmentForGroup || !relation) {
    return null;
  }

  const entries = relation.entries?.filter(
    (item) => item.method_id === method.method_id && item.method_type === method.method_type,
  );

  return { relation, entries };
}

export function hasAssessmentEntry(
  relationId: string,
  method: AssessmentMethod,
  assessmentForGroup?: AssessmentForGroup,
): boolean {
  return !!getAssessmentParams(relationId, method, assessmentForGroup);
}

export const AssessmentEntryMethod: FC<AssessmentEntryMethodProps> = ({
  relationId,
  groupId,
  method,
  assessmentForGroup,
  lists,
  onSuccess,
  commentControllerProps,
  gradeControllerProps,
  scoreControllerProps,
  updateAssessmentEntry,
}) => {
  const params = useMemo(
    () => getAssessmentParams(relationId, method, assessmentForGroup),
    [method, assessmentForGroup, relationId],
  );

  if (!params) {
    return null;
  }

  return (
    // TODO: Delete wrapper, put switch case
    <>
      {method.method_type === AssessmentMethodType.Score && (
        <ScoreController
          key={`score-${relationId}`}
          relationId={relationId}
          groupId={groupId}
          entry={params.entries?.[0] as AssessmentEntryScore}
          method={method}
          assessmentForGroup={assessmentForGroup!}
          onSuccess={onSuccess}
          updateAssessmentEntry={updateAssessmentEntry}
          {...scoreControllerProps}
        />
      )}

      {method.method_type === AssessmentMethodType.Grade && (
        <GradeController
          key={`grade-${relationId}`}
          relationId={relationId}
          groupId={groupId}
          entry={params.entries?.[0] as AssessmentEntryGrade}
          method={method}
          assessmentForGroup={assessmentForGroup!}
          list={lists?.[method.select_list_id]}
          onSuccess={onSuccess}
          updateAssessmentEntry={updateAssessmentEntry}
          {...gradeControllerProps}
        />
      )}

      {method.method_type === AssessmentMethodType.Comment && (
        <CommentController
          key={`comment-${relationId}`}
          relationId={relationId}
          groupId={groupId}
          entries={params.entries as AssessmentEntryComment[]}
          method={method}
          assessmentForGroup={assessmentForGroup!}
          onSuccess={onSuccess}
          updateAssessmentEntry={updateAssessmentEntry}
          {...commentControllerProps}
        />
      )}
    </>
  );
};
