import { useGetAssessmentsGradesForSchoolQuery } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';

export const useAssessmentsGradesForSchool = () => {
  const { schoolId, permissions } = useAuth();
  const isAssessmentViewer = permissions.includes('assessment_viewer');

  const {
    data,
    isLoading: fetching,
    error,
    refetch,
    isRefetching: refetching,
  } = useGetAssessmentsGradesForSchoolQuery(schoolId || '', {
    enabled: Boolean(schoolId && isAssessmentViewer),
    retry: isAssessmentViewer,
  });

  const grades = data ?? [];
  const activeGrades = grades?.filter((grade) => grade.active) ?? [];
  const archivedGrades = grades?.filter((grade) => grade.archived) ?? [];

  return {
    grades,
    activeGrades,
    archivedGrades,
    fetching,
    refetching,
    error,
    getAssessmentsGrades: refetch,
  };
};
