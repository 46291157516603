import { Icon, IconButton, Stack, Tooltip, Typography } from '@mui/material';
import {
  PaymentFrequency,
  Product,
  ProductType,
  SchoolYear,
  useGetSchoolPaymentFrequencies,
} from '@schooly/api';
import { Currencies, CURRENCY_SYMBOLS } from '@schooly/constants';
import {
  Counter,
  DropdownYears,
  EditIcon,
  EmptySchoolSvg,
  FullDayIcon,
  HalfDayIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import { FC, useCallback, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';

import useSchoolYears from '../../../../hooks/useSchoolYears';
import { EmptyTypes } from '../EmptyTypes';
import { getTypesByYears, useRenderFrequencyDate, useSchoolYearsInProduct } from '../helpers';
import { ProductApplicable } from '../ProductsApplicable';
import {
  CELL_HEIGHT,
  FREQ_CELL_WIDTH,
  FREQUENCIES,
  HEADER_HEIGHT,
} from '../SchoolProductCreateModal/SchoolProductCreateModalVariants';

type SchoolProductModalVariantsProps = {
  product: Product;
  schoolId: string;
  onEdit?: (yearId?: SchoolYear['id']) => void;
  currency?: Currencies;
};
export const SchoolProductModalVariants: FC<SchoolProductModalVariantsProps> = ({
  product,
  schoolId,
  onEdit,
  currency,
}) => {
  const { $t } = useIntl();
  const { schoolYears } = useSchoolYears();

  const { types } = product;
  const typesByYears = useMemo(() => getTypesByYears(types, schoolYears), [schoolYears, types]);

  const canShowYearInSelect = useCallback(
    (year?: SchoolYear) =>
      Boolean(year && (!isDateInPast(year.end) || typesByYears[year.id]?.length)),
    [typesByYears],
  );

  const { selectedYear, defaultYear, setSelectedYear, yearsForSelect } =
    useSchoolYearsInProduct(canShowYearInSelect);
  const selectedYearId = selectedYear?.id ?? '';

  const currentTypes = typesByYears[selectedYearId] ?? [];
  const hasCurrentTypes = Boolean(currentTypes.length);

  const currencySymbol = currency ? CURRENCY_SYMBOLS[currency] : '';

  //Based on TR-5885 user can only edit products for next year
  const canEdit = onEdit && selectedYearId === defaultYear?.id;

  return (
    <>
      <Stack justifyContent="space-between" flexDirection="row" alignItems="center" mb={2.25}>
        <Stack flexDirection="row" alignItems="center">
          <Typography variant="h2">
            {$t({ id: 'products-Types' }, { productName: product.name })}
          </Typography>
          {hasCurrentTypes && (
            <Counter sx={{ minWidth: 20, minHeight: 20, textAlign: 'center' }}>
              {currentTypes.length}
            </Counter>
          )}
        </Stack>
        <Stack gap={2.5} direction="row">
          <DropdownYears
            years={yearsForSelect}
            defaultYear={defaultYear}
            currentYear={selectedYear}
            onYearChange={setSelectedYear}
          />
          {!!canEdit && (
            <IconButton
              inverse
              onClick={() => onEdit(selectedYearId)}
              sx={{ color: 'common.grey2' }}
            >
              <EditIcon />
            </IconButton>
          )}
        </Stack>
      </Stack>
      {hasCurrentTypes ? (
        currentTypes.map((t) => (
          <ProductTypeVariants
            schoolId={schoolId}
            type={t}
            key={t.id}
            currencySymbol={currencySymbol}
          />
        ))
      ) : (
        <EmptyTypes
          svg={<EmptySchoolSvg />}
          onAdd={canEdit ? () => onEdit(selectedYearId) : undefined}
          messageId={'products-NoTypes'}
          productName={product.name}
        />
      )}
    </>
  );
};

type ProductTypeVariantsProps = {
  schoolId: string;
  type: ProductType;
  currencySymbol: string;
};

const ProductTypeVariants: FC<ProductTypeVariantsProps> = ({ type, schoolId, currencySymbol }) => {
  const { variants } = type;
  const [hoveredFrequencyId, setHoveredFrequencyId] = useState<string>();

  const { data } = useGetSchoolPaymentFrequencies(schoolId);

  const frequencies = useMemo(
    () =>
      FREQUENCIES.map((fr) => data?.find((f) => f.frequency_type === fr))
        .filter((fr) => variants.some((v) => v.prices.some((p) => p.frequency_id === fr?.id)))
        .filter(Boolean) as PaymentFrequency[],
    [data, variants],
  );

  const renderFrequencyDate = useRenderFrequencyDate();
  const { $t } = useIntl();

  return (
    <Stack gap={1}>
      <Typography variant="h2">{type.name}</Typography>
      <Stack
        sx={(theme) => ({
          backgroundColor: theme.palette.background.paper,
          borderRadius: theme.spacing(1),
          mb: theme.spacing(2.5),
          '.highlighted-cell': {
            backgroundColor: theme.palette.background.default,
          },
        })}
      >
        <Stack
          sx={{
            flexDirection: 'row',
            alignItems: 'center',
            height: `${HEADER_HEIGHT}px`,
          }}
        >
          <Stack flex={1} px={1}>
            <Typography color="common.grey">
              {$t({ id: 'products-TheProductIsApplicableTo' })}
            </Typography>
          </Stack>
          {frequencies.map((f) => {
            const isHighlighted = f.id === hoveredFrequencyId;

            return (
              <Stack
                key={f.id}
                className={isHighlighted ? 'highlighted-cell' : undefined}
                sx={(theme) => ({
                  width: `${FREQ_CELL_WIDTH}px`,
                  maxWidth: `${FREQ_CELL_WIDTH}px`,
                  px: 1,
                  position: 'relative',
                  justifyContent: 'center',
                  height: `${HEADER_HEIGHT}px`,
                  '&:hover': {
                    backgroundColor: theme.palette.background.default,
                    '.frequency-date': {
                      color: theme.palette.text.primary,
                    },
                  },
                })}
              >
                <Typography variant="h3">
                  {$t({ id: `products-Frequency-${f.frequency_type}` })}
                </Typography>
                <TypographyWithOverflowHint
                  className="frequency-date"
                  color={isHighlighted ? 'text.primary' : 'common.grey'}
                >
                  {renderFrequencyDate(f)}
                </TypographyWithOverflowHint>
              </Stack>
            );
          })}
        </Stack>
        <Stack
          flexDirection="row"
          sx={(theme) => ({ borderTop: `1px solid ${theme.palette.divider}` })}
        >
          <Stack flex={1}>
            {variants.map((variant, i) => {
              const DayIcon = variant.half_day ? HalfDayIcon : FullDayIcon;
              return (
                <Stack
                  key={variant.id}
                  sx={(theme) => ({
                    flexDirection: 'row',
                    alignItems: 'center',
                    minHeight: `${CELL_HEIGHT}px`,
                    '&:not(:first-child)': {
                      borderTop: `1px solid ${theme.palette.divider}`,
                    },
                    '&:hover': {
                      backgroundColor: theme.palette.background.default,
                      '.highlighted-cell': {
                        backgroundColor: theme.palette.common.highlightedBg,
                      },
                    },
                  })}
                >
                  <Stack
                    sx={(theme) => ({
                      padding: theme.spacing(0, 1),
                      borderRight: `1px solid ${theme.palette.divider}`,
                      alignSelf: 'stretch',
                      justifyContent: 'center',
                    })}
                  >
                    <Tooltip
                      title={$t({
                        id: variant.half_day
                          ? 'products-ProductVariantAppliesToHalfDay'
                          : 'products-ProductVariantAppliesToAll',
                      })}
                    >
                      <Icon color="primary">
                        <DayIcon />
                      </Icon>
                    </Tooltip>
                  </Stack>
                  <Stack flex={1} py={1.25}>
                    <ProductApplicable
                      schoolId={schoolId}
                      selectedAgeGroupIds={variant.age_groups}
                      selectedSubjectIds={variant.subjects}
                      variant="h3"
                      px={1}
                    />
                  </Stack>
                  {frequencies.map((f) => {
                    const relatedPrice = variant.prices.find((p) => p.frequency_id === f.id);
                    const isHighlighted = f.id === hoveredFrequencyId;

                    return (
                      <Stack
                        key={f.id}
                        onMouseOver={() => setHoveredFrequencyId(f.id)}
                        onMouseLeave={() => setHoveredFrequencyId(undefined)}
                        className={isHighlighted ? 'highlighted-cell' : undefined}
                        sx={(theme) => ({
                          width: `${FREQ_CELL_WIDTH}px`,
                          maxWidth: `${FREQ_CELL_WIDTH}px`,
                          borderLeft: `1px solid ${theme.palette.divider}`,
                          minHeight: `${CELL_HEIGHT}px`,
                          alignSelf: 'stretch',
                          justifyContent: 'center',
                        })}
                      >
                        <Typography variant="h3" px={1}>
                          {relatedPrice?.price ? `${currencySymbol} ${relatedPrice?.price}` : '—'}
                        </Typography>
                      </Stack>
                    );
                  })}
                </Stack>
              );
            })}
          </Stack>
        </Stack>
      </Stack>
    </Stack>
  );
};
