import { Checkbox } from '@mui/material';
import { SwitchBaseProps } from '@mui/material/internal/SwitchBase';
import React, { FC, useCallback } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

import { renderError } from '../../ui/Input/helpers';
import { BaseFormInputProps, useFormInput2 } from '../../ui/Input/utils';
import { FormCheckboxStyled } from './FormCheckbox.styled';

export interface FormCheckboxProps extends BaseFormInputProps {
  hideErrorOnChange?: boolean;
  withBorder?: boolean;
  testId?: string;
}

export const FormCheckbox: FC<FormCheckboxProps> = ({
  name,
  labelText,
  labelTextId,
  hideErrorOnChange,
  withBorder = true,
  testId,
  ...props
}) => {
  const { error } = useFormInput2({ name, ...props });
  const { watch, setValue } = useFormContext();

  const value = Boolean(watch(name));

  const handleChange = useCallback<Exclude<SwitchBaseProps['onChange'], undefined>>(
    (event, checked) => {
      setValue(name, checked);
    },
    [name, setValue],
  );

  return (
    <>
      <FormCheckboxStyled
        control={<Checkbox name={name} checked={value} onChange={handleChange} />}
        label={labelText ?? <FormattedMessage id={labelTextId} />}
        error={error}
        withBorder={withBorder}
        data-cy={testId}
      />
      {renderError(error)}
    </>
  );
};
