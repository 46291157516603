import { Stack, Typography } from '@mui/material';
import {
  ApplicationCustomField,
  ApplicationDefaultCustomFields,
  CustomFieldApplicableTo,
  CustomFieldDataType,
  DEFAULT_DATE_FORMAT_FNS,
  SelectOption,
} from '@schooly/api';
import { DateSelect } from '@schooly/components/filters';
import { ControlBoolean } from '@schooly/components/form-boolean';
import { ControlSwitch } from '@schooly/components/form-switch';
import { ControlTextField } from '@schooly/components/form-text-field';
import { useFormValidation } from '@schooly/hooks/use-form-validation';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
import { format } from 'date-fns';
import { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

import { CreateCustomField } from './CreateCustomField.type';

export const CustomFieldsFormContainer: FC = () => {
  const { $t } = useIntl();
  const { mustBeInteger } = useFormValidation();
  const { control, watch, setValue, clearErrors } =
    useFormContext<{ custom_fields: Array<CreateCustomField> }>();

  const customFields = watch('custom_fields');

  const getDefaultCustomLabelTranslation = (label: ApplicationCustomField['label']) => {
    const [key] =
      Object.entries(ApplicationDefaultCustomFields).find(([_, v]) => v === label) ?? [];

    return key ? $t({ id: `applications-CustomFieldsDefault-${key}` }) : label;
  };

  return (
    <Stack gap={2} pt={1} position="relative">
      {customFields &&
        customFields?.map(
          (
            {
              label: _label,
              id,
              data_type,
              applicable_to,
              checked,
              required,
              value,
              select_type_options,
            },
            index,
          ) => {
            const isBuildIn = applicable_to.includes(
              CustomFieldApplicableTo.DefaultApplicationValues,
            );
            const fieldPath = `custom_fields.${index}` as const;
            const disabled = isBuildIn && !checked;
            const isRequired = (isBuildIn || required) && !disabled;

            const label = getDefaultCustomLabelTranslation(_label);

            return (
              <Stack
                direction="row"
                alignItems="flex-start"
                gap={2}
                key={id}
                sx={(theme) => ({
                  '& .Mui-disabled': {
                    color: theme.palette.common.grey,
                  },

                  '& .MuiInputBase-root': {
                    background: isBuildIn && !checked ? theme.palette.common.lightBg : undefined,
                    border: 0,
                    margin: 0,
                  },

                  '& .MuiOutlinedInput-root.MuiInputBase-fullWidth.MuiInputBase-root': {
                    width: value ? undefined : '100%',
                  },

                  '& .MuiInputAdornment-root': {
                    zIndex: 1,
                    pointerEvents: 'none',
                  },

                  [theme.breakpoints.down('sm')]: {
                    flexDirection: 'column',
                  },
                })}
              >
                {isBuildIn && (
                  <ControlBoolean
                    name={`${fieldPath}.checked`}
                    trueValue={1}
                    falseValue={0}
                    onChange={(value) => {
                      if (!value) {
                        setValue(`${fieldPath}.value`, '');
                        setValue(`${fieldPath}.is_severe`, false);
                        clearErrors(fieldPath);
                      }
                    }}
                  />
                )}

                {(() => {
                  switch (data_type) {
                    case CustomFieldDataType.MEDICAL:
                      return (
                        <Stack position="relative" alignSelf="stretch" width="100%">
                          <ControlTextField
                            key={`${id}-text-${checked}`}
                            name={`${fieldPath}.value`}
                            control={control}
                            label={label}
                            rules={{ required: isRequired }}
                            locked={disabled}
                            disabled={disabled}
                            fullWidth
                            hideLabel
                            InputProps={{
                              sx: (theme) => ({
                                paddingRight: disabled
                                  ? undefined
                                  : `${theme.spacing(12)} !important`,
                              }),
                            }}
                          />
                          {!disabled && (
                            <Stack
                              sx={(theme) => ({
                                flexDirection: 'row',
                                alignItems: 'center',
                                position: 'absolute',
                                right: theme.spacing(1.5),
                                top: theme.spacing(1.5),
                                gap: 1,
                              })}
                            >
                              <ControlSwitch
                                labelPlacement="start"
                                name={`${fieldPath}.is_severe`}
                                label={
                                  <Typography>
                                    {$t({
                                      id: 'applications-CustomFieldsDefault-MedicalNeeds-Severe',
                                    })}
                                  </Typography>
                                }
                                sx={{
                                  m: 0,
                                  '&.MuiFormControlLabel-root:has(.Mui-checked)': {
                                    color: 'primary.main',
                                  },
                                  '& .MuiSwitch-track, .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track':
                                    {
                                      opacity: 1,
                                    },
                                  '&:hover': {
                                    color: 'primary.main',
                                  },
                                }}
                              />
                            </Stack>
                          )}
                        </Stack>
                      );

                    case CustomFieldDataType.BOOLEAN:
                      return (
                        <ControlBoolean
                          name={`${fieldPath}.value`}
                          label={label}
                          rules={{ required: isRequired }}
                          disabled={disabled}
                        />
                      );

                    case CustomFieldDataType.DATE:
                      return (
                        <Stack width="100%">
                          <Controller
                            control={control}
                            name={`${fieldPath}.value`}
                            rules={{ required: isRequired }}
                            render={({ field, fieldState }) => {
                              return (
                                <DateSelect
                                  name={field.name}
                                  ref={field.ref}
                                  onSetDate={(date) => {
                                    field.onChange(format(date, DEFAULT_DATE_FORMAT_FNS));
                                  }}
                                  date={field.value}
                                  placeholder={label}
                                  requiredLabel={isRequired ? 'required' : 'optional'}
                                  error={fieldState.error}
                                  disabled={disabled}
                                  onClear={() => field.onChange('')}
                                  popperZIndex={(theme) => theme.zIndex.tooltip}
                                  hideTodayButton
                                />
                              );
                            }}
                          />
                        </Stack>
                      );

                    case CustomFieldDataType.NUMBER:
                      return (
                        <ControlTextField
                          name={`${fieldPath}.value`}
                          control={control}
                          label={label}
                          rules={{
                            required: isRequired,
                            validate: disabled ? undefined : mustBeInteger,
                          }}
                          locked={disabled}
                          disabled={disabled}
                          fullWidth
                        />
                      );

                    case CustomFieldDataType.SELECT:
                      const selectOptions =
                        [...(select_type_options ?? [])]
                          .sort((option) => option.order)
                          .map<SelectOption>((option) => ({
                            value: option.label,
                            label: option.label,
                          })) ?? [];

                      return (
                        <FormSelect2
                          name={`${fieldPath}.value`}
                          options={selectOptions}
                          rules={{
                            required: isRequired,
                          }}
                          labelText={label}
                          disabled={disabled}
                        />
                      );

                    default:
                      return (
                        <ControlTextField
                          name={`${fieldPath}.value`}
                          control={control}
                          label={label}
                          rules={{ required: isRequired }}
                          locked={disabled}
                          disabled={disabled}
                          fullWidth
                        />
                      );
                  }
                })()}
              </Stack>
            );
          },
        )}
    </Stack>
  );
};
