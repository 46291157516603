import { Box, Icon, Stack, Tooltip, Typography } from '@mui/material';
import { FilterValue, SelectOption } from '@schooly/api';
import { useConfirmationDialog } from '@schooly/components/confirmation-dialog';
import { SchoolPropertyType } from '@schooly/constants';
import {
  ArchiveIcon,
  CheckIcon,
  CrossIcon,
  DropdownIcon,
  TagSelectProperty,
  theme,
  TypographyWithOverflowHint,
} from '@schooly/style';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { FieldValues } from 'react-hook-form';
import { Controller, ControllerProps, get, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import useDropdown from '../../../hooks/useDropdown';
import useKeyboardListNavigation from '../../../hooks/useKeyboardListNavigation';
import useVirtualBackdrop from '../../../hooks/useVirtualBackdrop';
import buildClassName from '../../../utils/buildClassName';
import captureKeyboardListeners from '../../../utils/captureKeyboardListeners';
import searchWords from '../../../utils/searchWords';
import Preloader from '../../uikit/Preloader/Preloader';
import Tag from '../Tag';
import { renderError, renderLabel, renderPlainError } from './helpers';
import {
  BaseFormInputProps,
  getControllerErrorText,
  getIsFieldTouched,
  getSelectedOptions,
  hasFieldValue,
  useFormInput2,
} from './utils';

import './FormSelect.scss';

type PickedInputProps = Pick<
  React.InputHTMLAttributes<HTMLInputElement>,
  'autoComplete' | 'multiple' | 'defaultValue'
>;

interface FormSelect2BaseProps<TFieldValue extends FilterValue = FilterValue>
  extends Omit<ControllerProps<FieldValues>, 'name' | 'render' | 'defaultValue'>,
    BaseFormInputProps,
    PickedInputProps {
  options: SelectOption<TFieldValue>[];
  maxCount?: number;
  canRemove?: boolean;
  hideErrorOnChange?: boolean;
  hideArrow?: boolean;
  disabledValue?: TFieldValue;
  hiddenOptions?: SelectOption<TFieldValue>[];
  isLoading?: boolean;
  optionClassName?: string;
  showSelectedValue?: boolean;
  endIcon?: JSX.Element;
  searchEmptyStub?: JSX.Element;
  propertyType?: SchoolPropertyType;
}

interface FormSelect2SingleProps<TFieldValue extends FilterValue = FilterValue>
  extends FormSelect2BaseProps {
  multiple?: false;
  value?: TFieldValue;
  renderAsTag?: boolean;
  renderOptionsAsTags?: boolean;
  opened?: boolean;
  onClose?: (v: FilterValue) => void;
  onSelectOption?: (option?: SelectOption<TFieldValue>) => void;
}

interface FormSelect2MultipleProps<TFieldValue extends FilterValue = FilterValue>
  extends FormSelect2BaseProps {
  multiple: true;
  value?: TFieldValue[];
  renderAsTag?: boolean;
  renderOptionsAsTags?: boolean;
  opened?: boolean;
  onClose?: (v: FilterValue) => void;
  onSelectOption?: (option?: SelectOption<TFieldValue>) => void;
}

type FormSelect2Props<TFieldValue extends FilterValue = FilterValue> =
  | FormSelect2SingleProps<TFieldValue>
  | FormSelect2MultipleProps<TFieldValue>;

const FOCUS_SUPRESS_DURATION = 300;

/** Port of obsolete FormSelect to react-hook-forms@7+ */
const FormSelect2 = <TFieldValue extends FilterValue = FilterValue>(
  props: FormSelect2Props<TFieldValue>,
) => {
  const {
    id,
    className,
    options,
    disabled,
    multiple,
    autoComplete,
    defaultValue = props.multiple ? [] : '',
    maxCount,
    hideArrow,
    canRemove,
    hideErrorOnChange,
    disabledValue,
    hiddenOptions,
    isLoading,
    renderAsTag,
    renderOptionsAsTags,
    optionClassName,
    showSelectedValue,
    endIcon,
    searchEmptyStub,
    propertyType,
    required: initialRequired,
    opened: initialOpened,
    onSelectOption,
    name,
  } = props;

  const { formatMessage } = useIntl();
  const [bottomOffset, setBottomOffset] = useState(0);
  const { getConfirmation } = useConfirmationDialog();

  const required = Boolean(props.rules?.required || initialRequired);

  const { control, error: contextError, fullName, value } = useFormInput2(props);
  const { $t } = useIntl();

  const errorMessage = getControllerErrorText(get(control._formState.errors, fullName), $t);

  const error = Boolean(errorMessage) ?? Boolean(contextError);

  const {
    formState: { isSubmitted },
  } = useFormContext();
  const isTouched = getIsFieldTouched(value, defaultValue);
  const selectedOption = getSelectedOptions(options, value, defaultValue);

  const wrapperRef = useRef<HTMLLabelElement>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const searchInputRef = useRef<HTMLInputElement>(null);

  const { isOpen, openPopup, closePopup, Popup, recalculatePopupPosition } = useDropdown(
    wrapperRef,
    multiple ? searchInputRef : inputRef,
    true,
  );

  // This helps with unwanted input focusing when clearing selected option
  const shouldSupressFocus = useRef<boolean>(false);
  const isInitialOpened = useRef(Boolean(initialOpened));

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [hideError, setHideError] = useState(isSubmitted ? hideErrorOnChange : false);

  const handleOpenPopup = useCallback(
    (e?: React.MouseEvent<HTMLOrSVGElement, MouseEvent> | React.FocusEvent<HTMLElement>) => {
      openPopup(e);
      setHideError(true);
    },
    [openPopup],
  );

  const handleBlur = useCallback(
    (onFormBlur: VoidFunction) => () => {
      onFormBlur();
      setHideError(false);
    },
    [],
  );

  function handleSearchQueryChange(e: React.ChangeEvent<HTMLInputElement>) {
    const searchValue = e.currentTarget.value;

    setSearchQuery(searchValue);

    if (searchInputRef.current) {
      searchInputRef.current.style.width = searchValue?.length
        ? `${searchValue.length * 0.5}rem`
        : '0.5rem';
    }
  }

  function handleSearchFocus(e: React.FocusEvent<HTMLElement>) {
    if (shouldSupressFocus.current) {
      inputRef.current?.blur();
      searchInputRef.current?.blur();

      return;
    }

    searchInputRef.current?.focus();
    inputRef.current?.focus();
    handleOpenPopup(e);
  }

  const menuRef = useRef<HTMLUListElement>(null);
  const keyDownHandler = useKeyboardListNavigation(menuRef, isOpen, [searchQuery, value]);

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLElement>) => {
      shouldSupressFocus.current = true;
      keyDownHandler(e);

      setTimeout(() => {
        shouldSupressFocus.current = false;
      }, 0);
    },
    [keyDownHandler],
  );

  useVirtualBackdrop(
    isOpen,
    () => {
      closePopup();
      props.onClose?.(value);
    },
    multiple ? menuRef : wrapperRef,
    wrapperRef,
  );

  useEffect(() => {
    if (isInitialOpened.current) {
      openPopup();
    }
  }, [openPopup]);

  useEffect(() => {
    setSearchQuery('');
  }, [isOpen]);

  useEffect(() => {
    recalculatePopupPosition();
  }, [recalculatePopupPosition, value, isOpen]);

  // On TAB click unfocus from portal popup back to select, then simmulte TAB click again
  const handleTabClick = useCallback(() => {
    inputRef.current?.focus();
    closePopup();
    document.dispatchEvent(new KeyboardEvent('keypress', { key: 'Tab' }));
  }, [closePopup]);

  useEffect(
    () => (isOpen ? captureKeyboardListeners({ onTab: handleTabClick }) : undefined),
    [isOpen, handleTabClick],
  );

  const getOptionText = useCallback(
    (option: SelectOption) =>
      option.labelTextId ? formatMessage({ id: option.labelTextId }) : option.label,
    [formatMessage],
  );

  const renderSingleValue = useCallback(
    (option: SelectOption) => {
      const text = getOptionText(option);

      if (option.archived) {
        const label = (
          <Stack direction="row" alignItems="center" gap={0.75}>
            <Icon>
              <ArchiveIcon />
            </Icon>
            <div className="form-select-value form-select-value-text">{text}</div>
          </Stack>
        );

        return propertyType ? (
          <Tooltip title={formatMessage({ id: `schoolProperty-Archived-${propertyType}` })}>
            <div>{label}</div>
          </Tooltip>
        ) : (
          label
        );
      }

      return renderAsTag ? (
        <Tag className="form-select-value">{getOptionText(option)}</Tag>
      ) : (
        <div className="form-select-value form-select-value-text">
          {showSelectedValue
            ? (selectedOption as SelectOption).value
            : getOptionText(selectedOption as SelectOption)}
        </div>
      );
    },
    [formatMessage, getOptionText, propertyType, renderAsTag, selectedOption, showSelectedValue],
  );

  const isOptionActive = useCallback(
    (option: SelectOption) => {
      if (multiple) {
        return !!(selectedOption as SelectOption[])?.find(
          (item) => item.value.toString() === option.value.toString(),
        );
      }

      return selectedOption && option.value === (selectedOption as SelectOption).value;
    },
    [multiple, selectedOption],
  );

  const getOptionClassName = useCallback(
    (option: SelectOption) =>
      buildClassName(
        'dropdown-item form-select-option',
        isOptionActive(option) && 'active',
        optionClassName,
      ),
    [optionClassName, isOptionActive],
  );

  const displayedOptions = useMemo(() => {
    const hiddenValues = hiddenOptions?.map((o) => o.value);
    let filteredOptions = options?.filter((o) => !o.archived && !hiddenValues?.includes(o.value));

    if (searchQuery) {
      filteredOptions = filteredOptions?.filter((o) => {
        const text = getOptionText(o);
        return text && searchWords(text, searchQuery);
      });
    }

    return filteredOptions.reduce<Map<string, SelectOption[]>>((prev, o) => {
      const { group = '' } = o;

      if (!prev.has(group)) {
        prev.set(group, []);
      }

      prev.get(group)?.push(o);

      return prev;
    }, new Map());
  }, [options, searchQuery, getOptionText, hiddenOptions]);

  const handleValueRemove = useCallback(
    async (
      onChange: (value?: TFieldValue | TFieldValue[]) => void,
      e: React.MouseEvent<HTMLElement, MouseEvent>,
      d?: SelectOption,
    ) => {
      if (canRemove === false) return;

      if (!multiple) {
        // This logic was taken from TR-4609 - Ability to archive age groups
        if ((selectedOption as SelectOption)?.archived) {
          const isConfirmed = await getConfirmation({
            textId: `deselect-property-archived-${propertyType}`,
            textValues: { name: (selectedOption as SelectOption).label },
            sx: { zIndex: theme.zIndex.tooltip + 1 },
          });

          if (isConfirmed) {
            onChange();
          }
        } else {
          onChange();
        }
      } else if (d?.archived) {
        const isConfirmed = await getConfirmation({
          textId: `deselect-property-archived-${propertyType}`,
          textValues: { name: d.label },
          sx: { zIndex: theme.zIndex.tooltip + 1 },
        });

        if (isConfirmed) {
          onChange(
            value?.filter((item: SelectOption) => item.value.toString() !== d.value.toString()),
          );
        }
      } else if (d) {
        onChange(value?.filter((item: TFieldValue) => item.toString() !== d.value.toString()));
      } else {
        onChange([]);
      }
      setSearchQuery('');
    },
    [canRemove, multiple, selectedOption, getConfirmation, propertyType, value],
  );

  const handleDropdownIconClick = useCallback(
    (e: React.MouseEvent<HTMLOrSVGElement, MouseEvent>) => {
      if (!isOpen) {
        handleOpenPopup(e);

        return;
      }

      shouldSupressFocus.current = true;

      closePopup(e);

      setTimeout(() => {
        shouldSupressFocus.current = false;
      }, FOCUS_SUPRESS_DURATION);
    },
    [isOpen, handleOpenPopup, closePopup],
  );

  const handleSelectOption = useCallback(
    async (
      onChange: (newValue?: TFieldValue | TFieldValue[]) => void,
      e: React.MouseEvent<HTMLButtonElement | HTMLDivElement, MouseEvent>,
      clickedOption?: SelectOption,
    ) => {
      shouldSupressFocus.current = true;
      e.stopPropagation();

      const option = e.currentTarget;

      const curOption = options?.find(
        (item: SelectOption) => item.value.toString() === option.dataset.value?.toString(),
      ) as SelectOption<TFieldValue> | undefined;

      if (multiple) {
        if (
          value?.find((item: TFieldValue) => item.toString() === clickedOption?.value.toString()) !=
          null
        ) {
          onChange(
            value?.filter(
              (item: TFieldValue) => item.toString() !== clickedOption?.value.toString(),
            ),
          );
        } else if (!maxCount || value.length < maxCount) {
          const newValue: TFieldValue[] = [...(value ?? [])];

          if (curOption?.value != null && curOption?.value !== '') {
            newValue.push(curOption.value);
          }

          onChange(newValue);
        }
      } else if ((selectedOption as SelectOption)?.archived && propertyType) {
        const isConfirmed = await getConfirmation({
          textId: `deselect-property-archived-${propertyType}`,
          textValues: { name: (selectedOption as SelectOption).label },
          sx: { zIndex: theme.zIndex.tooltip + 1 },
        });

        if (isConfirmed) {
          onChange(curOption?.value);
        }
      } else {
        onChange(curOption?.value);
      }

      onSelectOption?.(curOption);

      setSearchQuery('');

      if (!multiple) {
        closePopup();
      }

      setTimeout(() => {
        shouldSupressFocus.current = false;

        if (multiple) {
          searchInputRef.current?.focus();
        }
      }, FOCUS_SUPRESS_DURATION);
    },
    [
      options,
      multiple,
      value,
      maxCount,
      selectedOption,
      propertyType,
      getConfirmation,
      closePopup,
      onSelectOption,
    ],
  );

  useEffect(() => {
    // this logic needed for the https://treehive.atlassian.net/browse/TR-1771
    if (!isOpen) return;

    setBottomOffset(0);

    setTimeout(() => {
      const list = menuRef.current;
      const listRect = list?.getBoundingClientRect();
      const listPosition = Number(listRect?.height) + Number(listRect?.top);

      if (listPosition > window.innerHeight) {
        const offset = listPosition - window.innerHeight;
        setBottomOffset(offset);
      }
    }, 300);
  }, [isOpen]);

  const fullClassName = buildClassName(
    'form-group form-select form-select2',
    disabled && 'disabled',
    isOpen && 'open',
    (isTouched || isOpen) && 'touched',
    error && !hideError && !isLoading && 'error',
    (multiple || renderAsTag) && 'form-select-multiple',
    !hasFieldValue(value) && 'no-value',
    (disabledValue === null || disabledValue === '') && 'disabled-value',
    className,
  );

  const popupClassName = buildClassName(
    'form-select-popup',
    'list-unstyled',
    'dropdown-menu',
    'shadow-sm',
    isOpen && 'show',
  );

  const valueCanBeRemoved = isOpen && hasFieldValue(value) && !required && canRemove !== false;

  const renderErrors = useMemo(() => {
    if (hideError) {
      return null;
    }
    if (contextError) {
      return renderError(contextError);
    }
    if (errorMessage) {
      return renderPlainError(errorMessage);
    }
    return null;
  }, [contextError, errorMessage, hideError]);

  if (isLoading) {
    return (
      <Controller
        {...props}
        control={control}
        name={fullName}
        defaultValue={defaultValue}
        render={() => (
          <>
            <label
              className={fullClassName}
              ref={wrapperRef}
              onFocus={handleSearchFocus}
              onKeyDown={handleKeyDown}
            >
              {!valueCanBeRemoved && !hideArrow && !endIcon && (
                <div
                  className="form-select-arrow"
                  role="button"
                  tabIndex={-1}
                  onClick={handleDropdownIconClick}
                >
                  <DropdownIcon />
                </div>
              )}
              <input className="form-control form-select-search" disabled={disabled} />
              <Popup>
                <ul className={buildClassName(popupClassName, 'hide-scrollbar')}>
                  <Preloader />
                </ul>
              </Popup>
              {renderLabel(props, false, multiple && (selectedOption as SelectOption[]).length > 1)}
            </label>
          </>
        )}
      />
    );
  }

  return (
    <Controller
      {...props}
      control={control}
      name={fullName}
      defaultValue={defaultValue}
      render={({ field: { onChange, onBlur } }) => (
        <>
          {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
          <label
            className={fullClassName}
            htmlFor={id}
            ref={wrapperRef}
            onFocus={handleSearchFocus}
            onKeyDown={handleKeyDown}
            data-cy={`dropdown-select-${fullName}`}
          >
            {multiple || renderAsTag ? (
              <>
                {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
                <div className="form-control" role="button" tabIndex={disabled ? -1 : 0} />
              </>
            ) : (
              <input
                name={name}
                id={id}
                className="form-control form-select-search"
                ref={inputRef}
                disabled={disabled}
                autoComplete={autoComplete}
                value={disabled ? disabledValue : searchQuery}
                onChange={handleSearchQueryChange}
                onBlur={handleBlur(onBlur)}
              />
            )}

            {multiple && (
              <div className="form-select-value-wrapper">
                <div className="form-select-value-wrapper-inner">
                  {(selectedOption as SelectOption[])?.map((item) => (
                    <Tag
                      key={item.value}
                      selectOption={item}
                      className="form-select-value"
                      onRemove={isOpen ? (e, o) => handleValueRemove(onChange, e, o) : undefined}
                    >
                      <Typography>{getOptionText(item)}</Typography>
                    </Tag>
                  ))}

                  <input
                    name={name}
                    id={id}
                    className="form-select-search-input"
                    tabIndex={-1}
                    ref={searchInputRef}
                    value={searchQuery}
                    onChange={handleSearchQueryChange}
                    onBlur={handleBlur(onBlur)}
                  />
                </div>
              </div>
            )}

            {!multiple && selectedOption && !searchQuery && (
              <div className="form-select-value-wrapper">
                <div className="form-select-value-wrapper-inner">
                  {renderSingleValue(selectedOption as SelectOption)}
                </div>
              </div>
            )}

            {endIcon && (
              <Box
                sx={{
                  position: 'absolute',
                  top: 13,
                  right: 16,
                }}
              >
                {endIcon}
              </Box>
            )}

            {valueCanBeRemoved && !endIcon && (
              <div
                className="form-select-remove"
                role="button"
                tabIndex={-1}
                onClick={(e) => handleValueRemove(onChange, e)}
              >
                <CrossIcon />
              </div>
            )}

            {!valueCanBeRemoved && !hideArrow && !endIcon && (
              <div
                className="form-select-arrow"
                role="button"
                tabIndex={-1}
                onClick={handleDropdownIconClick}
              >
                <DropdownIcon />
              </div>
            )}

            <Popup>
              {renderOptionsAsTags ? (
                <Box ref={menuRef}>
                  <Stack className={popupClassName}>
                    {Array.from(displayedOptions.keys()).map((group) => (
                      <Stack direction="row" alignItems="center" gap={1} width="100%" p={1}>
                        {displayedOptions.get(group)?.map((option) => (
                          <TagSelectProperty
                            key={option.labelTextId}
                            property={{
                              id: option.labelTextId,
                              name: getOptionText(option),
                            }}
                            variant={
                              (selectedOption as SelectOption[])?.find(
                                (opt) => opt.value === option.value,
                              )
                                ? 'filled'
                                : undefined
                            }
                            data-value={option.value}
                            onClick={(e) => handleSelectOption(onChange, e, option)}
                          />
                        ))}
                      </Stack>
                    ))}
                  </Stack>
                </Box>
              ) : (
                <ul
                  ref={menuRef}
                  className={popupClassName}
                  style={{ paddingBottom: `${bottomOffset}px` }}
                >
                  {!searchQuery && !!searchEmptyStub && searchEmptyStub}

                  {!!searchQuery && !displayedOptions?.size && (
                    <li>
                      <span
                        className={`dropdown-item form-select-option form-select-no-options-found ${
                          optionClassName ?? ''
                        }`}
                      >
                        <FormattedMessage id="input-NoOptionsFound" />
                      </span>
                    </li>
                  )}
                  {Array.from(displayedOptions.keys()).map((group) => (
                    <React.Fragment key={group}>
                      {group && (
                        <li>
                          <Typography variant="h4" px={1}>
                            {group}
                          </Typography>
                        </li>
                      )}

                      {displayedOptions.get(group)?.map((option) => (
                        <li key={`${option.label}${option.value}`}>
                          <button
                            type="button"
                            tabIndex={-1}
                            data-value={option.value}
                            className={getOptionClassName(option)}
                            onClick={(e) => handleSelectOption(onChange, e, option)}
                          >
                            <Stack
                              direction="row"
                              alignItems="center"
                              justifyContent="space-between"
                              width="100%"
                            >
                              <TypographyWithOverflowHint
                                variant="h3"
                                sx={{
                                  color: 'text.primary',
                                  '&:hover': { color: 'primary.main' },
                                }}
                                noWrap
                              >
                                {getOptionText(option)}
                              </TypographyWithOverflowHint>

                              {isOptionActive(option) ? (
                                <Icon>
                                  <CheckIcon />
                                </Icon>
                              ) : (
                                <Icon />
                              )}
                            </Stack>
                          </button>
                        </li>
                      ))}
                    </React.Fragment>
                  ))}
                </ul>
              )}
            </Popup>

            {renderLabel(
              { ...props, required },
              false,
              multiple && ((selectedOption as SelectOption[])?.length ?? 0) > 1,
            )}

            {renderErrors}
          </label>
        </>
      )}
    />
  );
};

export default FormSelect2;
