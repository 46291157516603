import { Skeleton, Stack, Typography } from '@mui/material';
import {
  Company,
  SORT_DIRECTION,
  useGetCompaniesListQuery,
  useGetCompanyQuery,
} from '@schooly/api';
import { PlusIcon, SimpleButton, TypographyWithOverflowHint } from '@schooly/style';
import { FC, useCallback, useRef, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { DropdownSelect, DropdownSelectProps } from '../DropdownSelect';
import { SelectContentSkeleton } from '../SelectContentSkeleton';
import { SelectSearchInput } from '../SelectSearchInput';
import { CompanySelectRow } from './CompanySelectRow';

type CompanySelectProps = {
  schoolId: string;
  selectedId?: string;
  onSelectCompanyId: (v: string) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const CompanySelect: FC<CompanySelectProps> = ({
  schoolId,
  selectedId,
  onSelectCompanyId,
  placeholder,
  ...dropdownProps
}) => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const dropdown = useRef<DropdownSelect | null>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [queryEnabled, setQueryEnabled] = useState(false);

  const { data, params, isFetchingNextPage, fetchNextPage, hasNextPage, isLoading, setParams } =
    useGetCompaniesListQuery(
      {
        schoolId,
        query: '',
        sort: { columnTextId: 'student_count', direction: SORT_DIRECTION.DESC },
      },
      { refetchOnMount: 'always', enabled: queryEnabled },
    );

  const handleChangeQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const renderContent = useCallback(() => {
    if (!data) return <SelectContentSkeleton />;

    const entries =
      data.pages.reduce((prev, curr) => [...prev, ...curr.results], [] as Company[]) ?? [];

    if (!entries.length)
      return (
        <Typography p={1}>
          <FormattedMessage id="input-NoOptionsFound" />
        </Typography>
      );

    return (
      <>
        {entries.map((company) => (
          <CompanySelectRow
            onClick={(id) => {
              onSelectCompanyId(id);
              handleChangeQuery('');
              dropdown.current?.close();
            }}
            key={company.id}
            company={company}
            isSelected={company.id === selectedId}
          />
        ))}
        <Stack alignItems="flex-start" p={1}>
          <SimpleButton startIcon={<PlusIcon />} onClick={() => navigate('/companies/new')}>
            {$t({ id: 'companies-AddCompany' })}
          </SimpleButton>
        </Stack>
      </>
    );
  }, [$t, data, handleChangeQuery, navigate, onSelectCompanyId, selectedId]);

  return (
    <DropdownSelect
      {...dropdownProps}
      ref={dropdown}
      onToggle={setQueryEnabled}
      placeholder={placeholder}
      hasValues={!!selectedId}
      renderContent={renderContent}
      onClickInputArea={() => inputRef.current?.focus()}
      isFetchingNextPage={isLoading || isFetchingNextPage}
      hasNextPage={hasNextPage}
      onFetchNextPage={fetchNextPage}
    >
      {(opened) => (
        <>
          {selectedId && <CompanyValue companyId={selectedId} />}
          {opened && !selectedId && (
            <SelectSearchInput
              ref={inputRef}
              autoFocus
              value={params.query || ''}
              onChangeText={handleChangeQuery}
            />
          )}
        </>
      )}
    </DropdownSelect>
  );
};

const CompanyValue: FC<{ companyId: string }> = ({ companyId }) => {
  const { data } = useGetCompanyQuery(companyId, {
    refetchOnMount: 'always',
  });

  if (!data)
    return (
      <Typography width="100%" maxWidth={180} variant="h3">
        <Skeleton variant="text" />
      </Typography>
    );

  return <TypographyWithOverflowHint variant="h3">{data.name}</TypographyWithOverflowHint>;
};
