import { UserRolePermission } from '@schooly/api';

export const allowRenderStudents = (permissions: UserRolePermission[]) =>
  permissions.includes('student_viewer');

export const allowRenderParents = (permissions: UserRolePermission[]) =>
  permissions.includes('parent_viewer');

export const allowRenderStaff = (permissions: UserRolePermission[]) =>
  permissions.includes('staff_viewer');

export const allowRenderGroups = (permissions: UserRolePermission[]) =>
  permissions.includes('group_viewer');

export const allowRenderMessages = (permissions: UserRolePermission[]) =>
  permissions.includes('message_viewer');

export const allowRenderAssessments = (permissions: UserRolePermission[]) =>
  permissions.includes('assessment_viewer');

export const allowRenderReports = (permissions: UserRolePermission[]) =>
  permissions.includes('assessment_viewer');

export const allowRenderAttendance = (permissions: UserRolePermission[]) =>
  permissions.includes('attendance_viewer');

export const allowRenderConduct = (permissions: UserRolePermission[]) =>
  permissions.includes('conduct_viewer') || permissions.includes('conduct_manager');

export const allowRenderUserRoles = (permissions: UserRolePermission[]) =>
  permissions.includes('userrole_viewer');

export const allowRenderSchoolSettings = (permissions: UserRolePermission[]) =>
  permissions.includes('school_admin');

export const allowRenderEvents = (permissions: UserRolePermission[]) =>
  permissions.includes('event_viewer');

export const allowRenderSignUps = (permissions: UserRolePermission[]) =>
  permissions.includes('event_viewer');

export const allowRenderPlanner = (permissions: UserRolePermission[]) =>
  permissions.includes('planner_viewer') &&
  permissions.some(
    (permission) => permission === 'event_viewer' || permission === 'assessment_viewer',
  );

export const allowRenderCompanies = (permissions: UserRolePermission[]) =>
  permissions.includes('payer_and_product_assignment_viewer');

export const allowRenderSchoolProducts = (permissions: UserRolePermission[]) =>
  permissions.includes('product_and_invoice_viewer');

export const allowRenderSchoolLegalEntities = (permissions: UserRolePermission[]) =>
  permissions.some(
    (permission) => permission === 'product_and_invoice_manager' || permission === 'school_admin',
  );
