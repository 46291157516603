import { IconButton, Stack, Typography } from '@mui/material';
import { CrossIcon, InformationIcon, Loading, ModalSmall } from '@schooly/style';
import { FC } from 'react';
import { FormattedMessage } from 'react-intl';

import AccessDenied from '../../../components/common/AccessDenied';
import { GroupCard } from '../../../components/common/GroupCard/GroupCard';
import { ModalMain, ModalPanel } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalEmptyMainArea } from '../../../components/uikit-components/Modal/ModalEmptyMainArea';
import { ModalFooterWithActions } from '../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { ModalPeopleExtensionPanel } from '../../../components/uikit-components/Modal/ModalPeopleExtensionPanel';
import { getUserFullName } from '../../../helpers/users';
import { ManageGroupModalTooltip } from '../../Groups/ManageGroupModal/ManageGroupModalTooltip';
import { AddUserRoleExternalSidebar } from './AddUserRoleExternalSidebar';
import { useManageUserRoleModal } from './useManageUserRoleModal';

interface ManageUserRoleModalProps {}

export const ManageUserRoleModal: FC<ManageUserRoleModalProps> = () => {
  const {
    modalActive,
    isFetching,
    actions,
    currentRoles,
    user,
    notAvailableRoles,
    isUserRoleManager,
    isUserRoleViewer,
    ...state
  } = useManageUserRoleModal(true);

  if (!isUserRoleManager && !isUserRoleViewer) {
    return (
      <ModalSmall open>
        <ModalHeader active>
          <IconButton onClick={actions.closeModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <AccessDenied />
      </ModalSmall>
    );
  }

  const roleCount = (currentRoles?.length ?? 0) + (notAvailableRoles?.length ?? 0);

  return (
    <ModalSmall open={modalActive}>
      {isFetching ? (
        <Loading />
      ) : (
        <>
          <ModalHeader title={user ? getUserFullName(user) : ''} active>
            <IconButton onClick={actions.closeModal}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>

          <ModalPeopleExtensionPanel
            addActionId="groups-AddGroups"
            editActionId="groups-EditGroups"
            titleId="userRoles-UserRoles"
            active
            count={roleCount}
            onAddClick={() => {}}
            sidebarContent={
              <AddUserRoleExternalSidebar
                onSelect={actions.onSelectRole}
                selectedRoles={currentRoles}
              />
            }
            withoutGap
          >
            <ModalPanel fullHeight active sx={{ pt: 0 }}>
              {!currentRoles?.length && !notAvailableRoles?.length && (
                <ModalEmptyMainArea
                  label=""
                  labelNode={
                    <FormattedMessage
                      id="userRoles-MemberAssignToRole"
                      values={{
                        //TODO use userType
                        userType: 'staff',
                      }}
                    />
                  }
                />
              )}
              <Stack gap={2.5}>
                {!!currentRoles?.length && (
                  <Stack gap={1}>
                    {currentRoles.map((role) => (
                      <GroupCard
                        onSelect={() => actions.onSelectRole(role)}
                        title={role.name}
                        id={role.id}
                        withBorder
                        selectable
                        added
                        withoutTitleLink
                      />
                    ))}
                  </Stack>
                )}

                {!!notAvailableRoles?.length && (
                  <Stack gap={1}>
                    <Stack direction="row" alignItems="center" gap={0.5}>
                      <Typography variant="h4" color="text.primary">
                        <FormattedMessage id="userRoles-NotAllowedToAssign" />
                      </Typography>
                      {/*  TODO move tooltip to ui-kit */}
                      <ManageGroupModalTooltip
                        text={
                          <Typography color="primary.main">
                            <FormattedMessage id="userRoles-NoPermissionsToManage" />
                          </Typography>
                        }
                        arrow
                      >
                        <IconButton inverse>
                          <InformationIcon />
                        </IconButton>
                      </ManageGroupModalTooltip>
                    </Stack>

                    {notAvailableRoles.map((group) => (
                      <GroupCard
                        title={group.name}
                        id={group.id}
                        withBorder
                        locked
                        withoutTitleLink
                      />
                    ))}
                  </Stack>
                )}
              </Stack>
            </ModalPanel>
          </ModalPeopleExtensionPanel>

          <ModalMain />
          <ModalFooterWithActions saving={state.isSaving} onSaveClick={actions.onSubmit} />
        </>
      )}
    </ModalSmall>
  );
};
