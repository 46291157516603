import { DATE_FORMAT_FULL_MONTH_FNS } from '@schooly/api';
import { getMessagesForRelation } from '@schooly/api';
import { MessageForGroup } from '@schooly/api';
import { format } from 'date-fns';
import { useCallback, useMemo } from 'react';

import { MONTH_NONE } from '../constants/misc';
import { retrieveApiDate } from '../helpers/misc';
import updateCancelToken from '../utils/updateCancelToken';
import usePagedApiResourceWithFilter, {
  GetResourceFunction,
} from './usePagedApiResourceWithFilter';

const cancelTokenSource = {};

export const useMessagesForRelation = (relationId: string, shouldRequest = true) => {
  const request = useCallback<GetResourceFunction<MessageForGroup>>(
    async ({ pageSize, pageNumber }) => {
      if (!relationId) {
        return undefined;
      }

      const response = await getMessagesForRelation({
        relationId,
        pageSize,
        pageNumber,
        token: updateCancelToken(cancelTokenSource),
      });

      return {
        ...response,
        results: response?.results ?? [],
      };
    },
    [relationId],
  );

  const { displayedList, ...other } = usePagedApiResourceWithFilter<MessageForGroup>({
    getResource: request,
    defaultLoadingValue: true,
  });

  const messages = useMemo(() => displayedList?.results ?? [], [displayedList?.results]);

  const messagesByMonth = useMemo(() => {
    return messages.reduce<Record<string, MessageForGroup[]>>((prev, message) => {
      const date = message.publish_date && retrieveApiDate(message.publish_date);
      const month = date ? format(date, DATE_FORMAT_FULL_MONTH_FNS).toUpperCase() : MONTH_NONE;

      if (!prev[month]) {
        prev[month] = [];
      }

      prev[month].push(message);

      return prev;
    }, {});
  }, [messages]);

  return { messages, messagesByMonth, request, ...other };
};
