import { Skeleton, Typography } from '@mui/material';
import { PayerType, useGetCompanyQuery, useGetParentMembership } from '@schooly/api';
import {
  DropdownSelect,
  DropdownSelectProps,
  DropdownSelectRow,
  SelectContentSkeleton,
} from '@schooly/components/filters';
import { useFlag } from '@schooly/hooks/use-flag';
import { getUserFullName } from '@schooly/utils/user-helpers';
import { FC, useCallback, useRef } from 'react';

type PayerTypeSelectProps = {
  value: PayerType;
  shownInHead?: boolean;
  companyId: string;
  adultId: string;
  schoolId: string;
  onSelect: (v: PayerType) => void;
} & Omit<DropdownSelectProps, 'children' | 'renderContent'>;

export const PayerTypeSelect: FC<PayerTypeSelectProps> = ({
  value,
  schoolId,
  shownInHead,
  companyId,
  adultId,
  onSelect,
  ...dropdownProps
}) => {
  const dropdown = useRef<DropdownSelect | null>(null);

  const [opened, open, close] = useFlag();

  const { data: company } = useGetCompanyQuery(companyId);
  const { data: adult } = useGetParentMembership({ schoolId, id: adultId });

  const renderContent = useCallback(() => {
    if (!company || !adult) return <SelectContentSkeleton />;

    return (
      <>
        <DropdownSelectRow
          isSelected={value === PayerType.Default}
          onClick={() => {
            onSelect(PayerType.Default);
            dropdown.current?.close();
          }}
        >
          {getUserFullName(adult)}
        </DropdownSelectRow>
        <DropdownSelectRow
          isSelected={value === PayerType.Company}
          onClick={() => {
            onSelect(PayerType.Company);
            dropdown.current?.close();
          }}
        >
          {company.name}
        </DropdownSelectRow>
      </>
    );
  }, [adult, company, onSelect, value]);

  return (
    <DropdownSelect
      ref={dropdown}
      sx={(theme) => ({
        height: 46,
        borderTopLeftRadius: shownInHead ? undefined : 0,
        borderTopRightRadius: shownInHead ? undefined : 0,
        borderBottomRightRadius: 0,
        borderBottomLeftRadius: 0,
        margin: shownInHead ? theme.spacing(-1, -1, '-9px', -1) : theme.spacing('-1px', 0),
        backgroundColor: opened ? undefined : 'transparent',
        borderColor: opened ? undefined : 'transparent',
        outlineColor: 'transparent !important',
      })}
      hasValues
      renderContent={renderContent}
      onOpen={open}
      onClose={close}
      {...dropdownProps}
    >
      {() => {
        if (!company || !adult) return <Skeleton />;

        return (
          <Typography color="text.primary" noWrap>
            {value === PayerType.Company ? company.name : getUserFullName(adult)}
          </Typography>
        );
      }}
    </DropdownSelect>
  );
};
