import { UserRole, UserRoleList } from '@schooly/api';
import { createContext } from 'react';

export interface UserRoleForRelationContextProps {
  relationRoles?: UserRoleList;
  isRolesLoading: boolean;
  relationId?: string;
  allRelationRoles: UserRole[];
}

export const UserRoleForRelationContext = createContext<UserRoleForRelationContextProps>({
  relationRoles: undefined,
  isRolesLoading: false,
  relationId: undefined,
  allRelationRoles: [],
});

UserRoleForRelationContext.displayName = 'UserRoleForRelationContext';
