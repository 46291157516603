import { Button, Icon, IconButton, Tooltip, Typography } from '@mui/material';
import { EventsStatuses } from '@schooly/api';
import { CrossIcon, InformationIcon, ModalConfirm, ModalContent, Spin } from '@schooly/style';
import { isDateInPast } from '@schooly/utils/date';
import * as React from 'react';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormProvider, SubmitHandler, useForm, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { ModalFooter } from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { EventForm, useEvent } from '../../../context/events/WithEvent';
import { SignUpForm } from '../../../context/signUps/WithSignUp';
import {
  convertEventForForm,
  defaultValues,
} from '../../Events/EventCreateModal/EventCreateContent';
import { EventDateAndTimeWrapper } from '../../Events/EventCreateModal/EventDateAndTimeWrapper';

export interface SignUpTimeSlotsModalProps {
  onCancel?: () => void;
  onSave?: () => void;
}

export const SignUpTimeSlotsModal: FC<SignUpTimeSlotsModalProps> = ({ onCancel, onSave }) => {
  const { $t } = useIntl();
  const {
    actions: { updating, updateEvent },
  } = useEvent();
  const { watch, setValue } = useFormContext<SignUpForm>();

  const event = watch('event');

  const form = useForm<EventForm>({
    defaultValues: {
      ...((event && convertEventForForm(event)) ?? defaultValues),
      date_times: [['', '']],
    },
  });

  useEffect(() => {
    setTimeout(() => {
      form.trigger();
    });
  }, [form]);

  const start = form.watch('start');
  const end = form.watch('end');
  const dateTimes = form.watch('date_times');
  const startTimeOfStartDate = dateTimes[0]?.[0];

  const { dates, times } = useMemo(() => {
    return {
      dates: start && end ? ([start, end] as [string, string]) : undefined,
      times: dateTimes ?? undefined,
    };
  }, [dateTimes, end, start]);

  const startDateError = useMemo(
    () =>
      event?.event_status === EventsStatuses.Draft &&
      isDateInPast(start, startTimeOfStartDate ?? null),
    [event?.event_status, start, startTimeOfStartDate],
  );

  const onSubmit = useCallback<SubmitHandler<EventForm>>(
    async (eventForm) => {
      if (!event) {
        return;
      }

      updateEvent(
        {
          ...eventForm,
          originalEvent: event,
          confirmed: true,
          withFollowing: false,
        },
        {
          onSuccess: () => {
            setValue('event', { ...event, ...eventForm, recurring_state: event.recurring_state });
            onSave?.();
          },
        },
      );
    },
    [event, onSave, setValue, updateEvent],
  );

  if (!event) {
    return null;
  }

  return (
    <ModalConfirm open>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalHeader
            title={$t({ id: 'eventSignUps-create-Duration-SelectTime-Title' })}
            multiline
            active
          >
            <IconButton onClick={onCancel}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>

          <ModalContent active>
            <Typography variant="h2">{event.title}</Typography>
            <EventDateAndTimeWrapper
              fromDateError={startDateError}
              dates={dates}
              times={times}
              isPublished={event?.event_status === EventsStatuses.Published}
              disableIcon={
                event?.recurring_state ? (
                  <Tooltip title={$t({ id: 'events-recurring-DisabledDateSelectText' })}>
                    <Icon sx={{ color: 'text.secondary' }}>
                      <InformationIcon />
                    </Icon>
                  </Tooltip>
                ) : undefined
              }
            />
          </ModalContent>

          <ModalFooter active>
            <Button data-cy="modal-cancel" variant="outlined" onClick={onCancel}>
              <FormattedMessage id="action-Cancel" />
            </Button>
            <Button
              type="submit"
              startIcon={updating && <Spin />}
              disabled={updating}
              data-cy="modal-submit"
            >
              <FormattedMessage id="action-Save" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalConfirm>
  );
};
