import { IconButton, Tooltip } from '@mui/material';
import { useExportAssessmentForGroupMutation } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CheckIcon, DownloadIcon, Spin } from '@schooly/style';
import { IntlError } from '@schooly/utils/intl-error';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

import { useAssessmentsMarkbook } from '../../../hooks/useAssessmentsMarkbook';
import { downloadFile } from '../../../utils/downloadFile';

export const AssessmentMarkbookExport: FC = () => {
  const { $t } = useIntl();
  const { showError } = useNotifications();
  const { group, dateFrom, dateTo, selectedIds } = useAssessmentsMarkbook();

  // is being set after successful download and reset in 3 sec afterwards
  const [isSuccess, setIsSuccess] = useState(false);

  const mutation = useExportAssessmentForGroupMutation();

  const handleClick = useCallback(() => {
    if (!group?.id || !dateFrom || !dateTo) {
      return;
    }

    mutation.mutate(
      {
        groupId: group.id,
        date: [dateFrom, dateTo],
        assessmentIds: selectedIds !== 'all' ? Array.from(selectedIds) : undefined,
      },
      {
        onSuccess: (data) => {
          try {
            downloadFile(data, `${group.name ?? ''}.markbook.csv`);
            setIsSuccess(true);
          } catch (err) {
            showError(err as IntlError);
          }
        },
        onError: showError,
      },
    );
  }, [dateFrom, dateTo, group?.id, group?.name, mutation, selectedIds, showError]);

  /** resets success icon in 3 sec */
  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => setIsSuccess(false), 3 * 1000);
    }
  }, [isSuccess]);

  return (
    <Tooltip title={$t({ id: 'assessments-ExportAllToCSV' })}>
      <IconButton
        sx={{ '&&': { color: mutation.isLoading || isSuccess ? 'primary.main' : undefined } }}
        onClick={mutation.isLoading ? undefined : handleClick}
      >
        {(() => {
          if (mutation.isLoading) {
            return <Spin />;
          }

          if (isSuccess) {
            return <CheckIcon />;
          }

          return <DownloadIcon />;
        })()}
      </IconButton>
    </Tooltip>
  );
};
