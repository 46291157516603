import { Box, Stack } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { TITLE_OPTIONS } from '@schooly/constants';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import FormSelect2 from 'apps/web/src/components/ui/Input/FormSelect2';
import { FC } from 'react';
import { useFormContext } from 'react-hook-form-lts';
import { useIntl } from 'react-intl';

export const ParentInfoFormContainer: FC = () => {
  const { control } = useFormContext();
  const { $t } = useIntl();
  return (
    <Stack gap={2}>
      <Stack direction="row" gap={2}>
        <Box flex="0 0 205px">
          <FormSelect2 name="title" labelTextId="peopleDetail-Title" options={TITLE_OPTIONS} />
        </Box>

        <ControlTextField
          name="given_name"
          control={control}
          rules={{ required: true }}
          label={$t({ id: 'peopleDetail-GivenNames' })}
          canClear
          fullWidth
        />
      </Stack>

      <ControlTextField
        name="last_name"
        control={control}
        rules={{ required: true }}
        label={$t({ id: 'peopleDetail-FamilyName' })}
        canClear
        fullWidth
      />
    </Stack>
  );
};
