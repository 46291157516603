import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { updateUserRole, UserRoleList } from '@schooly/api';
import { deleteUserRole, getUserRolesList } from '@schooly/api';
import { ApiError } from '@schooly/api';

export interface IUserRolesState {
  userRoles: {
    error?: ApiError;
    isLoading: boolean;
    roles?: UserRoleList;
  };
}

export const initialState: IUserRolesState = {
  userRoles: { error: undefined, isLoading: false, roles: undefined },
};

export const extraActions = {
  getUserRoles: createAsyncThunk(
    'userRoles/getUserRoles',
    async ({ schoolId, searchQuery }: { schoolId: string; searchQuery?: string }, thunkAPI) => {
      if (!schoolId) {
        return undefined;
      }

      const userRoles = await getUserRolesList(schoolId, searchQuery);

      return userRoles;
    },
  ),
  updateUserRole: createAsyncThunk(
    'userRoles/updateUserRole',
    async (
      {
        schoolId,
        roleId,
        relationIds,
      }: { schoolId: string; roleId: string; relationIds: string[] },
      thunkAPI,
    ) => {
      try {
        await updateUserRole({ id: roleId, relation_ids: relationIds });
        await thunkAPI.dispatch(extraActions.getUserRoles({ schoolId, searchQuery: '' }));
      } catch (error) {
        return thunkAPI.rejectWithValue(error);
      }
    },
  ),
  deleteUserRole: createAsyncThunk(
    'userRoles/deleteUserRole',
    async ({ schoolId, roleId }: { schoolId: string; roleId: string }, thunkAPI) => {
      await deleteUserRole(roleId);
      await thunkAPI.dispatch(extraActions.getUserRoles({ schoolId, searchQuery: '' }));
    },
  ),
};

const slice = createSlice({
  name: 'userRoles',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(extraActions.getUserRoles.pending, (state) => {
        state.userRoles.error = undefined;
        state.userRoles.isLoading = true;
      })
      .addCase(extraActions.getUserRoles.fulfilled, (state, action) => {
        state.userRoles.isLoading = false;

        if (!action.payload) {
          return;
        }

        state.userRoles.roles = action.payload;
      })
      .addCase(extraActions.getUserRoles.rejected, (state, action) => {
        state.userRoles.error = action.error as ApiError;
        state.userRoles.isLoading = false;
      });
  },
});

export const actions = {
  ...slice.actions,
  ...extraActions,
};

export default slice;
