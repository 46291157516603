import { Icon, Link, Stack, Typography } from '@mui/material';
import { Application, Parent } from '@schooly/api';
import { DELAY_BEFORE_HIDE_ICON_DONE } from '@schooly/constants';
import {
  CopyIcon,
  DoneIcon,
  EmailIcon,
  PhoneIcon,
  StarIcon,
  StudentIcon,
  theme,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { Counter } from 'apps/web/src/components/uikit/Counter/Counter.styled';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { GridRowName } from 'apps/web/src/components/uikit-components/Grid/Grid';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { LongNameWithVerticalTooltip } from 'apps/web/src/components/uikit-components/LongNameWithVerticalTooltip/LongNameWithVerticalTooltip';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { ManageGroupModalTooltip } from 'apps/web/src/pages/Groups/ManageGroupModal/ManageGroupModalTooltip';
import { FC, useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';

import { ApplicationPreviewRow } from './ApplicationPreviewRow';

interface ApplicationParentsPreviewProps {
  parents: Application['adults'];
  withoutActionBackground?: boolean;
}

export const ApplicationParentPreview: FC<ApplicationParentsPreviewProps> = ({
  parents,
  withoutActionBackground,
}) => {
  return (
    <Stack>
      <Stack direction="row" alignItems="center" pt={4} pb={3}>
        <Typography variant="h2">
          <FormattedMessage id="applications-ParentsGuardians" />
        </Typography>
        <Counter>{Number(parents.length)}</Counter>
      </Stack>

      <Stack>
        {parents.map((p) => (
          <ApplicationPreviewRow
            key={p.id}
            sxProps={{
              ...(withoutActionBackground && {
                '&:hover': {
                  background: theme.palette.background.paper,
                  '& .MuiTypography-root': {
                    color: `${theme.palette.common.grey2} !important`,
                  },
                  '.copy-button': {
                    visibility: 'visible',
                  },
                },
              }),
            }}
          >
            <ApplicationParentPreviewRowContent parent={p} />
          </ApplicationPreviewRow>
        ))}
      </Stack>
    </Stack>
  );
};

export const ApplicationParentPreviewRowContent: FC<{ parent: Parent }> = ({ parent }) => {
  const [showDoneIcon, setShowDoneIcon] = useState(false);

  const icon = showDoneIcon ? <DoneIcon /> : <CopyIcon />;

  const onCopy = useCallback((email: string) => {
    navigator.clipboard.writeText(email);
    setShowDoneIcon(true);
    setTimeout(() => setShowDoneIcon(false), DELAY_BEFORE_HIDE_ICON_DONE);
  }, []);

  return (
    <>
      <GridRowName
        sx={{
          flex: '0 0 30%',
          minWidth: 0,
        }}
        variant="h3"
      >
        {parent.primary ? (
          <Stack direction="row" sx={{ '>.MuiBox-root': { maxWidth: '100%' } }}>
            {/* TODO move tooltip to ui-kit */}
            <ManageGroupModalTooltip
              sx={{ p: 1.25 }}
              offset={[0, -10]}
              text={
                <Typography color="primary.main">
                  <FormattedMessage id="applications-PrimaryContactForAll" />
                </Typography>
              }
              arrow
            >
              <Stack
                direction="row"
                alignItems="center"
                sx={(theme) => ({
                  ':hover': {
                    '.svg-icon': {
                      color: theme.palette.primary.main,
                    },
                  },
                })}
              >
                <LongNameWithVerticalTooltip>{getUserFullName(parent)}</LongNameWithVerticalTooltip>
                <StarIcon />
              </Stack>
            </ManageGroupModalTooltip>
          </Stack>
        ) : (
          <LongNameWithVerticalTooltip>{getUserFullName(parent)}</LongNameWithVerticalTooltip>
        )}
      </GridRowName>
      <GridRowName>
        {parent.children && parent.children.length > 0 && (
          <Typography
            variant="inherit"
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 0,
            }}
          >
            <Icon sx={{ color: 'text.secondary' }}>
              <StudentIcon />
            </Icon>
            <LongNameWithVerticalTooltip>
              {parent.children.reduce((acc, child, index) => {
                const separator = index === 0 ? '' : ', ';
                return (acc += `${separator}${child.given_name}`);
              }, '')}
            </LongNameWithVerticalTooltip>
          </Typography>
        )}
      </GridRowName>
      <GridRowName>
        {parent.telephone && (
          <Typography
            variant="inherit"
            sx={{
              display: 'flex',
              alignItems: 'center',

              minWidth: 0,
            }}
          >
            <Icon sx={{ color: 'text.secondary' }}>
              <PhoneIcon />
            </Icon>
            <LongNameWithVerticalTooltip>{parent.telephone}</LongNameWithVerticalTooltip>
          </Typography>
        )}
      </GridRowName>
      <GridRowName
        sx={(theme) => ({
          flex: '0 0 370px',

          '& .emailTooltip.UIKit-LongNameWithVerticalTooltip_tooltip': {
            ':hover': {
              '.UIKit-LongNameWithVerticalTooltip__info': {
                backgroundColor: theme.palette.common.white,
              },
            },
          },
        })}
      >
        {parent.email && (
          <Typography
            variant="inherit"
            sx={{
              display: 'flex',
              alignItems: 'center',
              minWidth: 0,
            }}
          >
            <Icon sx={{ color: 'text.secondary' }}>
              <EmailIcon />
            </Icon>
            <LongNameWithVerticalTooltip>
              <Link
                sx={{
                  transition: 'none',
                }}
                underline="hover"
                href={`mailto:${parent.email}`}
                target="_blank"
              >
                {parent.email}
              </Link>
            </LongNameWithVerticalTooltip>
          </Typography>
        )}
      </GridRowName>
      <GridRowName
        sx={{
          flex: '0 0 auto',
        }}
      >
        <Stack
          sx={{
            cursor: 'pointer',
            justifyContent: 'center',
            alignItems: 'center',
            visibility: 'hidden',
            pr: 1.5,
            color: 'text.primary',
            ' .svg-icon': {
              width: 20,
              height: 20,
              visibility: showDoneIcon ? 'visible' : undefined,
            },
          }}
          className="copy-button"
          onClick={() => {
            if (parent.email) {
              onCopy(parent.email);
            }
          }}
        >
          {parent.email && icon}
        </Stack>
      </GridRowName>
    </>
  );
};
