import { IContextAction } from '@schooly/api';
import { MoreIcon } from '@schooly/style';
import React, { FC, PropsWithChildren, RefAttributes } from 'react';

import buildClassName from '../../../utils/buildClassName';
import Button from '../Button';
import ContextMenu from '../ContextMenu';

import './index.scss';

interface ICardProps extends PropsWithChildren {
  className?: string;
  contextActions?: IContextAction[];
  onClick?: VoidFunction;
  shouldPlaceChildrenDirectly?: boolean;
  borderColor?: string;
  isListItem?: boolean;
  testId?: string;
}

const Card: FC<ICardProps & RefAttributes<HTMLDivElement>> = React.forwardRef<
  HTMLDivElement,
  ICardProps
>(
  (
    {
      className,
      contextActions,
      children,
      onClick,
      shouldPlaceChildrenDirectly,
      borderColor,
      isListItem,
      testId,
    },
    ref,
  ) => {
    const fullClassName = buildClassName(
      'Card card',
      borderColor && `Card--border border-left-${borderColor}`,
      onClick && 'Card--clickable',
      isListItem && 'Card--list-item',
      className,
    );

    const content = (
      <>
        {shouldPlaceChildrenDirectly ? children : <div className="card-body">{children}</div>}
        {contextActions && !!contextActions.length && (
          <ContextMenu
            className="Card__context-menu"
            trigger={({ toggleOpen }) => (
              <Button
                className="Card__context-menu__toggle"
                isCustomShape
                size="small"
                color="none"
                onClick={toggleOpen}
                icon={<MoreIcon />}
              />
            )}
            actions={contextActions}
            position="bottom"
          />
        )}
      </>
    );

    if (onClick) {
      return (
        <div
          ref={ref}
          className={fullClassName}
          role="button"
          tabIndex={0}
          onClick={onClick}
          data-cy={testId}
        >
          {content}
        </div>
      );
    }

    return (
      <div ref={ref} className={fullClassName} data-cy={testId}>
        {content}
      </div>
    );
  },
);

export default Card;
