import { Checkbox, FormControlLabel, IconButton, Stack } from '@mui/material';
import { DateTime, DEFAULT_DATE_FORMAT_FNS, Event } from '@schooly/api';
import { DateRangeSelect } from '@schooly/components/filters';
import { usePrevious } from '@schooly/hooks/use-previous';
import { CalendarTodayIcon, DeleteIcon, PlusIcon, SimpleButton } from '@schooly/style';
import { eachDayOfInterval, format, isBefore, isToday, startOfToday } from 'date-fns';
import React, { FC, PropsWithChildren, useCallback, useEffect, useMemo, useState } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import { TimeRangeInputs } from './TimeRangeInputs';

const defaultDateTime: DateTime = ['', ''];

interface EventDateAndTimeWrapperProps extends PropsWithChildren {
  dates?: [Event['start'], Event['end']];
  times?: Event['date_times'];
  fromDateError?: boolean;
  recurrenceError?: boolean;
  isPublished?: boolean;
  disableIcon?: JSX.Element;
  helperText?: string;
}

export const EventDateAndTimeWrapper: FC<EventDateAndTimeWrapperProps> = ({
  dates,
  times,
  fromDateError,
  isPublished,
  recurrenceError,
  children,
  disableIcon,
  helperText,
}) => {
  const { $t } = useIntl();
  const [dateRange, setDateRange] = useState(dates ?? []);
  const [addingTime, setAddingTime] = useState(Boolean(times?.length));

  const { control, setValue, register, watch, formState, clearErrors } = useFormContext<Event>();
  const { replace } = useFieldArray<Event>({
    name: 'date_times',
    control,
  });
  const error = Boolean(formState.errors?.start);

  const today = startOfToday();
  const isFromDateInPast = isBefore(new Date(dateRange[0]), today);
  const isFromDateDisabled = Boolean(dates && dates[0] && isFromDateInPast && !fromDateError);
  const singleDatePossible = !(isPublished && isFromDateInPast);

  const eventType = watch('event_type');
  const dateTimes = watch('date_times');

  const isHoliday = eventType === 'holiday';

  const allDays = useMemo(() => {
    if (!dateRange.length) return [];
    return eachDayOfInterval({
      start: new Date(dateRange[0]),
      end: new Date(dateRange[1]),
    });
  }, [dateRange]);

  const prevAllDays = usePrevious(allDays);
  const todayIndex = allDays.findIndex((day) => isToday(day));
  const multipleDates = allDays.length > 1;

  const initialSameTimeState = Boolean(times && times.length === 1 && multipleDates);
  const [sameTime, setSameTime] = useState(initialSameTimeState);
  const sameDateDisabled = Boolean(isPublished && isFromDateInPast && !initialSameTimeState);

  useEffect(() => {
    if (times?.length) {
      setAddingTime(true);
      setSameTime(Boolean(times.length === 1 && multipleDates));
    }
  }, [multipleDates, times]);

  const disableDeleteTime = Boolean(
    isPublished && isFromDateInPast && !!times?.length && times[0][0],
  );

  useEffect(() => {
    // Changes times inputs if dates range changed
    if (prevAllDays !== allDays && addingTime && !sameTime) {
      if (dateTimes.length > allDays.length) {
        const numberOfElementsToRemove = dateTimes.length - allDays.length;
        const newDateTimes = dateTimes.slice(0, dateTimes.length - numberOfElementsToRemove);
        replace(newDateTimes);
      } else if (dateTimes.length < allDays.length) {
        const numberOfElementsToAdd = allDays.length - dateTimes.length;
        const elementsToAdd: DateTime[] = Array.from(
          { length: numberOfElementsToAdd },
          () => defaultDateTime,
        );
        const newDateTimes = [...dateTimes, ...elementsToAdd];
        replace(newDateTimes);
      }
    }
  }, [addingTime, allDays, dateTimes, prevAllDays, replace, sameTime]);

  const onAddTimeClick = useCallback(() => {
    const emptyTimes = allDays.map((): DateTime => defaultDateTime);
    replace(emptyTimes);
    setAddingTime(true);
  }, [allDays, replace]);

  const onDeleteTimesClick = useCallback(() => {
    replace([]);
    setAddingTime(false);
  }, [replace]);

  const onSetDate = useCallback(
    (v: [Date, Date | undefined]) => {
      if (!v?.length) {
        return;
      }
      const [start, end] = v;

      if (!start) {
        return;
      }

      setValue('start', format(start, DEFAULT_DATE_FORMAT_FNS));
      const endDate = end ?? start;
      setValue('end', format(endDate, DEFAULT_DATE_FORMAT_FNS));

      if (error) {
        clearErrors(['start']);
      }

      setDateRange([
        format(start, DEFAULT_DATE_FORMAT_FNS),
        format(endDate, DEFAULT_DATE_FORMAT_FNS),
      ]);
    },
    [clearErrors, error, setValue],
  );

  const onCheckBoxChange = useCallback(() => {
    if (sameTime) {
      setSameTime(false);
      const newDateTimes = allDays.map((day, index) => {
        if (index === 0) {
          return dateTimes[0];
        }
        if (isBefore(day, today)) {
          return dateTimes[0];
        } else {
          return defaultDateTime;
        }
      });
      replace(newDateTimes);
    } else {
      setSameTime(true);
      replace([dateTimes[0]]);
    }
  }, [allDays, dateTimes, replace, sameTime, today]);

  const renderCalendarFooter = (onClick: () => void, isFromDateToday: boolean) => {
    return (
      <Stack
        alignItems="center"
        sx={(theme) => ({
          borderTop: theme.mixins.borderValue(),
          py: 2,
          mx: 1.5,
        })}
      >
        <SimpleButton
          inverse
          startIcon={<CalendarTodayIcon />}
          onClick={onClick}
          sx={(theme) => ({ color: isFromDateToday ? theme.palette.primary.main : undefined })}
        >
          <FormattedMessage id="datepicker-Today" />
        </SimpleButton>
      </Stack>
    );
  };

  return (
    <Stack gap={2.5} pt={2} pb={4} alignItems="flex-start">
      <Stack width="100%" direction="row" gap={1}>
        <input type="hidden" ref={register(`start`, { required: true }).ref} />
        <Stack flex={1}>
          <DateRangeSelect
            onSetDate={onSetDate}
            date={dateRange}
            requiredLabel="required"
            singleDatePossible={singleDatePossible}
            DateRangeSelectContentProps={{
              disablePast: true,
            }}
            disabled={Boolean(disableIcon)}
            helperText={helperText}
            renderRightIcon={disableIcon ? () => disableIcon : undefined}
            error={
              recurrenceError
                ? { type: 'validate', messageTextId: 'events-recurring-DateOverlapError' }
                : undefined
            }
            renderFooter={renderCalendarFooter}
            opened={fromDateError && isFromDateInPast ? fromDateError : undefined}
            fromDateError={fromDateError && isFromDateInPast}
            isFromDateDisabled={isFromDateDisabled}
            popperZIndex={(theme) => theme.zIndex.tooltip}
            {...(error && {
              error: {
                type: 'required',
              },
            })}
          />
        </Stack>
        {children}
      </Stack>

      {isHoliday ? null : addingTime ? (
        <Stack direction="row" justifyContent="space-between" width="100%" alignItems="flex-start">
          {multipleDates && (
            <FormControlLabel
              label={$t({ id: 'events-time-SameTime' })}
              control={
                <Checkbox
                  checked={sameTime}
                  onChange={onCheckBoxChange}
                  disabled={sameDateDisabled}
                />
              }
            />
          )}
          <Stack direction="row" alignItems="flex-start" gap={2}>
            <Stack gap={1}>
              {dateTimes.map((times, index: number) => {
                if (allDays[index]) {
                  return (
                    <TimeRangeInputs
                      key={`${format(allDays[index], DEFAULT_DATE_FORMAT_FNS)}-${index}`}
                      index={index}
                      date={sameTime && todayIndex >= 0 ? allDays[todayIndex] : allDays[index]}
                      showDate={multipleDates && !sameTime}
                      disabled={isFromDateInPast && sameTime}
                    />
                  );
                }
                return null;
              })}
            </Stack>
            <IconButton
              inverse
              onClick={onDeleteTimesClick}
              disabled={disableDeleteTime}
              sx={(theme) => ({
                pt: 1.5,
                '&.Mui-disabled': {
                  color: theme.palette.common.grey6,
                },
              })}
            >
              <DeleteIcon />
            </IconButton>
          </Stack>
        </Stack>
      ) : (
        <>
          {!!dateRange.length && (
            <SimpleButton startIcon={<PlusIcon />} onClick={onAddTimeClick}>
              {$t({ id: 'events-time-AddTime' })}
            </SimpleButton>
          )}
        </>
      )}
    </Stack>
  );
};
