import { useMutation, useQuery } from '@tanstack/react-query';

import { ApiError } from './apiTypes/misc';
import { RQUseMutationOptions, RQUseMutationResult, RQUseQueryOptions } from './apiTypes/query';
import { XeroAccount } from './invoices';
import { LegalEntityCommon } from './legalEntities';
import * as api from './requests';

const INTEGRATION_URL = '/integration';
const REDIRECT_URL = `${window.location.origin}/settings/xero-auth`;

export type XeroTenant = {
  id: string;
  name: string;
  accounts: XeroAccount[];
} & LegalEntityCommon;

export enum XeroAuthState {
  Init = 'init', // not authenticated
  Auth = 'auth', // authenticating at this moment
  Expired = 'expired', // needs reauthentication
  Ready = 'api_ready', // authenticated
}

type InitializeXeroAuthParams = {
  schoolId: string;
};

type InitializeXeroAuthResponse = {
  auth_uri: string;
};

const initializeXeroAuth = async ({
  schoolId,
}: InitializeXeroAuthParams): Promise<InitializeXeroAuthResponse> => {
  return await api.post(`${INTEGRATION_URL}/xero/setup`, {
    school_id: schoolId,
    redirect_uri: REDIRECT_URL,
  });
};

const INITIALIZE_XERO_AUTH_QUERY = `${INTEGRATION_URL}/INITIALIZE_XERO_AUTH_QUERY`;

export const useInitializeXeroAuth = (
  params: InitializeXeroAuthParams,
  options?: RQUseQueryOptions<InitializeXeroAuthResponse>,
) => {
  return useQuery<InitializeXeroAuthResponse, ApiError>(
    [INITIALIZE_XERO_AUTH_QUERY, params],
    () => initializeXeroAuth(params),
    {
      ...options,
    },
  );
};

type CompleteXeroAuthParams = {
  schoolId: string;
  code: string;
  state: string;
  session_state: string;
};

type CompleteXeroAuthResponse = {
  connected_tenant: XeroTenant | null;
};

export const COMPLETE_XERO_AUTH_MUTATION = 'COMPLETE_XERO_AUTH_MUTATION';

const completeXeroAuth = async ({
  schoolId,
  ...params
}: CompleteXeroAuthParams): Promise<CompleteXeroAuthResponse> => {
  return await api.post(`${INTEGRATION_URL}/xero/setup`, {
    school_id: schoolId,
    redirect_uri: REDIRECT_URL,
    ...params,
  });
};

export const useCompleteXeroAuth = (
  options?: RQUseMutationOptions<CompleteXeroAuthResponse, CompleteXeroAuthParams>,
): RQUseMutationResult<CompleteXeroAuthResponse, CompleteXeroAuthParams> => {
  return useMutation([COMPLETE_XERO_AUTH_MUTATION], completeXeroAuth, options);
};

type CheckXeroAuthParams = {
  schoolId: string;
  vacantTenants?: boolean;
};

type CheckXeroAuthResponse = {
  state: XeroAuthState;
  connected_tenants: XeroTenant[];
};

const checkXeroAuth = async ({
  schoolId,
  vacantTenants,
}: CheckXeroAuthParams): Promise<CheckXeroAuthResponse> => {
  const vacantTenantsParam = vacantTenants !== undefined ? `&vacant_tenants=${vacantTenants}` : '';
  return await api.get(`${INTEGRATION_URL}/xero/check?school_id=${schoolId}${vacantTenantsParam}`);
};

const CHECK_XERO_AUTH_QUERY = `${INTEGRATION_URL}/CHECK_XERO_AUTH_QUERY`;

export const useCheckXeroAuthQuery = (
  params: CheckXeroAuthParams,
  options?: RQUseQueryOptions<CheckXeroAuthResponse>,
) => {
  return useQuery<CheckXeroAuthResponse, ApiError>(
    [CHECK_XERO_AUTH_QUERY, params],
    () => checkXeroAuth(params),
    {
      ...options,
    },
  );
};

export type DisconnectXeroAuthParams = {
  schoolId: string;
  tenant_id: string;
};
export type DisconnectXeroAuthResponse = {
  success: string;
};

const disconnectXeroAuthToken = async ({
  schoolId,
  tenant_id,
}: DisconnectXeroAuthParams): Promise<DisconnectXeroAuthResponse> => {
  return await api.remove(
    `${INTEGRATION_URL}/xero/setup?school_id=${schoolId}&tenant_id=${tenant_id}`,
  );
};

export const useDisconnectXeroAuthTokenMutation = (
  options?: RQUseMutationOptions<DisconnectXeroAuthResponse, DisconnectXeroAuthParams>,
): RQUseMutationResult<DisconnectXeroAuthResponse, DisconnectXeroAuthParams> => {
  return useMutation(disconnectXeroAuthToken, {
    ...options,
  });
};
