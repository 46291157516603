import {
  Box,
  Icon,
  IconButton,
  Link as MuiLink,
  Skeleton,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import {
  Company,
  DefaultPayer,
  StudentProduct,
  useGetSchoolQuery,
  useGetStudentProductsQuery,
  useSendInviteMutation,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolInviteStatus } from '@schooly/constants';
import {
  Attention2Icon,
  Counter,
  DoneIcon,
  EditIcon,
  GridRowCell,
  GridRowItem,
  GridRowName,
  GridRowStyled,
  MailIcon,
  SimpleButton,
  Spin,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { getUserFullName } from '@schooly/utils/user-helpers';
import React, { FC, useCallback } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';
import { Link } from 'react-router-dom';

import AccessDenied from '../../../../components/common/AccessDenied';
import { useProfile } from '../../../../context/profile/useProfile';
import { getRouteModalPathname } from '../../../../helpers/misc';
import getIsAccessDeniedError from '../../../../utils/getIsAccessDeniedError';
import {
  FeesList,
  FeesListEmpty,
  FeesListSkeleton,
} from '../ProfileModalFeesList/ProfileModalFeesList';
import { StudentProductsModalState } from './StudentProductsModal/StudentProductsModalContent';

export const ProfileModalPayers: FC = () => {
  const { $t } = useIntl();
  const { schoolMembership, user } = useProfile();
  const navigate = useNavigate();
  const { permissions, schoolId = '' } = useAuth();
  const canView = permissions.includes('payer_and_product_assignment_viewer');
  const canEdit = permissions.includes('payer_and_product_assignment_manager');

  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    enabled: !!schoolId,
  });

  const { data, error } = useGetStudentProductsQuery(
    {
      relationId: schoolMembership?.relation_id || '',
    },
    { enabled: !!schoolMembership, refetchOnMount: 'always' },
  );

  const handleEditFees = useCallback(() => {
    navigate('payers');
  }, [navigate]);

  const handleNavigateToProduct = useCallback(
    (product: StudentProduct) => {
      navigate(`/settings/products/${product.id}`);
    },
    [navigate],
  );

  if (getIsAccessDeniedError(error) || !canView || !user) {
    return <AccessDenied />;
  }

  const renderFeesList = () => {
    if (!data) return <FeesListSkeleton />;
    if (!data.products.length)
      return <FeesListEmpty onAdd={canEdit ? handleEditFees : undefined} />;

    return (
      <Stack pb={3}>
        <FeesList
          products={data.products}
          payer={data.default_payer}
          company={data.company_payer}
          schoolId={schoolId}
          onRowClick={handleNavigateToProduct}
          currency={currentSchool?.currency}
        />
      </Stack>
    );
  };

  const renderPayers = () => {
    if (!data) return <AdultSkeleton />;
    return (
      <>
        <DefaultPayerRow payer={data.default_payer} schoolId={schoolId} />
        {data.company_payer && <CompanyPayerRow company={data.company_payer} />}
      </>
    );
  };

  return (
    <Stack className="section section-wrapper" gap={3} pb={10}>
      <Stack flexDirection="row" justifyContent="space-between">
        <Typography variant="h2">{$t({ id: 'profile-Payers' })}</Typography>
        {canEdit && (
          <IconButton onClick={() => navigate('payers')} inverse>
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      <Stack>{renderPayers()}</Stack>
      <Stack flexDirection="row" justifyContent="space-between" mt={2}>
        <Stack flexDirection="row" alignItems="center">
          <Typography variant="h2">{$t({ id: 'profile-Products' })}</Typography>
          {!!data?.products.length && (
            <Counter sx={{ minWidth: 20, minHeight: 20, textAlign: 'center' }}>
              {data.products.length}
            </Counter>
          )}
        </Stack>
        {canEdit && (
          <IconButton
            inverse
            onClick={() => navigate(`payers?${StudentProductsModalState.AddProducts}`)}
          >
            <EditIcon />
          </IconButton>
        )}
      </Stack>
      {renderFeesList()}
    </Stack>
  );
};

type WithEmail<T> = T & { email: string };

const isAdultUserWithEmail = <T extends {}>(user: T): user is WithEmail<T> => {
  return (user as WithEmail<T>).email !== undefined;
  return false;
};

const AdultSkeleton: FC = () => (
  <GridRowStyled height={44} sx={{ pointerEvents: 'none' }}>
    <GridRowItem gap={1}>
      <Box minWidth={30} height={30} my={-1}>
        <Skeleton variant="circular" />
      </Box>
      <GridRowName minWidth={0}>
        <Skeleton width="50%" />
      </GridRowName>
      <GridRowCell minWidth={200}>
        <Skeleton width="50%" />
      </GridRowCell>
      <GridRowCell minWidth={120}>
        <Skeleton />
      </GridRowCell>
    </GridRowItem>
  </GridRowStyled>
);

const DefaultPayerRow: FC<{ payer: DefaultPayer; schoolId: string }> = ({ payer, schoolId }) => {
  const { $t } = useIntl();
  const sendInvite = useSendInviteMutation();
  const { showError } = useNotifications();
  const { isLoading } = sendInvite;

  const userInvited = payer?.invite_status === SchoolInviteStatus.Invited;
  const inviteSent = sendInvite.isSuccess;

  const sendInviteHandler = useCallback(() => {
    if (!payer) return;

    sendInvite.mutate(
      { schoolId, relationId: payer.id },
      {
        onError: showError,
      },
    );
  }, [payer, schoolId, sendInvite, showError]);

  const buttonTextId = () => {
    if (isLoading) return 'sending-invite';
    if (inviteSent) return 'inviteStatus-Invited';
    if (userInvited) return 'resend-invite';
    return 'send-invite';
  };

  const buttonIcon = () => {
    if (isLoading) return <Spin />;
    if (inviteSent) return <DoneIcon />;
    return <MailIcon />;
  };

  const name = getUserFullName(payer);

  return (
    <GridRowStyled height={44} sx={{ cursor: 'pointer' }}>
      <GridRowItem gap={1}>
        <AvatarAuth user={payer} sx={{ my: -20 }} />
        <GridRowName minWidth={0} sx={(theme) => ({ display: 'flex', gap: theme.spacing(1) })}>
          <TypographyWithOverflowHint
            variant="h3"
            component={Link}
            to={getRouteModalPathname('parent', payer)}
            sx={{ '&:hover': { textDecoration: 'underline' } }}
          >
            {name}
          </TypographyWithOverflowHint>
          {payer.invite_status !== SchoolInviteStatus.Active && (
            <Tooltip
              title={
                <Stack alignItems="flex-start" gap={1}>
                  <Typography>
                    {$t(
                      {
                        id: userInvited
                          ? 'profile-DidNotAcceptTheInvitation'
                          : 'profile-WasNotInvitedToSchooly',
                      },
                      { name },
                    )}
                  </Typography>
                  <SimpleButton
                    size="small"
                    sx={(theme) => ({
                      '&.Mui-disabled': { color: 'primary.main' },
                    })}
                    disabled={isLoading || inviteSent}
                    startIcon={buttonIcon()}
                    onClick={sendInviteHandler}
                  >
                    {$t({ id: buttonTextId() })}
                  </SimpleButton>
                </Stack>
              }
            >
              <Icon
                sx={{
                  color: 'warning.main',
                  '& .svg-icon': { '& circle, & rect': { color: 'common.white' } },
                }}
              >
                <Attention2Icon />
              </Icon>
            </Tooltip>
          )}
        </GridRowName>
        <GridRowCell minWidth={200}>
          <TypographyWithOverflowHint>
            {isAdultUserWithEmail(payer) ? (
              <MuiLink
                color="text.primary"
                underline="hover"
                sx={{ '.GridRowItem:hover &': { color: 'primary.main' } }}
                href={`mailto:${payer.email}`}
                target="_blank"
              >
                {payer.email}
              </MuiLink>
            ) : (
              '-'
            )}
          </TypographyWithOverflowHint>
        </GridRowCell>
        <GridRowCell pr={1} minWidth={120}>
          <Typography>{payer.telephone ? payer.telephone : '-'}</Typography>
        </GridRowCell>
      </GridRowItem>
    </GridRowStyled>
  );
};

const CompanyPayerRow: FC<{ company: Company }> = ({ company }) => (
  <GridRowStyled height={44} sx={{ cursor: 'pointer' }}>
    <GridRowItem gap={1}>
      <GridRowName minWidth={0}>
        <TypographyWithOverflowHint
          variant="h3"
          component={Link}
          to={`/companies/${company.id}`}
          sx={{ '&:hover': { textDecoration: 'underline' } }}
        >
          {company.name}
        </TypographyWithOverflowHint>
      </GridRowName>
      <GridRowCell minWidth={175}>
        <TypographyWithOverflowHint>{company.contact_name}</TypographyWithOverflowHint>
      </GridRowCell>
      <GridRowCell minWidth={200}>
        <TypographyWithOverflowHint>
          <MuiLink
            color="text.primary"
            underline="hover"
            sx={{ '.GridRowItem:hover &': { color: 'primary.main' } }}
            href={`mailto:${company.email}`}
            target="_blank"
          >
            {company.email}
          </MuiLink>
        </TypographyWithOverflowHint>
      </GridRowCell>
      <GridRowCell pr={1} minWidth={120}>
        <Typography>{company.telephone}</Typography>
      </GridRowCell>
    </GridRowItem>
  </GridRowStyled>
);
