import { IconButton } from '@mui/material';
import {
  DEFAULT_DATE_FORMAT_FNS,
  GroupType,
  GroupWithMembers,
  useGetGroupDetailsQuery,
} from '@schooly/api';
import { CopyIcon, Spin } from '@schooly/style';
import { addYears, format, isBefore, parseISO } from 'date-fns';
import { FC, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import { ContextMenu } from '../../../components/common/ContextMenu/ContextMenu';
import { getCriteriaArchived } from '../../../helpers/misc';
import useSchoolYears from '../../../hooks/useSchoolYears';

interface GroupPreviewModalCloneButtonProps {
  group?: GroupWithMembers;
}

export enum CloneAction {
  CloneWithMembers = 'withMembers',
  CloneForNextYear = 'forNextYear',
}

export const GroupPreviewModalCloneButton: FC<GroupPreviewModalCloneButtonProps> = ({ group }) => {
  const navigate = useNavigate();

  const { nextSchoolYear, getDefaultValidity } = useSchoolYears();
  const { isFetching, refetch } = useGetGroupDetailsQuery(
    { id: group?.id ?? '' },
    { enabled: false },
  );

  const actions = useMemo(() => {
    if (!group) return [];

    const currentYear = getDefaultValidity();
    const currentYearDateFrom = currentYear ? parseISO(currentYear.start) : undefined;

    const currentYearValidity = {
      date_from: currentYear?.start,
      date_to: currentYear?.end,
    };

    const nextYearValidity = {
      date_from: nextSchoolYear?.start,
      date_to: nextSchoolYear?.end,
    };

    return [
      {
        titleTextId: 'group-cloneForNextYear',
        handler: () => {
          const { date_from, date_to, from_school_year, to_school_year } = group.validity;

          const parseAndAddYear = (dateString?: string) =>
            dateString ? addYears(parseISO(dateString), 1) : undefined;

          const clonedDateFrom = parseAndAddYear(date_from || from_school_year?.start);
          const clonedDateTo = parseAndAddYear(date_to || to_school_year?.start);

          const clonedValidity = {
            date_from: clonedDateFrom ? format(clonedDateFrom, DEFAULT_DATE_FORMAT_FNS) : undefined,
            date_to: clonedDateTo ? format(clonedDateTo, DEFAULT_DATE_FORMAT_FNS) : undefined,
          };

          const isClonedValidityStartsThisYearOrAfter =
            clonedDateFrom && currentYearDateFrom && !isBefore(clonedDateFrom, currentYearDateFrom);

          navigate(
            {
              pathname: `/groups/new`,
            },
            {
              state: {
                replace: true,
                initialState: {
                  validity: isClonedValidityStartsThisYearOrAfter
                    ? clonedValidity
                    : nextYearValidity,
                  name: group.name,
                  description: group.description,
                  subject: group.subject?.archived ? undefined : group.subject,
                  limited_to_staff: group.limited_to_staff,
                  limited_to_students: group.limited_to_students,
                  group_type:
                    group.group_type === GroupType.TutorGroup ? group.group_type : undefined,
                },
              },
            },
          );
        },
      },
      {
        titleTextId: 'group-cloneWithMembers',
        handler: async () => {
          const { data } = await refetch();

          if (!data) return;

          const {
            group: { criteria, validity, students, staff },
          } = data;

          const fromDateString = validity.date_from || validity.from_school_year?.start;
          const fromDate = fromDateString ? parseISO(fromDateString) : undefined;

          navigate(
            {
              pathname: `/groups/new`,
            },
            {
              state: {
                replace: true,
                initialState: {
                  validity:
                    fromDate && currentYearDateFrom && !isBefore(fromDate, currentYearDateFrom)
                      ? validity
                      : currentYearValidity,
                  criteria: criteria.filter((criteria) => !getCriteriaArchived(criteria)),
                  students: students,
                  staff: staff,
                  limited_to_staff: group.limited_to_staff,
                  limited_to_students: group.limited_to_students,
                },
              },
            },
          );
        },
      },
    ];
  }, [getDefaultValidity, group, navigate, nextSchoolYear?.end, nextSchoolYear?.start, refetch]);

  if (!group) return null;

  return (
    <ContextMenu actions={actions}>
      {(toggleOpen) => (
        <IconButton
          onClick={toggleOpen}
          disabled={isFetching}
          sx={{ '&&': { color: isFetching ? 'primary.main' : undefined } }}
          data-cy="group-clone-button"
        >
          {isFetching ? <Spin /> : <CopyIcon />}
        </IconButton>
      )}
    </ContextMenu>
  );
};
