import { IconButton, Stack, Typography, TypographyProps } from '@mui/material';
import { DEFAULT_DATE_FORMAT_FNS, SchoolYear } from '@schooly/api';
import { CalendarTodayIcon } from '@schooly/style';
import { endOfMonth, endOfWeek, format, startOfMonth, startOfWeek } from 'date-fns';
import { FC, useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

interface SchoolPeriodSelectListProps {
  onShowListClick: () => void;
  onSetDate: (start: string, end: string) => void;
  defaultSchoolYear?: SchoolYear;
  isThisWeek?: boolean;
  isThisMonth?: boolean;
  isCurrentYear?: boolean;
  isToday?: boolean;
}

export const SchoolPeriodSelectList: FC<SchoolPeriodSelectListProps> = ({
  onShowListClick,
  onSetDate,
  defaultSchoolYear,
  isThisWeek,
  isThisMonth,
  isCurrentYear,
  isToday,
}) => {
  const handleTodayClick = useCallback(() => {
    const today = format(new Date(), DEFAULT_DATE_FORMAT_FNS);
    onSetDate(today, today);
  }, [onSetDate]);

  const handleThisWeekClick = useCallback(() => {
    const start = format(startOfWeek(new Date()), DEFAULT_DATE_FORMAT_FNS);
    const end = format(endOfWeek(new Date()), DEFAULT_DATE_FORMAT_FNS);
    onSetDate(start, end);
  }, [onSetDate]);

  const handleThisMonthClick = useCallback(() => {
    const start = format(startOfMonth(new Date()), DEFAULT_DATE_FORMAT_FNS);
    const end = format(endOfMonth(new Date()), DEFAULT_DATE_FORMAT_FNS);
    onSetDate(start, end);
  }, [onSetDate]);

  const handleDefaultYearClick = useCallback(() => {
    if (defaultSchoolYear) {
      onSetDate(defaultSchoolYear.start, defaultSchoolYear.end);
    }
  }, [defaultSchoolYear, onSetDate]);

  return (
    <Stack
      data-cy={'school-dates-select'}
      sx={(theme) => ({
        flexDirection: 'row',
        alignItems: 'center',
        overflowX: 'scroll',
        justifyContent: 'center',
        flexWrap: 'wrap',
        borderTop: `1px solid ${theme.palette.common.light2}`,
        gap: 3,
        py: 1.5,
      })}
    >
      <Stack
        gap={1}
        direction="row"
        alignItems="center"
        onClick={handleTodayClick}
        sx={(theme) => ({
          cursor: 'pointer',
          '.svg-icon, .MuiTypography-root': {
            color: isToday ? theme.palette.primary.main : theme.palette.common.grey2,
          },
          '&:hover': {
            '.svg-icon, .MuiTypography-root': {
              color: theme.palette.primary.main,
            },
          },
        })}
      >
        <IconButton inverse>
          <CalendarTodayIcon />
        </IconButton>

        <ListItem>
          <FormattedMessage id="datepicker-Today" />
        </ListItem>
      </Stack>
      <ListItem onClick={handleThisWeekClick} color={isThisWeek ? 'primary.main' : 'common.grey2'}>
        <FormattedMessage id="datepicker-ThisWeek" />
      </ListItem>
      <ListItem
        onClick={handleThisMonthClick}
        color={isThisMonth ? 'primary.main' : 'common.grey2'}
      >
        <FormattedMessage id="datepicker-ThisMonth" />
      </ListItem>
      <ListItem
        onClick={handleDefaultYearClick}
        color={isCurrentYear ? 'primary.main' : 'common.grey2'}
      >
        <FormattedMessage id="datepicker-ThisYear" />
      </ListItem>
      <ListItem onClick={onShowListClick}>
        <FormattedMessage id="datepicker-OtherPeriods" />
      </ListItem>
    </Stack>
  );
};

export const ListItem: FC<TypographyProps> = ({ children, ...props }) => (
  <Typography
    sx={(theme) => ({
      cursor: 'pointer',
      '&:hover': {
        color: theme.palette.common.main2,
      },
    })}
    color="common.grey2"
    {...props}
  >
    {children}
  </Typography>
);
