import {
  AttendanceEntriesForDate,
  GroupType,
  useGetAttendanceEntriesForDateQuery,
  useGetGroupDetailsQuery,
} from '@schooly/api';
import { AttendanceEntryForGroup } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useNotifications } from '@schooly/components/notifications';
import moment from 'moment';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { DEFAULT_DATE_FORMAT } from '../../config';
import useQueryStringParams from '../../hooks/useQueryStringParams';

const isAttendanceEntriesForDate = (
  data?: AttendanceEntriesForDate | {},
): data is AttendanceEntriesForDate => {
  return Boolean(data && 'register_id' in data);
};

export const useAttendanceForGroup = ({ refetchOnMount }: { refetchOnMount?: 'always' }) => {
  const { id = '' } = useParams<'id'>();
  const { attendanceDate } = useQueryStringParams();
  const { showError } = useNotifications();
  const { permissions } = useAuth();
  const [query, setQuery] = useState('');
  const [date, setDate] = useState<string>(
    moment(attendanceDate ?? undefined).format(DEFAULT_DATE_FORMAT),
  );

  const isAttendanceViewer = permissions.includes('attendance_viewer');

  const today = moment().format(DEFAULT_DATE_FORMAT);
  const isToday = today === date;

  const {
    data,
    error,
    isLoading: isLoadingEntries,
  } = useGetAttendanceEntriesForDateQuery(
    {
      groupId: id,
      date,
      query,
    },
    {
      enabled: !!id && permissions.includes('attendance_viewer'),
      onError: showError,
      refetchOnMount: refetchOnMount ?? false,
    },
  );

  const entries = isAttendanceEntriesForDate(data) ? data : undefined;

  const entriesMap = useMemo(
    () =>
      entries?.relations.reduce<Record<string, AttendanceEntryForGroup>>((prev, relation) => {
        prev[relation.relation_id] = relation;
        return prev;
      }, {}),
    [entries?.relations],
  );

  const { data: groupDetails, isLoading: isLoadingGroup } = useGetGroupDetailsQuery(
    { id: id ?? '', date, with_absence_request: 1 },
    { enabled: Boolean(id) },
  );

  const canTakeAttendance = Boolean(
    !!groupDetails &&
      entries?.register_id &&
      entries.enterable &&
      groupDetails?.group.group_type === GroupType.TutorGroup &&
      isToday,
  );
  const canEditAttendance = Boolean(
    !!groupDetails &&
      entries?.register_id &&
      entries?.relations?.length &&
      entries.enterable &&
      isToday,
  );

  return {
    groupDetails: groupDetails?.group,
    query,
    setQuery,
    date,
    setDate,
    entries,
    entriesMap,
    students: groupDetails?.group.students.map((student) => student.user) || [],
    canTakeAttendance,
    canEditAttendance,
    fetching: isLoadingGroup || isLoadingEntries,
    error,
    isAttendanceViewer,
  };
};
