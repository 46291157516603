import { Box, Skeleton, Stack, styled, Typography, useTheme } from '@mui/material';
import {
  GetPayableFeesStatisticsParams,
  PayableFeeStatus,
  useGetPayableFeesStatisticsQuery,
  useGetSchoolQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { HashIcon, PercentIcon } from '@schooly/style';
import _ from 'lodash';
import { FC, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { PieChart } from 'react-minimal-pie-chart';

import {
  PercentButton,
  PercentIconButton,
} from '../../../pages/Attendance/AttendanceStatistics/AttendanceStats.styled';
import { payableFeeStatusColor } from './helpers';

type ChartStatistics = {
  label: PayableFeeStatus;
  value: number;
  color: string;
  opacity: number;
  title: string;
};

type PayableFeesChartProps = {
  onCloseChart: () => void;
  columns: number;
  onStatusSelect: (s: PayableFeeStatus) => void;
  selectedStatus?: PayableFeeStatus;
} & GetPayableFeesStatisticsParams;

export const PayableFeesChart: FC<PayableFeesChartProps> = ({
  onCloseChart,
  columns = 3,
  onStatusSelect,
  selectedStatus,
  ...props
}) => {
  const { $t } = useIntl();
  const { schoolId = '' } = useAuth();
  const [showPercents, setShowPercents] = useState(false);
  const { isLoading, data } = useGetPayableFeesStatisticsQuery(props, { refetchOnMount: 'always' });
  const theme = useTheme();
  const shouldShowSkeleton = isLoading;

  const pieChartData = useMemo(
    () =>
      data?.statistics.reduce<ChartStatistics[]>((acc, { label, value }) => {
        if (!value) return acc;
        return [
          ...acc,
          {
            title: $t({ id: `payableFees-status-${label}` }),
            label,
            value: showPercents ? _.round((value / data.total) * 100, 0) : value,
            color: payableFeeStatusColor[label],
            opacity: selectedStatus && label !== selectedStatus ? 0.3 : 1,
          },
        ];
      }, []),
    [$t, data?.statistics, data?.total, selectedStatus, showPercents],
  );

  const { data: currentSchool } = useGetSchoolQuery(schoolId, {
    enabled: !!schoolId,
  });

  if (!isLoading && !data?.total) return null;

  return (
    <PayableFeesCharLayout>
      <Stack
        direction="row"
        alignItems="center"
        sx={{
          py: 2.5,
          pl: 2.5,
          gap: (theme) => theme.spacing(4.5),
        }}
      >
        <Box sx={{ height: (theme) => theme.spacing(16) }}>
          <PieChart
            data={pieChartData || []}
            lineWidth={38}
            background={theme.palette.background.default}
            segmentsStyle={(i) => {
              const item = pieChartData?.[i];
              if (!item || !selectedStatus) return {};
              if (selectedStatus === item.label) return;
              return {
                opacity: 0.3,
              };
            }}
            label={(props) => {
              return (
                // Label accept only svg tags
                <text {...props}>
                  <tspan>{data?.total.toLocaleString() ?? ''}</tspan>
                  <tspan
                    {...props}
                    dy={props.dy + 10}
                    style={{
                      paddingTop: '2px',
                      fontSize: '8px',
                      fill: theme.palette.common.grey2,
                    }}
                  >
                    {currentSchool?.currency ?? ''}
                  </tspan>
                </text>
              );
            }}
            labelStyle={{
              fontSize: theme.typography.h3.fontSize,
              fill: theme.palette.primary.main,
            }}
            labelPosition={0}
          />
        </Box>
        <Stack flexWrap="wrap" height="122px" gap={0.5} columnGap={8.5}>
          {pieChartData?.map(({ title, color, value, label, opacity }) => (
            <Stack
              key={title}
              p={1}
              direction="row"
              alignItems="center"
              sx={{ opacity, cursor: 'pointer' }}
              onClick={() => onStatusSelect(label)}
            >
              <Box
                sx={{
                  width: 10,
                  height: 10,
                  borderRadius: '50%',
                  backgroundColor: color,

                  whiteSpace: 'nowrap',
                }}
              />
              <Stack direction="row" pl={1.5} gap={0.75} alignItems="center">
                <Typography variant="h3" color="text.primary">
                  {title}
                </Typography>
                <Typography variant="h3">
                  {showPercents ? `${value}%` : value.toLocaleString()}
                </Typography>
              </Stack>
            </Stack>
          ))}
          {shouldShowSkeleton &&
            [...new Array(5)].map((_, i) => (
              <Stack key={i} p={1} width={100 / columns + '%'}>
                <Skeleton width="100px" />
              </Stack>
            ))}
        </Stack>
      </Stack>

      <Stack alignSelf="baseline" p={2.5}>
        <PercentButton variant="outlined" onClick={() => setShowPercents((val) => !val)}>
          <PercentIconButton>{showPercents ? <HashIcon /> : <PercentIcon />}</PercentIconButton>
        </PercentButton>
      </Stack>
    </PayableFeesCharLayout>
  );
};

export const PayableFeesCharLayout = styled(Stack)(({ theme }) => ({
  flexDirection: 'row',
  justifyContent: 'space-between',
  background: theme.palette.common.white,
  borderRadius: theme.shape.borderRadius,
}));
