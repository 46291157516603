import { SchoolYear } from '@schooly/api';
import { getReportsForRelation } from '@schooly/api';
import { ApiError, SORT_DIRECTION } from '@schooly/api';
import { Report } from '@schooly/api';
import { useCallback, useEffect, useState } from 'react';

import { IColumnSort } from '../components/ui/ListView';
import updateCancelToken from '../utils/updateCancelToken';
import usePrevious from './usePrevious';
import useSchoolYears from './useSchoolYears';

const cancelTokenSource = {};

export interface UseReportsProps {
  id?: string;
  shouldRequest?: boolean;
  sort?: IColumnSort<keyof Report>[];
}

export const useReportsForRelation = ({ id, shouldRequest = true, sort }: UseReportsProps) => {
  const { isLoading: schoolYearsFetching, defaultValidity } = useSchoolYears();
  const [schoolYear, setSchoolYear] = useState(defaultValidity);
  const [filter, setFilter] = useState('');
  const [count, setCount] = useState(0);
  const [error, setError] = useState<ApiError>();
  const [fetching, setFetching] = useState(false);
  const [reports, setReports] = useState<Report[]>([]);

  const prevFilter = usePrevious(filter);
  const prevSchoolYear = usePrevious(schoolYear);

  const request = useCallback(async () => {
    if (!id || !schoolYear) {
      return;
    }

    if (!filter) {
      setCount(0);
    }

    setFetching(true);

    const defaultSort = [
      { columnTextId: 'report_date', direction: SORT_DIRECTION.DESC },
      { columnTextId: 'name', direction: SORT_DIRECTION.ASC },
    ] as IColumnSort<keyof Report | 'report_date'>[];

    try {
      const response = await getReportsForRelation({
        relationId: id,
        dateFrom: schoolYear.start,
        dateTo: schoolYear.end,
        query: filter,
        sort: sort ?? defaultSort,
        token: updateCancelToken(cancelTokenSource),
      });

      setReports(response ?? []);

      if (!filter) {
        setCount(response?.length ?? 0);
      }
    } catch (err) {
      setError(err as ApiError);
    }

    setFetching(false);
  }, [filter, id, schoolYear, sort]);

  const handleFilterChange = useCallback((value: string) => {
    setFilter(value);
  }, []);

  const handleSchoolYearChange = (schoolYear: SchoolYear) => {
    setSchoolYear(schoolYear);
  };

  useEffect(() => {
    if (shouldRequest && (prevFilter !== filter || prevSchoolYear !== schoolYear)) {
      request();
    }
  }, [filter, prevFilter, prevSchoolYear, request, schoolYear, shouldRequest]);

  useEffect(() => {
    if (defaultValidity) {
      setSchoolYear(defaultValidity);
    }
  }, [defaultValidity]);

  return {
    reports,
    count,
    filter,
    schoolYear,
    fetching,
    error,
    schoolYearsFetching,
    request,
    handleSchoolYearChange,
    handleFilterChange,
  };
};
