import {
  Currencies,
  SchoolEducationSystem,
  SchoolPropertyType,
  SchoolType,
  SchoolUserRole,
} from '@schooly/constants';

import { NumberOrNull, StringOrNull } from './misc';
import { WithAddress } from './partials';
import { RegistrationStatus } from './registrations';

export enum SchoolVerificationStatus {
  NotRequested = 'not-requested',
  Pending = 'pending',
  Verified = 'verified',
}

export interface School extends WithAddress {
  id: string;
  code: string;
  name: string;
  image?: StringOrNull;
  is_public_listing?: boolean;
  verification?: boolean;
  verification_status?: SchoolVerificationStatus;
  listed?: boolean;
  description?: StringOrNull;
  telephone?: StringOrNull;
  website?: StringOrNull;
  contact_email?: StringOrNull;
  type?: SchoolType;
  education_system?: SchoolEducationSystem | null;
  currency?: Currencies;
}

export type DirectorySchoolItem<T> = { label: T; id: string };
export interface DirectorySchool extends School {
  curriculums: Array<DirectorySchoolItem<string>>;
  languages: {
    primary: Array<DirectorySchoolItem<string>> | null;
    taught: Array<DirectorySchoolItem<string>> | null;
  };
  students_age: Array<DirectorySchoolItem<number>> | null;
  fees: StringOrNull;
  students_count: NumberOrNull;
  foundation_year: StringOrNull;
  students_teacher_ratio: StringOrNull;
  accreditation: StringOrNull;
  school_type: StringOrNull;
}

export interface SchoolYearPeriod {
  id: string;
  name: string;
  date_from: string;
  date_to: string;
}

export interface SchoolYearPeriodGroup {
  id: string;
  name: string;
  should_publish_events: boolean;
  periods: Array<SchoolYearPeriod>;
}

export type SchoolYearInUse = {
  periods: number;
  groups: number;
  registrations_with_current_status: {
    applies_from: null | string;
    count: number;
  };
  has_started: boolean;
  related_registrations: number;
};
export interface SchoolYear {
  id: string;
  name: string;
  start: string;
  end: string;
  can_be_deleted?: boolean;
  period_groups?: Array<SchoolYearPeriodGroup>;
  in_use_info: SchoolYearInUse;
}

export interface DefaultSchoolYear extends SchoolYear {
  isActual?: boolean;
}

export interface SchoolYearUpdate {
  // `school_year_id` is used to update existing school year by id
  school_year_id: string;
  name: string;
  start: string;
  end: string;
}

export interface SchoolPropertyCategory {
  name: string;
  order: number;
  user_type: SchoolUserRole;
  current: boolean;
  final: boolean;
  id?: string;
}

export const PENDING_PARENT_RESPONSE = 'Pending parent response' as const;
export const EXPIRED_RE_ENROLLMENT = 'Expired re-enrollment' as const;

type ReEnrollmentSource = {
  type: 're_enrollment';
  read_only: boolean;
};

export interface SchoolProperty {
  id: string;
  type: SchoolPropertyType;
  name: string;
  order: number;
  archived?: boolean;
  category?: SchoolPropertyCategory | null;
  student_default?: boolean;
  staff_default?: boolean;
  group_default?: boolean;
  conduct_default?: boolean;
  re_enrollment_default?: boolean;
  source?: ReEnrollmentSource | null;
}
export interface Registration {
  id: string;
  initial: boolean;
  school_properties: SchoolProperty[];
  school_year: SchoolYear;
  statuses: RegistrationStatus[];
}

export interface SchoolLevel {
  id: string;
  name: string;
  order: number;
}
export interface AgeGroup {
  id: string;
  name: string;
  order: number;
  archived?: boolean;
  level_id: string | null;
  student_default?: boolean;
  staff_default?: boolean;
  group_default?: boolean;
  conduct_default?: boolean;
}

export enum DemoSchoolType {
  UK = 'UK',
  BR = 'BR',
}
