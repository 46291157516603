import { Icon, Skeleton, Stack } from '@mui/material';
import {
  ASSESSMENTS_FILTER_KEYS,
  FilterKeys,
  FilterSection,
  GetAssessmentsQueryFilters,
  useGetAssessmentsGradesForSchoolQuery,
  useGetFiltersQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useChartOpenFromSearchParams } from '@schooly/components/charts';
import { PageHeader, pickOnlyParamsFromFilterKeys } from '@schooly/components/filters';
import {
  GridBody,
  GridHead,
  MainPageGrid,
  MarkIcon,
  RowSkeleton,
  SettingsIcon,
  SkeletonRows,
} from '@schooly/style';
import React, { FC, useCallback, useMemo } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../components/common/AccessDenied';
import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import MainLayout from '../../components/uikit-components/MainLayout/MainLayout';
import { useSchool } from '../../hooks/useSchool';
import AssessmentsContent, { PAGE_SIZE, SKELETON_COLS } from './AssessmentsContent';

export const AssessmentsPage: FC = () => {
  const { permissions, relationId = '', schoolId = '' } = useAuth();
  const { isFetching: isSchoolFetching } = useSchool();
  const {
    data: grades,
    isLoading: isLoadingGrades,
    error,
  } = useGetAssessmentsGradesForSchoolQuery(schoolId, { refetchOnMount: 'always' });

  const { data: filters, isLoading: isLoadingFilters } = useGetFiltersQuery(
    { schoolId, relationId, section: FilterSection.Assessment },
    { enabled: !!schoolId && !!relationId },
  );

  const navigate = useNavigate();
  const { $t } = useIntl();

  const canConfigureAssessments =
    permissions.includes('assessment_manager') && permissions.includes('school_admin');

  const isConfigured = !!grades?.length;

  const onConfigurationClick = useCallback(() => {
    navigate('/settings/assessments');
  }, [navigate]);

  const initialFilters: GetAssessmentsQueryFilters | undefined = useMemo(() => {
    if (isLoadingFilters) return;

    const defaultFilter = filters?.find((f) => f.is_default);

    if (defaultFilter) {
      return pickOnlyParamsFromFilterKeys(ASSESSMENTS_FILTER_KEYS, defaultFilter.filter);
    }

    return {
      [FilterKeys.Staff]: relationId ? [relationId] : [],
    };
  }, [filters, isLoadingFilters, relationId]);

  const isChartsOpen = useChartOpenFromSearchParams();

  const renderContent = () => {
    if (isLoadingGrades || !initialFilters) {
      return (
        <Stack gap={2}>
          <Stack gap={1}>
            <PageHeader pageTitleTextId="section-Assessments">
              <Skeleton variant="rectangular" sx={{ maxWidth: 660 }} />
            </PageHeader>
            <Stack direction="row" alignItems="center" gap={2}>
              <Icon
                className="mark-icon"
                sx={(theme) => ({ width: 30, color: theme.palette.common.grey })}
              >
                <MarkIcon />
              </Icon>
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Skeleton
                variant="rectangular"
                width={100}
                height={26}
                sx={{ '&&': { borderRadius: 13 } }}
              />
              <Stack height={30} justifyContent="center">
                <Skeleton variant="rectangular" width={60} height={26} />
              </Stack>
            </Stack>
          </Stack>
          <MainPageGrid>
            <GridHead borderBottom>
              <RowSkeleton columnsCount={SKELETON_COLS} />
            </GridHead>
            <GridBody>
              <SkeletonRows columnsCount={SKELETON_COLS} amount={PAGE_SIZE} />
            </GridBody>
          </MainPageGrid>
        </Stack>
      );
    }

    if (error) {
      return <AccessDenied />;
    }

    if (!isSchoolFetching && !isConfigured) {
      return (
        <NoListItemsStub
          titleText={$t({ id: 'moduleNotConfigured' })}
          type="assessment"
          subTitleText={
            canConfigureAssessments
              ? $t({ id: 'assessments-CreateGradeSystem' })
              : $t({ id: 'assessments-ContactSchoolAdministrator' })
          }
          buttonText={canConfigureAssessments && $t({ id: 'assessments-AssessmentsSettings' })}
          buttonIcon={canConfigureAssessments && <SettingsIcon />}
          onButtonClick={onConfigurationClick}
        />
      );
    }

    return <AssessmentsContent isChartsOpen={isChartsOpen} initialFilters={initialFilters} />;
  };

  return <MainLayout>{renderContent()}</MainLayout>;
};
