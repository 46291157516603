import { IconButton, MenuItem, MenuList, Stack, Typography } from '@mui/material';
import { CrossIcon, EditIcon, Loading, LockIcon, ModalSearch } from '@schooly/style';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import { CollapsiblePanel } from '../../../components/common/CollapsiblePanel';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { LongTitleVerticalTooltip } from '../../../components/uikit/LongTitleVerticalTooltip/LongTitleVerticalTooltip';
import { GridContainer } from '../../../components/uikit-components/Grid/Grid';
import {
  ModalLarge,
  ModalMain,
  ModalSideBar,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { ModalHeaderV2 } from '../../../components/uikit-components/Modal/ModalHeaderV2';
import {
  UserRoleModalProps,
  useUserRoleModal,
} from '../CreateUserRoleModal/Context/useUserRoleModal';
import { UserRolePermissions } from '../CreateUserRoleModal/UserRolePermissions/UserRolePermissions';
import { UserRoleStaffGrid } from './UserRoleStaffGrid';

enum SidebarTabs {
  Permissions = 'permissions',
  Staff = 'staff',
}

const PermissionsEditButton: FC<{
  roleId?: string;
  section: string;
  assign?: boolean;
  routePath?: string;
}> = ({ roleId, section, assign, routePath = '/userroles' }) => {
  const navigate = useNavigate();

  const editUrl = `${routePath}/${roleId}/` + (assign ? 'assign' : `edit?section=${section}`);

  return (
    <IconButton inverse onClick={() => navigate(editUrl)}>
      <EditIcon />
    </IconButton>
  );
};

export const UserRolePreviewModal: FC<UserRoleModalProps> = (props) => {
  const [searchParams] = useSearchParams();
  const tab = searchParams.get('tab') ?? SidebarTabs.Permissions;
  const {
    isFetching,
    canManageRole,
    roleIsEditable,
    isUserRoleManager,
    isUserRoleViewer,
    isPermissionChecked,
    hasPermissionToAssign,
    roleId,
    actions,
    ...state
  } = useUserRoleModal({ core: true, ...props });
  const hasAccess = isUserRoleViewer;

  const [filter, setFilter] = useState('');
  const [filteredStaff, setFilteredStaff] = useState(state.selectedStuff);
  const { $t } = useIntl();

  useEffect(() => {
    if (!filter) {
      setFilteredStaff(state.selectedStuff);

      return;
    }

    const searchRegExp = new RegExp(filter, 'i');
    const filtered = state.selectedStuff?.filter(
      ({ given_name, last_name }) => `${given_name} ${last_name}`.search(searchRegExp) >= 0,
    );

    setFilteredStaff(filtered);
  }, [filter, state.selectedStuff]);

  const renderContent = useCallback(() => {
    if (!hasAccess && !isFetching) {
      return (
        <>
          <ModalHeader withGoBack active>
            <IconButton onClick={actions.closeModal}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <AccessDenied />
        </>
      );
    }
    return (
      <>
        <ModalHeaderV2
          active
          subHeader={
            <Stack overflow="hidden" minHeight={0} width="100%">
              <LongTitleVerticalTooltip
                renderTooltipContent={(longName) => (
                  <Typography color="text.primary">{longName}</Typography>
                )}
                getOffset={(height) => [-8, -(height + 12)]}
              >
                {state.description ?? ''}
              </LongTitleVerticalTooltip>
            </Stack>
          }
          title={
            <Stack direction="row" gap={1}>
              {state.roleName}
              {!roleIsEditable && (
                <IconButton inverse>
                  <LockIcon />
                </IconButton>
              )}
            </Stack>
          }
        >
          {canManageRole && (
            <PermissionsEditButton
              roleId={roleId}
              section="permissions"
              routePath={props.routePath}
            />
          )}

          <IconButton onClick={actions.closeModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeaderV2>

        <ModalMain>
          <CollapsiblePanel>
            <ModalSideBar
              sx={{
                paddingTop: (theme) => theme.spacing(1),
              }}
            >
              <MenuList>
                <Link to={{ search: `?tab=${SidebarTabs.Permissions}` }} replace>
                  <MenuItem selected={tab === SidebarTabs.Permissions}>
                    <FormattedMessage id="userRoles-Permissions" />
                  </MenuItem>
                </Link>
                <Link to={{ search: `?tab=${SidebarTabs.Staff}` }} replace>
                  <MenuItem selected={tab === SidebarTabs.Staff}>
                    <FormattedMessage id="userRoles-Staff" />
                  </MenuItem>
                </Link>
              </MenuList>
            </ModalSideBar>
          </CollapsiblePanel>

          <Stack px={2.5} pt={2.5} pb={3.75} width="100%" gap={3}>
            {tab === SidebarTabs.Permissions && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Typography variant="h2">
                    <FormattedMessage id="userRoles-Permissions" />
                  </Typography>
                  {canManageRole && (
                    <PermissionsEditButton
                      roleId={roleId}
                      section="permissions"
                      routePath={props.routePath}
                    />
                  )}
                </Stack>
                <GridContainer>
                  <UserRolePermissions
                    editable={false}
                    isPermissionChecked={isPermissionChecked}
                    onSelectPermission={actions.setSelectedPermissions}
                    cellProps={{ borderTop: true, borderBottom: false }}
                  />
                </GridContainer>
              </>
            )}

            {tab === SidebarTabs.Staff && (
              <>
                <Stack direction="row" justifyContent="space-between">
                  <Stack direction="row" alignItems="center">
                    <Typography variant="h2">
                      <FormattedMessage id="userRoles-Staff" />
                    </Typography>
                    <Counter>{state.selectedStuff?.length}</Counter>
                  </Stack>

                  <Stack direction="row" alignItems="center" gap={2}>
                    <ModalSearch
                      value={filter}
                      onChange={setFilter}
                      placeholder={$t({ id: 'people-Search' })}
                    />

                    {(isUserRoleManager || hasPermissionToAssign) && (
                      <PermissionsEditButton
                        roleId={roleId}
                        section="staff"
                        assign={!roleIsEditable}
                        routePath={props.routePath}
                      />
                    )}
                  </Stack>
                </Stack>
                <UserRoleStaffGrid staff={filteredStaff} />
              </>
            )}
          </Stack>
        </ModalMain>
      </>
    );
  }, [
    $t,
    actions.closeModal,
    actions.setSelectedPermissions,
    canManageRole,
    filter,
    filteredStaff,
    hasAccess,
    hasPermissionToAssign,
    isFetching,
    isPermissionChecked,
    isUserRoleManager,
    props.routePath,
    roleId,
    roleIsEditable,
    state.description,
    state.roleName,
    state.selectedStuff?.length,
    tab,
  ]);

  return (
    <ModalLarge open onClose={actions.closeModal}>
      {isFetching ? <Loading /> : renderContent()}
    </ModalLarge>
  );
};
