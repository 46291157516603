import { Spin } from '@schooly/style';
import React, { PropsWithChildren, useCallback, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import buildClassName from '../../../utils/buildClassName';

import './index.scss';

type PickedButtonProps = Pick<React.ButtonHTMLAttributes<HTMLButtonElement>, 'type' | 'onClick'>;

export type ButtonColor =
  | string
  | 'primary'
  | 'secondary'
  | 'white'
  | 'secondary-action'
  | 'table-action'
  | 'remove-action'
  | 'white-text'
  | 'none';

interface IProps extends PropsWithChildren<PickedButtonProps> {
  color?: ButtonColor;
  isDisabled?: boolean;
  isOutline?: boolean;
  isCustomShape?: boolean;
  size?: 'default' | 'x-small' | 'small' | 'large';
  icon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  counter?: number;
  className?: string;
  isLoading?: boolean;
  onlyLoadingIcon?: boolean;
  titleTextId?: string;
  shouldBlurOnClick?: boolean;
  testId?: string;
}

const Button: React.FC<IProps> = ({
  type,
  isDisabled,
  onClick,
  color,
  isOutline,
  isCustomShape,
  size,
  icon,
  rightIcon,
  counter,
  className,
  isLoading,
  onlyLoadingIcon,
  titleTextId,
  shouldBlurOnClick,
  children,
  testId,
}) => {
  const { formatMessage } = useIntl();

  const displayedBeforeIcon: React.ReactNode = useMemo(
    () => (isLoading && !rightIcon ? <Spin /> : icon),
    [icon, isLoading, rightIcon],
  );
  const displayAfterIcon: React.ReactNode = useMemo(
    () => (isLoading && rightIcon ? <Spin /> : rightIcon),
    [isLoading, rightIcon],
  );
  const isIconOnly = Boolean((displayedBeforeIcon || displayAfterIcon || counter) && !children);

  const fullClassName = buildClassName(
    'Button btn',
    `btn-${isOutline ? 'outline-' : ''}${color}`,
    size === 'x-small' && 'btn-xsm',
    size === 'small' && 'btn-sm',
    size === 'large' && 'btn-lg',
    !isCustomShape && 'rounded',
    isIconOnly && 'btn-icon-only',
    className,
  );

  const contents = useMemo(() => {
    if (isIconOnly) {
      return displayedBeforeIcon || displayAfterIcon;
    }

    const loadingContent = onlyLoadingIcon ? null : <FormattedMessage id="pleaseWait" />;

    return (
      <>
        {displayedBeforeIcon && <span className="btn-icon">{displayedBeforeIcon}</span>}
        <div className="btn-inner">
          <span className="btn-content">{isLoading ? loadingContent : children}</span>
          {displayAfterIcon && <span className="btn-icon">{displayAfterIcon}</span>}
          {!!counter && <span className="btn-counter">{counter}</span>}
        </div>
      </>
    );
  }, [
    children,
    counter,
    displayAfterIcon,
    displayedBeforeIcon,
    isIconOnly,
    isLoading,
    onlyLoadingIcon,
  ]);

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      if (!onClick) {
        return;
      }

      if (shouldBlurOnClick) {
        e.currentTarget.blur();
      }

      onClick(e);
    },
    [shouldBlurOnClick, onClick],
  );

  return (
    // ESLint rule disabled for questionable handling of dynamic typing
    // eslint-disable-next-line react/button-has-type
    <button
      type={type}
      className={fullClassName}
      disabled={isLoading || isDisabled}
      title={titleTextId && formatMessage({ id: titleTextId })}
      aria-label={titleTextId && formatMessage({ id: titleTextId })}
      onClick={handleClick}
      data-cy={testId}
    >
      {contents}
    </button>
  );
};

Button.defaultProps = {
  color: 'primary',
  size: 'default',
};

export default Button;
