/*
 * Hook fixes broken cases when a user clicks on an applink in an email message.
 * The applink allows users to tap a link to website and get seamlessly
 * redirected to installed app without going through browser.
 *
 * Broken cases:
 * 1. The email client broke the link.
 * 2. The domain didn't pass the verification process
 *
 * As a solution to the problem:
 * we simply redirect the misguided user to the link that contains the application schema.
 *
 * Currently, the hook only works with the two pathnames that exist
 * in Schooly emails: '/accept-invite', '/reset-password',
 *
 * If the application scheme is not detected then you will see an error in browser console:
 * «Failed to launch 'schooly://accept-invite?invite_id={inviteId}'
 *  because the scheme does not have a registered handler.»
 */

import { useEffect } from 'react';

import useAppLocation from '../hooks/useAppLocation';
import { isAndroid, isIOS } from '../utils/detectDevice';

const appLinks = new Set([
  '/accept-invite',
  '/reset-password',
  '/auth/accept-invite',
  '/auth/reset-password',
  '/workspace/inbox/notifications',
  '/workspace/inbox/messages',
]);
const APP_SCHEME = 'schooly';

export const useAppLinkRedirect = () => {
  const location = useAppLocation();

  useEffect(() => {
    const isAppLink = appLinks.has(location.pathname);
    const isMobile = isIOS() || isAndroid();

    if (isAppLink && isMobile) {
      if (typeof window !== `undefined`) {
        window.location.assign(`${APP_SCHEME}:/${location.pathname}${location.search}`);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};

export default useAppLinkRedirect;
