// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { AUTH_APP_BASE_PATH } from 'apps/auth/src/config';

export const SERVER = import.meta.env.VITE_SERVER;
export const AUTH_APP_URL =
  import.meta.env.VITE_OVERRIDE_AUTH_APP_URL || `${window.location.origin}${AUTH_APP_BASE_PATH}`;
export const APP_DOMAIN = 'https://app.schooly-app.com';
export const DEBUG = import.meta.env.VITE_DEBUG;
export const IS_DEMO = import.meta.env.VITE_DEMO === 'true';
export const IS_DEV = import.meta.env.DEV;
export const SENTRY_DSN = import.meta.env.VITE_SENTRY_DSN;
export const ENV_TYPE = import.meta.env.VITE_ENV_TYPE;

// Capitalized keys are expected for amplify config
export const AWS_CONFIG = {
  Auth: {
    mandatorySignIn: true,
    region: import.meta.env.VITE_AWS_REGION,
    userPoolId: import.meta.env.VITE_COGNITO_IDENTITY_USER_POOL_ID,
    userPoolWebClientId: import.meta.env.VITE_COGNITO_IDENTITY_USER_POOL_CLIENT_ID,
    identityPoolId: import.meta.env.VITE_COGNITO_IDENTITY_POOL,
    cookieStorage: {
      domain: import.meta.env.VITE_ORIGIN,
      sameSite: IS_DEV ? 'lax' : 'none',
      secure: !IS_DEV,
    },
  },
  Storage: {
    bucket: import.meta.env.VITE_PUBLIC_UPLOADS_S3_BUCKET,
    region: import.meta.env.VITE_AWS_REGION,
  },
};

export const DEFAULT_DATE_FORMAT = 'YYYY-MM-DD';

export const {
  VITE_DATE: BUILD_DATE,
  VITE_COMMIT_SHA: COMMIT_SHA,
  VITE_LANGUAGE: LANGUAGE,
} = import.meta.env;

export const FORSED_POPOVER_ZINDEX = 1400;

export const MUI_PRO_LICENSE_KEY =
  '89da4285d6698ea82f350e18a70f8795Tz04NDE0MixFPTE3Mzk0MjIwMTYwMDAsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=';
