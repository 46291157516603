import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { GENDER_OPTIONS } from '@schooly/constants';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  pickFormFieldsFromUser,
  UserFormData,
} from '../../../components/common/ProfileModal/profileEditSchema';
import { CheckboxArray } from '../../../components/ui/Input';
import { useProfile } from '../../../context/profile/useProfile';
import IntlError from '../../../utils/intlError';
import { PropertyModal } from './PropertyModal';

interface GenderModalProps {
  onClose: () => void;
  isOpen: boolean;
}

const GenderModal = ({ onClose, isOpen }: GenderModalProps) => {
  const { showNotification, showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);

  const { user, actions } = useProfile();

  const form = useForm<UserFormData>({
    mode: 'onChange',
    defaultValues: {
      gender: user?.gender,
    },
  });

  const handleSubmit = useCallback(
    async ({ gender }: UserFormData) => {
      try {
        setIsUpdating(true);

        const res = await actions.updateProfile({ gender: String(gender) === '' ? null : gender });
        if (!res) return;

        await actions.handleUserUpdate();

        showNotification({
          textId: 'confirmation-Gender',
          type: 'success',
        });
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);

        onClose();
      }
    },
    [actions, showNotification, showError, onClose],
  );

  const { reset } = form;

  useEffect(() => {
    if (user) {
      reset(pickFormFieldsFromUser(user));
    }
  }, [user, reset]);

  return (
    <PropertyModal
      isOpen={isOpen}
      user={user}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <CheckboxArray options={GENDER_OPTIONS} name="gender" className="check-array" />
    </PropertyModal>
  );
};

export default GenderModal;
