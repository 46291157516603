import {
  FilterValue,
  Group,
  RelationGroup,
  SchoolUserRelationInBaseUser,
  SchoolYear,
  SyncUser,
  UserType,
} from '@schooly/api';
import { createContext } from 'react';

export enum ManageGroupModalMode {
  Individual,
  ByCriteria,
}

export interface ManageRelationGroupContextState {
  mode: ManageGroupModalMode;
  filterRelatedGroupsParams: {
    subjects?: FilterValue[];
    onlyTutorGroups?: FilterValue[];
    searchQuery?: string;
  };
  relatedGroups: Group[];
  isFetching: boolean;
  isRelatedGroupsFetching: boolean;
  isSaving: boolean;
  currentGroups: RelationGroup[];
  removedGroups: Group[];
  user?: SyncUser;
  schoolMembership?: SchoolUserRelationInBaseUser;
  schoolYear?: SchoolYear;
  userType?: UserType;
  active: boolean;
  init?: boolean;
}

export const CONTEXT_NAME = 'ManageRelationGroup';

export const getInitialState = (): ManageRelationGroupContextState => ({
  isFetching: false,
  mode: ManageGroupModalMode.Individual,
  user: undefined,
  userType: undefined,
  schoolMembership: undefined,
  schoolYear: undefined,
  filterRelatedGroupsParams: {},
  relatedGroups: [],
  currentGroups: [],
  removedGroups: [],
  active: true,
  isSaving: false,
  isRelatedGroupsFetching: false,
  init: false,
});

export interface ManageRelationGroupProps extends ManageRelationGroupContextState {
  actions: {
    setMode: (mode: ManageGroupModalMode) => void;
    addGroup: (group: Group) => void;
    removeGroup: (relationGroup: RelationGroup) => void;
    setDefaultFilter: (subjects: FilterValue[], only_tutor_groups: FilterValue[]) => void;
    closeModal: () => void;
    filterRelatedGroups: (
      params: ManageRelationGroupContextState['filterRelatedGroupsParams'],
    ) => void;
    saveGroups: (groups: RelationGroup[]) => void;
  };
}

export const ManageRelationGroupContext = createContext<ManageRelationGroupProps>({
  ...getInitialState(),

  actions: {
    setMode: () => {},
    addGroup: () => {},
    removeGroup: () => {},
    closeModal: () => {},
    filterRelatedGroups: () => {},
    setDefaultFilter: () => {},
    saveGroups: () => {},
  },
});

ManageRelationGroupContext.displayName = CONTEXT_NAME;
