import { IconButton } from '@mui/material';
import { CrossIcon, Loading, ModalLarge } from '@schooly/style';
import React, { FC } from 'react';

import AccessDenied from '../../../components/common/AccessDenied';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { useRouter } from '../../../context/router/useRouter';
import { WithDataGridHover } from '../../../context/table/dataGridHover/WithDataGridHover';
import { useAssessmentsMarkbook } from '../../../hooks/useAssessmentsMarkbook';
import { AssessmentMarkbookExport } from './AssessmentMarkbookExport';
import { AssessmentMarkbookGrid } from './AssessmentMarkbookGrid';

export const AssessmentMarkbookModal: FC = () => {
  const { goBack } = useRouter();
  const { group, assessments, students, lists, entries, dateFrom, dateTo, fetching, error } =
    useAssessmentsMarkbook();

  return (
    <ModalLarge onClose={goBack} open>
      {error && !fetching ? (
        <AccessDenied />
      ) : (
        <>
          {!students || !group || fetching ? (
            <Loading />
          ) : (
            <>
              <ModalHeader title={group.name} active>
                <AssessmentMarkbookExport />

                <IconButton onClick={goBack}>
                  <CrossIcon />
                </IconButton>
              </ModalHeader>
              <WithDataGridHover>
                <AssessmentMarkbookGrid
                  groupId={group.id}
                  assessments={assessments}
                  students={students}
                  lists={lists}
                  entries={entries}
                  dateFrom={dateFrom}
                  dateTo={dateTo}
                />
              </WithDataGridHover>
            </>
          )}
        </>
      )}
    </ModalLarge>
  );
};
