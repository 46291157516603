import { Box, Typography } from '@mui/material';
import {
  AssessmentForRelation,
  AssessmentMethodType,
  AssessmentsGrade,
  ReportForAssessment,
  SORT_DIRECTION,
  StudentSchoolRelation,
  useGetAssessmentsForRelationQuery,
  useGetAssessmentsGradesForSchoolQuery,
  useGetStudentMembership,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { useFlag } from '@schooly/hooks/use-flag';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { CommentFilledIcon, Loading, SimpleButton } from '@schooly/style';
import React, { FC, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { ListViewAssessments } from '../../../../components/uikit-components/ListViewAssessments/ListViewAssessments';
import { getUserFullName } from '../../../../helpers/users';
import { WithRef } from '../../../../hooks/useWithRef';
import {
  ReportsPreviewEntriesContent,
  ReportsPreviewEntriesHeader,
  ReportsPreviewEntriesStyled,
} from './ReportsPreviewEntries.styled';

export interface ReportsPreviewEntriesProps {
  showEmptyStub: boolean;
  report?: ReportForAssessment;
  recipientId?: string;
}

const PAGE_SIZE = 30;

export const ReportsPreviewEntries: FC<ReportsPreviewEntriesProps> = ({
  recipientId,
  report,
  showEmptyStub,
}) => {
  const { schoolId = '' } = useAuth();

  const { data: grades } = useGetAssessmentsGradesForSchoolQuery(schoolId, {
    refetchOnMount: 'always',
    enabled: !!schoolId,
  });

  const lists = useMemo(() => {
    return grades?.reduce((prev, list) => {
      if (list) {
        prev[list.id] = list;
      }

      return prev;
    }, {} as Record<string, AssessmentsGrade>);
  }, [grades]);

  const { data: recipient } = useGetStudentMembership(
    {
      schoolId,
      id: recipientId || '',
    },
    { enabled: !!recipientId },
  );

  const renderContent = () => {
    if (showEmptyStub) return null;
    if (!recipient || !lists || !report) return <Loading />;

    return (
      <RecepientAssessments
        // Force recalculate memoized table data from cache
        key={recipient.relation_id}
        lists={lists}
        report={report}
        recipient={recipient}
      />
    );
  };

  return <ReportsPreviewEntriesStyled>{renderContent()}</ReportsPreviewEntriesStyled>;
};

type RecepientAssessmentsProps = {
  lists: Record<string, AssessmentsGrade>;
  report: ReportForAssessment;
  recipient: StudentSchoolRelation;
};

const RecepientAssessments: FC<RecepientAssessmentsProps> = ({ report, recipient, lists }) => {
  const [commentsShown, showComments, hideComments] = useFlag(false);
  useEffect(() => {
    // reset showAllComments on swithing between recipients
    hideComments();
  }, [recipient.relation_id, hideComments]);

  const { data, setParams, hasNextPage, isLoading, isFetchingNextPage, fetchNextPage } =
    useGetAssessmentsForRelationQuery(
      {
        relationId: recipient.relation_id,
        reportId: report.id,
        pageSize: PAGE_SIZE,
        sort: [
          { columnTextId: 'subject_order', direction: SORT_DIRECTION.ASC },
          { columnTextId: 'assessment_date', direction: SORT_DIRECTION.DESC },
          { columnTextId: 'group_name', direction: SORT_DIRECTION.ASC },
          // TODO: refine after BE fix
          { columnTextId: 'assessment_name', direction: SORT_DIRECTION.ASC },
        ],
      },
      { refetchOnMount: 'always' },
    );
  const containerRef = useRef<HTMLDivElement>(null);

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const assessments = useMemo(() => {
    return (
      data?.pages.reduce<AssessmentForRelation[]>((prev, curr) => [...prev, ...curr.results], []) ??
      []
    );
  }, [data?.pages]);

  const hasComments = useMemo(
    () =>
      Boolean(
        assessments.some((entry) =>
          entry.methods.some((method) => method.method_type === AssessmentMethodType.Comment),
        ),
      ),
    [assessments],
  );

  useEffect(() => {
    setParams((params) => ({ ...params, reportId: report.id, relationId: recipient.relation_id }));
  }, [report.id, recipient.relation_id, setParams]);

  return (
    <>
      <ReportsPreviewEntriesHeader>
        <Typography variant="h2">
          <FormattedMessage id="reports-Assessments" />
          {` – ${getUserFullName(recipient)}`}
        </Typography>
        {hasComments && (
          <SimpleButton
            startIcon={<CommentFilledIcon />}
            inverse
            onClick={commentsShown ? hideComments : showComments}
          >
            <FormattedMessage
              id={commentsShown ? 'reports-HideAllComments' : 'reports-ShowAllComments'}
            />
          </SimpleButton>
        )}
      </ReportsPreviewEntriesHeader>

      <ReportsPreviewEntriesContent ref={containerRef}>
        {isLoading ? (
          <Loading />
        ) : (
          <WithRef containerRef={containerRef}>
            <ListViewAssessments
              type="for-relation"
              assessments={assessments}
              lists={lists}
              id={recipient.relation_id}
              showAllComments={commentsShown}
              wideComments
              onAllCommentsClosed={hideComments}
              onAllCommentsOpen={showComments}
            />
          </WithRef>
        )}
        {hasNextPage && (
          <>
            {isFetchingNextPage && (
              <Box>
                <Loading />
              </Box>
            )}
            <Box ref={loaderRef} />
          </>
        )}
      </ReportsPreviewEntriesContent>
    </>
  );
};
