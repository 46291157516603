import {
  AssessmentsGradeUpdate,
  AttendanceCode,
  ConductConnotation,
  ConductTypeRepresentation,
  DEFAULT_DATE_FORMAT_FNS,
  UserRolePermissions,
} from '@schooly/api';
import {
  SchoolEducationSystem,
  SchoolKind,
  SchoolPresetsLanguage,
  SchoolPropertyType,
} from '@schooly/constants';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { SCHOOL_PRESETS_BY_LANG_CODE } from '@schooly/utils/translations';
import { addYears, format, isPast, isSameYear, subDays } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';

import { SchoolTuneStatusType } from '../SchoolTune/tabs/statuses/scheme';
import { SchoolCreateForm } from './useSchoolCreate';

const generateAcademyStudentStatuses = (lang: SchoolPresetsLanguage): SchoolTuneStatusType => {
  return {
    prospective: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.applying,
      },
    ],
    current: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.enrolled,
      },
    ],
    former: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.left,
      },
    ],
    unsuccessful: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.cancelled,
      },
    ],
  };
};

const generateAcademyStaffStatuses = (lang: SchoolPresetsLanguage): SchoolTuneStatusType => {
  return {
    prospective: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].staffStatuses.applicant,
      },
    ],
    current: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].staffStatuses.employed,
      },
    ],
    former: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].staffStatuses.left,
      },
    ],
    unsuccessful: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].staffStatuses.unsuccessful,
      },
    ],
  };
};

const generateSchoolSubjects = (
  lang: SchoolPresetsLanguage,
  kind: SchoolKind.Elementary | SchoolKind.High | SchoolKind.ElementaryAndHigh,
) => {
  return SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolSubjects[kind].map((name) => ({ name }));
};

const generateMusicSubjects = (lang: SchoolPresetsLanguage) => {
  return SCHOOL_PRESETS_BY_LANG_CODE[lang].musicSubjects.map((name) => ({ name }));
};

const generateSchoolStudentStatuses = (lang: SchoolPresetsLanguage) => {
  return {
    prospective: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.applying,
        order: 0,
      },
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.placeOffered,
        order: 1,
      },
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.waitingList,
        order: 2,
      },
    ],
    current: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.enrolled,
      },
    ],
    former: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.left,
      },
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.expelled,
      },
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.graduated,
      },
    ],
    unsuccessful: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.rejected,
      },
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].studentStatuses.cancelled,
      },
    ],
  };
};

const generateStaffStudentStatuses = (lang: SchoolPresetsLanguage) => {
  return {
    prospective: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].staffStatuses.applicant,
      },
    ],
    current: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].staffStatuses.employed,
      },
    ],
    former: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].staffStatuses.left,
      },
    ],
    unsuccessful: [
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].staffStatuses.rejected,
      },
      {
        name: SCHOOL_PRESETS_BY_LANG_CODE[lang].staffStatuses.cancelled,
      },
    ],
  };
};

export const SPORTS_AGE_GROUPS = [
  { name: 'U6', order: 1, level_id: null },
  { name: 'U7', order: 2, level_id: null },
  { name: 'U8', order: 3, level_id: null },
  { name: 'U9', order: 4, level_id: null },
  { name: 'U10', order: 5, level_id: null },
  { name: 'U11', order: 6, level_id: null },
  { name: 'U12', order: 7, level_id: null },
  { name: 'U13', order: 8, level_id: null },
  { name: 'U14', order: 9, level_id: null },
  { name: 'U15', order: 10, level_id: null },
  { name: 'U16', order: 11, level_id: null },
  { name: 'U18', order: 12, level_id: null },
];

const generateSchoolDepartments = (lang: SchoolPresetsLanguage) => {
  return [
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.administration,
      type: SchoolPropertyType.Department,
      order: 1,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.academic,
      type: SchoolPropertyType.Department,
      order: 2,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.leadership,
      type: SchoolPropertyType.Department,
      order: 3,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.supportStaff,
      type: SchoolPropertyType.Department,
      order: 4,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.externalStaff,
      type: SchoolPropertyType.Department,
      order: 5,
    },
  ];
};

const generateMusicDepartments = (lang: SchoolPresetsLanguage) => {
  return [
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.administration,
      type: SchoolPropertyType.Department,
      order: 1,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.teaching,
      type: SchoolPropertyType.Department,
      order: 2,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.leadership,
      type: SchoolPropertyType.Department,
      order: 3,
    },
  ];
};

const generateSportsDepartments = (lang: SchoolPresetsLanguage) => {
  return [
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.administration,
      type: SchoolPropertyType.Department,
      order: 1,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.coaching,
      type: SchoolPropertyType.Department,
      order: 2,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].departments.leadership,
      type: SchoolPropertyType.Department,
      order: 3,
    },
  ];
};

export const ROLE_PERMISSIONS = {
  Teacher: [
    'staff_base',
    'student_viewer',
    'parent_viewer',
    'custom_field_viewer',
    'profile_viewer',
    'staff_viewer',
    'group_viewer',
    'message_viewer',
    'message_creator',
    'assessment_viewer',
    'assessment_creator',
    'attendance_viewer',
    'attendance_creator',
    'event_viewer',
    'event_creator',
    'conduct_viewer',
    'conduct_manager',
  ] as UserRolePermissions[],
  Coach: [
    'custom_field_viewer',
    'staff_base',
    'student_viewer',
    'parent_viewer',
    'profile_viewer',
    'staff_viewer',
    'group_viewer',
    'group_manager',
    'message_viewer',
    'message_creator',
    'message_publisher',
    'assessment_viewer',
    'assessment_creator',
    'assessment_manager',
    'attendance_viewer',
    'attendance_creator',
    'attendance_manager',
    'conduct_viewer',
    'conduct_manager',
    'event_viewer',
    'event_creator',
    'event_manager',
  ] as UserRolePermissions[],
  Admissions: [
    'application_viewer',
    'application_manager',
    'staff_base',
    'student_viewer',
    'parent_viewer',
    'custom_field_viewer',
    'profile_viewer',
    'student_manager',
    'parent_manager',
    'profile_manager',
    'registration_manager',
    'staff_viewer',
    'group_viewer',
    'group_manager',
    'message_viewer',
    'message_creator',
    'assessment_viewer',
    'attendance_viewer',
    'conduct_viewer',
    'userrole_viewer',
    'event_viewer',
  ] as UserRolePermissions[],
  HR: [
    'staff_base',
    'staff_viewer',
    'custom_field_viewer',
    'profile_viewer',
    'staff_manager',
    'profile_manager',
    'employment_manager',
    'userrole_viewer',
  ] as UserRolePermissions[],
  Administrator: [
    'staff_base',
    'student_viewer',
    'parent_viewer',
    'custom_field_viewer',
    'profile_viewer',
    'student_manager',
    'parent_manager',
    'profile_manager',
    'application_viewer',
    'application_manager',
    'registration_manager',
    'staff_viewer',
    'staff_manager',
    'employment_manager',
    'group_viewer',
    'group_manager',
    'message_viewer',
    'message_creator',
    'message_publisher',
    'assessment_viewer',
    'attendance_viewer',
    'attendance_creator',
    'conduct_viewer',
    'event_viewer',
    'event_creator',
    'event_manager',
    'userrole_viewer',
    'userrole_manager',
    'custom_field_manager',
    'product_and_invoice_manager',
    'payer_and_product_assignment_manager',
  ] as UserRolePermissions[],
  'Leadership team': [
    'custom_field_viewer',
    'staff_base',
    'student_viewer',
    'parent_viewer',
    'profile_viewer',
    'staff_viewer',
    'group_viewer',
    'group_manager',
    'message_viewer',
    'message_creator',
    'message_publisher',
    'assessment_viewer',
    'assessment_creator',
    'assessment_manager',
    'attendance_viewer',
    'attendance_creator',
    'conduct_viewer',
    'conduct_manager',
    'event_viewer',
    'event_creator',
    'event_manager',
  ] as UserRolePermissions[],
  'Message publisher': [
    'staff_base',
    'student_viewer',
    'parent_viewer',
    'custom_field_viewer',
    'profile_viewer',
    'staff_viewer',
    'group_viewer',
    'message_viewer',
    'message_creator',
    'message_publisher',
  ] as UserRolePermissions[],
  'Group manager': ['staff_base', 'group_viewer', 'group_manager'] as UserRolePermissions[],
  'Attendance manager': [
    'staff_base',
    'group_viewer',
    'attendance_viewer',
    'attendance_creator',
    'attendance_manager',
  ] as UserRolePermissions[],
  'Assessments manager': [
    'group_viewer',
    'staff_base',
    'assessment_viewer',
    'assessment_creator',
    'assessment_manager',
  ] as UserRolePermissions[],
  'Event & Sign up manager': [
    'staff_base',
    'group_viewer',
    'event_viewer',
    'event_creator',
    'event_manager',
  ] as UserRolePermissions[],
  'School settings manager': ['school_admin', 'custom_field_manager'] as UserRolePermissions[],
  'Invoicing manager': [
    'staff_base',
    'student_viewer',
    'parent_viewer',
    'custom_field_viewer',
    'profile_viewer',
    'product_and_invoice_manager',
    'payer_and_product_assignment_manager',
  ] as UserRolePermissions[],
};

const generateSchoolRoles = (lang: SchoolPresetsLanguage) => {
  return [
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.teacher,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS.Teacher,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.leadershipTeam,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS['Leadership team'],
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.admissions,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS.Admissions,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.hr,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS.HR,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.messagePublisher,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS['Message publisher'],
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.groupManager,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS['Group manager'],
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.attendanceManager,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS['Attendance manager'],
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.assessmentsManager,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS['Assessments manager'],
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.eventManager,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS['Event & Sign up manager'],
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.settingsManager,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS['School settings manager'],
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.invoicingManager,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS['Invoicing manager'],
    },
  ];
};

const generateMusicRoles = (lang: SchoolPresetsLanguage) => {
  return [
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.teacher,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS.Teacher,
    },

    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.administrator,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS.Administrator,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.settingsManager,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS['School settings manager'],
    },
  ];
};

const generateSportsRoles = (lang: SchoolPresetsLanguage) => {
  return [
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.coach,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS.Coach,
    },

    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.administrator,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS.Administrator,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].schoolRoles.settingsManager,
      description: '',
      permission_to_assign: [],
      permissions: ROLE_PERMISSIONS['School settings manager'],
    },
  ];
};

export const generateSchoolPresets = (
  kind: SchoolKind,
  lang: SchoolPresetsLanguage,
): Pick<
  SchoolCreateForm,
  'staffStatuses' | 'studentStatuses' | 'subjects' | 'userRoles' | 'departments'
> => {
  switch (kind) {
    case SchoolKind.Elementary:
      return {
        staffStatuses: generateStaffStudentStatuses(lang),
        studentStatuses: generateSchoolStudentStatuses(lang),
        subjects: generateSchoolSubjects(lang, kind),
        userRoles: generateSchoolRoles(lang),
        departments: generateSchoolDepartments(lang),
      };
    case SchoolKind.High:
      return {
        staffStatuses: generateStaffStudentStatuses(lang),
        studentStatuses: generateSchoolStudentStatuses(lang),
        subjects: generateSchoolSubjects(lang, kind),
        userRoles: generateSchoolRoles(lang),
        departments: generateSchoolDepartments(lang),
      };
    case SchoolKind.ElementaryAndHigh:
      return {
        staffStatuses: generateStaffStudentStatuses(lang),
        studentStatuses: generateSchoolStudentStatuses(lang),
        subjects: generateSchoolSubjects(lang, kind),
        userRoles: generateSchoolRoles(lang),
        departments: generateSchoolDepartments(lang),
      };
    case SchoolKind.Music:
      return {
        staffStatuses: generateAcademyStaffStatuses(lang),
        studentStatuses: generateAcademyStudentStatuses(lang),
        subjects: generateMusicSubjects(lang),
        userRoles: generateMusicRoles(lang),
        departments: generateMusicDepartments(lang),
      };
    case SchoolKind.Sports:
      return {
        staffStatuses: generateAcademyStaffStatuses(lang),
        studentStatuses: generateAcademyStudentStatuses(lang),
        subjects: [],
        userRoles: generateSportsRoles(lang),
        departments: generateSportsDepartments(lang),
      };
  }
};

type YearStartsFrom = 'jan' | 'aug';

export const getYearStartsFrom = (
  type: SchoolKind,
  educationSystem: SchoolEducationSystem,
): YearStartsFrom => {
  switch (type) {
    case SchoolKind.Elementary:
    case SchoolKind.ElementaryAndHigh:
    case SchoolKind.High:
      if (
        educationSystem === SchoolEducationSystem.AU ||
        educationSystem === SchoolEducationSystem.BR
      ) {
        return 'jan';
      }
      return 'aug';
    default:
      return 'aug';
  }
};

export const getInitialSchoolYears = (type: SchoolKind, educationSystem: SchoolEducationSystem) => {
  const startsFrom = getYearStartsFrom(type, educationSystem);

  return [generateYearStartsFrom(startsFrom)];
};

export const getInitialAgeGroups = (type: SchoolKind, educationSystem: SchoolEducationSystem) => {
  switch (type) {
    case SchoolKind.Sports:
      return SPORTS_AGE_GROUPS;
    case SchoolKind.Music:
      return [];
    default:
      return SCHOOL_AGE_GROUPS[educationSystem][type];
  }
};

export const getInitialSchoolLevels = (
  type: SchoolKind,
  educationSystem: SchoolEducationSystem,
) => {
  switch (type) {
    case SchoolKind.Sports:
      return [];
    case SchoolKind.Music:
      return [];
    default:
      return SCHOOL_LEVELS[educationSystem][type];
  }
};

export const getInitialAssessmentGrades = (
  type: SchoolKind,
  educationSystem: SchoolEducationSystem,
  lang: SchoolPresetsLanguage,
) => {
  switch (type) {
    case SchoolKind.Sports:
      return [];
    case SchoolKind.Music:
      return [];
    default:
      return generateSchoolAssessmentGrades(educationSystem, type, lang);
  }
};

export const getInitialConductTypes = (
  type: SchoolKind,
  educationSystem: SchoolEducationSystem,
  lang: SchoolPresetsLanguage,
) => {
  switch (type) {
    case SchoolKind.Sports:
      return [];
    case SchoolKind.Music:
      return [];
    default:
      return generateSchoolConductTypes(educationSystem, lang);
  }
};

export const getInitialAttendanceCodes = (
  type: SchoolKind,
  educationSystem: SchoolEducationSystem,
  lang: SchoolPresetsLanguage,
) => {
  switch (type) {
    case SchoolKind.Sports:
      return generateAttendanceCodes(lang);
    case SchoolKind.Music:
      return generateAttendanceCodes(lang);
    default:
      return generateSchoolAttendance(educationSystem, lang);
  }
};

export const generateYearStartsFrom = (from: YearStartsFrom, year = new Date().getFullYear()) => {
  let start = newDateTimezoneOffset(from === 'jan' ? `${year}-01-01` : `${year}-08-01`);
  if (isPast(start)) start = addYears(start, 1);

  const end = subDays(addYears(start, 1), 1);
  const sameYear = isSameYear(start, end);

  return {
    name: `${format(start, 'yyyy')}${!sameYear ? `/${format(end, 'yy')}` : ''}`,
    start: format(start, DEFAULT_DATE_FORMAT_FNS),
    end: format(end, DEFAULT_DATE_FORMAT_FNS),
  };
};

const generateAttendanceCodes = (lang: SchoolPresetsLanguage): Omit<AttendanceCode, 'id'>[] => {
  return [
    {
      order: 0,
      code: '**/',
      in_school: true,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.present,
    },
    {
      order: 1,
      code: 'L',
      in_school: true,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.late,
    },
    {
      order: 2,
      code: 'C',
      in_school: false,
      is_present: false,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.authorisedAbsence,
    },
    {
      order: 3,
      code: 'N',
      in_school: false,
      is_present: false,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.unauthorisedAbsence,
    },
    {
      order: 4,
      code: 'M',
      in_school: false,
      is_present: false,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.medical,
    },
    {
      order: 5,
      code: 'B',
      in_school: false,
      is_present: true,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.externalActivity,
    },
  ];
};

const generateExtendedAttendanceCodes = (
  lang: SchoolPresetsLanguage,
): Omit<AttendanceCode, 'id'>[] => {
  return [
    {
      order: 0,
      code: '**/',
      in_school: true,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.present,
    },
    {
      order: 1,
      code: 'L',
      in_school: true,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.tardy,
    },
    {
      order: 2,
      code: 'C',
      in_school: false,
      is_present: false,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.excusedAbsence,
    },
    {
      order: 3,
      code: 'N',
      in_school: false,
      is_present: false,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.unexcusedAbsence,
    },
    {
      order: 4,
      code: 'M',
      in_school: false,
      is_present: false,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.medical,
    },
    {
      order: 5,
      code: 'B',
      in_school: false,
      is_present: true,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.externalActivity,
    },
    {
      order: 6,
      code: 'E',
      in_school: true,
      ...SCHOOL_PRESETS_BY_LANG_CODE[lang].attendanceCodes.earlyDismissal,
    },
  ];
};

const generateSchoolAttendance = (
  educationSystem: SchoolEducationSystem,
  lang: SchoolPresetsLanguage,
): Omit<AttendanceCode, 'id'>[] => {
  switch (educationSystem) {
    case SchoolEducationSystem.UK:
    case SchoolEducationSystem.BR:
      return generateAttendanceCodes(lang);
    case SchoolEducationSystem.US:
    case SchoolEducationSystem.AU:
      return generateExtendedAttendanceCodes(lang);
  }
};

const SCHOOL_LEVELS = {
  [SchoolEducationSystem.UK]: {
    [SchoolKind.Elementary]: [
      { name: 'EYFS', order: 1, id_tmp: uuidv4() },
      { name: 'KS1', order: 2, id_tmp: uuidv4() },
      { name: 'KS2', order: 3, id_tmp: uuidv4() },
    ],
    [SchoolKind.High]: [
      { name: 'KS3', order: 1, id_tmp: uuidv4() },
      { name: 'KS4', order: 2, id_tmp: uuidv4() },
      { name: 'KS5', order: 3, id_tmp: uuidv4() },
    ],
    [SchoolKind.ElementaryAndHigh]: [
      { name: 'EYFS', order: 1, id_tmp: uuidv4() },
      { name: 'KS1', order: 2, id_tmp: uuidv4() },
      { name: 'KS2', order: 3, id_tmp: uuidv4() },
      { name: 'KS3', order: 4, id_tmp: uuidv4() },
      { name: 'KS4', order: 5, id_tmp: uuidv4() },
      { name: 'KS5', order: 6, id_tmp: uuidv4() },
    ],
  },
  [SchoolEducationSystem.US]: {
    [SchoolKind.Elementary]: [
      { name: 'ECEC', order: 1, id_tmp: uuidv4() },
      { name: 'Elementary School', order: 2, id_tmp: uuidv4() },
    ],
    [SchoolKind.High]: [
      { name: 'Middle School', order: 1, id_tmp: uuidv4() },
      { name: 'High School', order: 2, id_tmp: uuidv4() },
    ],
    [SchoolKind.ElementaryAndHigh]: [
      { name: 'ECEC', order: 1, id_tmp: uuidv4() },
      { name: 'Elementary School', order: 2, id_tmp: uuidv4() },
      { name: 'Middle School', order: 3, id_tmp: uuidv4() },
      { name: 'High School', order: 4, id_tmp: uuidv4() },
    ],
  },
  [SchoolEducationSystem.AU]: {
    [SchoolKind.Elementary]: [{ name: 'Primary School', order: 1, id_tmp: uuidv4() }],
    [SchoolKind.High]: [
      { name: 'Secondary School', order: 1, id_tmp: uuidv4() },
      { name: 'Senior Secondary School', order: 2, id_tmp: uuidv4() },
    ],
    [SchoolKind.ElementaryAndHigh]: [
      { name: 'Primary School', order: 1, id_tmp: uuidv4() },
      { name: 'Secondary School', order: 2, id_tmp: uuidv4() },
      { name: 'Senior Secondary School', order: 3, id_tmp: uuidv4() },
    ],
  },
  [SchoolEducationSystem.BR]: {
    [SchoolKind.Elementary]: [
      { name: 'Educação Infantil', order: 1, id_tmp: uuidv4() },
      { name: 'Ensino Fundamental I', order: 2, id_tmp: uuidv4() },
    ],
    [SchoolKind.High]: [
      { name: 'Ensino Fundamental II', order: 1, id_tmp: uuidv4() },
      { name: 'Ensino Médio', order: 2, id_tmp: uuidv4() },
    ],
    [SchoolKind.ElementaryAndHigh]: [
      { name: 'Educação Infantil', order: 1, id_tmp: uuidv4() },
      { name: 'Ensino Fundamental I', order: 2, id_tmp: uuidv4() },
      { name: 'Ensino Fundamental II', order: 3, id_tmp: uuidv4() },
      { name: 'Ensino Médio', order: 4, id_tmp: uuidv4() },
    ],
  },
};

//TODO: Getting levels' ids through indexes is not ideal. To be reconsidered and improved.
const SCHOOL_AGE_GROUPS = {
  [SchoolEducationSystem.UK]: {
    [SchoolKind.Elementary]: [
      {
        name: 'Pre-Nursery',
        order: 1,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Nursery',
        order: 2,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Reception',
        order: 3,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Year 1',
        order: 4,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: 'Year 2',
        order: 5,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: 'Year 3',
        order: 6,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.Elementary][2].id_tmp,
      },
      {
        name: 'Year 4',
        order: 7,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.Elementary][2].id_tmp,
      },
      {
        name: 'Year 5',
        order: 8,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.Elementary][2].id_tmp,
      },
      {
        name: 'Year 6',
        order: 9,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.Elementary][2].id_tmp,
      },
    ],
    [SchoolKind.High]: [
      {
        name: 'Year 7',
        order: 10,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.High][0].id_tmp,
      },
      {
        name: 'Year 8',
        order: 11,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.High][0].id_tmp,
      },
      {
        name: 'Year 9',

        order: 12,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.High][0].id_tmp,
      },
      {
        name: 'Year 10',

        order: 13,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.High][1].id_tmp,
      },
      {
        name: 'Year 11',

        order: 14,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.High][1].id_tmp,
      },
      {
        name: 'Year 12',

        order: 15,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.High][2].id_tmp,
      },
      {
        name: 'Year 13',

        order: 16,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.High][2].id_tmp,
      },
    ],
    [SchoolKind.ElementaryAndHigh]: [
      {
        name: 'Pre-Nursery',
        order: 1,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Nursery',

        order: 2,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Reception',

        order: 3,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Year 1',

        order: 4,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Year 2',
        order: 5,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Year 3',

        order: 6,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: 'Year 4',

        order: 7,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: 'Year 5',
        order: 8,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: 'Year 6',

        order: 9,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: 'Year 7',

        order: 10,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][3].id_tmp,
      },
      {
        name: 'Year 8',

        order: 11,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][3].id_tmp,
      },
      {
        name: 'Year 9',

        order: 12,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][3].id_tmp,
      },
      {
        name: 'Year 10',

        order: 13,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][4].id_tmp,
      },
      {
        name: 'Year 11',

        order: 14,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][4].id_tmp,
      },
      {
        name: 'Year 12',
        order: 15,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][5].id_tmp,
      },
      {
        name: 'Year 13',
        order: 16,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.UK][SchoolKind.ElementaryAndHigh][5].id_tmp,
      },
    ],
  },
  [SchoolEducationSystem.US]: {
    [SchoolKind.Elementary]: [
      {
        name: 'Pre kindergarten 1',
        order: 1,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Pre kindergarten 2',
        order: 2,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Kindergarten',
        order: 3,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Grade 1',
        order: 4,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: 'Grade 2',
        order: 5,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: 'Grade 3',
        order: 6,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: 'Grade 4',
        order: 7,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: 'Grade 5',
        order: 8,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: 'Grade 6',
        order: 9,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.Elementary][1].id_tmp,
      },
    ],
    [SchoolKind.High]: [
      {
        name: 'Grade 7',
        order: 10,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.High][0].id_tmp,
      },
      {
        name: 'Grade 8',
        order: 11,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.High][0].id_tmp,
      },
      {
        name: 'Grade 9',
        order: 12,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.High][1].id_tmp,
      },
      {
        name: 'Grade 10',
        order: 13,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.High][1].id_tmp,
      },
      {
        name: 'Grade 11',
        order: 14,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.High][1].id_tmp,
      },
      {
        name: 'Grade 12',
        order: 15,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.High][1].id_tmp,
      },
    ],
    [SchoolKind.ElementaryAndHigh]: [
      {
        name: 'Pre kindergarten 1',
        order: 1,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Pre kindergarten 2',
        order: 2,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Kindergarten',
        order: 3,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Grade 1',
        order: 4,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Grade 2',
        order: 5,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Grade 3',
        order: 6,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Grade 4',
        order: 7,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Grade 5',
        order: 8,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Grade 6',
        order: 9,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Grade 7',
        order: 10,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: 'Grade 8',
        order: 11,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: 'Grade 9',
        order: 12,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][3].id_tmp,
      },
      {
        name: 'Grade 10',
        order: 13,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][3].id_tmp,
      },
      {
        name: 'Grade 11',
        order: 14,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][3].id_tmp,
      },
      {
        name: 'Grade 12',
        order: 15,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.US][SchoolKind.ElementaryAndHigh][3].id_tmp,
      },
    ],
  },
  [SchoolEducationSystem.AU]: {
    [SchoolKind.Elementary]: [
      {
        name: 'Kindergarten',
        order: 1,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Year 1',
        order: 2,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Year 2',
        order: 3,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Year 3',
        order: 4,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Year 4',
        order: 5,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Year 5',
        order: 6,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Year 6',
        order: 7,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.Elementary][0].id_tmp,
      },
    ],
    [SchoolKind.High]: [
      {
        name: 'Year 7',
        order: 9,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.High][0].id_tmp,
      },
      {
        name: 'Year 8',
        order: 10,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.High][0].id_tmp,
      },
      {
        name: 'Year 9',
        order: 11,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.High][0].id_tmp,
      },
      {
        name: 'Year 10',
        order: 12,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.High][0].id_tmp,
      },
      {
        name: 'Year 11',
        order: 13,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.High][1].id_tmp,
      },
      {
        name: 'Year 12',
        order: 14,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.High][1].id_tmp,
      },
    ],
    [SchoolKind.ElementaryAndHigh]: [
      {
        name: 'Kindergarten',
        order: 1,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Year 1',
        order: 2,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Year 2',
        order: 3,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Year 3',
        order: 4,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Year 4',
        order: 5,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Year 5',
        order: 6,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Year 6',
        order: 7,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Year 7',
        order: 8,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Year 8',
        order: 9,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Year 9',
        order: 10,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Year 10',
        order: 11,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: 'Year 11',
        order: 12,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: 'Year 12',
        order: 13,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.AU][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
    ],
  },
  [SchoolEducationSystem.BR]: {
    [SchoolKind.Elementary]: [
      {
        name: 'Bercário',
        order: 1,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Maternal 1',
        order: 2,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Maternal 2',
        order: 3,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Pré-Escola 1',
        order: 4,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: 'Pré-Escola 2',
        order: 5,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.Elementary][0].id_tmp,
      },
      {
        name: '1º ano EF',
        order: 6,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: '2º ano EF',
        order: 7,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: '3º ano EF',
        order: 8,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: '4º ano EF',
        order: 9,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.Elementary][1].id_tmp,
      },
      {
        name: '5º ano EF',
        order: 10,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.Elementary][1].id_tmp,
      },
    ],
    [SchoolKind.High]: [
      {
        name: '6º ano EF',
        order: 1,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.High][0].id_tmp,
      },
      {
        name: '7º ano EF',
        order: 2,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.High][0].id_tmp,
      },
      {
        name: '8º ano EF',
        order: 3,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.High][0].id_tmp,
      },
      {
        name: '9º ano EF',
        order: 4,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.High][0].id_tmp,
      },
      {
        name: '1º ano EM',
        order: 5,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.High][1].id_tmp,
      },
      {
        name: '2º ano EM',
        order: 6,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.High][1].id_tmp,
      },
      {
        name: '3º ano EM',
        order: 7,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.High][1].id_tmp,
      },
    ],
    [SchoolKind.ElementaryAndHigh]: [
      {
        name: 'Bercário',
        order: 1,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Maternal 1',
        order: 2,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Maternal 2',
        order: 3,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Pré-Escola 1',
        order: 4,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: 'Pré-Escola 2',
        order: 5,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][0].id_tmp,
      },
      {
        name: '1º ano EF',
        order: 6,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: '2º ano EF',
        order: 7,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: '3º ano EF',
        order: 8,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: '4º ano EF',
        order: 9,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: '5º ano EF',
        order: 10,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][1].id_tmp,
      },
      {
        name: '6º ano EF',
        order: 11,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: '7º ano EF',
        order: 12,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: '8º ano EF',
        order: 13,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: '9º ano EF',
        order: 14,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][2].id_tmp,
      },
      {
        name: '1º ano EM',
        order: 15,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][3].id_tmp,
      },
      {
        name: '2º ano EM',
        order: 16,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][3].id_tmp,
      },
      {
        name: '3º ano EM',
        order: 17,
        level_id: SCHOOL_LEVELS[SchoolEducationSystem.BR][SchoolKind.ElementaryAndHigh][3].id_tmp,
      },
    ],
  },
};

const generatePositiveConductTypes = (lang: SchoolPresetsLanguage) => {
  return [
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].conductTypes.certificate,
      connotation: ConductConnotation.POSITIVE,
      type: ConductTypeRepresentation.TEXT,
      order: 0,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].conductTypes.award,
      connotation: ConductConnotation.POSITIVE,
      type: ConductTypeRepresentation.TEXT,
      order: 1,
    },
  ];
};

const generateHousePointConductType = (lang: SchoolPresetsLanguage) => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].conductTypes.housePoints,
    connotation: ConductConnotation.POSITIVE,
    type: ConductTypeRepresentation.NUMBER,
    order: 2,
  };
};

const generateNegativeConductTypes = (lang: SchoolPresetsLanguage) => {
  return [
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].conductTypes.behaviourWarning,
      connotation: ConductConnotation.NEGATIVE,
      type: ConductTypeRepresentation.TEXT,
      order: 3,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].conductTypes.detention,
      connotation: ConductConnotation.NEGATIVE,
      type: ConductTypeRepresentation.TEXT,
      order: 4,
    },
    {
      name: SCHOOL_PRESETS_BY_LANG_CODE[lang].conductTypes.suspension,
      connotation: ConductConnotation.NEGATIVE,
      type: ConductTypeRepresentation.TEXT,
      order: 5,
    },
  ];
};

const generateSchoolConductTypes = (
  educationSystem: SchoolEducationSystem,
  lang: SchoolPresetsLanguage,
) => {
  switch (educationSystem) {
    case SchoolEducationSystem.UK:
    case SchoolEducationSystem.BR:
      return [
        ...generatePositiveConductTypes(lang),
        generateHousePointConductType(lang),
        ...generateNegativeConductTypes(lang),
      ];
    case SchoolEducationSystem.US:
      return [...generatePositiveConductTypes(lang), ...generateNegativeConductTypes(lang)];
    case SchoolEducationSystem.AU:
      return [...generatePositiveConductTypes(lang), ...generateNegativeConductTypes(lang)];
  }
};

const generateAssessmentGradesPrimarySimplified = (
  lang: SchoolPresetsLanguage,
): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.primarySimplified,
    description: '',
    options: ['4', '3', '2', '1'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesPrimaryNC = (lang: SchoolPresetsLanguage): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.primaryNC,
    description: '',
    options: ['GDS', 'EXS', 'WTS', 'HNM', 'PKG', 'PKF', 'BLW'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesPearsonOld = (
  lang: SchoolPresetsLanguage,
): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.pearsonOld,
    description: '',
    options: ['A*', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'U'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesPearsonNew = (
  lang: SchoolPresetsLanguage,
): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.pearsonNew,
    description: '',
    options: ['9', '8', '7', '6', '5', '4', '3', '2', '1', 'U'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesCambridge = (lang: SchoolPresetsLanguage): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.cambridge,
    description: '',
    options: ['A*', 'A', 'B', 'C', 'D', 'E', 'F', 'G'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesALevel = (lang: SchoolPresetsLanguage): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.aLevels,
    description: '',
    options: ['A*', 'A', 'B', 'C', 'D', 'E'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesPerformanceDescriptors = (
  lang: SchoolPresetsLanguage,
): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.performanceDescriptors,
    description: '',
    options: ['O', 'S', 'N', 'U'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesLetter = (lang: SchoolPresetsLanguage): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.letter,
    description: '',
    options: ['A', 'A-', 'B+', 'B', 'B-', 'C+', 'C', 'C-', 'D+', 'D', 'D-', 'F'].map((name) => ({
      name,
    })),
  };
};

const generateAssessmentGradesIBGrading = (lang: SchoolPresetsLanguage): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.IBGradingScale,
    description: '',
    options: ['7', '6', '5', '4', '3', '2', '1'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesLettered = (lang: SchoolPresetsLanguage): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.letteredSystem,
    description: '',
    options: ['A', 'B', 'C', 'D', 'E', 'F'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesHonours = (lang: SchoolPresetsLanguage): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.honoursSystem,
    description: '',
    options: ['HD', 'D', 'C', 'P', 'F'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesNumbered = (lang: SchoolPresetsLanguage): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.NSWNumbered,
    description: '',
    options: ['6', '5', '4', '3', '2', '1'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesDoubleLetter = (
  lang: SchoolPresetsLanguage,
): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.doubleLetters,
    description: '',
    options: ['SS', 'MS', 'MM', 'CC', 'MI', 'II', 'SR'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesBrazilLetter = (
  lang: SchoolPresetsLanguage,
): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.brazilLetter,
    description: '',
    options: ['E', 'MB', 'B', 'S', 'R', 'I', 'D'].map((name) => ({ name })),
  };
};

const generateAssessmentGradesBrazilNational = (
  lang: SchoolPresetsLanguage,
): AssessmentsGradeUpdate => {
  return {
    name: SCHOOL_PRESETS_BY_LANG_CODE[lang].assessmentGrades.brazilNational,
    description: '',
    options: ['10', '9', '8', '7', '6', '5', '4', '3', '2', '1'].map((name) => ({ name })),
  };
};

const generateSchoolAssessmentGrades = (
  educationSystem: SchoolEducationSystem,
  kind: SchoolKind,
  lang: SchoolPresetsLanguage,
) => {
  switch (educationSystem) {
    case SchoolEducationSystem.UK:
      if (kind === SchoolKind.Elementary) {
        return [
          generateAssessmentGradesPrimarySimplified(lang),
          generateAssessmentGradesPrimaryNC(lang),
        ];
      }
      if (kind === SchoolKind.High) {
        return [
          generateAssessmentGradesPearsonOld(lang),
          generateAssessmentGradesPearsonNew(lang),
          generateAssessmentGradesCambridge(lang),
          generateAssessmentGradesALevel(lang),
        ];
      }
      if (kind === SchoolKind.ElementaryAndHigh) {
        return [
          generateAssessmentGradesPrimarySimplified(lang),
          generateAssessmentGradesPrimaryNC(lang),
          generateAssessmentGradesPearsonOld(lang),
          generateAssessmentGradesPearsonNew(lang),
          generateAssessmentGradesCambridge(lang),
          generateAssessmentGradesALevel(lang),
        ];
      }
      break;
    case SchoolEducationSystem.US:
      if (kind === SchoolKind.Elementary) {
        return [
          generateAssessmentGradesPerformanceDescriptors(lang),
          generateAssessmentGradesLetter(lang),
          generateAssessmentGradesIBGrading(lang),
        ];
      }
      if (kind === SchoolKind.High) {
        return [
          generateAssessmentGradesPerformanceDescriptors(lang),
          generateAssessmentGradesLetter(lang),
          generateAssessmentGradesIBGrading(lang),
        ];
      }
      if (kind === SchoolKind.ElementaryAndHigh) {
        return [
          generateAssessmentGradesPerformanceDescriptors(lang),
          generateAssessmentGradesLetter(lang),
          generateAssessmentGradesIBGrading(lang),
        ];
      }
      break;
    case SchoolEducationSystem.AU:
      if (kind === SchoolKind.Elementary) {
        return [
          generateAssessmentGradesLettered(lang),
          generateAssessmentGradesHonours(lang),
          generateAssessmentGradesNumbered(lang),
        ];
      }
      if (kind === SchoolKind.High) {
        return [
          generateAssessmentGradesLettered(lang),
          generateAssessmentGradesHonours(lang),
          generateAssessmentGradesNumbered(lang),
        ];
      }
      if (kind === SchoolKind.ElementaryAndHigh) {
        return [
          generateAssessmentGradesLettered(lang),
          generateAssessmentGradesHonours(lang),
          generateAssessmentGradesNumbered(lang),
        ];
      }
      break;
    case SchoolEducationSystem.BR:
      if (kind === SchoolKind.Elementary) {
        return [
          generateAssessmentGradesIBGrading(lang),
          generateAssessmentGradesDoubleLetter(lang),
          generateAssessmentGradesBrazilLetter(lang),
          generateAssessmentGradesBrazilNational(lang),
        ];
      }
      if (kind === SchoolKind.High) {
        return [
          generateAssessmentGradesIBGrading(lang),
          generateAssessmentGradesDoubleLetter(lang),
          generateAssessmentGradesBrazilLetter(lang),
          generateAssessmentGradesBrazilNational(lang),
        ];
      }
      if (kind === SchoolKind.ElementaryAndHigh) {
        return [
          generateAssessmentGradesIBGrading(lang),
          generateAssessmentGradesDoubleLetter(lang),
          generateAssessmentGradesBrazilLetter(lang),
          generateAssessmentGradesBrazilNational(lang),
        ];
      }
      break;
  }
  return [];
};
