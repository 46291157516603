import { Grid } from '@mui/material';
import { ApiError } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { COUNTRY_OPTIONS } from '@schooly/constants';
import { FC, useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import {
  pickFormFieldsFromUser,
  UserFormData,
} from '../../../components/common/ProfileModal/profileEditSchema';
import { FormInput, FormSelect } from '../../../components/ui/Input';
import { hasFieldValue } from '../../../components/ui/Input/utils';
import { useProfile } from '../../../context/profile/useProfile';
import IntlError from '../../../utils/intlError';
import { PropertyModal } from './PropertyModal';

interface AddressModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const AddressModal: FC<AddressModalProps> = ({ isOpen, onClose }) => {
  const { showNotification, showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);

  const { user, actions } = useProfile();

  const form = useForm<UserFormData>({
    mode: 'all',
    defaultValues: pickFormFieldsFromUser(user!),
  });

  const handleSubmit = useCallback(
    async (data: UserFormData) => {
      setIsUpdating(true);

      const { address_line_1, address_line_2, city, region, zip_code, country } = data;

      try {
        const res = await actions.updateProfile({
          address_line_1,
          address_line_2,
          city,
          region,
          zip_code,
          country: hasFieldValue(country) ? country : null,
        });

        if (!res) return;

        await actions.handleUserUpdate();

        showNotification({
          textId: 'confirmation-Address',
          type: 'success',
        });
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, onClose, showError, showNotification],
  );

  const { reset } = form;

  useEffect(() => {
    reset(pickFormFieldsFromUser(user!));
  }, [user, reset]);

  return (
    <PropertyModal
      isOpen={isOpen}
      user={user}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <FormInput name="address_line_1" type="text" labelTextId="peopleDetail-AddressLine1" />
      <FormInput name="address_line_2" type="text" labelTextId="peopleDetail-AddressLine2" />
      <FormInput name="city" type="text" labelTextId="peopleDetail-City" />

      <Grid container columnSpacing={2}>
        <Grid item md={6}>
          <FormInput name="region" type="text" labelTextId="peopleDetail-StateProvince" />
        </Grid>
        <Grid item md={6}>
          <FormInput name="zip_code" type="text" labelTextId="peopleDetail-ZipCode" />
        </Grid>
      </Grid>

      <FormSelect name="country" labelTextId="peopleDetail-Country" options={COUNTRY_OPTIONS} />
    </PropertyModal>
  );
};

export default AddressModal;
