import { Backdrop, ClickAwayListener, Icon, IconButton, Theme, Tooltip } from '@mui/material';
import { SyncUser } from '@schooly/api';
import { useUserContext } from '@schooly/components/authentication';
import { AvatarAuth } from '@schooly/components/avatar-auth';
import { LanguageSelect } from '@schooly/components/language-select';
import { useFlag } from '@schooly/hooks/use-flag';
import { BellIcon, ProfileIcon } from '@schooly/style';
import React, { FC, useCallback } from 'react';

import { UserContexts } from '../../../constants/userContexts';
import { WithProfile } from '../../../context/profile/WithProfile';
import { ProfileModal } from '../../../pages/ProfileModal/ProfileModal';
import { clearReactQueryStorageCache } from '../../../queryClient';
import { useAppDispatch, useAppSelector } from '../../../redux/hooks';
import { actions as messagesActions } from '../../../redux/slices/messagesSlice';
import buildClassName from '../../../utils/buildClassName';
import UserNav from './UserNav';

import './index.scss';

interface UserMenuProps {
  onToggle?: (open: boolean) => void;
}

const UserMenu: React.FC<UserMenuProps> = ({ onToggle }) => {
  const dispatch = useAppDispatch();
  const { logout, user: currentUser } = useUserContext();
  const {
    messages: { isInboxModalOpen },
  } = useAppSelector((state) => state);

  const [isProfileModalOpen, openProfileModal, closeProfileModal] = useFlag();

  const userSchools = currentUser?.school_relations;
  const isShowInboxModalButton = Boolean(userSchools && userSchools.length > 0);

  const handleLogOut = useCallback(async () => {
    logout();
    clearReactQueryStorageCache();
  }, [logout]);

  const handleInboxModalShow = useCallback(() => {
    dispatch(messagesActions.toggleInboxModal(true));
  }, [dispatch]);

  if (!currentUser) {
    return null;
  }

  return (
    <>
      <div className="UserMenu">
        <ProfileButton
          currentUser={currentUser}
          onLogOut={handleLogOut}
          onOpenProfile={openProfileModal}
        />
        <LanguageSelect variant="icon" />
        {isShowInboxModalButton && (
          <div
            role="button"
            tabIndex={0}
            className={buildClassName(
              'UserMenu__user-info',
              'UserMenu__button',
              'UserMenu__messages-counter-wrapper',
              isInboxModalOpen && 'active',
            )}
            onClick={handleInboxModalShow}
          >
            <BellIcon className={buildClassName('reset-svg-currentColor', 'messages-counter')} />
          </div>
        )}
      </div>

      {
        /*
        Don't use `ProfileModal.open` prop. There should not be two ProfileContext instances
        in the DOM simultaneously as it produces collisions.
        */
        isProfileModalOpen && (
          <WithProfile
            userType="profile"
            id={currentUser.user_id}
            context={UserContexts.Individual}
            onClose={closeProfileModal}
          >
            <ProfileModal />
          </WithProfile>
        )
      }
    </>
  );
};

type ProfileButtonProps = {
  currentUser: SyncUser;
  onOpenProfile: () => void;
  onLogOut: () => void;
};
const ProfileButton: FC<ProfileButtonProps> = ({ currentUser, onLogOut, onOpenProfile }) => {
  const [opened, open, close] = useFlag();

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={close}>
        <div>
          <Tooltip
            componentsProps={{
              tooltip: {
                sx: () => ({
                  width: 300,
                  maxWidth: 300,
                  padding: 0,
                  overflow: 'hidden',
                }),
              },
            }}
            title={
              <UserNav
                user={currentUser}
                onClick={close}
                onProfileOpen={onOpenProfile}
                logOut={onLogOut}
              />
            }
            disableFocusListener
            disableHoverListener
            disableTouchListener
            onClose={close}
            open={opened}
            placement="top-start"
          >
            <IconButton onClick={open} data-cy="user-avatar-button">
              <AvatarAuth
                sx={(theme: Theme) => ({
                  ml: theme.spacing(1.5),
                  border: 0,
                  width: theme.spacing(4.5),
                  height: theme.spacing(4.5),
                  outline: opened
                    ? `2px solid ${theme.palette.primary.main}`
                    : `2px solid transparent`,
                  transition: 'all .2s',
                  '.svg-icon': {
                    color: theme.palette.primary.main,
                  },
                })}
                user={currentUser}
              >
                <Icon>
                  <ProfileIcon />
                </Icon>
              </AvatarAuth>
            </IconButton>
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export default UserMenu;
