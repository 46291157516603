import { BoxProps, IconButton, Stack, Typography, TypographyProps } from '@mui/material';
import { useSpring } from '@react-spring/web';
import { CheckIcon } from '@schooly/style';
import React, { FC } from 'react';
import { useIntl } from 'react-intl';

import {
  NOTIFICATION_GAP,
  NOTIFICATION_HEIGHT,
  NotificationButton,
  NotificationContainer,
  NotificationToastContainer,
} from './Notifications.styled';

export type ButtonColor = 'light' | 'white-text';

export type NotificationId = string | number;

export interface NotificationButtonAction {
  textId: string;
  handler: VoidFunction;
  buttonColor?: ButtonColor;
  isOutline?: boolean;
}

export interface NotificationIconButtonAction {
  icon: React.ReactNode;
  handler: VoidFunction;
}

export type NotificationAction = NotificationButtonAction | NotificationIconButtonAction;

export type NotificationActions = NotificationAction[];

export interface NotificationProps {
  message?: string;
  textId?: string;
  // Dynamic values for `react-intl` messages
  values?: Record<string, any>;
  type?: 'success' | 'error' | 'info';
  icon?: React.ReactElement;
  id?: NotificationId;
  persistent?: boolean;
  actions?: NotificationActions;
}

export const Notification: FC<
  {
    notification: NotificationProps;
    toast?: boolean;
    typographyProps?: TypographyProps;
  } & BoxProps
> = ({ notification, toast, typographyProps, ...props }) => {
  const { actions, icon, message, textId, type, values } = notification;

  const { $t } = useIntl();

  const fadeInFromBottom = useSpring({
    from: {
      marginTop: -NOTIFICATION_HEIGHT,
      translateY: 240,
      opacity: 0,
    },
    to: {
      marginTop: NOTIFICATION_GAP,
      translateY: 0,
      opacity: 1,
    },
    config: {
      tension: 600,
      friction: 50,
      mass: 1,
    },
  });

  const finalMessage =
    textId && textId.trim && textId.trim()
      ? $t({ id: textId, defaultMessage: message }, values)
      : message;

  if (!finalMessage || !(finalMessage.trim && finalMessage.trim())) {
    return null;
  }

  const renderContent = () => {
    return (
      <>
        {type === 'success' ? <CheckIcon fill="white" /> : icon}
        <Typography
          textAlign="center"
          color="white"
          variant="h3"
          whiteSpace="pre"
          {...typographyProps}
        >
          {finalMessage}
        </Typography>

        {!!actions?.length && (
          <Stack direction="row" gap={1} ml={2}>
            {actions.map((action) =>
              'icon' in action ? (
                <IconButton sx={{ '& .svg-icon': { m: 0 } }} onClick={action.handler}>
                  {action.icon}
                </IconButton>
              ) : (
                <NotificationButton
                  key={action.textId}
                  buttonColor={action.buttonColor}
                  onClick={action.handler}
                >
                  {$t({ id: action.textId })}
                </NotificationButton>
              ),
            )}
          </Stack>
        )}
      </>
    );
  };

  return toast ? (
    <NotificationToastContainer type={type} style={fadeInFromBottom} data-cy="notification">
      {renderContent()}
    </NotificationToastContainer>
  ) : (
    <NotificationContainer type={type} {...props} data-cy="notification">
      {renderContent()}
    </NotificationContainer>
  );
};
