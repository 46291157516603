import { Button, IconButton } from '@mui/material';
import { WithName } from '@schooly/api';
import { CheckIcon, CloseIcon, ModalContent, ModalFooter, ModalHeader, Spin } from '@schooly/style';
import { getUserFullName } from '@schooly/utils/get-user-full-name';
import { PropsWithChildren } from 'react';
import { FieldValues } from 'react-hook-form';
import { FormProvider, SubmitHandler, UseFormReturn } from 'react-hook-form-lts';
import { FormattedMessage } from 'react-intl';

import { PropertyModalContainer } from './PropertyModal';

type PropertyModal2Props<T extends FieldValues = FieldValues> = PropsWithChildren<{
  user?: WithName;
  isOpen: boolean;
  onClose?: () => void;
  isUpdating: boolean;
  submitDisabled?: boolean;
  form: UseFormReturn<T>;
  onSubmit: SubmitHandler<T>;
}>;
export const PropertyModal2 = <T extends FieldValues = FieldValues>({
  children,
  isOpen,
  user,
  form,
  submitDisabled,
  isUpdating,
  onClose,
  onSubmit,
}: PropertyModal2Props<T>) => {
  return (
    <PropertyModalContainer
      onClose={isUpdating ? undefined : onClose}
      open={isOpen}
      className="PropertyModal"
    >
      <ModalHeader title={user && getUserFullName(user)} withBorderBottom={false} active>
        <IconButton disabled={isUpdating} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </ModalHeader>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <ModalContent active>{children}</ModalContent>
          <ModalFooter active withBorderTop={false}>
            <Button disabled={isUpdating} variant="outlined" onClick={onClose}>
              <FormattedMessage id="action-Cancel" />
            </Button>
            <Button
              disabled={submitDisabled || isUpdating}
              type="submit"
              endIcon={isUpdating ? <Spin /> : <CheckIcon />}
              data-cy="property-modal-submit"
            >
              <FormattedMessage id="action-Save" />
            </Button>
          </ModalFooter>
        </form>
      </FormProvider>
    </PropertyModalContainer>
  );
};
