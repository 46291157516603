import { Backdrop, ClickAwayListener, Popper, styled, Tooltip, TooltipProps } from '@mui/material';
import { useFlag } from '@schooly/hooks/use-flag';
import { FC, ReactElement, ReactNode } from 'react';
import { useIntl } from 'react-intl';

import { SimpleButton } from './SimpleButton/SimpleButton';

export type ContextMenuAction = {
  labelTextId: string;
  onClick: () => void;
  icon?: ReactNode;
};

type ContextMenuProps = {
  children: (open: () => void) => ReactElement<any, any>;
  actions: ContextMenuAction[];
} & Partial<Omit<TooltipProps, 'children'>>;

const noop = () => {};

export const ContextMenu: FC<ContextMenuProps> = ({ children, actions, ...tooltipProps }) => {
  const [opened, open, close] = useFlag();
  const { $t } = useIntl();

  return (
    <>
      <Backdrop open={opened} invisible sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} />
      <ClickAwayListener onClickAway={opened ? close : noop}>
        <div>
          <Tooltip
            PopperProps={{
              disablePortal: true,
            }}
            onClose={close}
            open={opened}
            placement="bottom-start"
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <>
                {actions.map((a) => (
                  <SimpleButton onClick={a.onClick} key={a.labelTextId} startIcon={a.icon}>
                    {$t({ id: a.labelTextId })}
                  </SimpleButton>
                ))}
              </>
            }
            {...tooltipProps}
          >
            {children(open)}
          </Tooltip>
        </div>
      </ClickAwayListener>
    </>
  );
};

export const MenuItemArrow = styled(Popper)(({ theme }) => ({
  zIndex: theme.zIndex.tooltip,
  width: theme.spacing(),

  '& > .MuiPaper-root': {
    position: 'absolute',
    width: theme.spacing(2),
    height: theme.spacing(2),
    background: theme.palette.background.paper,
    borderRadius: 2, // non-standard shape
    transform: 'rotate(-45deg)',
    transformOrigin: 'right bottom',
    boxShadow: 'none',
  },
}));
