import { IconButton, Stack, Typography } from '@mui/material';
import { Child, SchoolRelation, SyncUser } from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import { RelationsToChild } from '@schooly/constants';
import { EditIcon } from '@schooly/style';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { EditFamilyModal } from 'apps/web/src/components/common/EditFamilly/EditFamilyModal';
import React, { FC, useEffect, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import Hint from '../../../components/common/Hint';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { SchoolUserRole } from '../../../constants/school';
import { useProfile } from '../../../context/profile/useProfile';
import { getStudentPrimaryContactId } from '../../../helpers/students';
import { getUserFullName } from '../../../helpers/users';
import useFlag from '../../../hooks/useFlag';
import ProfileCard from '../ProfileCard';

export const ProfileModalFamily: FC = () => {
  const { user, userType, schoolMembership, actions } = useProfile();
  const { permissions } = useAuth();

  const [primaryContactId, setPrimaryContactId] = useState('');
  const [isDialogOpen, showDialog, hideDialog] = useFlag();

  const { $t } = useIntl();

  // TODO: WTF? How `schoolMembership` can contain all these fields?
  const child_associations =
    (schoolMembership as unknown as SyncUser)?.child_associations || user?.child_associations;
  const guardian_relations =
    (schoolMembership as unknown as SyncUser)?.guardian_relations || user?.guardian_relations;
  const possible_siblings =
    (schoolMembership as unknown as SyncUser)?.possible_siblings || user?.possible_siblings;

  useEffect(() => {
    if (user) {
      const id = getStudentPrimaryContactId(user, userType, false);
      setPrimaryContactId(id || '');
    }
  }, [user, userType]);

  const totalFamilyCount = useMemo(() => {
    if (userType === 'child') {
      return child_associations?.length + Number(possible_siblings?.length);
    }

    return guardian_relations?.length + Number(possible_siblings?.length);
  }, [child_associations, guardian_relations, possible_siblings, userType]);

  const showEditButton = permissions.includes('parent_manager');

  return (
    <>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={2.5}>
        <Stack direction="row" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="profile-Family" />
          </Typography>
          {!!totalFamilyCount && <Counter>{totalFamilyCount}</Counter>}
        </Stack>

        {showEditButton && (
          <IconButton onClick={showDialog} inverse>
            <EditIcon />
          </IconButton>
        )}
      </Stack>

      {((userType === 'student' && !!guardian_relations?.length) ||
        (userType === 'child' && !!child_associations?.length)) && (
        <>
          <Typography variant="h4">
            <FormattedMessage id="peopleDetail-ParentInformation" />
          </Typography>

          <div className="cards-wrapper">
            {userType === 'child'
              ? child_associations.map((item) => (
                  <ProfileCard
                    key={item.user_id}
                    user={item}
                    userType="adult"
                    profileSchoolContext={SchoolUserRole.Parent}
                  />
                ))
              : guardian_relations.map((item) => (
                  <ProfileCard
                    key={item.id}
                    showInviteStatus
                    inviteStatus={item.guardian.invite_status}
                    user={item.guardian}
                    primary={item.guardian.user_id === primaryContactId}
                    userType="parent"
                    relationType={
                      typeof item.relation_type === 'number' && RelationsToChild[item.relation_type]
                        ? $t({ id: `relationship-Type-${RelationsToChild[item.relation_type]}` })
                        : undefined
                    }
                  />
                ))}
          </div>
        </>
      )}
      {!!possible_siblings?.length && (
        <>
          <h4 className="mt-4 mb-2 d-flex align-items-center">
            <FormattedMessage id="peopleDetail-PossibleSiblings" />
            <Hint className="ml-1">
              <FormattedMessage
                id="peopleDetail-PossibleSiblingsTooltip"
                values={{ userName: user && getUserFullName(user) }}
              />
            </Hint>
          </h4>

          <div className="cards-wrapper">
            {possible_siblings.map((item) => (
              <ProfileCard
                key={item.user_id}
                user={
                  !!item.school_user_relation && !!Object.keys(item.school_user_relation).length
                    ? item.school_user_relation
                    : item
                }
                userType={
                  !!item.school_user_relation && !!Object.keys(item.school_user_relation).length
                    ? 'student'
                    : 'child'
                }
              />
            ))}
          </div>
        </>
      )}
      {/* TODO: figure out with the `user` type */}
      <EditFamilyModal
        isOpen={isDialogOpen}
        editedStudent={user as unknown as Child}
        userType={userType}
        studentMembership={schoolMembership || (user as unknown as SchoolRelation)}
        onClose={hideDialog}
        onParentAdd={actions.request}
      />
    </>
  );
};
