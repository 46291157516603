import { Box, Stack, Typography } from '@mui/material';
import { DATE_FORMAT_FULL_MONTH_FNS, useGetMessagesForGroupQuery } from '@schooly/api';
import { FilterElementType, MessageForGroup } from '@schooly/api';
import { Loading, ModalSearch, PlusIcon } from '@schooly/style';
import { format } from 'date-fns';
import React, { useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { Link } from 'react-router-dom';

import AccessDenied from '../../../../components/common/AccessDenied';
import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import { ListViewMessages } from '../../../../components/uikit-components/ListViewMessages/ListViewMessages';
import { ModalContentStickyTitle } from '../../../../components/uikit-components/Modal/Modal.styled';
import { useGroup } from '../../../../context/groups/useGroup';
import { retrieveApiDate } from '../../../../helpers/misc';
import getIsAccessDeniedError from '../../../../utils/getIsAccessDeniedError';
import { GroupTabEmptyStub } from '../GroupTabEmptyStub';
import {
  GroupPreviewModalAccessDenied,
  GroupPreviewModalContent,
  GroupPreviewModalMessagesStyled,
  GroupPreviewModalMonthName,
  GroupPreviewModalMonths,
} from './GroupPreviewModalMessages.styled';

const NONE = 'NONE';

export const GroupPreviewModalMessages: React.FC = () => {
  const { group } = useGroup();
  const [filter, setFilter] = useState('');

  const { $t } = useIntl();

  const { data, error } = useGetMessagesForGroupQuery(
    { groupId: group?.id || '', searchQuery: filter },
    { refetchOnMount: 'always', enabled: !!group?.id },
  );

  const results = data?.results;

  const messagesByMonths = useMemo(() => {
    return (
      results?.reduce<Record<string, MessageForGroup[]>>((prev, message) => {
        const date = message.publish_date && retrieveApiDate(message.publish_date);
        const month = date ? format(date, DATE_FORMAT_FULL_MONTH_FNS).toUpperCase() : NONE;

        if (!prev[month]) {
          prev[month] = [];
        }

        prev[month].push(message);

        return prev;
      }, {}) || {}
    );
  }, [results]);

  const renderContent = useCallback(() => {
    return (
      <GroupPreviewModalMonths>
        {!group || !Object.keys(messagesByMonths).length ? (
          <GroupTabEmptyStub textId="groups-NoResultsMatching-Message" />
        ) : (
          Object.keys(messagesByMonths).map((month) => (
            <Box key={month}>
              {month !== NONE && <GroupPreviewModalMonthName>{month}</GroupPreviewModalMonthName>}
              <ListViewMessages messages={messagesByMonths[month]} groupId={group.id} />
            </Box>
          ))
        )}
      </GroupPreviewModalMonths>
    );
  }, [group, messagesByMonths]);

  if (getIsAccessDeniedError(error)) {
    <GroupPreviewModalAccessDenied>
      <AccessDenied />
    </GroupPreviewModalAccessDenied>;
  }

  return (
    <GroupPreviewModalMessagesStyled empty={!results?.length}>
      <ModalContentStickyTitle
        sx={(theme) => ({
          [theme.breakpoints.down('md')]: {
            '@media (orientation: portrait)': {
              paddingTop: 3,
            },
          },
        })}
      >
        <Stack direction="row" mb={2.5} justifyContent="space-between" alignItems="center">
          <Typography variant="h2">
            <FormattedMessage id="groups-Messages" />
            {!!data && Number(data.count) > 1 && <Counter>{data.count}</Counter>}
          </Typography>

          <Stack direction="row" gap={2.5}>
            <ModalSearch
              value={filter}
              onChange={setFilter}
              withDebounce
              placeholder={$t({ id: 'people-Search' })}
            />

            <Stack
              component={Link}
              sx={{ color: 'text.primary' }}
              justifyContent="center"
              to={{ pathname: '/messages/new' }}
              state={{
                parentsCriteria: [{ type: FilterElementType.Group, group }],
                staffCriteria: [{ type: FilterElementType.Group, group }],
              }}
            >
              <SimpleButton startIcon={<PlusIcon />} color="inherit">
                <FormattedMessage id="groups-Message-CreateMessage" />
              </SimpleButton>
            </Stack>
          </Stack>
        </Stack>
      </ModalContentStickyTitle>
      <GroupPreviewModalContent empty={!results?.length}>
        {!data ? <Loading /> : renderContent()}
      </GroupPreviewModalContent>
    </GroupPreviewModalMessagesStyled>
  );
};
