import { Box, IconButton, Stack, Typography } from '@mui/material';
import {
  LockIcon,
  MinusIcon,
  PlusIcon,
  TagSubject,
  TypographyWithOverflowHint,
} from '@schooly/style';
import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { getRouteModalPathname } from '../../../helpers/misc';
import { GridRowItem, GridRowName, GridRowStyled } from '../../uikit-components/Grid/Grid';
import { GroupCardIconButton, GroupCardLayout } from './GroupCard.styled';

interface GroupCardProps {
  // TODO add entity as generic example <T extends {id: string, name: string...}
  title: string;
  id: string;
  added?: boolean;
  date?: string;
  subjectName?: string;
  subjectArchived?: boolean;
  onSelect?: () => void;
  selectable?: boolean;
  rowItemNode?: React.ReactNode;
  withBorder?: boolean;
  locked?: boolean;
  withoutTitleLink?: boolean;
}
//TODO rename component to Card with generic type prop, example <T extends {id: string, title: string}>
export const GroupCard: React.FC<GroupCardProps> = ({
  title,
  subjectName,
  subjectArchived,
  date,
  added,
  onSelect,
  selectable,
  rowItemNode,
  withBorder,
  id,
  locked,
  withoutTitleLink,
}) => {
  const submitContent = useMemo(() => {
    if (locked) {
      return (
        <IconButton disabled>
          <LockIcon />
        </IconButton>
      );
    }
    if (!selectable) {
      return null;
    }

    return (
      <GroupCardIconButton onClick={onSelect}>
        {added ? <MinusIcon /> : <PlusIcon />}
      </GroupCardIconButton>
    );
  }, [added, locked, onSelect, selectable]);

  const titleNode = (
    <Stack
      component={Link}
      to={getRouteModalPathname('group', { id })}
      direction="row"
      onClick={(e) => e.stopPropagation()}
      color="common.grey2"
      alignItems="center"
      sx={{
        ':hover': {
          '*': {
            textDecoration: 'underline',
          },
        },
      }}
    >
      <TypographyWithOverflowHint variant="h3" color="common.grey2" minWidth={140}>
        {title}
      </TypographyWithOverflowHint>
    </Stack>
  );

  return (
    <GroupCardLayout withBorder={withBorder}>
      <GridRowStyled flexGrow={1} width="100%">
        <GridRowItem justifyContent="space-between" direction="row" gap={1} width="100%">
          <Stack flexDirection="row" gap={1} alignItems="baseline" overflow="hidden">
            <GridRowName sx={{ width: 160 }}>
              {withoutTitleLink ? (
                titleNode
              ) : (
                //TODO route by type prop
                <Link to={getRouteModalPathname('group', { id })}>{titleNode}</Link>
              )}
            </GridRowName>
            <Box>
              {subjectName && <TagSubject archived={subjectArchived}>{subjectName}</TagSubject>}
            </Box>
          </Stack>
          <Stack
            direction="row"
            width={date ? '50%' : undefined}
            justifyContent="space-between"
            alignItems="center"
            gap={1}
          >
            <Typography>{date}</Typography>

            <Stack flexDirection="row" alignItems="center" gap={2}>
              {rowItemNode}
              {submitContent}
            </Stack>
          </Stack>
        </GridRowItem>
      </GridRowStyled>
    </GroupCardLayout>
  );
};
