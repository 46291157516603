import { FormControlLabel, Switch } from '@mui/material';
import {
  ChartBarData,
  FilterKeys,
  GetMessagesQueryFilters,
  MESSAGE_ARRANGE_BY_FILTER_KEYS,
  MessageArrangeBy,
  useGetMessagesAggregatedData,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { SchoolUserRole } from '@schooly/constants';
import { useSchoolProperties } from '@schooly/hooks/use-school-properties';
import { format } from 'date-fns';
import { FC, useCallback, useEffect, useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import { BarClickData, BarSelectProps, ChartBar, ChartBarProps } from './chartBar/ChartBar';
import { ChartsContainer } from './chartBar/ChartsContainer';
import { useRenderTooltip } from './chartBar/ChartTooltip';
import { useGetLabel } from './chartBar/useGetLabel';
import { useCharts } from './context/ChartsContext';
import { ChartsCustomGrid } from './StudentsStaffCharts';

const BAR_ITEM_LIMIT = 5;
interface ChartsProps extends Omit<ChartBarProps, 'chartData' | 'getDataName'> {
  onOpenArrangeByDropdown?: () => void;
  onOpenCustomGrid: (v: ChartsCustomGrid<GetMessagesQueryFilters> | null) => void;
  arrangeBy: MessageArrangeBy | null;
  filters: GetMessagesQueryFilters;
  searchQuery?: string;
}

export const MessageCharts: FC<ChartsProps> = ({
  filters,
  onOpenArrangeByDropdown,
  onOpenCustomGrid,
  arrangeBy,
  searchQuery,
}) => {
  const {
    isChartsOpened,
    schoolId,
    chartSelectedElement,
    setChartSelectedElement,
    onToggleChartsOpened,
    showZeroValues,
    onToggleZeroValues,
  } = useCharts();
  const { showError } = useNotifications();
  const renderTooltip = useRenderTooltip();

  const { $t } = useIntl();
  const { schoolProperties, isLoading: isLoadingSchoolProperties } = useSchoolProperties({
    userType: SchoolUserRole.Student,
    schoolId,
    showReEnrollmentProperties: true,
  });

  const { data, isFetching } = useGetMessagesAggregatedData(
    {
      schoolId,
      filters,
      arrangeBy,
      searchQuery,
    },
    { onError: showError, refetchOnMount: 'always', enabled: !!isChartsOpened && !!arrangeBy },
  );

  const getDataName = useGetLabel({
    arrangeBy,
    schoolProperties,
  });

  const getBarName = useCallback(
    (name: string) => {
      if (arrangeBy === FilterKeys.MessagesReadPercent) {
        const [percentFrom, percentTo] = name.split('-');

        return `${percentFrom ?? ''}${' - '}${percentTo ?? ''}${'%'}`;
      }

      if (arrangeBy === FilterKeys.Creator) {
        const [firstName, lastName, ...rest] = name.split(' ');
        const firstLetter = `${firstName?.at(0) ?? ''}${'. '}`;

        return `${data?.rows?.length ?? 0 < 30 ? firstLetter : firstName}${' '}${lastName}${
          rest.length ? '...' : ''
        }`;
      }

      if (arrangeBy === FilterKeys.MessagesMonth) {
        return format(new Date(name), "MMM''yy");
      }
      return name;
    },
    [arrangeBy, data?.rows?.length],
  );

  const [chartData, hasZeroValues] = useMemo(() => {
    if (!data?.rows.length) return [];

    const normalizedData: ChartBarData = {
      arrange_by: {
        dataKey: data.key,
        data: [],
      },
      series: [
        {
          dataValue: null,
          dataKey: null,
          name: null,
          data: [],
        },
      ],
    };

    let hasZeroValues = false;

    data.rows.forEach(({ total, value, name }, i, arr) => {
      if (total === 0) {
        hasZeroValues = true;
      }

      if (!showZeroValues && !total) {
        return;
      }

      const arrangeByData = {
        dataValue: value,
        name: getBarName(name),
      };

      if (arrangeBy === FilterKeys.MessagesReadPercent) {
        normalizedData.arrange_by.data.unshift(arrangeByData);
      } else normalizedData.arrange_by.data.push(arrangeByData);

      normalizedData.series.forEach((seriesItem) => {
        if (arrangeBy === FilterKeys.MessagesReadPercent) seriesItem.data.unshift(total);
        else seriesItem.data.push(total);
      });
    });

    return [normalizedData, hasZeroValues];
  }, [arrangeBy, data?.key, data?.rows, getBarName, showZeroValues]);

  useEffect(() => {
    setChartSelectedElement(undefined);
    onOpenCustomGrid(null);
  }, [arrangeBy, filters, setChartSelectedElement, onOpenCustomGrid]);

  const onBarSelect = useCallback(
    ({ data: chartData, bar }: BarSelectProps) => {
      const getFiltersFromBarData = (data: BarSelectProps['data']): GetMessagesQueryFilters => {
        const filters: GetMessagesQueryFilters = {};
        if (!data) return filters;

        for (const filterKey of MESSAGE_ARRANGE_BY_FILTER_KEYS) {
          const filterKeyData = data.find((d) => d.dataKey === filterKey);

          if (!filterKeyData || !filterKeyData.dataValue) continue;

          filters[filterKey] = [filterKeyData.dataValue.toString()];
        }

        return filters;
      };

      setChartSelectedElement((old) => {
        const isElementSelected =
          old?.dataIndex === bar.dataIndex && old?.seriesIndex === bar.seriesIndex;
        const newSelectedBar = isElementSelected ? undefined : bar;

        if (newSelectedBar && chartData) {
          const barFilters = getFiltersFromBarData(chartData);
          const { name: title, value } = newSelectedBar;

          const originTitle = data?.rows.find((r) =>
            chartData.some((v) => r.value === v.dataValue),
          )?.name;

          onOpenCustomGrid({
            title: originTitle ?? title,
            count: typeof value === 'number' ? value : 0,
            filters: { ...filters, ...barFilters },
          });
        } else {
          onOpenCustomGrid(null);
        }

        return newSelectedBar;
      });
    },
    [data?.rows, filters, onOpenCustomGrid, setChartSelectedElement],
  );

  const handleDataName = useCallback(
    ({ dataKey, dataValue, name }: BarClickData) => {
      const isLegend = !dataKey && !dataValue && !name;
      if (arrangeBy === FilterKeys.Creator && isLegend) {
        return $t({ id: 'messages-Sender' });
      }

      return getDataName({ dataKey, dataValue, name });
    },
    [$t, arrangeBy, getDataName],
  );

  if (!isChartsOpened) return null;

  return (
    <ChartsContainer
      isEmpty={!chartData?.series.some((s) => !!s.data.reduce((acc, v) => acc + v, 0))}
      isLoading={isFetching || isLoadingSchoolProperties}
      onToggleChartsOpened={onToggleChartsOpened}
      arrangeBy={arrangeBy}
      onOpenArrangeByDropdown={onOpenArrangeByDropdown}
      zeroValuesToggler={
        hasZeroValues ? (
          <FormControlLabel
            control={<Switch checked={showZeroValues} onChange={onToggleZeroValues} />}
            label={<FormattedMessage id="showZeroValues" />}
            sx={{
              m: 0,
              color: showZeroValues ? 'text.primary' : 'text.secondary',
            }}
          />
        ) : null
      }
    >
      {chartData && (
        <ChartBar
          barPosition={
            chartData.arrange_by.data.length < BAR_ITEM_LIMIT ? 'vertical' : 'horizontal'
          }
          renderTooltip={renderTooltip}
          chartData={chartData}
          onBarSelect={onBarSelect}
          getDataName={handleDataName}
          selectedBar={chartSelectedElement}
        />
      )}
    </ChartsContainer>
  );
};
