import { Box, Divider, IconButton, Stack, TableCellProps, Typography } from '@mui/material';
import {
  ATTENDANCE_REGISTERS_FILTER_KEYS,
  AttendanceCode,
  AttendanceRegisterForSchool,
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetAttendanceRegistersQueryFilters,
  SORT_DIRECTION,
  useGetAttendanceRegistersQuery,
  useGetAttendanceStatsQuery,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  checkHasFiltersApplied,
  PageHeader,
  PageHeaderSearchInput,
  StoredFilterSections,
  useFiltersStateFromSearchParams,
  useLastAppliedFiltersState,
  useSaveLastAppliedFiltersState,
  useSyncFiltersStateWithSearchParams,
} from '@schooly/components/filters';
import { MainGridNoResultsStub } from '@schooly/components/stubs';
import { DATE_FORMAT_SHORT } from '@schooly/constants';
import { useFlag } from '@schooly/hooks/use-flag';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import {
  ChartIcon,
  GridCell,
  GridRowCell,
  GridRowDate,
  Loading,
  PlusIcon,
  TypographyWithOverflowHint,
} from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
import { format, isToday } from 'date-fns';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../components/common/AccessDenied';
import { NoListItemsStub } from '../../components/common/NoListItemsStub/NoListItemsStub';
import { Table, TableCell } from '../../components/uikit-components/Table/Table';
import useAppLocation from '../../hooks/useAppLocation';
import { useAttendanceStatistics } from '../../hooks/useAttendanceStatistics';
import { useSchool } from '../../hooks/useSchool';
import useSchoolYears from '../../hooks/useSchoolYears';
import {
  getAbsentTotal,
  getAttendanceDetailByCode,
  getNoStatusTotal,
  getPresentTotal,
  getRegisterTotal,
} from './attendance-utils';
import { AttendanceExport } from './AttendanceExport/AttendanceExport';
import { AttendanceFilters } from './AttendanceFilters';
import { GridRowDateToday } from './AttendanceList.styled';
import { AttendanceStats } from './AttendanceStatistics/AttendanceStats';
import { AttendanceStatsPieChart } from './AttendanceStatistics/AttendanceStatsPieChart';

type AttendanceContentProps = {
  codes: AttendanceCode[];
  initialFilters?: GetAttendanceRegistersQueryFilters;
};

const PAGE_SIZE = 40;

export const AttendanceContent: FC<AttendanceContentProps> = ({ initialFilters, codes }) => {
  const { permissions } = useAuth();
  const { schoolId = '' } = useSchool();
  const { formatMessage } = useIntl();
  const { $t } = useIntl();

  const canView = permissions.includes('attendance_viewer');
  const navigate = useNavigate();
  const location = useAppLocation();
  const { percentIconButton, getTotalCount, renderCount, showPercents } = useAttendanceStatistics();
  const [isChartsOpened, openCharts, closeCharts] = useFlag(false);

  const { defaultValidity } = useSchoolYears();

  const defaultFilters: GetAttendanceRegistersQueryFilters = useMemo(
    () => ({
      [FilterKeys.Date]: [
        defaultValidity?.start || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
        defaultValidity?.end || format(new Date(), DEFAULT_DATE_FORMAT_FNS),
      ],
    }),
    [defaultValidity],
  );

  const { lastAppliedFilter, lastAppliedShowByPresentAbsent } = useLastAppliedFiltersState({
    type: StoredFilterSections.Attendance,
    filterKeys: ATTENDANCE_REGISTERS_FILTER_KEYS,
    schoolId: schoolId || '',
  });

  const [defaultShowByPresentAbsent, setDefaultShowByPresentAbsent] = useState(
    lastAppliedShowByPresentAbsent,
  );

  const initialFiltersState = useFiltersStateFromSearchParams({
    filterKeys: ATTENDANCE_REGISTERS_FILTER_KEYS,
    defaultFilters,
    initialFilters,
  });

  const defaultUserFilters = useMemo(() => {
    return { ...defaultFilters, ...initialFilters };
  }, [defaultFilters, initialFilters]);

  const {
    data,
    isLoading,
    params,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    setParams,
    error,
  } = useGetAttendanceRegistersQuery(
    {
      schoolId,
      query: '',
      pageSize: PAGE_SIZE,
      sort: { columnTextId: 'register_date', direction: SORT_DIRECTION.ASC },
      filters: lastAppliedFilter ?? initialFiltersState,
    },
    { refetchOnMount: 'always' },
  );

  const { data: chartData, isLoading: isLoadingChartsData } = useGetAttendanceStatsQuery(
    {
      schoolId,
      dateFrom: params.filters.date?.[0] ?? '',
      dateTo: params.filters.date?.[1] ?? '',
      ageGroups: params.filters.age_group,
    },
    { refetchOnMount: 'always' },
  );

  const filtersDateString = params.filters.date?.join('');
  const notActualInitialDate =
    !defaultValidity?.isActual &&
    filtersDateString === defaultFilters?.date?.join('') &&
    filtersDateString !== initialFilters?.date?.join('');

  useSyncFiltersStateWithSearchParams({
    pathname: '/attendance',
    filters: params.filters,
    showByPresentAbsent: defaultShowByPresentAbsent ? defaultShowByPresentAbsent : undefined,
  });

  useSaveLastAppliedFiltersState({
    type: StoredFilterSections.Attendance,
    filters: params.filters,
    schoolId: schoolId || '',
    showByPresentAbsent: defaultShowByPresentAbsent,
  });

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      setParams((p) => ({ ...p, query }));
    },
    [setParams],
  );

  const handleSetFilters = useCallback(
    (filters: GetAttendanceRegistersQueryFilters) => {
      setParams((p) => ({ ...p, filters }));
    },
    [setParams],
  );

  const loaderRef = useInfiniteScroll(isLoading || isFetchingNextPage, fetchNextPage, hasNextPage);

  const entries = useMemo(
    () =>
      data?.pages.reduce<AttendanceRegisterForSchool[]>(
        (prev, curr) => [...prev, ...curr.results],
        [],
      ) ?? [],
    [data?.pages],
  );

  const hasFiltersApplied =
    !!params.query?.length ||
    checkHasFiltersApplied(ATTENDANCE_REGISTERS_FILTER_KEYS, params.filters);

  const rowClickHandler = useCallback(
    (register: AttendanceRegisterForSchool) => () =>
      navigate(`/attendance/${register.id}`, { state: { backgroundLocation: location } }),
    [navigate, location],
  );

  const colWidth = codes.length ? `calc(276px - ${100 / codes.length + 1}%)` : undefined;

  const ageGroupFilterApplied = !!params.filters.age_group?.length;

  const columns = useMemo(
    () => [
      {
        id: 'name',
        renderContent: () => <GridCell pl={2.5} sx={{ width: 80 }} sticky />,
      },
      {
        id: 'attendance-RegisterName',
        renderContent: (props: TableCellProps) => (
          <GridCell sx={{ width: defaultShowByPresentAbsent ? 300 : 216 }} {...props}>
            <FormattedMessage id="attendance-RegisterName" />
          </GridCell>
        ),
        hoverProps: {
          disableHover: true,
        },
      },
      ...(!defaultShowByPresentAbsent
        ? (codes || []).map((code) => ({
            id: code.id,
            renderContent: (props: TableCellProps) => (
              <GridCell
                key={code.code}
                sx={(theme) => ({ minWidth: theme.spacing(6.5), width: colWidth })}
                {...props}
              >
                {code.name}
              </GridCell>
            ),
          }))
        : [
            {
              id: 'attendance-Present',
              renderContent: (props: TableCellProps) => (
                <GridCell
                  pr={0.75}
                  sx={{
                    right: 40,
                  }}
                  {...props}
                  sticky
                >
                  <FormattedMessage id="attendance-Present" />
                </GridCell>
              ),
            },
            {
              id: 'attendance-Absent',
              renderContent: (props: TableCellProps) => (
                <GridCell
                  pr={0.75}
                  sx={{
                    right: 40,
                  }}
                  {...props}
                  sticky
                >
                  <FormattedMessage id="attendance-Absent" />
                </GridCell>
              ),
            },
          ]),
      {
        id: 'attendance-NoStatus',
        renderContent: (props: TableCellProps) => (
          <GridCell pr={0.75} sx={{ width: 94 }} {...props}>
            <FormattedMessage id="attendance-NoStatus" />
          </GridCell>
        ),
      },
      {
        id: 'attendance-Total',
        renderContent: (props: TableCellProps) => (
          <GridCell
            sx={{
              width: 72,
              minWidth: 60,
              right: 40,

              overflow: 'hidden',
            }}
            pr={0.75}
            sticky
            {...props}
          >
            <FormattedMessage id="attendance-Total" />
          </GridCell>
        ),
      },
      {
        id: 'attendance-Download',
        renderContent: (props: TableCellProps) => (
          <GridCell sx={{ right: 0, width: 60 }} noPadding sticky {...props}>
            <Stack direction="row" justifyContent={!isChartsOpened ? 'space-between' : 'center'}>
              {!!entries?.length && !isChartsOpened && (
                <IconButton inverse onClick={openCharts}>
                  <ChartIcon />
                </IconButton>
              )}
              {percentIconButton}
            </Stack>
          </GridCell>
        ),
        hoverProps: {
          disableHover: true,
        },
      },
    ],
    [
      codes,
      defaultShowByPresentAbsent,
      colWidth,
      entries.length,
      isChartsOpened,
      openCharts,
      percentIconButton,
    ],
  );

  const rows = useMemo(() => {
    if (!entries || !codes) {
      return [];
    }

    return entries.map((register) => {
      const registerDate = newDateTimezoneOffset(register.register_date);
      const isDayToday = isToday(registerDate);
      const totalCount = getRegisterTotal(register.attendance_details);
      const noStatusCount = getNoStatusTotal(register.attendance_details);

      const cellArray: TableCell[] = [
        {
          id: 'date',
          renderContent: (props) => (
            <GridCell pl={2.5} sticky borderRight {...props}>
              {isDayToday ? (
                <GridRowDateToday>
                  <FormattedMessage id="datepicker-Today" />
                </GridRowDateToday>
              ) : (
                <GridRowDate>{format(registerDate, DATE_FORMAT_SHORT)}</GridRowDate>
              )}
            </GridCell>
          ),
        },
        {
          id: 'attendance-RegisterName',
          renderContent: (props) => (
            <GridCell
              {...props}
              sx={{ cursor: 'pointer', paddingLeft: 2 }}
              onClick={rowClickHandler(register)}
            >
              <TypographyWithOverflowHint variant="h3" color="common.grey2">
                {register.name}
              </TypographyWithOverflowHint>
            </GridCell>
          ),
          hoverProps: {
            onlyXHover: true,
          },
        },
        ...(!defaultShowByPresentAbsent
          ? codes.map((code) => ({
              renderContent: (props: TableCellProps) => {
                return (
                  <GridCell key={code.id} {...props}>
                    <GridRowCell>
                      {renderCount(
                        getAttendanceDetailByCode(code, register.attendance_details)?.count,
                        totalCount,
                      )}
                    </GridRowCell>
                  </GridCell>
                );
              },
              id: code.id,
            }))
          : [
              {
                id: 'attendance-InSchool',
                renderContent: (props: TableCellProps) => (
                  <GridCell
                    sx={{
                      right: 40,
                    }}
                    sticky
                    {...props}
                  >
                    <GridRowCell>
                      {getPresentTotal(codes, register.attendance_details) ? (
                        <Typography color="text.primary">
                          {getPresentTotal(codes, register.attendance_details)}
                        </Typography>
                      ) : (
                        <Typography color="text.secondary">-</Typography>
                      )}
                    </GridRowCell>
                  </GridCell>
                ),
              },
              {
                id: 'attendance-Absent',
                renderContent: (props: TableCellProps) => (
                  <GridCell
                    sx={{
                      right: 40,
                    }}
                    sticky
                    {...props}
                  >
                    <GridRowCell>
                      {getAbsentTotal(codes, register.attendance_details) ? (
                        <Typography color="text.primary">
                          {getAbsentTotal(codes, register.attendance_details)}
                        </Typography>
                      ) : (
                        <Typography color="text.secondary">-</Typography>
                      )}
                    </GridRowCell>
                  </GridCell>
                ),
              },
            ]),
        {
          id: 'attendance-NoStatus',
          renderContent: (props) => (
            <GridCell {...props} borderLeft>
              <GridRowCell>{renderCount(noStatusCount, totalCount)}</GridRowCell>
            </GridCell>
          ),
        },
        {
          id: 'attendance-Total',
          renderContent: (props) => (
            <GridCell
              sx={{
                right: 40,
              }}
              sticky
              {...props}
            >
              <GridRowCell>
                {getTotalCount(totalCount) ? (
                  <Typography color="text.primary">{getTotalCount(totalCount)}</Typography>
                ) : (
                  <Typography color="text.secondary">-</Typography>
                )}
              </GridRowCell>
            </GridCell>
          ),
        },
        {
          id: 'attendance-Download',
          renderContent: (props) => (
            <GridCell pl={0} sx={{ right: 0 }} sticky {...props}>
              {totalCount && !ageGroupFilterApplied ? (
                <AttendanceExport register={register} primary={isDayToday} />
              ) : (
                <Box />
              )}
            </GridCell>
          ),
          hoverProps: {
            onlyXHover: true,
          },
        },
      ];

      return {
        id: register.id,
        cells: cellArray,
      };
    });
  }, [
    entries,
    codes,
    defaultShowByPresentAbsent,
    rowClickHandler,
    renderCount,
    getTotalCount,
    ageGroupFilterApplied,
  ]);

  const handleAddButtonClick = useCallback(() => {
    navigate('/attendance/new', {
      state: { backgroundLocation: location },
    });
  }, [location, navigate]);

  if (error || !canView) {
    return <AccessDenied />;
  }

  return (
    <Stack flex={!rows.length ? 1 : 0}>
      <Stack mb={2}>
        <Stack gap={1}>
          <PageHeader
            buttonTextId="attendance-NewRegister"
            pageTitleTextId="section-Attendance"
            showActionButton={permissions.includes('attendance_manager')}
            buttonIcon={<PlusIcon />}
            onButtonClick={handleAddButtonClick}
          >
            <PageHeaderSearchInput
              value={params.query || ''}
              onChangeText={handleSetFiltersQuery}
              placeholder={formatMessage({ id: 'people-Search' })}
            />
          </PageHeader>

          <AttendanceFilters
            notActualInitialDate={notActualInitialDate}
            onSetFilters={handleSetFilters}
            defaultFilters={defaultFilters}
            filters={params.filters}
            schoolId={schoolId || ''}
            defaultSchoolYear={defaultValidity}
            defaultUserFilters={defaultUserFilters}
            defaultShowByPresentAbsent={defaultShowByPresentAbsent}
            onSetShowByPresentAbsent={setDefaultShowByPresentAbsent}
          />
        </Stack>

        {isChartsOpened && (
          <>
            <Stack
              sx={(theme) => ({
                flexDirection: 'row',
                alignItems: 'center',
                height: theme.spacing(3.5),
                gap: 2,
                mb: 1,
                justifyContent: 'flex-end',
              })}
            >
              <IconButton onClick={closeCharts}>
                <ChartIcon />
              </IconButton>
            </Stack>

            <Divider sx={{ mb: 2 }} />

            <AttendanceStats
              showByPresentAbsent={defaultShowByPresentAbsent}
              statistics={chartData?.statistic ?? []}
              totalCount={chartData?.register_count}
              loading={isLoadingChartsData}
              rowsPerColumnCount={3}
              renderChart={(data, total) => (
                <Box sx={{ height: 126 }}>
                  <AttendanceStatsPieChart
                    data={data}
                    total={total}
                    labelCountText={$t({ id: 'schoolDays' }).toLowerCase()}
                  />
                </Box>
              )}
              showPercents={showPercents}
            />
          </>
        )}
      </Stack>

      <Table
        columns={columns}
        rows={rows}
        isEmpty={!isLoading && !entries.length}
        isLoading={isLoading}
        loaderColor="primary.main"
      />

      {hasNextPage && (
        <Stack py={5}>
          <div ref={loaderRef} />
          <Loading />
        </Stack>
      )}

      {!isLoading &&
        !entries.length &&
        (hasFiltersApplied ? (
          <MainGridNoResultsStub textId="attendance-NoMatches" />
        ) : (
          <NoListItemsStub
            titleText={<FormattedMessage id="attendance-NoRegistersExist-title" />}
            subTitleText={<FormattedMessage id="attendance-NoRegistersExist-subtitle" />}
            buttonText={<FormattedMessage id="attendance-NewRegister" />}
            onButtonClick={handleAddButtonClick}
            type="attendance"
          />
        ))}
    </Stack>
  );
};
