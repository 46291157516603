import { Stack, Typography } from '@mui/material';
import {
  ApiError,
  CustomField,
  CustomFieldValue,
  getCustomFieldsValuesFoRelation,
  setCustomFieldValueForRelation,
} from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { CUSTOM_FIELDS_BOOLEAN_OPTIONS } from '@schooly/constants';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import { CheckboxArray } from '../../../../components/ui/Input';
import { useProfile } from '../../../../context/profile/useProfile';
import IntlError from '../../../../utils/intlError';
import { PropertyModal } from '../PropertyModal';

export interface CustomFieldBooleanModalProps {
  onClose: () => void;
  isOpen: boolean;
  field: CustomField;
  value?: CustomFieldValue;
}

interface CustomFieldFormBooleanData {
  value?: number;
}

export function getCustomFieldBooleanValue(data: CustomFieldValue) {
  if ('boolean' === typeof data.value) {
    return data.value ? 1 : 0;
  }

  return undefined;
}

export const CustomFieldBooleanModal: FC<CustomFieldBooleanModalProps> = ({
  isOpen,
  onClose,
  field,
  value,
}) => {
  const { showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);

  const { user, schoolMembership, actions } = useProfile();

  const defaultValue = value && getCustomFieldBooleanValue(value);

  const form = useForm<CustomFieldFormBooleanData>({
    mode: 'onChange',
    defaultValues: {
      value: defaultValue,
    },
  });

  const {
    reset,
    formState: { isValid },
    watch,
  } = form;

  const handleSubmit: SubmitHandler<CustomFieldFormBooleanData> = useCallback(
    async ({ value }) => {
      if (!schoolMembership) {
        return;
      }

      setIsUpdating(true);

      try {
        const res = await setCustomFieldValueForRelation(
          schoolMembership.relation_id,
          field,
          value,
        );

        if (!res) return;

        const newCustomFieldValues = await getCustomFieldsValuesFoRelation(
          schoolMembership.relation_id,
        );
        actions.setCustomFieldValues(newCustomFieldValues);
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, field, onClose, schoolMembership, showError],
  );

  useEffect(() => {
    reset({ value: defaultValue });
  }, [reset, defaultValue]);

  const formValue = !!watch('value');

  const disabled = typeof defaultValue === 'undefined' && !formValue;

  return (
    <PropertyModal
      isOpen={isOpen}
      user={user}
      submitDisabled={!isValid || disabled}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <Stack gap={2}>
        <Typography variant="h3">{field.label}</Typography>
        <CheckboxArray options={CUSTOM_FIELDS_BOOLEAN_OPTIONS} name="value" withoutUncheck />
      </Stack>
    </PropertyModal>
  );
};
