import { ConductEntry } from '@schooly/api';
import { SchoolYear } from '@schooly/api';
import { createContext, Dispatch, SetStateAction } from 'react';

import { UsePagedApiResourceWithFilter } from '../../hooks/usePagedApiResourceWithFilter';

export interface ConductForRelationContextProps
  extends UsePagedApiResourceWithFilter<ConductEntry> {
  entries?: ConductEntry[];
  groupedEntries?: ConductEntry[][];
  entriesByMonth: Record<string, ConductEntry[]>;
  schoolYearsFetching: boolean;
  schoolYear?: SchoolYear;
  setSchoolYear: Dispatch<SetStateAction<SchoolYear | undefined>>;
  setSchoolYearId: (schoolYearId?: string) => void;
  setEntryComment: (entryId: string, relationId?: string, comment?: string) => void;
  filter: string;
  setFilter: Dispatch<SetStateAction<string>>;
  canView: boolean;
  canEdit: boolean;
  pendingEntriesId: string[];
}

export const ConductForRelationContext = createContext<ConductForRelationContextProps>({
  entries: undefined,
  groupedEntries: undefined,
  entriesByMonth: {},
  schoolYearsFetching: false,
  schoolYear: undefined,
  setSchoolYear: () => {},
  setSchoolYearId: () => {},
  setEntryComment: () => {},
  filter: '',
  setFilter: () => {},
  canView: false,
  canEdit: false,
  pendingEntriesId: [],

  // UsePagedApiResourceWithFilter
  canShowMore: false,
  count: 0,
  displayedList: undefined,
  error: undefined,
  hasNextPage: false,
  isFetching: false,
  isSearching: false,
  pageNumber: 0,
  totalCount: 0,
  handleLocalItemDelete: () => {},
  handleShowMore: () => {},
  loadResource: () => {},
});

ConductForRelationContext.displayName = 'ConductForRelationContext';
