import { Icon, IconButton, Menu, Stack, Typography } from '@mui/material';
import { FilterValue, SelectOption } from '@schooly/api';
import { CheckIcon, DropdownIcon } from '@schooly/style';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { MenuItem } from 'apps/web/src/components/common/ContextMenu/ContextMenuItem.styled';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { BaseFormInputProps } from 'apps/web/src/components/ui/Input/utils';
import { MouseEvent, useRef, useState } from 'react';
import { Controller, ControllerProps, FieldValues, useFormContext } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

type PickedInputProps = Pick<React.InputHTMLAttributes<HTMLInputElement>, 'defaultValue'>;

interface FormTagSelectProps<T>
  extends Omit<ControllerProps<FieldValues>, 'name' | 'render' | 'defaultValue'>,
    BaseFormInputProps,
    PickedInputProps {
  options: SelectOption<T>[];
}

export const FormTagSelect = <T extends any = FilterValue>({
  options,
  labelTextId,
  name,
  ...props
}: FormTagSelectProps<T>) => {
  const { control, watch } = useFormContext();
  const { $t } = useIntl();
  const value = watch(name);
  const tagRef = useRef<HTMLDivElement>(null);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const open = Boolean(anchorEl);

  const onTagClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const onMenuClick = () => {
    handleClose();
  };
  return (
    <Controller
      {...props}
      control={control}
      name={name}
      render={({ field }) => {
        const name =
          typeof value === 'number'
            ? options.find((o) => o.value === value)?.labelTextId
            : $t({ id: 'relationship-SelectRelationship' });

        return (
          <>
            <Stack
              onClick={onTagClick}
              direction="row"
              className="FormTagSelect"
              sx={(theme) => ({
                display: 'inline-flex',
                width: 'min-content',
                border: theme.mixins.borderValue(),
                borderRadius: theme.shape.borderRadiusSm,
                borderColor: open ? theme.palette.common.light3 : undefined,
                padding: theme.spacing(0, 1),
                alignItems: 'center',
                gap: theme.spacing(0.5),
                whiteSpace: 'nowrap',
                justifyContent: 'center',
                cursor: 'pointer',
                color: theme.palette.common.grey2,
              })}
              ref={tagRef}
            >
              <Typography
                sx={(theme) => ({
                  color: open ? theme.palette.primary.main : theme.palette.common.grey2,
                })}
              >
                {name && <FormattedMessage id={name} />}
              </Typography>

              <IconButton
                sx={(theme) => ({
                  width: theme.spacing(1),
                  height: theme.spacing(0.5),
                  transition: theme.transitions.create('all', {
                    duration: theme.transitions.duration.short,
                    easing: theme.transitions.easing.sharp,
                  }),
                  color: open ? theme.palette.primary.main : theme.palette.common.grey,
                  rotate: open ? '180deg' : 0,
                })}
              >
                <DropdownIcon />
              </IconButton>
            </Stack>
            <Menu
              anchorEl={anchorEl}
              open={open}
              onClose={(e) => {
                handleClose();
              }}
              sx={(theme) => ({
                '.MuiPaper-root': {
                  border: theme.mixins.borderValue(),
                  borderColor: theme.palette.common.light3,
                  // TODO move boxShadow  property to styles
                  boxShadow: '0px 20px 40px rgba(37, 39, 90, 0.1)',
                },

                my: 0.5,
                '& .MuiList-root': {
                  padding: 0,
                  my: 1,
                },
                '& .MuiMenuItem-root': {
                  width: '173px',
                  height: '24px',
                  my: 0,
                  '&:hover': {
                    '& .MuiTypography-body1': {
                      color: 'primary.main',
                    },
                  },
                },
              })}
            >
              {options.map((option) => (
                <MenuItem
                  onClick={() => {
                    if (option.value === value) {
                      field.onChange(undefined);
                    } else {
                      field.onChange(option.value);
                    }
                    onMenuClick();
                  }}
                  key={option.labelTextId}
                >
                  <Stack
                    pl={1}
                    direction="row"
                    flex={1}
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography color="text.primary">
                      {option.labelTextId && <FormattedMessage id={option.labelTextId} />}
                    </Typography>

                    {value === option.value && (
                      <Icon color="primary">
                        <CheckIcon />
                      </Icon>
                    )}
                  </Stack>
                </MenuItem>
              ))}
            </Menu>
          </>
        );
      }}
    />
  );
};
