import { PayableFeeStatus, WithName } from '@schooly/api';
import { theme } from '@schooly/style';

export const payableFeeStatusColor: Record<PayableFeeStatus, string> = {
  paid: theme.palette.success.main,
  unpaid: theme.palette.common.orange,
  partially_paid: theme.palette.common.coral,
  overdue: theme.palette.error.main,
  upcoming: theme.palette.primary.main,
  voided: theme.palette.common.grey,
  cancelled: theme.palette.common.grey,
};

export function getStudentName<T extends WithName>(student: T) {
  return student.known_as || student.given_name || '';
}
