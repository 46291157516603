import { Box, Icon, Stack, Typography } from '@mui/material';
import { Company } from '@schooly/api';
import { DoneIcon, TypographyWithOverflowHint } from '@schooly/style';
import { getUserAddress } from '@schooly/utils/user-helpers';
import { FC } from 'react';

type CompanySelectRowProps = {
  isSelected: boolean;
  onClick: (v: string) => void;
  company: Company;
};

export const CompanySelectRow: FC<CompanySelectRowProps> = ({ isSelected, company, onClick }) => {
  return (
    <Stack
      onClick={() => onClick(company.id)}
      flexDirection="row"
      alignItems="center"
      px={1}
      gap={2}
      py={0.5}
      sx={(theme) => ({
        cursor: 'pointer',
        '&:hover': {
          backgroundColor: theme.palette.background.default,
          '& .MuiTypography-body1': {
            color: theme.palette.text.primary,
          },
          '& .MuiTypography-h3': {
            color: theme.palette.primary.main,
          },
        },
      })}
    >
      <Box flex={1}>
        <TypographyWithOverflowHint
          color={!isSelected ? 'common.grey2' : undefined}
          variant="h3"
          noWrap
        >
          {company.name}
        </TypographyWithOverflowHint>
      </Box>
      <Box whiteSpace="nowrap" width={150}>
        <Typography color="common.grey">{company.tax_number}</Typography>
      </Box>
      <Box width={180}>
        <TypographyWithOverflowHint color="common.grey">
          {getUserAddress(company)}
        </TypographyWithOverflowHint>
      </Box>

      <Icon
        sx={{
          visibility: isSelected ? 'visible' : 'hidden',
        }}
      >
        <DoneIcon />
      </Icon>
    </Stack>
  );
};
