import { Box, IconButton, Stack, Tab, Tabs } from '@mui/material';
import { CrossIcon, ModalHeader, ModalLarge } from '@schooly/style';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import HeaderFilter from 'apps/web/src/components/common/HeaderFilter/HeaderFilter';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useApplication } from 'apps/web/src/context/applications/useApplication';
import { FC, useEffect } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router';

import { WithFilters } from '../../../context/filters/WithFilters';
import { ApplicationListContent } from './ApplicationListContent';
import { ApplicationsSearchContent } from './ApplicationsSearchContent';

export const ApplicationListModal: FC = () => {
  const { setActiveTab, fetching, applicationList, activeTab, fetchApplicationList } =
    useApplication();
  const navigate = useNavigate();
  const { formatMessage } = useIntl();

  useEffect(() => {
    fetchApplicationList();
  }, [fetchApplicationList]);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  return (
    <WithFilters type="applications">
      <ModalLarge open>
        <ModalHeader active title={formatMessage({ id: 'applications-Title-plural' })}>
          <IconButton onClick={() => navigate('/students')}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <Stack direction="row" px={2.5} pt={2.5}>
          <Tabs sx={{ height: 36, minHeight: 36 }} value={activeTab} onChange={handleTabChange}>
            <Tab label={formatMessage({ id: 'applications-Status-Open' })} />
            <Tab label={formatMessage({ id: 'applications-Status-Converted' })} />
            <Tab label={formatMessage({ id: 'applications-Status-Rejected' })} />
          </Tabs>
          <Box
            sx={{
              '& .HeaderFilter, .search-wrapper': { maxWidth: '100%' },
              flexGrow: 1,
              pl: 10,
            }}
          >
            <HeaderFilter
              forceZIndex
              isModalOpen
              customSearchRenderer={(onCloseWithDelay) => (
                <ApplicationsSearchContent
                  applicationList={applicationList ?? []}
                  onClose={onCloseWithDelay}
                />
              )}
            />
          </Box>
        </Stack>
        <ApplicationListContent applicationsFetching={fetching} applications={applicationList} />
      </ModalLarge>
    </WithFilters>
  );
};
