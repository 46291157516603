import { Stack, Typography } from '@mui/material';
import {
  ApiError,
  AssessmentEntryScore,
  AssessmentForGroup,
  AssessmentMethodScore,
  upsertDeleteAssessmentEntry,
} from '@schooly/api';
import { useAuth } from '@schooly/components/authentication';
import {
  DEFAULT_NOTIFICATION_DURATION_MS,
  useNotifications,
} from '@schooly/components/notifications';
import { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { UpdateAssessmentEntryProps } from '../../../../pages/Assessments/AssessmentMarkbookModal/AssessmentMarkbookGrid';
import { FormTextFieldStyled } from '../../FormTextField/FormTextField.styled';

export interface ScoreControllerProps {
  groupId: string;
  relationId: string;
  entry?: AssessmentEntryScore;
  assessmentForGroup: AssessmentForGroup;
  method: AssessmentMethodScore;
  onSuccess?: () => void;
  focused?: boolean;
  updateAssessmentEntry?: (p: UpdateAssessmentEntryProps) => void;
}

export const ScoreController: FC<ScoreControllerProps> = ({
  groupId,
  relationId,
  assessmentForGroup,
  method,
  entry,
  onSuccess,
  focused,
  updateAssessmentEntry,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const { showNotification } = useNotifications();
  const { permissions } = useAuth();
  const [isEdit, setIsEdit] = useState(false);
  const [value, setValue] = useState(entry?.score ?? '');
  const [lastValue, setLastValue] = useState(value);
  const [error, setError] = useState<any>();
  const escPressed = useRef(false);

  const canEdit =
    method.method_id &&
    (permissions.includes('assessment_manager') || assessmentForGroup.enterable_and_my_group);
  const hasScoreOutOf = method.score_out_of != null;

  const handleFocus = useCallback(() => {
    if (focused === false) return;
    setIsEdit(true);
    const inputElement = inputRef.current?.querySelector('input');
    inputElement?.focus();
  }, [focused]);

  const handleBlur = useCallback(async () => {
    setError(undefined);
    setIsEdit(false);

    try {
      const currValue = value;
      if (lastValue !== value && !escPressed.current) {
        setError(undefined);
        const score = typeof currValue === 'number' ? currValue : null;

        const res = await upsertDeleteAssessmentEntry(method.method_id!, relationId, {
          group_id: groupId,
          score,
        });

        if (method.method_id) {
          updateAssessmentEntry?.({
            entry: res.assessment_entry,
            assessmentId: assessmentForGroup.id,
            methodId: method.method_id,
            studentId: relationId,
          });
        }
        setLastValue(currValue);
        onSuccess?.();
      }
      escPressed.current = false;
    } catch (err) {
      console.error(err);

      setError(err);
      showNotification({
        message: (err as ApiError)?.reason,
        type: 'error',
      });

      setTimeout(() => {
        setError(undefined);
        setValue(lastValue);
      }, DEFAULT_NOTIFICATION_DURATION_MS);
    }
  }, [
    value,
    lastValue,
    method.method_id,
    relationId,
    groupId,
    updateAssessmentEntry,
    assessmentForGroup.id,
    onSuccess,
    showNotification,
  ]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setError(undefined);

    const newValue = event.target.value;

    if (newValue === '' || newValue === '-' || /^\d*$/.test(newValue)) {
      setValue(newValue === '' || newValue === '-' ? '' : parseInt(newValue) ?? '');
    }
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      let inputElement;

      switch (event.code) {
        case 'Enter':
          inputElement = inputRef.current?.querySelector('input');
          inputElement?.blur();
          break;
        case 'Escape':
          escPressed.current = true;
          setValue(lastValue);
          inputElement = inputRef.current?.querySelector('input');
          inputElement?.blur();
      }
    },
    [lastValue],
  );

  const isEmpty = value === '';

  const backgroundColor = useMemo(() => {
    if (!isEdit && error) return 'error.superLight';
    if (isEdit) return 'background.paper';
  }, [error, isEdit]);

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      if (canEdit) {
        handleFocus();
      }
      if (focused) {
        e.stopPropagation();
      }
    },
    [canEdit, focused, handleFocus],
  );

  useEffect(() => {
    if (focused) {
      handleFocus();
    }
  }, [focused, handleFocus]);

  return (
    <Stack
      onClick={clickHandler}
      sx={{
        backgroundColor: backgroundColor,
      }}
    >
      {method.method_id && (
        <Stack
          width="100%"
          height={!canEdit ? 44 : undefined}
          direction="row"
          alignItems="center"
          justifyContent={!isEmpty && hasScoreOutOf ? 'flex-end' : 'center'}
          pr={hasScoreOutOf && !isEdit && !isEmpty ? 7 : 0}
          sx={(theme) => ({
            position: 'relative',
            //Active state of the cell
            border: isEdit ? theme.mixins.borderControlValue() : 'none',
            m: isEdit ? '-1px' : 0,
            width: isEdit ? 'calc(100% + 2px)' : '100%',
            zIndex: isEdit ? 2 : undefined,
          })}
        >
          {hasScoreOutOf && (!isEmpty || isEdit) && (
            <Typography
              variant="caption"
              color={!isEdit && error ? 'error.main' : 'text.secondary'}
              sx={{
                position: 'absolute',
                zIndex: 1,
                left: 56,
                top: 17,
                '.GridRow:hover &, .GridRowItem:hover &, .MuiDataGrid-row.Mui-hovered &': {
                  color: !error ? 'primary.main' : 'error.main',
                },
              }}
            >
              /{method.score_out_of}
            </Typography>
          )}
          {canEdit && (
            <FormTextFieldStyled
              variant="standard"
              sx={(theme) => ({
                width: isEdit ? '100%' : 0,
                textAlign: hasScoreOutOf ? 'right' : 'center',
                paddingRight: hasScoreOutOf && isEdit ? theme.spacing(7) : 0,
                '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
                  border: 'none !important',
                },
                '& .MuiInput-input': {
                  textAlign: hasScoreOutOf ? 'right' : 'center',
                  ...theme.typography.h3,
                  height: '44px !important',
                  margin: '0 !important',
                },
              })}
              ref={inputRef}
              onBlur={handleBlur}
              onFocus={handleFocus}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              value={value}
            />
          )}
          {!isEdit && (
            <>
              {isEmpty ? (
                <Typography
                  variant="h3"
                  display="inline"
                  color={error ? 'error.main' : 'text.primary'}
                  sx={{
                    '.GridRow:hover &, .GridRowItem:hover &, .MuiDataGrid-row.Mui-hovered &': {
                      color: error ? 'error.main' : 'primary.main',
                    },
                  }}
                >
                  -
                </Typography>
              ) : (
                <Typography
                  variant="h3"
                  display="inline-block"
                  color={error ? 'error.main' : undefined}
                  sx={{
                    textOverflow: 'ellipsis',
                    overflowX: 'hidden',
                    maxWidth: hasScoreOutOf ? 45 : 90,
                  }}
                >
                  {value}
                </Typography>
              )}
            </>
          )}
        </Stack>
      )}
    </Stack>
  );
};
