import { Stack, Typography } from '@mui/material';
import { FormTextFieldStyled } from '@schooly/components/form-text-field';
import { FC, useCallback, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

export interface ProductPriceInputProps {
  currency: string;
  value: number | undefined;
  onChange: (v: number | undefined) => void;
  onFocus?: () => void;
  onBlur?: () => void;
  error?: boolean;
}

export const ProductPriceInput: FC<ProductPriceInputProps> = ({
  error,
  value,
  currency,
  onChange,
  onBlur,
  onFocus,
}) => {
  const { $t } = useIntl();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isEdit, setIsEdit] = useState(false);
  const [lastValue, setLastValue] = useState(value);
  const escPressed = useRef(false);

  const handleFocus = useCallback(() => {
    setIsEdit(true);
    onFocus?.();
    const inputElement = inputRef.current?.querySelector('input');
    inputElement?.focus();
  }, [onFocus]);

  const handleBlur = useCallback(async () => {
    setIsEdit(false);
    onBlur?.();
  }, [onBlur]);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;

    if (newValue === '' || /^\d*$/.test(newValue)) {
      const resultValue = newValue === '' ? undefined : Math.abs(parseInt(newValue)) ?? undefined;

      onChange(resultValue);
      setLastValue(resultValue);
    }
  };

  const handleKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLInputElement>) => {
      let inputElement;

      switch (event.code) {
        case 'Enter':
          inputElement = inputRef.current?.querySelector('input');
          inputElement?.blur();
          break;
        case 'Escape':
          escPressed.current = true;
          onChange(lastValue);
          inputElement = inputRef.current?.querySelector('input');
          inputElement?.blur();
      }
    },
    [lastValue, onChange],
  );

  const isEmpty = value === undefined;

  const backgroundColor = useMemo(() => {
    if (isEdit) return 'background.paper';
  }, [isEdit]);

  const clickHandler = useCallback(
    (e: React.MouseEvent) => {
      handleFocus();
    },
    [handleFocus],
  );

  return (
    <Stack
      onClick={clickHandler}
      sx={{
        backgroundColor: backgroundColor,
        height: '100%',
      }}
    >
      <Stack
        width="100%"
        direction="row"
        flex={1}
        alignItems="center"
        sx={(theme) => ({
          position: 'relative',
          border: error
            ? `1px solid ${theme.palette.error.main}`
            : isEdit
            ? theme.mixins.borderControlValue()
            : 'none',
          m: isEdit || error ? '-1px' : 0,
          width: isEdit || error ? 'calc(100% + 2px)' : '100%',
          zIndex: isEdit ? 2 : undefined,
          'input[type=number]': {
            '-moz-appearance': 'textfield',
          },
          'input[type=number]::-webkit-outer-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
          'input[type=number]::-webkit-inner-spin-button': {
            '-webkit-appearance': 'none',
            margin: 0,
          },
        })}
      >
        <FormTextFieldStyled
          variant="standard"
          sx={(theme) => ({
            width: '100%',

            paddingLeft: theme.spacing(isEmpty ? 1 : 5.25),
            paddingRight: theme.spacing(1),
            '& .MuiInputBase-root::before, & .MuiInputBase-root::after': {
              border: 'none !important',
            },
            '& .MuiInput-input': {
              ...theme.typography.h3,

              minHeight: '44px !important',
              height: '100%',
              margin: '0 !important',
            },
          })}
          ref={inputRef}
          onBlur={handleBlur}
          onFocus={handleFocus}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          type="number"
          placeholder={$t({ id: 'products-AddPrice' })}
          value={value}
        />
        {!isEmpty && (
          <Typography
            variant="h3"
            color="common.grey"
            sx={(theme) => ({
              pointerEvents: 'none',
              position: 'absolute',
              left: theme.spacing(1),
            })}
          >
            {currency}
          </Typography>
        )}
      </Stack>
    </Stack>
  );
};
