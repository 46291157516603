import {
  CustomFieldValue,
  Guardian,
  RelationGroup,
  SchoolRelation,
  SchoolUserRelationInBaseUser,
  SchoolUserType,
  SchoolYear,
  StaffRegistration,
  StudentRegistration,
  SuggestedChange,
  SyncUser,
  UploadFile,
  UserType,
  WithRole,
} from '@schooly/api';
import { createContext } from 'react';

import { ModalSidebarItem } from '../../components/ui/ModalSidebar';
import { UserContexts } from '../../constants/userContexts';
import { ProfileModalMode } from './helpers';

export const CONTEXT_NAME = 'ProfileContext';

export interface ProfileSchoolRelation extends SchoolRelation, WithRole {
  userType: SchoolUserType;
}

export interface ProfileContextState {
  userContext: UserContexts;
  user?: SyncUser;
  userType?: UserType;
  allUserTypes?: UserType[];
  schoolMembership?: SchoolUserRelationInBaseUser;
  registrations?: (StudentRegistration | StaffRegistration)[];
  groups?: RelationGroup[];
  groupsSchoolYear?: SchoolYear;
  schoolRelations?: ProfileSchoolRelation[];
  customFieldValues?: CustomFieldValue[];
}

export interface ProfileContextAction {
  titleTextId: string;
  handler: () => void;
}

export interface ProfileContextProps extends ProfileContextState {
  mode: ProfileModalMode;
  contextActions: ProfileContextAction[];
  suggestedChanges: SuggestedChange[];
  tabs: ModalSidebarItem<ProfileModalMode>[];
  studentTabs: ModalSidebarItem<ProfileModalMode>[];
  parentTabs: ModalSidebarItem<ProfileModalMode>[];
  staffTabs: ModalSidebarItem<ProfileModalMode>[];
  profileTabs: ModalSidebarItem<ProfileModalMode>[];
  isFetching: boolean;
  isMembershipFetched: boolean;
  isUpdating: boolean;
  isSchoolUser: boolean;
  isSchoolContext: boolean;
  isIndividualContext: boolean;
  showLoader: boolean;
  hasAccess: boolean;
  hasEditingPermission: boolean;
  hasViewerPermission: boolean;
  canEditProfile: boolean;
  canEditProfilePicture: boolean;
  isChildOfCurrentUser: boolean;
  isContextMenuAvailable: boolean;
  isDeleteModalOpen: boolean;
  isPasswordChangeModalOpen: boolean;
  isCloseAccountModalOpen: boolean;

  actions: {
    handleClose: () => void;
    handleSelectUserType: (newUserType: UserType) => void;
    handleMembershipUpdate: () => Promise<void>;
    handleUserUpdate: () => Promise<void>;
    handleUserDelete: () => void;
    setMode: (mode: ProfileModalMode) => void;
    setUser: (user?: SyncUser) => void;
    setUserType: (userType?: UserType) => void;
    setUserContext: (userContext: UserContexts) => void;
    setSchoolMembership: (schoolMembership?: SchoolUserRelationInBaseUser) => void;
    setCustomFieldValues: (customFieldValues?: CustomFieldValue[]) => void;
    setRegistrations: (registrations?: (StudentRegistration | StaffRegistration)[]) => void;
    setGroups: (groups: RelationGroup[]) => void;
    setGroupsSchoolYear: (schoolYear?: SchoolYear) => void;
    updateProfile: (update: Partial<SyncUser>) => Promise<SyncUser | undefined>;
    updateStaffProfile: (update: Partial<Guardian>) => void;
    request: () => void;
    requestRegistrations: () => void;
    updateAvatar: (avatar: UploadFile) => void;
    showDeleteModal: () => void;
    hideDeleteModal: () => void;
    showPasswordChangeModal: () => void;
    hidePasswordChangeModal: () => void;
    showCloseAccountModal: () => void;
    hideCloseAccountModal: () => void;
    handleSchoolRoleChange: (relationData: ProfileSchoolRelation) => void;
    resetState: () => void;
  };
}

export const getInitialState = (): ProfileContextState => ({
  userContext: UserContexts.School,
  user: undefined,
  userType: undefined,
  allUserTypes: undefined,
  schoolMembership: undefined,
  registrations: [],
  groups: [],
  groupsSchoolYear: undefined,
  schoolRelations: undefined,
  customFieldValues: undefined,
});

export const ProfileContext = createContext<ProfileContextProps>({
  ...getInitialState(),
  mode: ProfileModalMode.About,
  contextActions: [],
  suggestedChanges: [],
  tabs: [],
  studentTabs: [],
  parentTabs: [],
  staffTabs: [],
  profileTabs: [],
  isFetching: false,
  isMembershipFetched: false,
  isUpdating: false,
  isSchoolUser: false,
  isSchoolContext: false,
  isIndividualContext: false,
  showLoader: false,
  hasAccess: false,
  hasEditingPermission: false,
  hasViewerPermission: false,
  canEditProfile: false,
  canEditProfilePicture: false,
  isChildOfCurrentUser: false,
  isContextMenuAvailable: false,
  isDeleteModalOpen: false,
  isPasswordChangeModalOpen: false,
  isCloseAccountModalOpen: false,

  actions: {
    handleClose: () => {},
    handleSelectUserType: () => {},
    handleMembershipUpdate: async () => {},
    handleUserUpdate: async () => {},
    handleUserDelete: () => {},
    setMode: () => {},
    setUser: () => {},
    resetState: () => {},
    setUserType: () => {},
    setUserContext: () => {},
    setSchoolMembership: () => {},
    setCustomFieldValues: () => {},
    setRegistrations: () => {},
    setGroups: () => {},
    setGroupsSchoolYear: () => {},
    request: () => {},
    requestRegistrations: () => {},
    updateProfile: async () => undefined,
    updateStaffProfile: () => {},
    updateAvatar: () => {},
    showDeleteModal: () => {},
    hideDeleteModal: () => {},
    showPasswordChangeModal: () => {},
    hidePasswordChangeModal: () => {},
    showCloseAccountModal: () => {},
    hideCloseAccountModal: () => {},
    handleSchoolRoleChange: () => {},
  },
});

ProfileContext.displayName = CONTEXT_NAME;
