import { Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ControlTextField } from '@schooly/components/form-text-field';
import { CheckIcon, CrossIcon, ModalHeader } from '@schooly/style';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { FormCheckbox } from 'apps/web/src/components/uikit-components/FormCheckbox/FormCheckbox';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { RejectApplicationForm } from 'apps/web/src/context/applications/ApplicationsContext';
import { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form-lts';
import { FormattedMessage, useIntl } from 'react-intl';

import {
  ModalConfirm,
  ModalContent,
  ModalFooter,
  ModalMain,
} from '../../../components/uikit-components/Modal/Modal.styled';

interface ApplicationRejectModalProps {
  onClose: () => void;
  onReject: (data: RejectApplicationForm) => void;
  open: boolean;
}

export const ApplicationRejectModal: FC<ApplicationRejectModalProps> = ({
  onClose,
  onReject,
  open,
}) => {
  const { $t } = useIntl();
  const form = useForm<RejectApplicationForm>();
  const required = Boolean(form.watch('share_with_parent'));

  return (
    <ModalConfirm open={open} fullWidth>
      <FormProvider {...form}>
        <form onSubmit={form.handleSubmit(onReject)}>
          <ModalHeader
            multiline
            title={$t({ id: 'applications-RejectReason' })}
            withBorderBottom={false}
            active
            sx={{
              pb: 0,
            }}
          >
            <IconButton onClick={onClose}>
              <CrossIcon />
            </IconButton>
          </ModalHeader>
          <ModalMain>
            <ModalContent
              active
              flat
              sx={{
                pt: 1.5,
                pb: 0,
              }}
            >
              <Typography variant="h3" sx={{ px: 2.5 }}>
                <FormattedMessage id="applications-RejectNotifyDescription" />
              </Typography>

              <Box sx={{ pl: 1, pt: 1 }}>
                <FormCheckbox
                  withBorder={false}
                  name="share_with_parent"
                  labelTextId="applications-RejectNotifyParent"
                />
              </Box>
              <Box sx={{ py: 1, px: 2.5 }}>
                <ControlTextField
                  name="rejection_reason"
                  control={form.control}
                  label={$t({ id: 'applications-RejectNotifyText' })}
                  fullWidth
                  canClear
                  multiline
                  rows={3}
                  rules={{ required }}
                />
              </Box>
            </ModalContent>
          </ModalMain>
          <ModalFooter withBorderTop={false} active>
            <Stack direction="row" gap={1}>
              <Button variant="outlined" onClick={onClose}>
                <FormattedMessage id="action-Cancel" />
              </Button>
              <Button type="submit" endIcon={<CheckIcon />}>
                <FormattedMessage id="applications-Action-Reject" />
              </Button>
            </Stack>
          </ModalFooter>
        </form>
      </FormProvider>
    </ModalConfirm>
  );
};
