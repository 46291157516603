import { IconButton, Stack, Tab, Tabs, Typography } from '@mui/material';
import { Box } from '@mui/system';
import {
  DEFAULT_DATE_FORMAT_FNS,
  FilterKeys,
  GetParentsQueryFilters,
  GetStaffQueryFilters,
  ParentSearchResult,
  SORT_DIRECTION,
  StaffSearchResult,
  StatusUserRelation,
  useGetParentsQuery,
  useGetStaffQuery,
} from '@schooly/api';
import { CreateCustomField, CreateParentForm } from '@schooly/components/applications';
import {
  getCombinedRowsFromParentSearchResults,
  getCombinedRowsFromSearchResults,
  PageHeaderSearchInput,
} from '@schooly/components/filters';
import { CustomFieldBooleanValues } from '@schooly/constants';
import { useInfiniteScroll } from '@schooly/hooks/use-infinite-scroll';
import { Loading, ModalContent, ModalMain } from '@schooly/style';
import { newDateTimezoneOffset } from '@schooly/utils/date';
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { useCustomFields } from 'apps/web/src/hooks/useCustomFields';
import { format, startOfDay } from 'date-fns';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';

import { NoSearchResultsFound } from '../../../../components/common/NoSearchResultsFound/NoSearchResultsFound';
import { CrossIcon } from '../../../../components/ui/Icons';
import { ModalSmall } from '../../../../components/uikit-components/Modal/Modal.styled';
import { ModalFooterWithActions } from '../../../../components/uikit-components/Modal/ModalFooterWithActions';
import { ModalHeader } from '../../../../components/uikit-components/Modal/ModalHeader';
import { useSchool } from '../../../../hooks/useSchool';
import { ParentsList } from './ParentsList';
import { StaffList } from './StaffList';

export const LEFT_COL_WIDTH = 260;
export const RIGHT_COL_WIDTH = 40;

export const PAGE_SIZE = 50;

export enum ExistingAdultsTab {
  Parents,
  Staff,
}

export interface AddParentModalProps {
  onClose: () => void;
  addedApplicationParents: CreateParentForm[];
  setApplicationParents: (p: CreateParentForm[]) => void;
}

export const AddParentModal: React.FC<AddParentModalProps> = ({
  onClose,
  addedApplicationParents,
  setApplicationParents,
}) => {
  const { $t } = useIntl();
  const { schoolId = '' } = useSchool();
  const { applicationAdultCustomFields } = useCustomFields({ refetchOnMount: 'always' });

  const [activeTab, setActiveTab] = useState(ExistingAdultsTab.Parents);
  const isParentsTabActive = activeTab === ExistingAdultsTab.Parents;

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const commonFilters: Record<FilterKeys.Date, [string]> = useMemo(
    () => ({
      [FilterKeys.Date]: [format(startOfDay(newDateTimezoneOffset()), DEFAULT_DATE_FORMAT_FNS)],
    }),
    [],
  );
  const parentsFilters: GetParentsQueryFilters = commonFilters;
  const staffFilters: GetStaffQueryFilters = commonFilters;

  const {
    data: parentsList,
    hasNextPage: parentsHasNextPage,
    isFetchingNextPage: fetchingNextParentsPage,
    isLoading: parentsLoading,
    params: parentsParams,
    setParams: setParentsParams,
    fetchNextPage: fetchNextParentsPage,
  } = useGetParentsQuery(
    {
      query: '',
      schoolId,
      filters: parentsFilters,
      pageSize: PAGE_SIZE,
      sort: { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
      showEditable: true,
    },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );

  const {
    data: staffList,
    isLoading: staffLoading,
    params: staffParams,
    hasNextPage: staffHasNextPage,
    isFetchingNextPage: fetchingNextStaffPage,
    setParams: setStaffParams,
    fetchNextPage: fetchNextStaffPage,
  } = useGetStaffQuery(
    {
      query: '',
      schoolId,
      pageSize: PAGE_SIZE,
      filters: staffFilters,
      sort: { columnTextId: 'last_name', direction: SORT_DIRECTION.ASC },
    },
    { refetchOnMount: 'always', enabled: !!schoolId },
  );

  const isFetchingNextPage = fetchingNextParentsPage || fetchingNextStaffPage;
  const isLoading = (isParentsTabActive && parentsLoading) || (!isParentsTabActive && staffLoading);
  const fetchNextPage = isParentsTabActive ? fetchNextParentsPage : fetchNextStaffPage;
  const hasNextPage = isParentsTabActive ? parentsHasNextPage : staffHasNextPage;
  const params = isParentsTabActive ? parentsParams : staffParams;

  const contentRef = useRef<HTMLDivElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const loaderRef = useInfiniteScroll(isLoading, fetchNextPage, hasNextPage);

  const staffEntries = useMemo(() => {
    return getCombinedRowsFromSearchResults(
      staffList?.pages.reduce(
        (prev, curr) => [...prev, ...curr.results],
        [] as StaffSearchResult[],
      ) ?? [],
    );
  }, [staffList?.pages]);

  const parentsEntries = useMemo(() => {
    return getCombinedRowsFromParentSearchResults(
      parentsList?.pages.reduce<ParentSearchResult[]>(
        (prev, curr) => [...prev, ...curr.results],
        [],
      ) ?? [],
    );
  }, [parentsList?.pages]);

  const emptyParentsList = isParentsTabActive && !parentsEntries.length && !parentsLoading;
  const emptyStaffList = !isParentsTabActive && !staffEntries.length && !staffLoading;

  const handleSetFiltersQuery = useCallback(
    (query: string) => {
      isParentsTabActive
        ? setParentsParams((p) => ({ ...p, showEditable: true, query }))
        : setStaffParams((p) => ({ ...p, query }));
    },
    [isParentsTabActive, setParentsParams, setStaffParams],
  );
  const [selectedAdults, setSelectedAdults] = useState<CreateParentForm[]>(addedApplicationParents);

  const addAdultToSelected = useCallback(
    (adult: ParentSearchResult | StatusUserRelation) => {
      const customFields: CreateCustomField[] =
        applicationAdultCustomFields?.reduce<CreateCustomField[]>((acc, value) => {
          const field = adult.custom_fields?.find((f) => value.id === f.custom_field_id);

          return field
            ? [
                ...acc,
                {
                  ...value,
                  value: Array.isArray(field.value) ? field.value[0] : field.value,
                  checked: CustomFieldBooleanValues.Yes,
                },
              ]
            : [
                ...acc,
                { ...value, value: '', checked: CustomFieldBooleanValues.No, is_severe: false },
              ];
        }, []) ?? [];

      setSelectedAdults((prev) => [
        ...prev,
        {
          email: adult?.email ?? '',
          profile_id: adult.user_id,
          given_name: adult.given_name ?? undefined,
          last_name: adult.last_name,
          id: adult.user_id,
          title: adult.title ?? undefined,
          primary: false,
          custom_fields: customFields,
          gender: adult.gender === null ? undefined : adult.gender,
          language: adult.language === null ? undefined : adult.language,
          telephone: adult.telephone === null ? undefined : adult.telephone,
          nationalities: adult.nationalities,
          ...('profession' in adult && { profession: adult?.profession }),
        },
      ]);
    },
    [applicationAdultCustomFields],
  );

  const addedAdultsIds = useMemo(() => {
    return addedApplicationParents.reduce<string[]>(
      (acc, p) => (p.profile_id ? [...acc, p.profile_id] : acc),
      [],
    );
  }, [addedApplicationParents]);

  const selectedAdultsIds = useMemo(() => {
    return selectedAdults.reduce<string[]>(
      (acc, p) => (p.profile_id ? [...acc, p.profile_id] : acc),
      [],
    );
  }, [selectedAdults]);

  const parentsListHasChanges = useMemo(() => {
    if (selectedAdultsIds.length !== addedAdultsIds.length) {
      return true;
    }
    if (!selectedAdultsIds.every((id) => addedAdultsIds.includes(id))) return true;
    return addedAdultsIds.some((k) => !selectedAdultsIds.includes(k));
  }, [selectedAdultsIds, addedAdultsIds]);

  const onSaveClick = useCallback(() => {
    setApplicationParents(selectedAdults);
    onClose();
  }, [onClose, selectedAdults, setApplicationParents]);

  useEffect(() => {
    if (!contentRef.current || !containerRef.current) {
      return;
    }

    if (contentRef.current.clientHeight < containerRef.current.clientHeight) {
      fetchNextPage();
    }
  }, [contentRef, containerRef, parentsEntries, staffEntries, fetchNextPage]);

  const content = useMemo(() => {
    if (isLoading) {
      return <Loading />;
    }
    if (emptyStaffList || emptyParentsList) {
      return <NoSearchResultsFound />;
    }
    return (
      <>
        <Box
          ref={contentRef}
          sx={{
            overflowY: 'scroll',
            '& .MuiTableBody-root .MuiTableRow-root:hover': {
              '& .MuiTypography-root, a': {
                color: 'primary.main',
              },
              '& .MuiCheckbox-root': {
                visibility: 'visible',
              },
            },
          }}
        >
          {isParentsTabActive ? (
            <ParentsList
              entries={parentsEntries}
              addAdultToSelected={addAdultToSelected}
              selectedAdultsIds={selectedAdultsIds}
              setSelectedAdults={setSelectedAdults}
            />
          ) : (
            <StaffList
              entries={staffEntries}
              addAdultToSelected={addAdultToSelected}
              selectedAdultsIds={selectedAdultsIds}
              setSelectedAdults={setSelectedAdults}
            />
          )}
          {hasNextPage && (
            <Box py={3}>
              {isFetchingNextPage && <Loading />}
              <Box ref={loaderRef} />
            </Box>
          )}
        </Box>
      </>
    );
  }, [
    isLoading,
    emptyStaffList,
    emptyParentsList,
    isParentsTabActive,
    parentsEntries,
    selectedAdultsIds,
    staffEntries,
    hasNextPage,
    isFetchingNextPage,
    loaderRef,
    addAdultToSelected,
  ]);

  return (
    <ModalSmall onClose={onClose} open>
      <ModalHeader active title={$t({ id: 'applications-AddExisting' })}>
        <IconButton onClick={onClose}>
          <CrossIcon />
        </IconButton>
      </ModalHeader>
      <ModalMain>
        <ModalContent active>
          <Stack gap={2.5} height="100%" sx={{ maxWidth: '100%' }}>
            <Tabs value={activeTab} onChange={handleTabChange}>
              <Tab
                label={<Typography variant="h2">{$t({ id: 'userType-parent-plural' })}</Typography>}
                data-cy="parent-modal-add-parent-tab"
              />
              <Tab
                label={<Typography variant="h2">{$t({ id: 'userType-staff-plural' })}</Typography>}
                data-cy="parent-modal-add-staff-tab"
              />
            </Tabs>
            <PageHeaderSearchInput
              value={params.query || ''}
              onChangeText={handleSetFiltersQuery}
              placeholder={$t(
                { id: 'people-SearchAmongExistingType' },
                {
                  userTypePlural: $t({
                    id:
                      activeTab === ExistingAdultsTab.Parents
                        ? 'userType-parent-plural'
                        : 'userType-staff-plural',
                  }).toLowerCase(),
                },
              )}
            />

            <Box ref={containerRef} flexGrow={1} sx={{ overflowY: 'scroll' }}>
              {content}
            </Box>
          </Stack>
        </ModalContent>
      </ModalMain>
      {parentsListHasChanges && (
        <ModalFooterWithActions onSaveClick={onSaveClick} isSelection active />
      )}
    </ModalSmall>
  );
};
