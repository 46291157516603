import { ApiError, PhoneNumberCountry } from '@schooly/api';
import { StringArrayOrNull, StringOrNull } from '@schooly/api';
import { useNotifications } from '@schooly/components/notifications';
import { getCountryFromPhoneNumber, getFormattedPhoneNumber } from '@schooly/utils/phone-number';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';

import FormPhoneNumbers from '../../../components/ui/Input/FormPhoneNumbers';
import { useProfile } from '../../../context/profile/useProfile';
import IntlError from '../../../utils/intlError';
import { PropertyModal } from './PropertyModal';

interface PhoneNumberModalProps {
  isOpen: boolean;
  onClose: () => void;
}

interface IPhoneNumber {
  country: PhoneNumberCountry | undefined;
  phoneNumber: string;
}

interface IFormPhoneNumber {
  telephones: IPhoneNumber[];
}

interface InvalidData {
  telIndex: number;
  telField: 'country' | 'phoneNumber';
}

const transformPhoneNumberToString = ({ country, phoneNumber }: IPhoneNumber) =>
  (country?.code || '') + phoneNumber;

const transformStringToPhoneNumber = (
  telephone?: StringOrNull,
  other_telephones?: StringArrayOrNull,
): IPhoneNumber[] => {
  if (!telephone && !other_telephones) {
    return [];
  }

  if (!telephone) {
    return [];
  }

  const result = [telephone];

  if (other_telephones) {
    result.push(...other_telephones);
  }

  return result.map((phoneNumber) => ({
    country: getCountryFromPhoneNumber(phoneNumber),
    phoneNumber: getFormattedPhoneNumber(phoneNumber),
  }));
};

const PhoneNumberModal = ({ isOpen, onClose }: PhoneNumberModalProps) => {
  const { showNotification, showError } = useNotifications();
  const [isUpdating, setIsUpdating] = useState(false);

  const { user, actions } = useProfile();

  const initialTelephones = useMemo(
    () => transformStringToPhoneNumber(user?.telephone, user?.other_telephones),
    [user?.telephone, user?.other_telephones],
  );

  const form = useForm<IFormPhoneNumber>({
    mode: 'all',
    resolver: (values) => {
      const { telIndex, telField } = values.telephones.reduce<InvalidData>(
        (acc, tel, index) => {
          if (!tel.country && tel.phoneNumber) {
            acc.telIndex = index;
            acc.telField = 'country';
          }

          if (tel.country && !tel.phoneNumber) {
            acc.telIndex = index;
            acc.telField = 'phoneNumber';
          }

          return acc;
        },
        { telIndex: -1, telField: 'country' },
      );

      if (telIndex !== -1) {
        const messageTextId = `error-${
          telField === 'country' ? 'SelectCountryCode' : 'EnterPhoneNumber'
        }`;

        return {
          values: {},
          errors: {
            [`telephones[${telIndex}].${telField}`]: {
              type: 'invalid',
              messageTextId,
            },
          },
        };
      }

      form.clearErrors();

      return {
        values,
        errors: {},
      };
    },
    defaultValues: {
      telephones: initialTelephones,
    },
  });

  const { reset } = form;

  useEffect(() => {
    reset({ telephones: initialTelephones });
  }, [initialTelephones, reset]);

  const handleSubmit = useCallback(
    async (data: IFormPhoneNumber) => {
      try {
        setIsUpdating(true);

        const [telephone, ...other_telephones] = data.telephones
          .map(transformPhoneNumberToString)
          .filter(Boolean);

        const res = await actions.updateProfile({
          telephone: telephone || '',
          other_telephones,
        });

        if (!res) return;

        await actions.handleUserUpdate();

        showNotification({
          textId: 'confirmation-PhoneNumber',
          type: 'success',
        });
      } catch (e) {
        showError(e as ApiError | IntlError);
      } finally {
        setIsUpdating(false);
        onClose();
      }
    },
    [actions, showNotification, showError, onClose],
  );

  return (
    <PropertyModal
      isOpen={isOpen}
      user={user}
      isUpdating={isUpdating}
      onClose={onClose}
      onSubmit={handleSubmit}
      form={form}
    >
      <FormPhoneNumbers name="telephones" maxNumbers={4} />
    </PropertyModal>
  );
};

export default PhoneNumberModal;
