import {
  Card,
  CardContent,
  CardHeader,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { SchoolProperty } from '@schooly/api';
import { EditIcon, PlusIcon } from '@schooly/style';
import React, { FC, useCallback, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';

import { Counter } from '../../../../components/uikit/Counter/Counter.styled';
import { SimpleButton } from '../../../../components/uikit/SimpleButton/SimpleButton';
import {
  GridContainer,
  GridRowItem,
  GridRowName,
  GridRowStyled,
} from '../../../../components/uikit-components/Grid/Grid';

const DISPLAY_ROWS = 2;

interface SchoolGeneralDepartmentsProps {
  isLoading: boolean;
  departments: SchoolProperty[];
}

export const SchoolGeneralDepartments: FC<SchoolGeneralDepartmentsProps> = ({
  isLoading,
  departments,
}) => {
  const [expanded, setExpanded] = useState(false);

  const count = departments?.length ?? 0;
  const hasRows = count > 0;
  const canExpand = !isLoading && count > DISPLAY_ROWS;

  const rows = useMemo(
    () => departments?.slice(0, !canExpand || expanded ? undefined : DISPLAY_ROWS) ?? [],
    [canExpand, departments, expanded],
  );

  const toggleExpand = useCallback(() => {
    setExpanded((expanded) => !expanded);
  }, []);

  const counter = useMemo(() => {
    if (isLoading) {
      return (
        <Skeleton
          variant="circular"
          sx={(theme) => ({
            ml: 1,
            width: theme.spacing(2.5),
            height: theme.spacing(2.5),
          })}
        />
      );
    }
    if (hasRows) {
      return <Counter>{count}</Counter>;
    }
    return null;
  }, [count, hasRows, isLoading]);

  return (
    <Card component={Stack} sx={{ minHeight: 224 }}>
      <CardHeader
        title={
          <Stack direction="row" alignItems="center">
            <FormattedMessage id="school-tabs-Departments" />
            {counter}
          </Stack>
        }
        action={
          hasRows ? (
            <Link data-cy="settings-departments-edit" to="/settings/departments">
              <IconButton inverse>
                <EditIcon />
              </IconButton>
            </Link>
          ) : undefined
        }
      />
      <CardContent
        component={Stack}
        sx={{ flex: '1', justifyContent: 'space-between', alignItems: 'flex-start' }}
      >
        <GridContainer
          sx={{
            borderRadius: 0,
            borderBottom: (theme) =>
              !isLoading && hasRows ? theme.mixins.borderValue() : undefined,
          }}
        >
          {isLoading ? (
            <Stack>
              {new Array(3).fill(true).map((_, index) => (
                <GridRowStyled key={index} noBorder noBorderRadius>
                  <GridRowItem>
                    <GridRowName>
                      <Skeleton />
                    </GridRowName>
                  </GridRowItem>
                </GridRowStyled>
              ))}
            </Stack>
          ) : hasRows ? (
            rows.map((department) => (
              <GridRowStyled key={department.id} noBorderRadius>
                <GridRowItem>
                  <GridRowName>{department.name}</GridRowName>
                </GridRowItem>
              </GridRowStyled>
            ))
          ) : (
            <Typography variant="h3">
              <FormattedMessage id="school-tabs-Departments-NoActive" />
            </Typography>
          )}
        </GridContainer>

        {canExpand && (
          <SimpleButton sx={{ mt: 2.5 }} onClick={toggleExpand}>
            {expanded ? (
              <FormattedMessage id="action-Hide" />
            ) : (
              <FormattedMessage
                id="action-ViewMore-number"
                values={{ number: count - rows.length }}
              />
            )}
          </SimpleButton>
        )}

        {!hasRows && !isLoading && (
          <Link to="/settings/departments">
            <SimpleButton sx={{ mt: 2.5 }} startIcon={<PlusIcon />} onClick={toggleExpand}>
              <FormattedMessage id="school-tabs-Departments-AddDepartment" />
            </SimpleButton>
          </Link>
        )}
      </CardContent>
    </Card>
  );
};
