import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useMemo,
  useState,
} from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';

import { UseAnnualPlannerCellItem } from './useAnnualPlannerCell';

export const ANNUAL_PLANNER_DND_TYPE = 'ANNUAL_PLANNER_DND_TYPE';

export interface AnnualPlannerDndContextProps {
  movingItem?: UseAnnualPlannerCellItem;
  setMovingItem: Dispatch<SetStateAction<AnnualPlannerDndContextProps['movingItem']>>;
}

export const AnnualPlannerDndContext = createContext<AnnualPlannerDndContextProps>({
  movingItem: undefined,
  setMovingItem: () => {},
});

export const WithAnnualPlannerDnD: FC<PropsWithChildren> = ({ children }) => {
  const [movingItem, setMovingItem] = useState<AnnualPlannerDndContextProps['movingItem']>();

  const value = useMemo<AnnualPlannerDndContextProps>(
    () => ({
      movingItem,
      setMovingItem,
    }),
    [movingItem],
  );

  return (
    <DndProvider backend={HTML5Backend}>
      <AnnualPlannerDndContext.Provider value={value}>{children}</AnnualPlannerDndContext.Provider>
    </DndProvider>
  );
};

export const useAnnualPlannerDnD = () => {
  return useContext(AnnualPlannerDndContext);
};
