import { Autocomplete, Box, Button, IconButton, Stack, Typography } from '@mui/material';
import { ConductConnotation } from '@schooly/api';
import { DateSelect } from '@schooly/components/filters';
import {
  ArrowRightIcon,
  CheckIcon,
  CrossIcon,
  DeleteIcon,
  Loading,
  ModalSmall,
  Spin,
} from '@schooly/style';
import React, { FC, useCallback, useEffect, useMemo, useRef } from 'react';
import { FormattedMessage, MessageDescriptor, useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import AccessDenied from '../../../components/common/AccessDenied';
import PersonCardSelectable from '../../../components/common/PersonCard/PersonCardSelectable';
import { Counter } from '../../../components/uikit/Counter/Counter.styled';
import { FormTextField } from '../../../components/uikit-components/FormTextField/FormTextField';
import {
  ModalContent,
  ModalFooter,
  ModalLarge,
  ModalMain,
  ModalPanel,
} from '../../../components/uikit-components/Modal/Modal.styled';
import { ModalAccessDenied } from '../../../components/uikit-components/Modal/ModalAccessDenied';
import { ModalConfirmationDialog } from '../../../components/uikit-components/Modal/ModalConfirmationDialog';
import { ModalEmptyMainArea } from '../../../components/uikit-components/Modal/ModalEmptyMainArea';
import { ModalHeader } from '../../../components/uikit-components/Modal/ModalHeader';
import { ModalPeopleExtensionPanel } from '../../../components/uikit-components/Modal/ModalPeopleExtensionPanel';
import { useFormError } from '../../../hooks/useFormError';
import useUserCounts from '../../../hooks/useUserCounts';
import { WithRef } from '../../../hooks/useWithRef';
import { formatDateDefault } from '../../../utils/formatDate';
import { ConductConnotationIndicator } from '../ConductGrid';
import AddStudentsExternalSidebar from './AddStudentsExternalSidebar';
import { ConductCreateModalPreview } from './ConductCreateModalPreview';
import { ConductManageGrid } from './ConductManageGrid';
import { ConductTypeOption } from './ConductTypeOption';
import {
  ConductCreateModalContextState,
  ConductCreateModalMode,
  useConductCreateModal,
} from './useConductCreateModal';

// Cannot use FilterKeys.Group cuz it breaks existing filters
export const CREATE_CONDUCT_GROUP_SEARCH_PARAM = 'for_group';

const ConductCreateModal: FC = () => {
  const { $t } = useIntl();
  const navigate = useNavigate();
  const { userCounts, fetching: userCountsFetching } = useUserCounts();
  const {
    actions,
    form,
    errors,
    types,
    conductId,
    externalStudentRelationId,
    singleStudent,
    hasManagerPermission,
    ...contextState
  } = useConductCreateModal(true);

  useEffect(() => {
    if (!userCountsFetching && !userCounts?.student) {
      navigate('/conduct');
    }
  }, [navigate, userCounts?.student, userCountsFetching]);

  const containerRef = useRef<HTMLDivElement>(null);
  const conductTypes = [...(types ?? [])].sort((a, b) => a.connotation - b.connotation);

  const { renderError } = useFormError();
  const canEditConduct = true; // TODO
  const isActive =
    contextState.mode === ConductCreateModalMode.Students ||
    contextState.mode === ConductCreateModalMode.Table;

  const singleStudentContent = useMemo(
    () => (
      <ModalPanel
        ref={containerRef}
        active={contextState.mode === ConductCreateModalMode.Table}
        fullHeight
        flat
      >
        <Stack px={2.5} pt={2.5}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Stack direction="row" gap={0.25} alignItems="center">
              <Typography variant="h2">
                <FormattedMessage
                  id={
                    contextState.mode === ConductCreateModalMode.Table
                      ? 'section-Students'
                      : 'userType-student'
                  }
                />
              </Typography>
              <Counter>{contextState.selectedStudents?.length}</Counter>
            </Stack>
          </Stack>

          {contextState.mode === ConductCreateModalMode.Initial && (
            <Stack pt={2.5} pl={0.5}>
              {contextState.selectedStudents?.map((student) => (
                <PersonCardSelectable
                  key={student.relation_id}
                  user={student}
                  userType="student"
                  isListItem
                  isSingularSelected={false}
                />
              ))}
            </Stack>
          )}
        </Stack>
        {contextState.mode === ConductCreateModalMode.Table && (
          <Stack pt={2.5}>
            <ConductManageGrid
              errors={contextState.gridErrors}
              // onChange={actions.validateGrid}
            />
          </Stack>
        )}
      </ModalPanel>
    ),
    [contextState?.gridErrors, contextState?.mode, contextState?.selectedStudents],
  );

  const isDateFieldFocused = contextState.focusedField === 'date';

  const handleOpenDatePicker = useCallback(() => {
    if (isDateFieldFocused) {
      actions.clearFocusField();
    }
  }, [actions, isDateFieldFocused]);

  if (!canEditConduct) {
    return (
      <ModalSmall open onClose={actions.closeModal}>
        <ModalHeader active>
          <IconButton onClick={actions.closeModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalContent active>
          <ModalAccessDenied />
        </ModalContent>
      </ModalSmall>
    );
  }

  const ModalComponent =
    contextState.mode === ConductCreateModalMode.Table ? ModalLarge : ModalSmall;

  const renderContent = () => {
    if (!hasManagerPermission) {
      return (
        <Box>
          <AccessDenied />
        </Box>
      );
    }

    if (contextState.loading) {
      return <Loading />;
    }

    const isDateLoading = contextState.dateLoading;

    return (
      <form onSubmit={form.onSubmit(actions.submitForm)}>
        <ModalHeader
          active={contextState.mode !== ConductCreateModalMode.Students}
          title={$t({ id: 'conduct-Title' })}
        >
          <IconButton onClick={actions.closeModal}>
            <CrossIcon />
          </IconButton>
        </ModalHeader>
        <ModalPanel withBorderBottom active={contextState.mode !== ConductCreateModalMode.Students}>
          {contextState.mode === ConductCreateModalMode.Initial && (
            <Stack gap={2.5}>
              <Autocomplete
                openOnFocus
                fullWidth
                disableClearable
                value={contextState.selectedConductType}
                options={conductTypes ?? []}
                getOptionLabel={(option) => option.name}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                groupBy={(option) =>
                  option.connotation === ConductConnotation.POSITIVE
                    ? $t({ id: 'conduct-connotation-POSITIVE' }).toUpperCase()
                    : $t({ id: 'conduct-connotation-NEGATIVE' }).toUpperCase()
                }
                onChange={(e, selectedConductType) =>
                  form.set('selectedConductType', selectedConductType)
                }
                renderInput={(params) => (
                  <FormTextField
                    {...params}
                    label={$t({ id: 'conduct-ConductType' })}
                    autoFocus={contextState.focusedField === 'selectedConductType'}
                    fullWidth
                    dropdown
                    required
                    showEndAdornment={!contextState.selectedConductType}
                    startAdornment={
                      contextState.selectedConductType && (
                        <Box sx={{ ml: '13px', mr: 1 }}>
                          <ConductConnotationIndicator
                            connotation={contextState.selectedConductType?.connotation}
                          />
                        </Box>
                      )
                    }
                    error={!!errors?.selectedConductType}
                    helperText={renderError(errors?.selectedConductType)}
                  />
                )}
                renderOption={(props, option) => (
                  <li {...props}>
                    <ConductTypeOption {...option} />
                  </li>
                )}
              />

              <DateSelect
                date={contextState.date}
                onSetDate={actions.onDateChange}
                onOpen={handleOpenDatePicker}
                requiredLabel="required"
                placeholder={$t({ id: 'conduct-Date-modal' })}
                isLoading={isDateLoading}
                disabled={isDateLoading}
                opened={isDateFieldFocused || undefined}
                renderRightIcon={isDateLoading ? () => <Spin /> : undefined}
                error={
                  errors?.date
                    ? {
                        type: 'validate',
                        message: renderError(errors.date) || '',
                      }
                    : undefined
                }
              />
            </Stack>
          )}

          {isActive && (
            <ConductCreateModalPreview
              {...contextState}
              onClick={(focusedField: keyof ConductCreateModalContextState) => {
                actions.setContextState({
                  mode: ConductCreateModalMode.Initial,
                  focusedField,
                });
              }}
            />
          )}
        </ModalPanel>

        {singleStudent ? (
          singleStudentContent
        ) : (
          <ModalPeopleExtensionPanel
            titleId="section-Students"
            addActionId="groups-AddStudents"
            editActionId="groups-EditStudents"
            count={contextState.selectedStudents?.length}
            active={isActive}
            headerMode={contextState.mode === ConductCreateModalMode.Table}
            disabled={!contextState.selectedConductType || !contextState.date}
            errorId={(errors?.selectedStudents as MessageDescriptor)?.id}
            onAddClick={actions.setStateHandler('mode', ConductCreateModalMode.Students)}
            sidebarContent={
              <AddStudentsExternalSidebar
                students={contextState.selectedStudents}
                onStudentClick={actions.onStudentClick}
                date={formatDateDefault(contextState.date)}
              />
            }
            showActionIcon={
              !contextState.isEditing && contextState.mode === ConductCreateModalMode.Table
            }
            withoutGap
          >
            <ModalContent ref={containerRef} withBorderBottom active flat sx={{ pt: 0 }}>
              {!contextState.selectedStudents?.length ? (
                <ModalEmptyMainArea label={$t({ id: 'section-Students' }).toLowerCase()} />
              ) : (
                <>
                  {contextState.mode === ConductCreateModalMode.Students && (
                    <Stack sx={{ overflowY: 'auto', px: 2.5 }}>
                      {contextState.selectedStudents?.map((student) => (
                        <PersonCardSelectable
                          key={student.relation_id}
                          isStaticSelected
                          user={student}
                          userType="student"
                          onClick={() => actions.onStudentClick(student)}
                          isListItem
                          isDisabled={externalStudentRelationId === student.relation_id}
                        />
                      ))}
                    </Stack>
                  )}

                  {contextState.mode === ConductCreateModalMode.Table && (
                    <ConductManageGrid errors={contextState.gridErrors} />
                  )}
                </>
              )}
            </ModalContent>
          </ModalPeopleExtensionPanel>
        )}
        <ModalMain />

        <ModalFooter
          sx={{ justifyContent: conductId ? 'space-between' : 'flex-end' }}
          active={contextState.mode === ConductCreateModalMode.Table}
        >
          {conductId && (
            <Button
              variant="outlined"
              startIcon={contextState.deleting ? <Spin /> : <DeleteIcon />}
              disabled={contextState.deleting || contextState.saving}
              onClick={actions.deleteConduct}
            >
              <FormattedMessage id="action-Delete" />
            </Button>
          )}

          {contextState.mode !== ConductCreateModalMode.Table && (
            <Button type="submit" endIcon={<ArrowRightIcon />}>
              <FormattedMessage id="action-Continue" />
            </Button>
          )}

          {contextState.mode === ConductCreateModalMode.Table && (
            <Button
              endIcon={contextState.saving ? <Spin /> : <CheckIcon />}
              disabled={contextState.saving || contextState.deleting}
              onClick={actions.saveConduct}
            >
              <FormattedMessage id="action-Save" />
            </Button>
          )}
        </ModalFooter>
      </form>
    );
  };

  return (
    <ModalComponent open onClose={actions.closeModal}>
      <WithRef containerRef={containerRef}>{renderContent()}</WithRef>
      <ModalConfirmationDialog {...contextState.confirmationDialogProps} />
    </ModalComponent>
  );
};

export default ConductCreateModal;
