import {
  ClickAwayListener,
  debounce,
  IconButton,
  IconButtonProps,
  InputAdornment,
  Stack,
  TextFieldProps,
} from '@mui/material';
import {
  FormTextField,
  FormTextFieldStyledProps,
  InputEndAdornmentProps,
} from '@schooly/components/form-text-field';
import { useFlag } from '@schooly/hooks/use-flag';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { CrossIcon, SearchIcon } from '../../assets/assets';

type SearchProps = {
  value: string;
  onChange: (value: string) => void;
  placeholder?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  withDebounce?: boolean;
  iconProps?: IconButtonProps;
};

type ModalSearchProps = SearchProps &
  Omit<TextFieldProps, 'onChange'> &
  FormTextFieldStyledProps &
  InputEndAdornmentProps;

export const ModalSearch: FC<ModalSearchProps> = ({
  isDisabled,
  isLoading,
  value: initialValue,
  onChange,
  withDebounce,
  iconProps,
  ...rest
}) => {
  const [opened, open, close] = useFlag();
  const inputRef = useRef<HTMLInputElement>(null);
  const [value, setValue] = useState(initialValue);

  const onChangeDebounce = useMemo(
    () =>
      debounce(
        (val: string) => {
          onChange(val);
        },
        withDebounce ? 500 : 0,
      ),
    [withDebounce, onChange],
  );

  useEffect(() => {
    onChangeDebounce(value);
  }, [value, onChangeDebounce]);

  const handleClickAway = useCallback(() => {
    if (!value) close();
  }, [close, value]);

  const handleClear = useCallback(() => {
    setValue('');
    inputRef.current?.focus();
  }, []);

  useEffect(() => {
    if (opened && inputRef.current) {
      inputRef.current?.focus();
    }
  }, [opened]);

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <Stack
        data-cy={'small-search'}
        sx={{
          position: 'relative',
          height: 40,
          direction: 'row',
          justifyContent: 'center',
        }}
      >
        {opened ? (
          <FormTextField
            inputRef={inputRef}
            disabled={isLoading || isDisabled}
            value={value}
            onChange={(e) => setValue(e.target.value)}
            hideLabel
            solid
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disabled={isLoading || isDisabled} inverse onClick={handleClear}>
                    <CrossIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            showEndAdornment={true}
            sx={(theme) => ({
              maxWidth: 180,
              '&& .MuiInputBase-input': {
                padding: theme.spacing(1, 1.25),
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                width: 130,
              },
              '&&& .MuiInputAdornment-root': {
                mt: 0,
              },
            })}
            {...rest}
          />
        ) : (
          <IconButton
            sx={{
              color: 'text.primary',
            }}
            inverse
            onClick={open}
            {...iconProps}
          >
            <SearchIcon />
          </IconButton>
        )}
      </Stack>
    </ClickAwayListener>
  );
};
